import React from 'react';
import JarvislyAddressForm from './Items/JarvislyAddressForm';
import JarvislyContactForm from './Items/JarvislyContactForm';
import JarvislyCompanyForm from './Items/JarvislyCompanyForm';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************

const JarvislyCompanyIndex = props => {
  // props deconstruction ------------------------------------------------------

  // local variables -----------------------------------------------------------

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {/* COMPANY */}
      <JarvislyCompanyForm {...props} />
      {/* COMPANY */}

      {/* CONTACTS */}
      <JarvislyContactForm {...props} />
      {/* CONTACTS */}

      {/* ADDRESS */}
      <JarvislyAddressForm {...props} />
      {/* ADDRESS */}
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// EXPORT **********************************************************************
// *****************************************************************************

export default JarvislyCompanyIndex;
