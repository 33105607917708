import { Badge, Col, Row } from 'antd';
import moduleService from 'components/jarvisly-module/ModuleService';
import ChartWidget from 'components/shared-components/ChartWidget';
import GoalWidget from 'components/shared-components/GoalWidget';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatThousandsSeparator, translateX } from 'utils/helpers';
import { DeviceTag, OnOffStatusTag } from '../Components';
import ReactCardFlip from 'react-card-flip';

const IotDashboardWaterLevel = props => {
  // props deconstruction ------------------------------------------------------

  // local variables -----------------------------------------------------------
  const { direction } = useSelector(state => state.theme);
  const t = buildTranslations();

  // component states ----------------------------------------------------------
  const [devices, setDevices] = useState();
  const [series, setSeries] = useState([]);
  const [colors, setColors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isFlipped, setIsFlipped] = useState({});

  const fetchData = async () => {
    try {
      const [_devices] = await moduleService.getDashboard();
      setDevices(_devices);

      setSeries([]);
      setColors([]);
      setCategories([]);

      for (let index = 0; index < _devices.length; index++) {
        const data = _devices[index].water_level_sensor;
        const waterLevels = data;

        const extractedColors = waterLevels.map(sensor => sensor.color);

        const extractedHour = waterLevels.map(sensor => {
          const hour = sensor.last24Utc.map(entry => entry.label);

          return hour;
        });

        const extractedPercentages = waterLevels.map(sensor => {
          const percentages = sensor.last24Utc.map(entry =>
            parseFloat(entry.percentage.toFixed(2)),
          );

          return {
            name: sensor.title,
            data: percentages,
          };
        });

        setColors(extractedColors);
        setSeries(extractedPercentages);
        setCategories(extractedHour);
      }
    } catch (error) {
      console.error('Erro ao buscar dados do dashboard:', error);
    }
  };
  // hooks ---------------------------------------------------------------------

  useEffect(() => {
    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 5000);

    return () => clearInterval(intervalId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const toMeters = centimeters => (centimeters ? centimeters / 100 : 0);

  const handleClick = (e, device) => {
    e.preventDefault();
    setIsFlipped(p => ({
      ...p,
      [device._id]: !isFlipped?.[device._id],
    }));
  };

  // UI COMPONENT --------------------------------------------------------------
  const DeviceChart = ({ device }) => {
    console.log('device', device);

    return (
      <GoalWidget
        record={device}
        suffix="%"
        infoTitle={
          device?.installed ? t.additional_info : t.device_not_installed
        }
        /*        infoContent={
          !device?.installed ? (
            <div>
              <div>
                {t.channel}: <strong>{device?.channel}</strong>
              </div>
              <div>
                {t.tank_dimension}:{' '}
                <strong>{`${formatThousandsSeparator(
                  toMeters(device?.water_level_sensor?.width),
                )}m X ${formatThousandsSeparator(
                  toMeters(device?.water_level_sensor?.depth),
                )}m X ${formatThousandsSeparator(
                  toMeters(device?.water_level_sensor?.length),
                )}m (${formatThousandsSeparator(
                  toMeters(device?.water_level_sensor?.mt2),
                )}}m²)`}</strong>
              </div>
              <div>
                {t.capacity}:{' '}
                <strong>{`${formatThousandsSeparator(
                  device?.water_level_sensor?.capacity || '',
                )}m³ (${formatThousandsSeparator(
                  device?.water_level_sensor?.capacity
                    ? device?.water_level_sensor?.capacity * 1000
                    : 0,
                )}L)`}</strong>
              </div>
            </div>
          ) : (
            <div>
              <div>
                {t.channel}: <strong>{device?.channel}</strong>
              </div>
              <div>
                {t.tank_dimension}:{' '}
                <strong>{`${formatThousandsSeparator(
                  toMeters(device?.water_level_sensor?.width),
                )}m X ${formatThousandsSeparator(
                  toMeters(device?.water_level_sensor?.depth),
                )}m X ${formatThousandsSeparator(
                  toMeters(device?.water_level_sensor?.length),
                )}m`}</strong>
              </div>
              <div>
                {t.capacity}: <strong>{device?.tankWaterCapacity}L</strong>
              </div>
              <div>
                {t.current_content}:{' '}
                <strong>{device?.currentWaterVolume}L</strong>
              </div>

              <div>
                {t.last_reading}:
                <strong>
                  {device?.lastConnectionAt
                    ? dayjs(device?.lastConnectionAt).fromNow()
                    : 'n/a'}
                </strong>
              </div>
              <div>
                {t.sensor_distance}:<strong>{device?.currentValue}cm</strong>
              </div>

              <div>
                {t.limits_reached}:
                <strong>
                  Min: {device?.minReached ? `${device?.minReached}cm` : 'n/a'}{' '}
                  | Max:{' '}
                  {device?.maxReached ? `${device?.maxReached}cm` : 'n/a'}
                </strong>
              </div>

              {device?.status !== 'online' ? (
                <>
                  <div>
                    {t.last_try}:
                    {/!* <strong>
                              {dayjs(
                                device?.response.data.water_level_sensor
                                  .devices[0].lastReadingAttemptAt,
                              ).format('L LT')}
                            </strong> *!/}
                  </div>
                </>
              ) : null}
            </div>
          )
        }*/
        notFoundTitle={
          !device?.water_level_sensor?.installed
            ? t.device_not_installed.toUpperCase()
            : ''
        }
        strokeColor={device.color}
        title={
          <Badge
            className="badge-size-12"
            color={device.color || 'gray'}
            // color={
            //   device?.status === 'online'
            //     ? '#399E0D'
            //     : device?.status === 'calibrating'
            //     ? '#FFA500'
            //     : '#808080'
            // }
            text={device?.title}
          />
        }
        subtitle={
          <>
            {/* <div><span>{i?.subtitle}</span></div> */}
            <div>
              <OnOffStatusTag
                record={{ status: device?.status }}
                className="mt-3"
              />
            </div>
          </>
        }
        value={device?.waterPercentage?.toFixed(2)}
      />
    );
  };

  return (
    <>
      <Row gutter={ROW_GUTTER}>
        {/* {devices && devices?.map(device => ( */}
        <Col span={24}>
          <ChartWidget
            title={t.water_level_last_24h}
            series={series}
            xAxis={categories[0]}
            height={'300px'}
            direction={direction}
            customOptions={buildLast24hChartOptions(colors)}
          />
        </Col>
        {/* ))} */}
      </Row>

      <Row gutter={ROW_GUTTER}>
        {devices &&
          devices[0]?.water_level_sensor?.map((device, index) => (
            <Col key={device._id}>
              <ReactCardFlip
                isFlipped={isFlipped?.[device._id]}
                flipDirection="vertical"
              >
                <div onClick={e => handleClick(e, device)}>
                  <DeviceChart device={device} />
                </div>

                <div
                  onClick={e => handleClick(e, device)}
                  style={{ backgroundColor: 'red' }}
                >
                  BACK
                </div>
              </ReactCardFlip>
            </Col>
          ))}
      </Row>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTranslations() {
    return {
      additional_info: translateX('additional_info'),
      device_not_installed: translateX('device_not_installed'),
      not_installed: translateX('not_installed'),
      channel: translateX('channel'),
      tank_dimension: translateX('tank_dimension'),
      capacity: translateX('capacity'),
      current_content: translateX('current_content'),
      sensor_distance: translateX('sensor_distance'),
      limits_reached: translateX('limits_reached'),
      last_reading: translateX('last_reading'),
      last_try: translateX('last_try'),
      quantity: translateX('quantity'),
      last_value: translateX('last_value'),
      water_level_last_24h: translateX('water_level_last_24h'),
    };
  }

  function buildLast24hChartOptions(colors) {
    return {
      title: {
        margin: 16,
      },
      colors: colors,
      legend: {
        show: false,
      },
      yaxis: {
        min: 0,
        max: 110,
        labels: {
          formatter: function (value) {
            return value + '%';
          },
        },
      },
      markers: {
        size: 0,
      },
      fill: {
        type: 'gradient', // 'solid' / 'pattern' / 'image'

        gradient: {
          enabled: true,
          opacityFrom: 0.3,
          opacityTo: 0.3,
          type: 'vertical',
          // shade: 'dark',
          // shadeIntensity: 0.5,
          // gradientToColors: undefined,
          // inverseColors: true,
          // opacityFrom: 1,
          // opacityTo: 1,
          // stops: [0, 50, 100],
          // colorStops: []
        },
      },
    };
  }
};

export default IotDashboardWaterLevel;
