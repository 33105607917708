import { combineReducers } from 'redux'

import themeSlice from './slices/themeSlice'
import authSlice from './slices/authSlice'
import moduleSlice from './slices/moduleSlice'

// remove this two lines after change all to <>Slice
import auth from './slices/authSlice'
import theme from './slices/themeSlice'

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    themeSlice,
    auth,
    authSlice,
    moduleSlice,
    ...asyncReducers,
  })
  return combinedReducer(state, action)
}

export default rootReducer
