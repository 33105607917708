import { Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';
import appveerticalService from 'services/appveericalService';
import { translateX } from 'utils/helpers';

//cor da tag no campo de type do u
export const DeviceTag = props => {
  const { record } = props;
  const options = appveerticalService.buildDevicesOptions();
  const selected = record?.type
    ? options.find(d => d.value === record.type) || options[0]
    : options[0];

  return (
    <Tag
      className="text-capitalize"
      style={{ margin: 4 }}
      color={selected.color}
    >
      <span style={{ color: selected?.textColor || 'white' }}>
        {selected.text}
      </span>
    </Tag>
  );
};

//titulo do cabeçalho
export const IotDevicesFormHeaderTitle = props => {
  const { document } = props;

  return (
    <Tooltip title={translateX('device')}>
      <span>{document?.title}</span>
    </Tooltip>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const IotDevicesFormHeaderTags = props => {
  return <IotDevicesStatusTag {...props} />;
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const IotDevicesStatusTag = props => {
  const record = props?.record || props?.document;
  const dse = props?.status || record?.status; // document status in english
  const documentStatus = dse && translateX(dse);

  const overdue =
    !props?.status &&
    record?._metadata?.audit?.createdAt &&
    dayjs(record._metadata.audit.createdAt).diff(dayjs(), 'days') > 1;

  return (
    <>
      {documentStatus && (
        <Tag
          style={{ margin: 4 }}
          color={getIotDevicesStatusColor(overdue ? 'overdue' : dse)}
        >
          {documentStatus}
        </Tag>
      )}
    </>
  );
};

export const buildOnOffStatusFilter = status => {
  switch (status) {
    case 'online':
      return { value: translateX('online'), color: 'green' };
    case 'removed':
      return { value: translateX('removed'), color: 'red' };
    case 'calibrating':
      return { value: translateX('calibrating'), color: 'orange' };
    case 'offline':
      return { value: translateX('offline'), color: 'gray' };
    default:
      return { value: 'offline', color: 'gray' };
  }
};

export const OnOffStatusTag = props => {
  const { record } = props;

  const selected = buildOnOffStatusFilter(record?.status || 'offline');

  return (
    <Tag
      className={`text-capitalize ${props.className}`}
      style={{ margin: 4 }}
      color={selected.color}
    >
      <div style={{ marginTop: 2 }}>{selected.value}</div>
    </Tag>
  );
};

// INTERNAL FUNCTIONS ========================================================
// ===========================================================================
export const getIotDevicesStatusColor = status => {
  switch (status) {
    case 'calibrating':
      return 'orange';

    case 'offline':
      return 'gray';

    case 'online':
      return 'green';

    default:
      return 'gray';
  }
};
