import { EditOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import { setLocale, translateX } from 'utils/helpers';
import dayjs from 'dayjs';
import DocumentStatusTag from 'components/jarvisly-components/others-old/DocumentStatusTag';
import React from 'react';

const ProductsList = props => {
    // providers context ---------------------------------------------------------

    // props deconstruction ------------------------------------------------------
    const {
        isWorking,
        onTableChanges,
        // columnSorter,
        // columnSearch,
        // columnFilters,
        tableKey,
        resetTableSearch,
        pagination,
        dataList,
        buildRowSelection,
        showNumberOfLine,
        onClickOpen,
    } = props;

    // local variables I ---------------------------------------------------------
    const tableColumns = buildTableColumns();

    // component states ----------------------------------------------------------

    // local variables II --------------------------------------------------------

    // hooks ---------------------------------------------------------------------

    // methods -------------------------------------------------------------------

    // local variables III -------------------------------------------------------

    // UI COMPONENT --------------------------------------------------------------
    return (
        <>
            <Table
                key={tableKey}
                rowKey="_id"
                columns={tableColumns.filter(x => !x.hidden)}
                dataSource={dataList || []}
                pagination={pagination}
                onChange={onTableChanges}
                rowSelection={buildRowSelection}
                className="with-checkbox"
                resetTableSearch={resetTableSearch}
                loading={isWorking}
            />
        </>
    );

    // INTERNAL FUNCTIONS ========================================================
    // ===========================================================================
    function buildTableColumns() {
        return [
            {
                hidden: !showNumberOfLine,
                title: translateX('line'),
                width: 80,
                render: (item, record, index) => {
                    if (!pagination) return;

                    const { current, pageSize } = pagination;

                    return (
                        <div className="text-left">
                            {current * pageSize - pageSize + index + 1 || index}
                        </div>
                    );
                },
            },

            {
                title: translateX('reference'),
                dataIndex: 'reference',
                render: (_, record) => <span>{translateX(record?.reference)}</span>,
            },

            {
                title: translateX('description'),
                dataIndex: 'description',
                render: (_, record) => <span>{translateX(record?.description)}</span>,
            },

            {
                title: translateX('unit'),
                dataIndex: 'unit',
                render: (_, record) => <span>{translateX(record?.unit)}</span>,
            },

            {
                title: translateX('price'),
                dataIndex: 'price',
                render: (_, record) => <span>{translateX(record?.price)}</span>,
            },

            {
                title: translateX('status'),
                dataIndex: 'status',
                render: (_, record) => <DocumentStatusTag record={record} />,
            },

            {
                title: translateX('last_update'),
                dataIndex: '_metadata.audit.updatedAt',
                hidden: false,
                render: (_, record) => (
                    <>
                        {record?._metadata?.audit?.updatedAt &&
                            dayjs(record._metadata.audit.updatedAt).fromNow()}
                    </>
                ),
            },

            {
                title: setLocale('actions'),
                dataIndex: 'actions',
                align: 'center',
                width: 50,
                render: (_, record) => (
                    <div className="text-right d-flex justify-content-end">
                        <Tooltip title={setLocale('edit')}>
                            <Button
                                className="mr-2"
                                icon={<EditOutlined />}
                                onClick={() => onClickOpen(record)}
                                size="small"
                            />
                        </Tooltip>

                        {/* <Tooltip title={setLocale('view')}>
              <Button
                className="mr-2"
                icon={<EyeOutlined />}
                onClick={() => onClickPreview(record)}
                size="small"
              />
            </Tooltip>*/}
                    </div>
                ),
            },
        ].filter(x => !x.hidden);
    }
};

export default ProductsList;
