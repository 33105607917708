import React from 'react';
import { Input } from 'antd';
import { applyNumberMask, returnOnlyNumbers } from 'utils/helpers';

const CepInput = React.forwardRef((props, ref) => {
  const { type, prefix, suffix, allowClear } = props;
  const MASK = '99999-999';

  let value = returnOnlyNumbers(props.value);

  if (value) {
    value = applyNumberMask(value, MASK);
  }

  return (
    <Input
      {...props}
      prefix={prefix}
      suffix={suffix}
      allowClear={allowClear}
      ref={ref}
      type={type}
      value={value}
      // onChange={onLocalChange}
    />
  );
});

export default CepInput;
