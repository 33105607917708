import React, { useEffect, useState } from 'react';
import { Button, Form, Upload } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { storage } from 'auth/FirebaseAuth';
import ImgCrop from 'antd-img-crop';
import { clone, generateToken, translateX } from 'utils/helpers';
import appService from 'services/appService';
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import axiosService from 'services/axiosService';

/**
 * Upload wrapper files component. Works to firebase storage
 *
 * @param {object} props - The props passed to the component.
 * @param {object} props.form - The Ant Design Form instance to use.
 * @param {object} props.elRefs - The refs to the form fields.
 * @param {object} [props.initialValues] - The initial values for the form fields.
 *
 * @returns {JSX.Element} The rendered Form component wrapping the children.
 */
const UploadFirebaseComponent = props => {
  // props deconstruction ------------------------------------------------------
  const {
    name = 'filesUpload',
    fileSize = 1024, // bytes value, ex: 1024 = 1 KB, 100 = 100 bytes
    maxFiles,
    accept, // ex: ".jpeg,.png"
    fileFolder,
    listType = 'picture', // "picture" or "picture-card"
    isCroppingEnabled = false,
    attachments = [],
    tags = [],
    setAttachments,
    buttonTitle = 'upload',
    hideUploadButton = false,
    isDisabled,
    onUploadBefore,
    onUploadAfter,
    onUploadError,
    className,
  } = props;

  // local variables -----------------------------------------------------------
  const { selectedSubscription } = useSelector(s => s.authSlice);
  const theme = useSelector(state => state.theme);
  const { STORAGE_BUCKET } = theme.FIREBASE_CONFIG;

  // component states ----------------------------------------------------------
  const [fileList, setFileList] = useState([]);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    const t = Array.isArray(tags) ? [...tags] : [tags];

    const list = attachments?.filter(
      a => a?.tags?.some(tag => t.includes(tag)) && a?.action !== 'remove',
    );

    setFileList([...list]);
  }, [attachments]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const postAttachment = async file => {
    try {
      const _id = file?._id;
      delete file._id;

      const axiosOptions = {
        url: '/v1/attachments',
        _id,
        data: file,
      };
      return await axiosService.upsert(axiosOptions);
    } catch (error) {
      throw error;
    }
  };

  const handleUpload = async ({ onError, onSuccess, file }) => {
    if (maxFiles && fileList?.length > maxFiles) {
      file.status = 'error';
      return onError();
    }

    // file.size = value in bytes (bytes -> KB -> MB -> GB -> TB -> PB -> EB -> ZB -> YB
    const isLimitOk = file.size / 1024 < fileSize;

    if (!isLimitOk) {
      const message = translateX(
        'file_must_be_smaller_than',
        ['%SIZE%'],
        [fileSize],
      );

      appService.message('f', message);
      file.status = 'error';
      return onError();
    }

    onUploadBefore && (await onUploadBefore(file));

    // const storage = firebaseApp.storage();

    const f = buildFile(file);

    const filePath =
      `${selectedSubscription._id}/` +
      (fileFolder ? `${fileFolder}/${f.file}` : f.file);
    const fileRef = ref(storage, filePath);

    console.log('fileRef =>', fileRef);
    console.log('filhePath =>', filePath);
    console.log('file =>', file);

    try {
      const snapshot = await uploadBytes(fileRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);

      file.bucket = STORAGE_BUCKET;
      file.rootFolder = selectedSubscription._id;
      file.uri = filePath;
      file.url = downloadURL;
      file.file = f.file;
      file.fileName = f.fileName;
      file.fileExtension = f.fileExtension;
      file.uniqueKey = f.uniqueKey;

      const data = {
        uniqueKey: f.uniqueKey,
        uid: file.uid,
        service: 'firebase-storage',
        bucket: STORAGE_BUCKET,
        rootFolder: selectedSubscription._id,
        uri: filePath,
        file: f.file,
        fileExtension: f.fileExtension,
        fileName: f.fileName,
        url: downloadURL,

        type: f.type,
        name: f.name,
        size: file.size,
        lastModified: f.lastModifiedDate,
        // thumbUrl: String,
        persisted: false,
        tags: Array.isArray(tags) ? [...tags] : [tags],
      };

      const [doc] = await postAttachment(data);

      file._id = doc?._id;
      file.persisted = doc?.persisted;

      onSuccess(null, snapshot, file);

      onUploadAfter && (await onUploadAfter(doc, file));

      setTimeout(() => {
        setAttachments([...attachments, { ...doc, action: 'add' }]);
      }, 20);

      return file; // Retorna o snapshot para casos em que você deseja obter informações adicionais sobre o upload.
    } catch (e) {
      onUploadError && (await onUploadError(e, file));
      onError(e);
    }
  };

  const onRemove = value => {
    return new Promise(resolve => {
      const askOptions = {
        Content: translateX('remove_attachment_description'),
        onOk: () => {
          const idx = attachments.findIndex(f => f.uid === value.uid);
          const attachs = clone(attachments);

          if (idx > -1) {
            // mark to exclude when user click in save because it has persisted
            attachs[idx].action = 'remove';

            if (!attachs[idx]?.persisted) {
              const storageRef = ref(storage, value?.uri);
              deleteObject(storageRef).then();
            }

            setAttachments([...attachs]);
          }

          resolve();
        },
        onCancel: () => false,
      };

      appService.modal('c', translateX('remove_attachment'), askOptions);
    });
  };

  const onPreview = async file => {
    window.open(file?.url);
  };

  // UI COMPONENT --------------------------------------------------------------

  const UploadButton = () =>
    listType === 'picture' ? (
      <Button
        icon={<UploadOutlined />}
        disabled={isDisabled}
        className={className}
      >
        &nbsp;{buttonTitle}
      </Button>
    ) : (
      <div>
        <PlusOutlined className={className} />
        <div style={{ marginTop: 8 }}>{buttonTitle}</div>
      </div>
    );

  const CustomUpload = props => (
    <>
      <Upload
        {...props}
        // disabled={isDisabled}
        accept={accept}
        listType={listType}
        className={`width-100-percent no-margin ${
          isDisabled ? 'force-disabled' : ''
        }`}
        customRequest={handleUpload}
        onRemove={onRemove}
        onPreview={onPreview}
        fileList={fileList}
        maxCount={maxFiles}
      >
        {hideUploadButton ? (
          fileList.length < maxFiles && <UploadButton />
        ) : (
          <UploadButton />
        )}
      </Upload>
    </>
  );

  return (
    <Form.Item
      name={name}
      className="width-100-percent"
      valuePropName={fileList}
    >
      {isCroppingEnabled ? (
        <ImgCrop
          cropShape="round"
          // aspect={4 / 4}
          // aspectSlider
          // minZoom={-10}
          // maxZoom={20}
        >
          <CustomUpload />
        </ImgCrop>
      ) : (
        <CustomUpload />
      )}
    </Form.Item>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildFile(file) {
    const typeParts = file?.name?.split('.');
    let name, type;

    if (typeParts?.length > 0) {
      type = typeParts?.[typeParts?.length - 1];
      typeParts.pop();
      name = typeParts.join('.');
    } else {
      name = file?.name || +new Date();
      type = 'n_a';
    }

    const uniqueKey = generateToken();
    const fileNameAndExtension = `${uniqueKey}.${type.toLowerCase()}`;

    return {
      uniqueKey,
      fileName: name,
      fileExtension: type.toLowerCase(),
      file: fileNameAndExtension,
      url: null,
      fileBase64: btoa(file),

      name: file?.name,
      type: file.type,
      fileSize: file.size,
    };
  }
};

// EXPORT **********************************************************************
// *****************************************************************************

export default UploadFirebaseComponent;
