import React, { useEffect } from 'react';
import { Card } from 'antd';
import JarvislyCompanyIndex from 'components/jarvisly-components/forms/JarvislyCompanyIndex';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const CompaniesRecordIndex = props => {
  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {}, []);
  // methods -------------------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <Card>
      <JarvislyCompanyIndex {...props} showWebsite />
    </Card>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default CompaniesRecordIndex;
