import React, { useMemo } from 'react';
import LoginForm from '../../components/LoginForm';
import { Card, Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import NavLanguage from 'components/layout-components/NavLanguage';
import { version } from 'appversion';
import utils from 'utils';
import { translateX } from 'utils/helpers';
import { THEME_BACKGROUND } from 'constants/ThemeConstant';
import appService from 'services/appService';
import app from 'configs/ConfigIndex';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// const backgroundURL = '/img/others/img-17.jpg';
const backgroundURL = THEME_BACKGROUND[app.THEME_COLOR];
const backgroundStyle = {
  backgroundImage: `url(${backgroundURL})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

// COMPONENT *******************************************************************
// *****************************************************************************

const LoginUser = props => {
  // props deconstruction ------------------------------------------------------

  // component states ----------------------------------------------------------

  // local variables -----------------------------------------------------------
  const currentTheme = useSelector(state => state.theme.currentTheme);
  const headerNavColor = useSelector(state => state.theme.headerNavColor);
  const isDarkTheme = currentTheme === 'dark';
  const navMode = useMemo(() => {
    if (!headerNavColor) {
      return utils.getColorContrast(isDarkTheme ? '#000000' : '#ffffff');
    }
    return utils.getColorContrast(headerNavColor);
  }, [isDarkTheme, headerNavColor]);

  const logo = appService.getLogo(isDarkTheme);

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------

  return (
    <div className="h-100" style={backgroundStyle}>
      <div className="container d-flex flex-column justify-content-center h-100">
        <Row
          justify="center"
          align={{
            xs: 'stretch',
            sm: 'middle',
          }}
        >
          <Col>
            <Card>
              <div>
                <div className="text-center">
                  <Row justify="center" className="pt-2 pb-4">
                    <img src={logo} alt="logo" />
                  </Row>
                </div>

                <Row justify="center">
                  <Col
                    style={{
                      minWidth: 300,
                      maxWidth: 300,
                    }}
                  >
                    <LoginForm
                      {...props}
                      showForgetPassword={app.RESET_PASSWORD}
                    />
                  </Col>
                </Row>
              </div>

              <div className="text-center">
                {app.SELF_REGISTRATION && (
                  <p style={{ fontSize: 12 }}>
                    {translateX('dont_have_account_yet')}&nbsp;&nbsp;
                    <a href="/auth/signup">{translateX('sign_up')}</a>
                  </p>
                )}
              </div>
            </Card>
          </Col>
        </Row>

        <Row justify="center" align="middle">
          <Col
            style={{
              minWidth: 300,
              maxWidth: 300,
            }}
          >
            <Row justify="space-between" align="middle">
              <span className="font-size-sm text-white">
                Jarvisly App v{version}
              </span>
              {app?.NAVIGATION?.LANGUAGES ? (
                <NavLanguage
                  mode={navMode === 'light' ? 'dark' : 'light'}
                  onFixed="login"
                />
              ) : null}
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default LoginUser;
