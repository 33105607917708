import {
  JarvislyCnpj,
  JarvislyInput,
  JarvislySelectCompanyType,
  JarvislyTitle,
} from 'components/jarvisly-components/others/FormComponents';
import { Col, Row } from 'antd';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import React from 'react';

// COMPONENT *******************************************************************
// *****************************************************************************

const JarvislyCompanyForm = props => {
  // props deconstruction ------------------------------------------------------
  const { context, showCompanyType } = props;

  // local variables -----------------------------------------------------------

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <JarvislyTitle title="identification" />

      <Row gutter={ROW_GUTTER}>
        <Col xs={24} md={showCompanyType ? 16 : 24}>
          <JarvislyInput context={context} name={'name'} label="company" />
        </Col>
        {showCompanyType ? (
          <Col xs={24} md={8}>
            <JarvislySelectCompanyType context={context} />
          </Col>
        ) : null}
      </Row>

      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={8}>
          <JarvislyCnpj context={context} />
        </Col>

        <Col xs={24} sm={16}>
          <JarvislyInput
            context={context}
            name="fullName"
            label="company_name"
          />
        </Col>
      </Row>
    </>
  );
};

export default JarvislyCompanyForm;
