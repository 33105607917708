import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { useSelector } from 'react-redux';
import { setLocale } from 'utils/helpers';
import Icon from '../util-components/Icon';

const BreadcrumbRoute = ({ minWidth }) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { navigateMenu, selectedRouteInfo } = useSelector(s => s.moduleSlice);
  const breadcrumbItems = [];
  const pathSnippets = location.pathname.split('/'); //.filter(i => i)

  if (searchParams.get('tab')) pathSnippets.push(searchParams.get('tab'));
  pathSnippets.shift(); // to remove the first path (/app)

  if (selectedRouteInfo?.breadcrumbItems) {
    pathSnippets.forEach(i => {
      const item = selectedRouteInfo?.breadcrumbItems?.find(m => m.key === i);

      if (item && item?.icon) {
        breadcrumbItems.push({
          title: (
            <>
              <Icon type={item.icon} />
              &nbsp;
              <span>{setLocale(item.title)}</span>
            </>
          ),
        });
      } else if (item && !item?.icon) {
        breadcrumbItems.push({
          title: (
            <>
              <span>{setLocale(item.title)}</span>
            </>
          ),
        });
      }
    });
  } else {
    let menuItem1 = navigateMenu?.find(m => m.title === pathSnippets?.[1]);

    let menuItem2 = menuItem1?.submenu?.find(
      m => m?.title === pathSnippets?.[2],
    );
    let menuItem3 = menuItem2?.submenu?.find(
      m => m?.title === pathSnippets?.[3],
    );

    // breadcrumb part 1
    if (menuItem1) {
      breadcrumbItems.push({
        title: (
          <>
            {/*{menuItem2.icon ? <Icon type={menuItem1.icon} /> : null}*/}
            <span>{setLocale(menuItem1.title)}</span>
          </>
        ),
      });
    }

    // breadcrumb part 2
    if (menuItem2) {
      breadcrumbItems.push({
        title: (
          <>
            {/*{menuItem2.icon ? <Icon type={menuItem2.icon} /> : null}*/}
            &nbsp;
            <span>{setLocale(menuItem2.title)}</span>
          </>
        ),
      });
    }

    // breadcrumb part 3
    if (menuItem3) {
      breadcrumbItems.push({
        title: (
          <>
            {/*{menuItem2.icon ? <Icon type={menuItem3.icon} /> : null}*/}
            &nbsp;
            <span>{setLocale(menuItem3.title)}</span>
          </>
        ),
      });
    }
  }

  return (
    <div
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: 22,
        minWidth,
      }}
    >
      <Breadcrumb items={breadcrumbItems} />
    </div>
  );
};

const AppBreadcrumb = ({ minWidth = 300 }) => {
  return <BreadcrumbRoute minWidth={minWidth} />;
};

export default AppBreadcrumb;
