export const parseUrlQueryString = value => {
  const str = value.replace(/^(\?)/, '');

  // Decodifica a string URI
  const decodedQueryString = decodeURIComponent(str);
  const parsedQueryParams = {};

  const qsParts = decodedQueryString.split('&');

  if (!qsParts?.[0]) return null;

  qsParts.forEach(queryParam => {
    const [key, value] = queryParam.split('=');

    try {
      parsedQueryParams[key] = JSON.parse(value);
    } catch {
      parsedQueryParams[key] = value;
    }
  });

  return parsedQueryParams;
};

export const buildUrlQueryString = (query, tab) => {
  const queryObj = {};

  for (const [key, value] of Object.entries(query)) {
    if (Array.isArray(value)) {
      queryObj[key] = value.join(',');
    } else if (typeof value === 'object') {
      queryObj[key] = JSON.stringify(value);
    } else {
      queryObj[key] = value;
    }
  }

  const params = new URLSearchParams(queryObj);

  return params.toString();
};
