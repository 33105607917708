import {
  JarvislyAddressProvider,
  JarvislyCep,
  JarvislyGoogleMap,
  JarvislyInput,
} from 'components/jarvisly-components/others/FormComponents';
import { Col, Row } from 'antd';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import React from 'react';

// COMPONENT *******************************************************************
// *****************************************************************************

const JarvislyAddressForm = props => {
  // props deconstruction ------------------------------------------------------
  const {
    addressContext = 'root',
    showNeighborhood = true,
    showAddressReference = false,
    showMap = false,
  } = props;

  // local variables -----------------------------------------------------------

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <JarvislyAddressProvider addressContext={addressContext}>
      {/*<JarvislyTitle title="address" />*/}

      <Row gutter={ROW_GUTTER}>
        <Col xs={12} md={6}>
          <JarvislyCep name="zip" />
        </Col>

        <Col xs={24} sm={13}>
          <JarvislyInput name="address1" />
        </Col>

        <Col xs={24} sm={5}>
          <JarvislyInput name="number" />
        </Col>
      </Row>

      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={showNeighborhood ? 8 : 12}>
          <JarvislyInput name="address2" />
        </Col>

        {showNeighborhood ? (
          <Col xs={24} sm={8}>
            <JarvislyInput name="neighborhood" />
          </Col>
        ) : null}

        <Col xs={24} sm={showNeighborhood ? 8 : 12}>
          <JarvislyInput name="__city" label="city" />
        </Col>
      </Row>

      {showAddressReference ? (
        <Row gutter={ROW_GUTTER}>
          <Col xs={24}>
            <JarvislyInput name="addressReference" label="reference_point" />
          </Col>
        </Row>
      ) : null}

      {showMap ? (
        <Row gutter={ROW_GUTTER}>
          <Col xs={24}>
            <JarvislyGoogleMap name="addressMap" />
          </Col>
        </Row>
      ) : null}
    </JarvislyAddressProvider>
  );
};

export default JarvislyAddressForm;
