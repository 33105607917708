import React from 'react';
import { Flex, Tag } from 'antd';
import { translateX } from 'utils/helpers';
import appService from '../../../../services/appService';

const COLORS = {
  provider: '#3f51b5',
  customer: '#2196f3',
  operator: '#ffc107',
  contact: '#673ab7',
  collaborator: '#ff5722',
  resident: '#009688',
  owner: '#e91e63',
  system_user: '#607d8b',
  user: '#ff5722',
  traveller: '#00bcd4',

  fallback: '#f44336',
};

const JarvislyEntityProfileTag = props => {
  const { record, space, className } = props;

  if (!record?.dataProfile) return;

  /* <Tag color="#2db7f5">#2db7f5</Tag>
   <Tag color="#87d068">#87d068</Tag>
   <Tag color="#108ee9">#108ee9</Tag>
 */

  const isMobile = appService.isMobile();
  const margin =
    record?.dataProfile?.length <= 1
      ? 'tag-no-margin'
      : space === 'left'
      ? 'ml-1'
      : 'mr-1';

  return record.dataProfile.map((p, idx) => {
    const title = p?.toLowerCase();
    const color = COLORS?.[title] || COLORS.fallback;
    return (
      <Flex justify={isMobile ? 'center' : 'left'} key={idx}>
        <Tag color={color} className={`${className} ${margin}`}>
          {translateX(title)}
        </Tag>
      </Flex>
    );
  });
};

export default JarvislyEntityProfileTag;
