import { forwardRef, useContext, useEffect, useState } from 'react';
import { Button, Col, Modal } from 'antd';
import { translateX } from 'utils/helpers';
import {
  MobileOutlined,
  NumberOutlined,
  ReloadOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import {
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyInput,
  JarvislyPhone,
} from 'components/jarvisly-components/others/FormComponents';
import appService from 'services/appService';
import app from 'configs/ConfigIndex';
import authService from 'services/authService';
import { useSelector } from 'react-redux';
import whatsappService from 'services/whatsappService';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************
const loginTheme = app?.APPLICATION_LAYER;

const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setFormMeta(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // props deconstruction ------------------------------------------------------
  const {
    showModal,
    setShowModal,
    socket,
    showQrCode,
    setShowQrCode,
    qrCodeData,
    title,
    confirmLoading,
    setQrCodeData,
    currentStep,
    setCurrentStep,
    confirmLabel,
    setFormMeta,
    setSessionNameMeta,
    setConfirmLabel,
    sessionNameMeta,
    showReloadButton,
    setShowReloadButton,
    ressetQrLoading,
    setRessetQrLoading,
    setConfirmLoading,
    setSessionStatus,
  } = props;

  const onClose = () => {
    setCurrentStep(0);
    setShowQrCode(false);
    setQrCodeData(null);
    form.resetFields();
    setShowModal(false);
    setSessionNameMeta('');
    setConfirmLabel(translateX('next'));
    setShowReloadButton(false);
    setConfirmLoading(false);
    setSessionStatus('STANDBY');
  };

  const handleReloadQrCode = () => {
    if (sessionNameMeta) {
      socket.emit('create-session', { name: sessionNameMeta });
      setRessetQrLoading(true);
      setConfirmLabel(translateX('reloading_qrcode'));
    }
  };

  return (
    <Modal
      title={
        <>
          <WhatsAppOutlined />
          <span className="ml-2">{title}</span>
        </>
      }
      destroyOnClose={true}
      onClose={onClose}
      onCancel={onClose}
      onOk={() => form.submit()}
      open={showModal}
      width={640}
      confirmLoading={confirmLoading}
      okText={confirmLabel}
      okButtonProps={{ type: 'primary' }}
      getContainer={false}
      className="modal-without-padding"
    >
      <Col span={24}>
        <JarvislyPhone
          name="mobile"
          prefix={<MobileOutlined className="text-primary" />}
          disabled={currentStep >= 1}
          required
        />
        {currentStep >= 1 && (
          <JarvislyInput
            name="mobileCode"
            label="inform_sms_code"
            minLength={4}
            maxLength={4}
            prefix={<NumberOutlined className="text-primary" />}
            disabled={currentStep >= 2}
            required
          />
        )}
        {currentStep >= 2 && (
          <>
            <JarvislyInput
              name="title"
              minLength={4}
              maxLength={20}
              disabled={currentStep >= 3}
              required
            />
            <JarvislyInput
              name="description"
              disabled={currentStep >= 3}
              maxLength={100}
            />
          </>
        )}
      </Col>
      <Col span={24}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {showQrCode && currentStep >= 3 && (
            <>
              <span
                className="label-qrcode"
                style={{
                  marginBottom: '10px',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  color: '#6D99F7',
                  width: '80%',
                }}
              >
                Abra seu WhatsApp e clique em "Dispositivos conectados" no seu
                menu de menssagens e escaneie o QRCODE
              </span>
              <img
                className="Avatar"
                src={qrCodeData}
                alt="qrcode"
                style={{
                  width: '300px',
                  padding: '15px',
                  marginBottom: '20px',
                  borderRadius: '10px',
                  border: '1px solid #6D99F7',
                }}
              />
            </>
          )}
          {showReloadButton && (
            <Button
              icon={<ReloadOutlined />}
              type="default"
              size="small"
              className="m-1"
              style={{ marginTop: '10px', marginBottom: '10px' }}
              onClick={handleReloadQrCode}
              loading={ressetQrLoading}
            >
              Recarregar QRCODE
            </Button>
          )}
        </div>
      </Col>
    </Modal>
  );
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************

const AddNewWppSession = forwardRef((props, ref) => {
  // props deconstruction ------------------------------------------------------
  const {
    socket,
    sessionNameMeta,
    setSessionNameMeta,
    setShowModal,
    setQrCodeData,
    setShowQrCode,
    setShowReloadButton,
    sessionStatus,
    setSessionStatus,
    qrCodeData,
    sessionList,
  } = props;

  const { selectedSubscription } = useSelector(s => s?.authSlice);
  const lastSixDigitsId = selectedSubscription._id.slice(-6);

  // component states ----------------------------------------------------------
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [confirmLabel, setConfirmLabel] = useState(translateX('next'));
  const [formMeta, setFormMeta] = useState({});
  const [formBody, setFormBody] = useState({});

  // local variables ------------------------------------------------------------
  const title = translateX('add_new_wpp_session');

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    title,
    confirmLoading,
    setConfirmLoading,
    setCurrentStep,
    currentStep,
    confirmLabel,
    setFormMeta,
    setSessionNameMeta,
    setConfirmLabel,
  };

  // hooks ---------------------------------------------------------------------

  useEffect(() => {
    const interval = setInterval(async () => {
      if (sessionNameMeta && currentStep >= 3) {
        socket.emit('qrcode', sessionNameMeta);
        socket.emit('check_status', { name: sessionNameMeta });
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [sessionNameMeta, socket, currentStep]);

  useEffect(() => {
    if (qrCodeData) {
      setConfirmLabel(translateX('waiting_toread_qrcode'));
    }
  }, [qrCodeData]);

  useEffect(() => {
    if (sessionStatus === 'SUCESS_SESSION') {
      (async () => await postClientConnection(formBody))();
      setConfirmLoading(false);
      setShowModal(false);
      setCurrentStep(0);
      setShowQrCode(false);
      setQrCodeData(null);
      formMeta.resetFields();
      setSessionNameMeta('');
      setConfirmLabel(translateX('next'));
      setShowReloadButton(false);
      setSessionStatus('STANDBY');
    }

    if (sessionStatus === 'FAILED_SESSION') {
      setShowReloadButton(true);
      setShowQrCode(false);
    }

    if (sessionStatus === 'WAITING') {
      setConfirmLoading(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStatus]);

  // METHODS -------------------------------------------------------------------

  const postClientConnection = async body => {
    try {
      const customBody = {
        mobile: body.mobile,
        title: body.title,
        description: body.description,
        session: sessionNameMeta,
      };
      await whatsappService.postClientConnection(
        customBody,
        selectedSubscription._id,
      );
    } catch (error) {
      console.log('Error posting client dataSession --> ', error);
    }
  };

  const onConfirm = async ({ body }) => {
    const rawValue = body.mobile + lastSixDigitsId;
    const sanitizedValue = rawValue.replace(/[^a-zA-Z0-9]/g, '');
    switch (currentStep) {
      // SENT SMS CODE
      case 0: {
        setConfirmLoading(true);
        try {
          if (sanitizedValue && sessionList.includes(sanitizedValue)) {
            appService.notification(
              'w',
              'cellphone_already_registered',
              'existsKey',
              body.mobile,
              5,
            );
            setConfirmLoading(false);
            return;
          }

          appService.notification(
            <MobileOutlined />,
            'sending_sms',
            'sendingKey',
            'wait_for_sms',
            0,
          );

          const success = await authService.sendRegistrationCode(
            body,
            loginTheme,
          );

          appService.notificationDestroy('sendingKey');

          if (success) {
            setConfirmLoading(false);
            appService.notification(
              'i',
              body.mobile,
              'enterCodeKey',
              'inform_your_sms_code',
              0,
            );
            setCurrentStep(currentStep + 1);
          } else {
            appService.notification(
              'w',
              body.mobile,
              'failureKey',
              'error_429_description',
              5,
            );
          }
        } catch (error) {
          return;
        }
        setConfirmLabel(translateX('next'));

        break;
      }

      // VALIDADE SMS CODE
      case 1: {
        setConfirmLoading(true);
        try {
          const success = await authService.validateRegistrationCode(body);

          appService.notificationDestroy('enterCodeKey');
          appService.notificationDestroy('failureKey');

          if (success) {
            setCurrentStep(currentStep + 1);
            setConfirmLoading(false);
            setSessionNameMeta(sanitizedValue);
            setConfirmLabel(translateX('generate_qrcode'));
          } else {
            setConfirmLoading(false);
            appService.notification(
              'w',
              'invalid_code',
              'failureKey',
              'invalid_code_description',
              5,
            );
          }
        } catch (error) {
          setCurrentStep(0);
          return;
        }

        break;
      }

      // VALIDATE TITLE AND GENERATE QRCODE
      case 2: {
        if (body.title) {
          try {
            socket.emit('create-session', { name: sessionNameMeta });
            setConfirmLabel(translateX('generating_qrcode'));
            setConfirmLoading(true);
            setCurrentStep(currentStep + 1);
            setFormBody(body);
          } catch (error) {
            console.log('Error creating session --> ', error);
          }
        }

        break;
      }

      case 3: {
        setConfirmLoading(true);
        break;
      }

      default:
        console.warn('Error steps');
    }

    return null;
  };

  // UI COMPONENT --------------------------------------------------------------

  return (
    <JarvislyFormProvider
      name="whatsapp-form"
      requiredFields={['title', 'mobile']}
      showSubmitButton={false}
      onConfirm={onConfirm}
      abortComponentSave={true}
    >
      <ModalComponent {...childProps} />
    </JarvislyFormProvider>
  );
});

// EXPORT **********************************************************************
// *****************************************************************************

export default AddNewWppSession;
