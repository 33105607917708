import React from 'react';
import { Input } from 'antd';
import { applyNumberMask, returnOnlyNumbers } from 'utils/helpers';
import countriesProfiles from 'assets/data/countriesProfiles.json';

const InputPhone = React.forwardRef((props, ref) => {
  // props deconstruction ------------------------------------------------------
  const {
    onChange,
    profile,
    prefix,
    suffix,
    onBlur,
    autoFocus,
    allowClear,
    disabled,
    countryCode = 'br',
  } = props;

  // local variables I ---------------------------------------------------------
  let maxLength = 11;
  let value = $buildValueMaks();

  // methods -------------------------------------------------------------------
  const onLocalChange = ev => {
    const valueNumber = returnOnlyNumbers(ev?.target?.value);
    const mask = getPhoneProfileByNumberMask(valueNumber, countryCode, profile);

    let nextLength = valueNumber?.length || 0;

    if (nextLength > maxLength) return;

    value = $buildValueMaks();

    onChange && onChange(ev, mask);
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <Input
      {...props}
      ref={ref}
      value={value}
      onBlur={onBlur}
      autoFocus={autoFocus}
      prefix={prefix}
      suffix={suffix}
      allowClear={allowClear}
      disabled={disabled}
      onChange={onLocalChange}
    />
  );

  // internal functions --------------------------------------------------------
  function $buildValueMaks() {
    const sc = countriesProfiles.find(c => c.countryCode === countryCode);
    const phoneMasks = sc?.phoneMasks || {
      wired: '(99) 9999-9999',
      mobile: '(99) 9.9999-9999',
      free: '9999-999-9999',
    };

    const digits =
      profile && phoneMasks?.[profile?.toLowerCase()]
        ? returnOnlyNumbers(phoneMasks[profile.toLowerCase()])
        : returnOnlyNumbers(phoneMasks?.free || '9999-999-9999');

    maxLength = digits.length;

    let value = props?.value && returnOnlyNumbers(props.value);

    if (value) {
      return applyNumberMask(
        value,
        phoneMasks[getPhoneProfileByNumberMask(value, countryCode, profile)],
        true,
      );
    } else {
      return '';
    }
  }
});

export const getPhoneProfileByNumberMask = (value, countryCode, profile) => {
  if (profile) {
    return profile.toLowerCase();
  } else {
    if (countryCode === 'br') {
      if (value?.substring(0, 1) === '0') return 'free';
      if (value?.substring(2, 3) === '9') return 'mobile';
      if (value?.length <= 10) return 'wired';
    }

    return 'mobile';
  }
};

export const getPhoneProfileByNumber = (number, countryCode) => {
  const pn = returnOnlyNumbers(number);

  if (countryCode === 'br') {
    if (pn?.substring(0, 1) === '0') return 'free';
    if (pn?.substring(2, 3) === '9') return 'mobile';
    if (pn?.length <= 10) return 'wired';
  }

  return 'mobile';
};

export default InputPhone;
