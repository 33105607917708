import { EditOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import { setLocale, translateWord, translateX } from 'utils/helpers';
import dayjs from 'dayjs';
import React from 'react';
import financialService from 'services/financialService';
import { ReceivablesStatusTag } from '../Components';
import MULTI_RECEIVABLES_MODULE from '../receivables';

const ReceivablesList = props => {
  // props deconstruction ------------------------------------------------------
  const {
    isWorking,
    onTableChanges,
    columnSorter,
    columnSearch,
    columnFilters,
    tableKey,
    resetTableSearch,
    pagination,
    dataList,
    buildRowSelection,
    showNumberOfLine,
    onClickOpen,
  } = props;

  // component states ----------------------------------------------------------

  // local variables -----------------------------------------------------------
  const statusFilter = buildDocumentStatusFilter();
  const paymentMethodFilter = buildPaymentMethodFilter();
  const tableColumns = buildTableColumns();

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------

  return (
    <>
      <Table
        key={tableKey}
        rowKey="_id"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={dataList || []}
        pagination={pagination}
        onChange={onTableChanges}
        rowSelection={buildRowSelection}
        className="with-checkbox"
        resetTableSearch={resetTableSearch}
        loading={isWorking}
      />
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildPaymentMethodFilter() {
    const options =
    MULTI_RECEIVABLES_MODULE?.initialRequest?.filterOptions?.paymentMethod || [];

    return options.map(o => {
      return {
        text: translateX(o),
        value: o,
      };
    });
  }
  
  function buildDocumentStatusFilter() {
    const options =
    MULTI_RECEIVABLES_MODULE?.initialRequest?.filterOptions?.status || [];

    return options.map(o => {
      return {
        text: translateX(o),
        value: o,
      };
    });
  }

  function buildTableColumns() {
    return [
      {
        hidden: !showNumberOfLine,
        title: translateWord('line'),
        width: 80,
        render: (item, record, index) => {
          if (!pagination) return;

          const { current, pageSize } = pagination;

          return (
            <div className="text-left">
              {current * pageSize - pageSize + index + 1 || index}
            </div>
          );
        },
      },

      {
        title: translateX('document'),
        dataIndex: '_metadata.counter',
        ...columnSorter('_metadata.counter'),
        ...columnSearch('_metadata.counter'),
        width: 140,
        render: (_, record) => (
          <span className="text-uppercase text-muted">
            {record?._metadata?.__documentCode}
          </span>
        ),
      },

      {
        title: translateWord('customer'),
        dataIndex: '_indice',
        ...columnSearch('_indice', 'customer'),
        ellipsis: true,
        render: (_, record) => <span>{record?.customer?.name}</span>,
      },

      {
        title: translateWord('payment_method'),
        dataIndex: 'billingType',
        ...columnFilters('billingType', paymentMethodFilter),

        ellipsis: true,
        render: (_, record) => {
          const billingType =
            record?.billingType === 'whatever'
              ? translateX('all_payments_title')
              : translateX(record?.billingType);

          return (
            <>
              <div>{billingType}</div>
            </>
          );
        },
      },

      {
        title: translateWord('status'),
        dataIndex: 'status',
        ...columnFilters('status', statusFilter),
        ...columnSorter('status'),
        width: 190,
        align: 'center',
        ellipsis: true,
        render: (_, record) => <ReceivablesStatusTag record={record} />,
      },

      {
        title: translateWord('due_date_short'),
        dataIndex: 'dueDate',
        hidden: false,
        width: 120,
        align: 'center',
        ...columnSorter('dueDate'),
        render: (_, record) => (
          <>{record?.dueDate && dayjs(record.dueDate).format('L')}</>
        ),
      },

      {
        title: translateWord('value'),
        dataIndex: 'value',
        className: 'text-truncate',
        align: 'right',
        render: (_, record) => (
          <span>
            {financialService.formatMoney(record?.value, 2, 'brl', true)}
          </span>
        ),
      },

      {
        title: setLocale('actions'),
        dataIndex: 'actions',
        align: 'center',
        width: 80,
        render: (_, record) => (
          <div className="text-right d-flex justify-content-end">
            <Tooltip title={setLocale('edit')}>
              <Button
                className="mr-2"
                icon={<EditOutlined />}
                onClick={() => onClickOpen(record)}
                size="small"
              />
            </Tooltip>

            {/* <Tooltip title={setLocale('view')}>
              <Button
                className="mr-2"
                icon={<EyeOutlined />}
                onClick={() => onClickPreview(record)}
                size="small"
              />
            </Tooltip>*/}
          </div>
        ),
      },
    ].filter(x => !x.hidden);
  }
};

export default ReceivablesList;
