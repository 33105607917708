import { getFromLocalStorage } from 'utils/helpers';
import { MdOutlineLuggage } from 'react-icons/md';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import AccountOrganizationsForm from './organizationsTab/Form/Form';
import {
  AccountOrganizationsDocumentHeaderColumn2,
  AccountOrganizationsDocumentHeaderColumn3,
} from './organizationsTab/Components';
import { DefaultFormHeaderColumn1 } from 'components/jarvisly-module/Form/FormHeader';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const MENU_GROUP = 'account';
const MODULE_TITLE = 'settings';
const MODULE_ICON = MdOutlineLuggage;
const MODULE_API = '/v1/subscription';

export const ACCOUNTS_ROUTE = `/app/${MENU_GROUP}/${MODULE_TITLE}`;
export const ACCOUNTS_ORGANIZATIONS_ROUTE = `/app/${MENU_GROUP}/${MODULE_TITLE}/organization`;

// MODULE PARAMETERS ***********************************************************
// *****************************************************************************

const ACCOUNTS_MODULE = {
  noBlockScreenWithAppNotConfigured: true,
  name: 'accounts',
  title: MODULE_TITLE,
  icon: MODULE_ICON,
  api: MODULE_API,
  urlForm: ACCOUNTS_ORGANIZATIONS_ROUTE,
  defaultUrlQueryString: '?tab=organizations',

  customTitle: 'organizations',
  customIcon: HiOutlineOfficeBuilding,

  menus: [
    // menu outside of main navigation menu
    {
      path: ACCOUNTS_ROUTE,
      title: MODULE_TITLE,
      icon: MODULE_ICON,
      submenu: [],
    },
  ],

  url: ACCOUNTS_ROUTE,
  routes: [ACCOUNTS_ROUTE, `${ACCOUNTS_ORGANIZATIONS_ROUTE}/:_id`],

  components: {
    dashboard: null,
    // list: props => <AccountList {...props} />,
    form: props => <AccountOrganizationsForm {...props} />,
    formHeader: null,
    formHeaderSubtitle: null,
    formHeaderColumn1: props => <DefaultFormHeaderColumn1 {...props} />,
    formHeaderColumn2: props => (
      <AccountOrganizationsDocumentHeaderColumn2 {...props} />
    ),
    formHeaderColumn3: props => (
      <AccountOrganizationsDocumentHeaderColumn3 {...props} />
    ),
    formHeaderCustomIcons: null,
    settings: null,

    dataImport: null,
    previewHeader: null,
    previewBody: null,
    previewActions: null,
  },

  methods: {
    decorateDocument,
  },

  initialRequest: {
    folder: 'documents',
    folderOptions: ['documents', 'recycle', 'filed'],

    filter: {},
    filterOptions: {},

    pagination: {
      pageNumber: 1, // current page
      pageSize: getFromLocalStorage('pageSize'),
    },
    paginationOptions: {
      pageSize: [10, 20, 50, 100],
    },

    sorter: {
      '_metadata.audit.updatedAt': -1,
    },
    sorterOptions: ['organization', '_metadata.audit.updatedAt'],

    search: {},

    textSearch: {
      default: '',
    },
  },

  listState: {
    initTab: 'profile',
    viewMode: ['list'], // 'list', 'kanban', 'grid' or 'dashboard'
    showNumberOfLine: false, // show number of line in table list
    showCheckBoxes: false, // show check boxes on table list for ple selection
    hasFilter: true, // show the filter (folders and customs) drawer button
    hasAccessControl: true, // show the control access modal for this module
    isTableChanged: false, // detect if the table was changed, check if the currentQs is different of initialQs (includes column sorter, search and pagination)
    isFilterChanged: false, // detect if the filters was changed
  },

  documentState: {
    disableLockForEdit: false,
    initTab: 'record',
    fieldName: 'organization',
    newDocumentTitle: 'new_organization',
    autoFill: false,
    actions: ['new', 'edit', 'reload', 'divider', 'permanently_remove'],

    mockDocument: {
      name: 'Infodesk',
      cnpj: '02.934.311/0001-53',
      fullName: 'Infodesk Technologies Ltda.',
      phone: '(19) 9.8232-0000',
      email: 'biruel@infodesk.com.br',
      zip: '13010-111',
      address1: 'Rua General Osório',
      number: '311',
      address2: 'Sala 309',
      neighborhood: 'Centro',
      __city: 'Campinas - SP',
      addressTitle: 'Endereço Principal',
      city: 'Campinas',
      province: 'SP',
      addressCoordinates: null,
      addressAdditionalInfo: {
        note: 'de 762/763 a 1661/1662',
        areaCode: '19',
        giaCode: '2446',
        ibgeCode: '3509502',
        siafiCode: '6291',
      },
      addressMetadata: {
        countryCode: 'br',
        foundByApi: true,
        apiUrl: 'https://viacep.com.br/ws/13010111/json/',
        zipWithMultipleAddress: false,
      },
      dataType: 'company',
      dataProfile: 'operator',
      uniqueKey: '02934311000153',
    },
  },
};

// EXPORT ********************************************************************
// ***************************************************************************

export default ACCOUNTS_MODULE;

// INTERNAL FUNCTIONS ========================================================
// ===========================================================================

function decorateDocument(doc) {
  doc.name = doc.organization;

  const dataCompany = { ...doc.__mainCompany };

  delete dataCompany._id;
  return Object.assign(doc, dataCompany);
}
