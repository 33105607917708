import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  Col,
  Descriptions,
  Flex,
  Grid,
  Row,
  Spin,
} from 'antd';
import { FormButton } from 'components/jarvisly-components/others/FormComponents';
import {
  ArrowLeftOutlined,
  CheckOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';
import {
  parsePhoneToDisplay,
  parseZipToDisplay,
  returnOnlyNumbers,
  translateX,
} from 'utils/helpers';
import appmultiService from 'services/appmultiService';
import { Icon } from 'components/util-components/Icon';
import { MdPayment } from 'react-icons/md';
import bankSlipImg from 'assets/images/bank_slip.png';
import creditCardImg from 'assets/images/credit_card.png';
import pixImg from 'assets/images/pix.png';
import { Select } from 'antd/lib';
import utils from 'utils';
import financialService from 'services/financialService';
import appService from 'services/appService';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const { useBreakpoint } = Grid;

// COMPONENT *******************************************************************
// *****************************************************************************
const MultiQuotationsCheckoutStep3 = props => {
  // props deconstruction ------------------------------------------------------
  const {
    setStep,
    setIsGenerating,
    isGenerating,
    isMobile,
    buyer,
    travellers,
    plan,
    documentContext,
    order,
    setOrder,
    isDisabled,

    emergencyContacts,
    setPlan,

    paymentSelected,
    setPaymentSelected,
    creditCardInstallment,
    setCreditCardInstallment,
  } = props;

  // local variables I ---------------------------------------------------------
  const filter = documentContext?.filter || {};
  const isLarge = utils.getBreakPoint(useBreakpoint()).includes('lg');

  // component states ----------------------------------------------------------
  const [installmentsArr, setInstallmentsArr] = useState([]);

  // local variables II --------------------------------------------------------
  const onOpenPaymentUrl = () => {
    const link = order?.__receivables?.[0]?.paymentUrl || order?.paymentUrl;
    if (!link) return;
    window.open(link, '_blank');
  };
  const items = buildItems();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    const total = plan?.summary?.total?.netPriceWithCouponDiscount;

    if (!total) {
      const value = financialService.formatMoney(total, 2, 'brl', true);
      setInstallmentsArr([
        {
          value: `1X`,
          label: (
            <span style={{ fontSize: 14 }}>{`1X DE ${value} (S/JUROS)`}</span>
          ),
        },
      ]);
    } else {
      const options = [];
      for (let i = 0; i < 6; i++) {
        const value = financialService.formatMoney(
          total / (i + 1),
          2,
          'brl',
          true,
        );
        options.push({
          value: `${i + 1}X`,
          label: (
            <span style={{ fontSize: 14 }}>
              {`${i + 1}X DE ${value} (S/JUROS)`}
            </span>
          ),
        });
      }

      setInstallmentsArr(options);
    }
  }, [plan?.summary?.total?.netPriceWithCouponDiscount]);
  // methods -------------------------------------------------------------------
  const onGenerateOrder = async () => {
    const options = {
      Content: 'confirm_submit_text',
      okText: 'confirm',
      cancelText: 'cancel',
      onOk: async () => {
        try {
          setIsGenerating(true);

          const result = await appmultiService.generateOrderFromQuotation(
            filter,
            plan,
            travellers,
            buyer,
            emergencyContacts,
            paymentSelected,
          );

          setOrder(result);
          setIsGenerating(false);
        } catch (error) {
          console.log('Erro ao gerar uma ordem');
        }
      },
    };
    appService.modal('c', 'attention', options);
  };

  const onClickPaymentSelected = value => {
    if (value === paymentSelected) return;

    setPaymentSelected(value);
    const summary = appmultiService.calculatePlanValues({
      plan,
      days: filter.days,
      passengersArr: travellers,
      paymentMethod: value,
      creditCardInstallment:
        value === 'credit_card' ? creditCardInstallment : 1,
    });

    setPlan &&
      setPlan(prev => ({
        ...prev,
        summary: { ...summary },
      }));
  };

  const onClickCreditCardInstallment = parc => {
    setCreditCardInstallment(parc);

    const summary = appmultiService.calculatePlanValues({
      plan,
      days: filter.days,
      passengersArr: travellers,
      paymentMethod: paymentSelected,
      creditCardInstallment: parc,
    });

    setPlan &&
      setPlan(prev => ({
        ...prev,
        summary: { ...summary },
      }));
  };

  // UI COMPONENTS -------------------------------------------------------------

  return (
    <div className="w-100">
      <Descriptions bordered items={items} className="w-100 mb-4" />

      <Spin spinning={isGenerating} size="large">
        <Row justify="center" className="w-100">
          {/* BILLING TYPE */}
          <Col xs={24} lg={16} xl={16} className={isLarge ? 'pr-3' : ''}>
            <Card
              title={
                <Flex align="center" style={{ color: 'darkcyan' }}>
                  <div style={{ paddingTop: 4 }}>
                    <Icon type={MdPayment} />
                  </div>
                  <div style={{ marginLeft: 8 }}>
                    {translateX('select_payment_method')}
                  </div>
                </Flex>
              }
              className="w-100"
            >
              <Row justify="space-between">
                {/* bankslip */}
                <Col xs={24} xl={12} xxl={8}>
                  <Row justify="center">
                    <Card
                      className="m-3"
                      style={{
                        width: 278,
                        border:
                          paymentSelected === 'slip_pix'
                            ? '2px solid darkcyan'
                            : '',
                        boxShadow:
                          paymentSelected === 'slip_pix'
                            ? '0 4px 8px rgba(0, 0, 0, 0.1)'
                            : '',
                        pointerEvents: isDisabled ? 'none' : 'initial',
                        cursor: isDisabled ? 'default' : 'pointer',
                      }}
                      hoverable={!isDisabled}
                      onClick={() => onClickPaymentSelected('slip_pix')}
                      cover={
                        <img
                          alt="Boleto Bancário"
                          src={bankSlipImg}
                          style={{
                            objectFit: 'cover',
                            objectPosition: 'center',
                            width: 56,
                            margin: '28px 112px 0',
                          }}
                        />
                      }
                    >
                      <div
                        className="text-center w-100 font-weight-bold"
                        style={{ fontSize: 14 }}
                      >
                        BOLETO BANCÁRIO
                      </div>

                      <div
                        className="text-center w-100 text-muted"
                        style={{ fontSize: 14, padding: '24px 0 10px' }}
                      >
                        Desconto de 5%
                      </div>
                    </Card>
                  </Row>
                </Col>
                {/* bankslip */}

                {/* credit card */}
                <Col xs={24} xl={12} xxl={8}>
                  <Row justify="center">
                    <Card
                      className="m-3"
                      style={{
                        width: 278,
                        border:
                          paymentSelected === 'credit_card'
                            ? '2px solid darkcyan'
                            : '',
                        boxShadow:
                          paymentSelected === 'credit_card'
                            ? '0 4px 8px rgba(0, 0, 0, 0.1)'
                            : '',
                        pointerEvents: isDisabled ? 'none' : 'initial',
                        cursor: isDisabled ? 'default' : 'pointer',
                      }}
                      hoverable={!isDisabled}
                      onClick={() => onClickPaymentSelected('credit_card')}
                      cover={
                        <img
                          alt="Cartão de Crédito"
                          src={creditCardImg}
                          style={{
                            objectFit: 'cover',
                            objectPosition: 'center',
                            width: 56,
                            margin: '28px 112px 0',
                          }}
                        />
                      }
                    >
                      <div
                        className="text-center w-100 font-weight-bold"
                        style={{ fontSize: 14 }}
                      >
                        CARTÃO DE CRÉDITO
                      </div>

                      <Select
                        className="pt-3 ant-select-selector-p8 w-100"
                        size="small"
                        style={{ height: 'unset', fontSize: 14 }}
                        value={creditCardInstallment}
                        onChange={onClickCreditCardInstallment}
                        options={installmentsArr}
                      />
                    </Card>
                  </Row>
                </Col>
                {/* credit card */}

                {/* pix */}
                <Col xs={24} xl={12} xxl={8}>
                  <Row justify="center">
                    <Card
                      className="m-3"
                      style={{
                        width: 278,
                        border:
                          paymentSelected === 'pix' ? '2px solid darkcyan' : '',
                        boxShadow:
                          paymentSelected === 'pix'
                            ? '0 4px 8px rgba(0, 0, 0, 0.1)'
                            : '',
                        pointerEvents: isDisabled ? 'none' : 'initial',
                        cursor: isDisabled ? 'default' : 'pointer',
                      }}
                      hoverable={!isDisabled}
                      onClick={() => onClickPaymentSelected('pix')}
                      cover={
                        <img
                          alt="PIX"
                          src={pixImg}
                          style={{
                            objectFit: 'cover',
                            objectPosition: 'center',
                            width: 56,
                            margin: '28px 112px 0',
                          }}
                        />
                      }
                    >
                      <div
                        className="text-center w-100 font-weight-bold"
                        style={{ fontSize: 14 }}
                      >
                        PIX
                      </div>

                      <div
                        className="text-center w-100 text-muted"
                        style={{ fontSize: 14, padding: '24px 0 10px' }}
                      >
                        Desconto de 5%
                      </div>
                    </Card>
                  </Row>
                </Col>
                {/* pix */}
              </Row>
            </Card>
          </Col>
          {/* BILLING TYPE */}

          {/* CHECKOUT */}
          <Col xs={24} lg={8} xl={8}>
            <Card
              title={
                <Flex align="center" style={{ color: 'darkcyan' }}>
                  <div style={{ paddingTop: 0 }}>
                    <Icon type={ShoppingOutlined} />
                  </div>
                  <div style={{ marginLeft: 8 }}>{translateX('checkout')}</div>
                </Flex>
              }
              className="w-100"
            >
              <Row justify="space-between">
                <Col span={24} className="text-right">
                  {/* VALUE */}
                  {/*{plan?.summary?.total?.totalAdjusted !==*/}
                  {/*plan?.summary?.total?.totalToPay ? (*/}
                  {/*  <Flex justify="space-between" align="center">*/}
                  {/*    <div className="text-muted">VALOR</div>*/}
                  {/*    <div className="font-size-md">*/}
                  {/*      {financialService.formatMoney({*/}
                  {/*        value: plan?.summary?.total?.totalAdjusted,*/}
                  {/*        localeSymbol: 'brl',*/}
                  {/*        resultToStr: true,*/}
                  {/*      })}*/}
                  {/*    </div>*/}
                  {/*  </Flex>*/}
                  {/*) : null}*/}
                  {/* VALUE */}

                  {/* INSERT COUPOM */}
                  {/*<Flex justify="space-between" align="center" className="mt-2">*/}
                  {/*  <div className="text-muted">*/}
                  {/*    <Button*/}
                  {/*      type="link"*/}
                  {/*      onClick={onClickInsertCoupom}*/}
                  {/*      className="pl-0 pr-0"*/}
                  {/*    >*/}
                  {/*      INSERIR CUPOM DESCONTO*/}
                  {/*    </Button>*/}
                  {/*  </div>*/}
                  {/*  <div className="font-size-md text-danger">*/}
                  {/*    {financialService.formatMoney({*/}
                  {/*      value:*/}
                  {/*        plan?.summary?.total?.couponDiscount?.discountValue,*/}
                  {/*      localeSymbol: 'brl',*/}
                  {/*      resultToStr: true,*/}
                  {/*    })}*/}
                  {/*  </div>*/}
                  {/*</Flex>*/}
                  {/* INSERT COUPOM */}

                  {/* TOTAL */}
                  <Flex justify="space-between" align="center" className="mt-2">
                    <div className="text-muted">TOTAL</div>
                    <div className="font-size-md">
                      {financialService.formatMoney({
                        value: plan?.summary?.total?.netPriceWithCouponDiscount,
                        localeSymbol: 'brl',
                        resultToStr: true,
                      })}
                    </div>
                  </Flex>
                  {/* TOTAL */}

                  {/* PAYMENT DISCOUNT */}

                  <Flex justify="space-between" align="center" className="mt-2">
                    <div className="text-muted text-uppercase">
                      DESCONTO {translateX(paymentSelected)}{' '}
                      {paymentSelected === 'credit_card' ? '' : '(5%)'}
                    </div>
                    <div className="font-size-md text-danger">
                      -
                      {financialService.formatMoney({
                        value: plan?.summary?.total?.paymentDiscountValue,
                        localeSymbol: 'brl',
                        resultToStr: true,
                      })}
                    </div>
                  </Flex>

                  {/* PAYMENT DISCOUNT */}

                  {/* TOTAL TO PAY */}
                  <Flex justify="space-between" align="center" className="mt-2">
                    <div className="text-muted">TOTAL A PAGAR</div>
                    <div className="font-size-md">
                      {financialService.formatMoney({
                        value: plan?.summary?.total?.totalToPay,
                        localeSymbol: 'brl',
                        resultToStr: true,
                      })}
                    </div>
                  </Flex>
                  {/* TOTAL TO PAY */}

                  {/* INSTALLMENT */}
                  <Flex justify="space-between" align="center" className="mt-2">
                    <div className="text-muted">FORMA DE PAGAMENTO</div>
                    <div className="font-size-md">
                      {!paymentSelected
                        ? 'N/A'
                        : paymentSelected !== 'credit_card'
                        ? translateX(paymentSelected)
                        : creditCardInstallment === '1X'
                        ? 'CARTÃO Á VISTA'
                        : `${creditCardInstallment} ${financialService.formatMoney(
                            plan?.summary?.total?.netPriceWithCouponDiscount /
                              (returnOnlyNumbers(
                                plan?.summary?.total?.paymentInstallment,
                              ) || 1),
                            2,
                            'brl',
                            true,
                          )}`}
                    </div>
                  </Flex>

                  {/*
                      <Flex justify="flex-end" align="center" className="mt-1">
                    <div className="text-muted">
                      PARCELADO EM 10x DE R$ 4,96
                    </div>
                  </Flex>
*/}
                  {/* INSTALLMENT */}
                </Col>
              </Row>
            </Card>
          </Col>
          {/* CHECKOUT */}
        </Row>
      </Spin>

      {/*PREV/NEXT*/}
      {!isDisabled ? (
        <Flex
          justify={isMobile ? 'center' : 'flex-end'}
          align="center"
          className="mt-4"
        >
          <FormButton
            title="previous"
            className="mr-3"
            icon={<ArrowLeftOutlined />}
            style={{ width: 130 }}
            disabled={isDisabled}
            onClick={() => setStep(1)}
          />

          <FormButton
            title="confirm"
            type="primary"
            icon={<CheckOutlined />}
            style={{ width: 130 }}
            disabled={isDisabled || isGenerating || !paymentSelected}
            onClick={onGenerateOrder}
          />
        </Flex>
      ) : null}
      {/* PREV/NEXT */}
    </div>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildItems() {
    return [
      {
        key: 'buyer',
        label: translateX('buyer'),
        children: buyer?.name,
      },
      {
        key: 'phone',
        label: translateX('phone'),
        children: parsePhoneToDisplay(buyer?.phone),
      },
      {
        key: 'email',
        label: translateX('email'),
        children: buyer?.email,
      },
      {
        key: 'address',
        label: translateX('address'),
        children: (
          <>
            <div>
              {buyer?.address1}, {buyer?.number}
              {buyer?.address2 ? <span>, {buyer?.address2}</span> : null}
            </div>
            <div>
              {buyer?.city} -&nbsp;
              <span className="text-uppercase">{buyer?.province}</span>
            </div>
            <div>{parseZipToDisplay(buyer?.zip)}</div>
          </>
        ),
      },
      {
        key: 'travellers',
        label: translateX('travelers'),
        children: travellers?.map(t => (
          <div key={t.key}>
            {t.name}, {t.age} {translateX('years_old')}
            <br />
          </div>
        )),
      },
      {
        key: 'emergency',
        label: translateX('emergency_contacts'),
        children: emergencyContacts?.map((c, idx) => (
          <div key={idx}>
            <Badge
              status="default"
              text={`${c.name}, ${parsePhoneToDisplay(c.phone)}`}
            />
          </div>
        )),
      },
      {
        key: 'order',
        label: translateX('order'),
        children: order?._metadata.__documentCode ? (
          <strong className="text-uppercase">
            {order?._metadata.__documentCode}
          </strong>
        ) : (
          <span>N/A</span>
        ),
      },
      {
        key: 'status',
        label: translateX('status'),
        children: order?.status ? (
          <Badge
            status={order?.__badgeStatus || 'default'}
            text={translateX(order.status)}
          />
        ) : (
          <Badge status="default" text={translateX('waiting_for_generation')} />
        ),
      },
      {
        key: 'paymentLink',
        label: translateX('payment_link'),
        children:
          order?.__receivables?.[0]?.paymentUrl || order?.paymentUrl ? (
            <Button
              type="link"
              style={{ height: 22 }}
              className="p-0"
              onClick={onOpenPaymentUrl}
            >
              {translateX('view_billing')}
            </Button>
          ) : (
            <span>N/A</span>
          ),
      },
    ];
  }
};

export default MultiQuotationsCheckoutStep3;
