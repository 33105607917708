// COMPONENT *******************************************************************
// *****************************************************************************
import { Avatar, Card, Divider, Flex, Tooltip } from 'antd';
import { SubscriptionProfileIcon } from './SubscriberSelectorCombo';
import { translateX } from 'utils/helpers';
import utils from 'utils';
import { useNavigate } from 'react-router-dom';

/*const ItemAction = props => {
  // props deconstruction ------------------------------------------------------
  const { subscriptionId, status, profile } = props;

  // local variables -----------------------------------------------------------
  const navigate = useNavigate();
  const menu = buildMenu();

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onMenuClick = async action => {
    switch (action.key) {
      case 'accept': {
        console.log('clicked on accept!');
        // await putSubscriptionChangeTeamStatus(record?._id, rdxUser?._id, 2)
        // msg('s', invite_accepted_with_success)
        // onRefreshSubscriptions(record?._id)
        break;
      }

      case 'edit': {
        navigate(`/app/account/settings/organization/${subscriptionId}`);
        break;
      }

      case 'delete': {
        console.log('clicked on delete!');

        // abort if this have a team
        // if (record?.team?.length > 1) { // fist is the owner, the others are invited users (guests)
        //   return toast('f', delete_organization, delete_organization_team, 5)
        // }

        // ask to confirm
        // const organization = substitution(organization_noun,
        //   ['%ORGANIZATION%'], [record?.organization?.toUpperCase()])
        //
        // const message = substitution(delete_organization_text,
        //   ['%ORGANIZATION_NAME%'], [organization])
        //
        // const options = {
        //   type: 'confirm',
        //   title: delete_organization,
        //   message: message,
        //   okText: yes,
        //   cancelText: no,
        //   onOk: async () => {
        //     await delSubscription(record._id)
        //     onRefreshSubscriptions(record?._id)
        //     msg('s', organization_delete_with_success)
        //   },
        // }
        //
        // modal(options)
        break;
      }

      default: {
        console.error('unknowing case!');
        break;
      }
    }
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <EllipsisDropdown
      menu={{
        items: menu.filter(a => !a.hidden),
        onClick: onMenuClick,
      }}
    />
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildMenu() {
    const disableActions = [];
    if (profile !== 'owner') disableActions.push('delete');

    const availableActions = [];
    const actionsObj = {
      accept: {
        key: 'accept',
        hidden: false,
        disabled: disableActions.includes('accept'),
        icon: <LikeOutlined />,
        label: <div>{translateX('accept_invitation')}</div>,
      },

      decline: {
        key: 'decline',
        hidden: false,
        disabled: true, // disableActions.includes('decline'),
        label: <div>{translateX('decline_invitation')}</div>,
      },

      divider: {
        disabled:
          disableActions.includes('suspend') &&
          disableActions.includes('reactivate'),
        type: 'divider',
      },

      block: {
        key: 'block',
        hidden: false,
        disabled: disableActions.includes('block'),
        label: <div>{translateX('block_invitation')}</div>,
      },

      edit: {
        key: 'edit',
        hidden: false,
        disabled: disableActions.includes('edit'),
        icon: <EditOutlined />,
        label: <div>{translateX('edit')}</div>,
      },

      delete: {
        key: 'delete',
        hidden: false,
        disabled: disableActions.includes('delete'),
        icon: <DeleteOutlined />,
        label: <div>{translateX('delete_organization')}</div>,
      },
    };

    // -2: blocked, -1: declined, 0: suspended, 1: invited, 2: accepted
    switch (status) {
      case -2: {
        availableActions.push(actionsObj.delete);
        break;
      }

      case -1: {
        availableActions.push(
          actionsObj.block,
          actionsObj.divider,
          actionsObj.delete,
        );
        break;
      }

      case 0: {
        disableActions.push('suspend');
        availableActions.push(actionsObj.delete);
        break;
      }

      case 1: {
        availableActions.push(actionsObj.accept, actionsObj.decline);
        break;
      }

      default: {
        // 2

        if (profile === 'owner') {
          availableActions.push(
            actionsObj.edit,
            actionsObj.divider,
            actionsObj.delete,
          );
        } else {
          availableActions.push(actionsObj.delete);
        }

        break;
      }
    }

    return availableActions;
  }
};*/

// COMPONENT *******************************************************************
// *****************************************************************************
const ItemHeader = props => {
  // props deconstruction ------------------------------------------------------
  const { title, profile } = props;

  // component states ----------------------------------------------------------
  // local variables -----------------------------------------------------------
  // hooks ---------------------------------------------------------------------
  // methods -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <div>
      <h4 className="mb-1">{title}</h4>

      <div className="text-muted flex-row" style={{ marginLeft: -1 }}>
        <SubscriptionProfileIcon profile={profile} />
        &nbsp;&nbsp;
        {translateX(profile)}
      </div>
    </div>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// COMPONENT *******************************************************************
// *****************************************************************************
const ItemMember = props => {
  // props deconstruction ------------------------------------------------------
  const { record } = props;

  // component states ----------------------------------------------------------
  // local variables -----------------------------------------------------------
  // hooks ---------------------------------------------------------------------
  // methods -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  if (!record) return null;

  return (
    <>
      {record?.map((member, i) =>
        i <= 2 ? (
          <Tooltip title={member?.name} key={`avatar-${i}`}>
            <Avatar
              size="small"
              className={`ml-1 ant-avatar-${member.backColor}`}
              src={member.img}
            >
              {member.img ? (
                ''
              ) : (
                <span className="font-weight-semibold font-size-sm">
                  {utils.getNameInitial(member.name)}
                </span>
              )}
            </Avatar>
          </Tooltip>
        ) : null,
      )}

      {record.length > 3 ? (
        <Tooltip title={`${record.length - 3} More`}>
          <Avatar
            size={25}
            className="ml-1 cursor-pointer bg-white border font-size-sm"
          >
            <span className="text-gray-light font-weight-semibold">
              +{record.length - 3}
            </span>
          </Avatar>
        </Tooltip>
      ) : null}
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// COMPONENT *******************************************************************
// *****************************************************************************
const TileItem = props => {
  // props deconstruction ------------------------------------------------------
  const { record, hoverable, strongBorder } = props;

  // component states ----------------------------------------------------------
  // local variables -----------------------------------------------------------
  const navigate = useNavigate();

  // hooks ---------------------------------------------------------------------
  // methods -------------------------------------------------------------------
  const onCardClick = () => {
    if (record?.__myAccount?.profile === 'owner') {
      navigate(`/app/account/settings/organization/${record._id}`);
    } else {
      console.log('guest: abre modal com opções de ingresso ou recusa!');
    }
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <Card
      hoverable={hoverable}
      className={strongBorder ? 'strong-border' : ''}
      onClick={onCardClick}
    >
      <Flex align="start" justify="space-between">
        <ItemHeader
          title={record?.organization}
          profile={record?.__myAccount?.profile}
        />

        {/*<ItemAction*/}
        {/*  subscriptionId={record?._id}*/}
        {/*  status={record?.__myAccount?.status}*/}
        {/*  profile={record?.__myAccount?.profile}*/}
        {/*/>*/}
      </Flex>

      <Divider style={{ margin: '12px 0' }} />

      <div className="mt-2">
        <ItemMember record={record.team} />
      </div>
    </Card>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default TileItem;
