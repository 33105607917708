import React, { forwardRef, useContext, useState } from 'react';
import { Card, Col, Descriptions, Modal, Row } from 'antd';
import IntlMessage from 'components/util-components/IntlMessage';
import {
  MailOutlined,
  MobileOutlined,
  PrinterOutlined,
  SendOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import { translateX } from 'utils/helpers';
import {
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislySegmented,
  JarvislyTextArea,
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import appService from 'services/appService';
import authService from 'services/authService';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    showModal,
    setShowModal,
    afterClose,
    isDisabled,
    updating,
    document,
  } = props;

  // local variables I ----------------------------------------------------------

  //   // component states -----------------------------------------------------------
  const [channel, setChannel] = useState('whatsapp');

  // local variables II ---------------------------------------------------------
  const channelOptions = buildChannelOptions();

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    form.resetFields();
    setShowModal(false);
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        className="title-fixed-with-18 modal-top"
        title={
          <>
            <SendOutlined className="mr-2" />
            {translateX('request_update')}
          </>
        }
        destroyOnClose={true}
        open={showModal}
        width={640}
        okText={translateX(!isDisabled ? 'send' : 'close')}
        onCancel={onClose}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => (isDisabled ? onClose() : form.submit())}
        getContainer={false}
        okType={isDisabled ? 'default' : 'primary'}
        confirmLoading={updating}
        afterClose={afterClose}
      >
        <Card>
          <Descriptions
            title={setLocale(true, 'responsible')}
            size="small"
            column={1}
          >
            <Descriptions.Item
              label={setLocale(true, 'name')}
              labelStyle={{ width: 140 }}
            >
              <span className="text-muted">
                {document?.dataResponsible?.name}
              </span>
            </Descriptions.Item>
            <Descriptions.Item
              label={setLocale(true, 'mobile')}
              labelStyle={{ width: 140 }}
            >
              <span className="text-muted">
                {document?.dataResponsible?.phone}
              </span>
            </Descriptions.Item>
            <Descriptions.Item
              label={setLocale(true, 'email')}
              labelStyle={{ width: 140 }}
            >
              <span className="text-muted">
                {document?.dataResponsible?.email}
              </span>
            </Descriptions.Item>
            <Descriptions.Item
              label={setLocale(true, 'access_code')}
              labelStyle={{ width: 140 }}
            >
              <span className="text-muted">
                {document?.dataResponsible?.id}
              </span>
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <Row gutter={ROW_GUTTER}>
          {/* <Col xs={24} sm={24} lg={12}>
            <JarvislySelect
              name="campaign"
              label="campaign"
              options={[
                { value: 'Pai/Mãe', label: translateX('father_mother') },
                {
                  value: 'Padrasto/Madrasta',
                  label: translateX('stepfather_mother'),
                },
                { value: 'Avô/Avó', label: translateX('grandfather_mother') },
                { value: 'Tio(a)', label: translateX('uncle_aunt') },
              ]}
            />
          </Col>
          <Col xs={24} sm={24} lg={12}>
            <JarvislyDatePicker
              name="expireAt"
              label="expire_at"
              onChange={onChangeDueDate}
            />
          </Col> */}
          <Col span={24}>
            <JarvislySegmented
              name="channel"
              disabled={isDisabled}
              options={channelOptions}
              onChange={v => setChannel(v)}
              //   elRef={elRefs.channel}
            />
          </Col>
          {channel !== 'form' && (
            <Col xs={24}>
              <JarvislyTextArea
                name="message"
                disabled={isDisabled}
                rows={5}
                // elRef={elRefs.message}
              />
            </Col>
          )}
        </Row>
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************

const AskUpdateModal = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { document, isWorking, setShowModal } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  const onConfirm = async ({ body, form }) => {
    console.log('body ->', body);
    const data = {
      channel: body.channel,
      message: body.message,
    };

    switch (data.channel) {
      case 'sms':
        try {
          appService.notification(
            <MobileOutlined />,
            'sending_sms',
            'sendingKey',
            'wait_for_sms',
            0,
          );
          await authService.sendRequestUpdateMessage(
            document?.dataResponsible?.name,
            document?.dataResponsible?.phone,
            data,
          );
          appService.notificationDestroy('sendingKey');
          setShowModal(false);
        } catch (error) {
          console.log('Erro ao enviar --> ', error);
          return;
        }
        break;
      case 'email':
        console.log('Teste email');
        break;
      default:
        console.log('Selecione um canal de envio');
    }

    form?.resetFields();

    // refreshDocument(body._id, true);

    return body;
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <JarvislyFormProvider
      name="form-contact"
      //   document={selectedRecord}
      requiredFields={['message', 'status']}
      showSubmitButton={false}
      onConfirm={onConfirm}
      //   autoFocus="nameResponsable"
    >
      <ModalComponent
        {...props}
        updating={isWorking}
        // record={selectedRecord}
        afterClose={() => {}}
      />
    </JarvislyFormProvider>
  );
});

// INTERNAL FUNCTIONS ========================================================
// ===========================================================================
function buildChannelOptions() {
  return [
    {
      value: 'whatsapp',
      label: translateX('whatsapp'),
      icon: <WhatsAppOutlined />,
    },
    {
      value: 'sms',
      label: translateX('sms'),
      icon: <MobileOutlined />,
    },
    {
      value: 'email',
      label: translateX('email'),
      icon: <MailOutlined />,
    },
    {
      value: 'form',
      label: translateX('form'),
      icon: <PrinterOutlined />,
    },
  ];
}

// EXPORT **********************************************************************
// *****************************************************************************

export default AskUpdateModal;
