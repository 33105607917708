import { BulbOutlined } from '@ant-design/icons';

const MODULE_TITLE = 'foo';
const MODULE_ICON = BulbOutlined;
const MODULE_MAIN_ROUTE = `/app/scaffolding/${MODULE_TITLE}`;

const FOO_MODULE = {
  name: 'foo',
  title: MODULE_TITLE,
  icon: MODULE_ICON,
  menus: {
    scaffolding: [
      {
        path: MODULE_MAIN_ROUTE,
        title: MODULE_TITLE,
        icon: MODULE_ICON,
        submenu: [],
      },
    ],
  },

  url: MODULE_MAIN_ROUTE,
  routes: [`${MODULE_MAIN_ROUTE}/:_id/:section?`, MODULE_MAIN_ROUTE],
};

export default FOO_MODULE;
