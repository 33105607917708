import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Row } from 'antd'
import { setLocale } from 'utils/helpers'
import { useDispatch } from 'react-redux'
import { rdxSetModuleType, rdxSetShowBreadcrumb, rdxSetShowPageHeader } from "../../../store/slices/moduleSlice";

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************

const NoRouteSet = () => {

  // local variables -----------------------------------------------------------
  const dispatch = useDispatch()
  const location = useLocation()

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    dispatch(rdxSetModuleType(null))
    dispatch(rdxSetShowBreadcrumb(true))
    dispatch(rdxSetShowPageHeader(true));

  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // UI COMPONENT --------------------------------------------------------------
  return (<>

    <Row justify="center">

      <div className="text-center text-muted mt-5">

        <img src="/img/others/no-route-found.png" alt="in development"
             style={{
               height: 200,
               padding: 20,
               marginTop: 40,
             }}/>

        <h3 className="text-uppercase text-muted">
          {setLocale('no_route_set')}
        </h3>

        <div>{location.pathname}</div>

      </div>

    </Row>

  </>)
}

// EXPORT **********************************************************************
// *****************************************************************************

export default NoRouteSet
