import React from 'react';
import { Col, Empty, Row } from 'antd';

/*
import IntlMessage from '../util-components/IntlMessage';

const setLocale = (isLocaleOn, localeKey) => isLocaleOn ?
  <IntlMessage id={localeKey}/> : localeKey.toString();
*/

const NoData = ({ marginTop = 80 }) => {
  return (
    <Row
      justify="center"
      align="middle"
      style={{
        // height: '80vh',
        // position: 'absolute',
        width: '100%',
        marginTop,
      }}
    >
      <Col>
        <Empty />
      </Col>
    </Row>
  );
};

export default NoData;
