import React, { useState } from 'react';
import { AutoComplete, Flex, Spin } from 'antd';
import { debounce, normalize } from 'utils/helpers';

export const AsyncAutoComplete = ({
  'x-on-search': xOnSearch,
  'x-on-select': xOnSelect,
  'x-min-char-to-search': xMinCharToSearch,
  'x-inner-ref': xInnerRef,
  ...props
}) => {
  const { debounceTimeout: delay = 400 } = props;

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [valueSearched, setValueSearched] = useState();

  const debounceSearch = searchValue => {
    if (!xOnSearch || searchValue.length < (xMinCharToSearch || 3)) return;

    const sv = normalize(searchValue, 'lower');
    if (sv?.includes(valueSearched)) return;

    setOptions([]);
    setLoading(true);

    debounce(async () => {
      xOnSearch(searchValue).then(result => {
        if (!result) {
          const v = normalize(searchValue, 'lower');
          setValueSearched(v);
        }

        setOptions(result || []);
        setLoading(false);
      });
    }, delay);
  };

  return (
    <AutoComplete
      {...props}
      ref={xInnerRef}
      labelInValue
      allowClear
      backfill
      onSearch={debounceSearch}
      notFoundContent={
        loading ? (
          <Flex justify="center" style={{ padding: 11 }}>
            <Spin size="small" />
          </Flex>
        ) : null
      }
      onSelect={xOnSelect}
      options={options}
    />
  );
};
