import {
  Alert,
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Flex,
  Form,
  InputNumber,
  Menu,
  Row,
  Statistic,
  Steps,
  Switch,
  Tooltip,
} from 'antd';
import { downloadFile, toCapitalCase, translateX } from 'utils/helpers';
import { forwardRef, useEffect, useRef, useState } from 'react';
import PageHeaderInfo from 'components/layout-components/PageHeaderInfo';
import {
  CheckOutlined,
  CloseOutlined,
  DollarOutlined,
  DownloadOutlined,
  GlobalOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  ShoppingCartOutlined,
  TagOutlined,
  TrophyOutlined,
} from '@ant-design/icons';
import {
  FormDateRangerPicker,
  FormNumber,
  FormSelect,
  FormWrapper,
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { FaPlaneArrival, FaPlaneDeparture } from 'react-icons/fa';
import dayjs from 'dayjs';
import appmultiService from 'services/appmultiService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import routeService from 'services/routeService';
import Icon from 'components/util-components/Icon';
import { MdOutlineShoppingCart } from 'react-icons/md';
import appService from 'services/appService';
import Meta from 'antd/es/card/Meta';
import { AiOutlineSafetyCertificate } from 'react-icons/ai';
import financialService from 'services/financialService';
import MultiQuotationCoveragesModal from './List/Modals/CoveragesModal';
import MultiQuotationPriceAdjustmentModal from './Form/Steps/Modals/PriceAdjustmentModal';
import { useSelector } from 'react-redux';
import { BsPersonPlusFill  } from "react-icons/bs";


const defaultAge = 21;
const minAge = 1;
const maxAge = 99;

export const MultiQuotationsHeader = forwardRef((props, ref) => {
  // props deconstruction ------------------------------------------------------
  const {
    isWorking,
    document,
    setDocumentContext,
    setIsWorking,
    selectedModule,
  } = props;

  // component states ----------------------------------------------------------
  const [visible, setVisible] = useState(false);
  const [passengerAges, setPassengerAges] = useState([]);
  // const [passengerBirthdays, setPassengerBirthdays] = useState([]);

  // local variables -----------------------------------------------------------
  const elRefs = {};
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const destinationOptions = appmultiService.getDestinations();
  const [form] = Form.useForm();
  const isDone = document?.status === 'generated_order';
  const isDisabled = isWorking || isDone;
  const disabledDate = current => current && current < dayjs().startOf('day');
  const { selectedSubscription } = useSelector(s => s.authSlice);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    const fields = ['destiny', 'departure', 'arrival', 'passengers'];
    const filter = routeService.getQueryString(fields, searchParams);

    if (!destinationOptions) return;

    if (destinationOptions?.findIndex(d => d?.value === filter?.destiny) > -1) {
      form.setFieldValue('destiny', filter.destiny);
    } else {
      if (!isDone) form.setFieldValue('destiny', document?.destiny);
    }

    const travelPeriod = [];

    if (dayjs(filter?.departure).isValid()) {
      travelPeriod.push(dayjs(filter.departure, 'YYYY-MM-DD'));
    } else {
      if (document?.__travelPeriod?.[0] && !isDone) {
        travelPeriod.push(document.__travelPeriod[0]);
      }
    }

    if (dayjs(filter?.arrival).isValid()) {
      travelPeriod.push(dayjs(filter.arrival, 'YYYY-MM-DD'));
    } else {
      if (document?.__travelPeriod?.[1] && !isDone) {
        travelPeriod.push(document.__travelPeriod[1]);
      }
    }

    const departure = travelPeriod?.[0];
    const arrival = travelPeriod?.[1];

    if (filter?.passengers && filter.passengers?.includes(',')) {
      filter.passengers = filter.passengers.split(',');
      filter.passengers = filter.passengers.map(p => {
        if (!isNaN(p)) {
          const age = Number(p);
          return age < minAge || age > maxAge ? defaultAge : age;
        } else {
          return null;
        }
      });
      filter.passengers = filter.passengers.filter(p => p);
      if (filter.passengers.length === 0) filter.passengers.push(defaultAge);
    }

    const passengers =
      filter?.passengers &&
      Array.isArray(filter.passengers) &&
      filter.passengers.length > 0
        ? filter?.passengers
        : filter?.passengers && !isNaN(filter.passengers)
        ? [filter.passengers]
        : [defaultAge];

    setPassengerAges([...passengers]);

    const days =
      departure && arrival ? dayjs(arrival).diff(departure, 'days') + 1 : 0;

    form.setFieldValue('__travelPeriod', travelPeriod);
    form.setFieldValue('days', days);
    form.setFieldValue('passengers', passengers.length);

    setTimeout(async () => await filterPlans(passengers), 600);
  }, [form, document, selectedSubscription]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------

  const onChangeTravelPeriod = async value => {
    if (!value) {
      form.setFieldValue('__travelPeriod', []);
      form.setFieldValue('days', 0);
    } else {
      const days = dayjs(value[1]).diff(value[0], 'days') + 1;
      form.setFieldValue('__travelPeriod', value);
      form.setFieldValue('days', days);
    }
  
    await filterPlans();
  };
  

  const filterPlans = async travellers => {
    setIsWorking(true);

    const destiny = form.getFieldValue('destiny') || null;
    const travelPeriodArr = form.getFieldValue('__travelPeriod');
    const departure = travelPeriodArr?.[0]?.format('YYYY-MM-DD') || null;
    const arrival = travelPeriodArr?.[1]?.format('YYYY-MM-DD') || null;
    const passengers = Array.isArray(travellers) ? travellers : passengerAges;

    const qsObj = {
      ...routeService.getQueryString(['tab'], searchParams),
      destiny,
      departure,
      arrival,
      passengers,
    };

    routeService.setQueryString(qsObj, navigate);

    const days =
      departure && arrival
        ? dayjs(travelPeriodArr[1]).diff(travelPeriodArr[0], 'days') + 1
        : 0;

    if (isDone) return setIsWorking(false);

    const data = await appmultiService.getPlans(
      destiny,
      travelPeriodArr,
      passengers,
    );

    setDocumentContext({
      listPlans: data,
      filter: {
        destiny,
        travelPeriodArr,
        departure,
        arrival,
        passengers,
        days,
      },
    });
    setIsWorking(false);
  };

  const handleAgeChange = async (passengerIdx, value) => {
    if (value === null) {
      passengerAges[passengerIdx] = 0;
      setPassengerAges([...passengerAges]);
    } else {
      passengerAges[passengerIdx] = value;
      setPassengerAges([...passengerAges]);
    }
  };

  const onClickAddPassenger = async () => {
    const travellers = [...passengerAges, defaultAge];
    setPassengerAges(travellers);
    form.setFieldValue('passengers', passengerAges.length + 1);
  };

  const remove = async idx => {
    passengerAges.splice(idx, 1);
    setPassengerAges([...passengerAges]);
    form.setFieldValue('passengers', passengerAges.length);
  };

  const handleVisibleChange = async flag => {
    setVisible(flag);
    if (!flag) {
      setTimeout(async () => await filterPlans(passengerAges), 400);
    } else {
    }
  };

  // UI COMPONENT --------------------------------------------------------------

  const menu = buildMenuAges();

  const datePickerRef = useRef(null);

  useEffect(() => {
    if (datePickerRef.current) {
      datePickerRef.current.querySelector('.ant-picker-input').addEventListener('click', e => {
        e.stopPropagation();
      });
    }
  }, []);

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  return (
    <div ref={ref}>
      <FormWrapper
        name="form-quotation-header"
        form={form}
        className="mb-3"
        idField="_id"
        elRefs={elRefs}
        initialValues={document}
        // onValuesChange={onValuesChange}
      >
        <PageHeaderInfo className="no-padding-bottom">
          <Row justify="start" align="top">
            <Col xs={24}>
              <h3
                className="mb-0 mr-3 font-weight-semibold"
                style={{ minWidth: 300 }}
              >
                {selectedModule?.icon && (
                  <Icon type={selectedModule.icon} className="mr-2" />
                )}
                {translateX(selectedModule?.title)}
              </h3>
            </Col>
          </Row>

          <Row gutter={ROW_GUTTER} className="mt-3">
            {/* DESTINY */}
            <Col xs={24} md={8}>
              <FormSelect
                name="destiny"
                options={destinationOptions}
                onChange={filterPlans}
                elRefs={elRefs}
                prefix={<GlobalOutlined className="text-primary" />}
                disabled={isDisabled}
                autoFocus
                required
              />
            </Col>
            {/* DESTINY */}

            {/* PASSENGERS */}
            <Col xs={24} md={4}>
              <Row align="middle" style={{ flexWrap: 'nowrap' }}>
                <FormNumber
                  label="passengersAndAges"
                  name="passengers"
                  disabled={true}
                  itemStyle={{ width: '100%' }}
                  onChange={filterPlans}
                  min={1}
                  max={20}
                  required
                />

                <Tooltip title={translateX('inform_passengers_and_ages')}>
                  <Dropdown
                    overlay={menu}
                    placement="bottom"
                    // placement={isMobile ? 'bottomRight' : 'bottomCenter'}
                    open={visible}
                    onVisibleChange={handleVisibleChange}
                    trigger={['click']}
                    arrow
                  >
                    <Button
                      style={{
                        padding: 8,
                        width: 40,
                        height: 40,
                        marginTop: 4,
                        marginLeft: -40,
                        fontSize: '18px'
                      }}
                      icon={<BsPersonPlusFill  />}
                      // onClick={() => setShowDiagnosticModal(true)}
                      // onClick={showDiagnostic}
                    />
                  </Dropdown>

                  {/* <Dropdown
                    menu={{
                      items: menuItems,
                    }}
                    placement="bottom"
                    open={visible}
                    onOpenChange={handleVisibleChange}
                    arrow
                  >
                    <div>
                      <span style={{ marginRight: 8 }}>
                        Selecione as Idades
                      </span>
                    </div>
                  </Dropdown>*/}

                  {/*
                  <Button
                    style={{
                      padding: 8,
                      width: 40,
                      height: 40,
                      marginTop: 4,
                      marginLeft: -40,
                    }}
                    icon={<MdOutlineElderly />}
                    // onClick={() => setShowDiagnosticModal(true)}
                    // onClick={showDiagnostic}
                  />
*/}
                </Tooltip>
              </Row>
            </Col>
            {/* PASSENGERS */}

            {/* PASSENGERS */}
            {/*<Col xs={1} md={1}>*/}
            {/*  <Tooltip title={translateX('edit_age')}>*/}
            {/*    <Button*/}
            {/*      icon={<MdOutlineElderly />}*/}
            {/*      // onClick={() => setShowDiagnosticModal(true)}*/}
            {/*      // onClick={showDiagnostic}*/}
            {/*    />*/}
            {/*  </Tooltip>*/}
            {/*</Col>*/}
            {/* PASSENGERS */}

            {/* TRAVEL PERIOD */}
            <Col xs={20} md={9}>
              <FormDateRangerPicker
                name="__travelPeriod"
                label="travel_period"
                prefix={FaPlaneDeparture}
                disabled={isDisabled}
                onChange={onChangeTravelPeriod}
                disabledDate={disabledDate}
                elRef={elRefs}
                required
                format={{
                  format: 'DD/MM/YYYY',
                  type: 'mask',
                }}
              />
            </Col>
            {/* TRAVEL PERIOD */}

            {/* DAYS */}
            <Col xs={4} md={3}>
              <FormNumber
                name="days"
                elRef={elRefs}
                disabled={true}
                itemStyle={{ width: '100%' }}
              />
            </Col>
            {/* DAYS */}
          </Row>
        </PageHeaderInfo>
      </FormWrapper>
    </div>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildMenuAges() {
    return (
      <Menu>
        {passengerAges.map((p, idx) => (
          <Menu.Item key={idx}>
            <Row align="middle" gutter={ROW_GUTTER}>
              <Col
                className="mr-4"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                  height: '80px',
                }}
              >
                <p style={{ marginBottom: 0 }}>Passageiro: </p>
                <div style={{ marginBottom: '10px' }}>{idx + 1}</div>
              </Col>
              <Col style={{ display: 'flex', flexDirection: 'column' }}>
                <p
                  style={{
                    marginBottom: 0,
                    marginRight: '.5rem',
                    marginLeft: '.5rem',
                  }}
                >
                  Idade:{' '}
                </p>

                <InputNumber
                  className="m-2"
                  min={1}
                  max={99}
                  placeholder="Idade"
                  value={passengerAges[idx]}
                  onChange={value => handleAgeChange(idx, value)}
                />
              </Col>
              <Col
                style={{
                  height: '65px',
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
              >
                <Button
                  icon={<MinusCircleOutlined />}
                  disabled={passengerAges.length === 1}
                  onClick={() => remove(idx)}
                />
              </Col>
            </Row>
          </Menu.Item>
        ))}

        <Menu.Item key="add">
          <Row justify="center" align="middle">
            <Button
              type="primary"
              className="ml-2, mr-2"
              onClick={onClickAddPassenger}
            >
              <PlusOutlined />
              <span>Passageiro</span>
            </Button>
          </Row>
        </Menu.Item>
      </Menu>
    );
  }
});

const PlanComparatorSwitch = props => {
  const {
    plan,
    setCompare,
    compare,
    setCompareColumns,
    setCompareData,
    comparePlans,
    setComparePlans,
    setSelectedRecord,
    setShowCompareModal,
    onClickOpenCheckoutModal,
  } = props;

  const onDownloadFile = item => {
    if (!item?.coveragesFileUrl || !item?.name) return;
    const fileName = `COBERTURAS ${item?.name.toUpperCase()}.pdf`;
    downloadFile(item.coveragesFileUrl, fileName);
  };

  const onMoreInfo = item => {
    if (!item?.coverageAdditionalInfo) return;

    appService.modal('i', 'Info', {
      Content: item.coverageAdditionalInfo,
    });
  };

  const onClickCompare = checked => {
    let plans;

    if (checked) {
      plans = [...comparePlans, plan];

      plans.sort((a, b) => {
        const priceA = a.summary.total?.resellerNetPrice || 0;
        const priceB = b.summary.total?.resellerNetPrice || 0;
        return priceA - priceB;
      });

      setCompare([
        ...compare,
        {
          _id: plan?._id,
          name: plan?.name,
        },
      ]);
    } else {
      plans = comparePlans.filter(p => p?._id !== plan?._id);
      setCompare([...compare.filter(p => p?._id !== plan?._id)]);
    }

    const coveragesList = Array.from(
      new Set([
        'price',
        'make_purchase',
        ...plans.flatMap(item =>
          item.coverages.map(coverage => coverage.coverageKey),
        ),
        'all_coverages',
        'additional_info',
      ]),
    );

    // build columns

    const columns = [
      {
        title: translateX('coverages'),
        dataIndex: 'coverageKey',
        width: 400,
        fixed: 'left',
        render: value => translateX(value),
      },
      ...plans.map(plan => ({
        title: (
          <>
            <div style={{ marginBottom: 14 }}>
              <Avatar
                size={86}
                style={{
                  border: `1px ${
                    plan?.__operator?.backColor || 'darkcyan'
                  } solid`,
                }}
                src={plan?.__operator?.pictureUrl}
              />
            </div>
            <div>
              <span style={{ color: 'darkcyan' }}>{plan.name}</span>
            </div>

            <Flex justify="center">
              <div
                style={{
                  display: 'flex',
                  gap: '8px',
                  marginTop: 12,
                  // marginBottom: 12,
                }}
              >
                {plan?.cheaper ? (
                  <Alert
                    message="MAIS BARATO"
                    type="success"
                    icon={<TagOutlined />}
                    showIcon
                  />
                ) : null}

                {plan?.moreExpansive ? (
                  <Alert
                    message="MAIS CARO"
                    type="error"
                    icon={<TagOutlined />}
                    showIcon
                  />
                ) : null}

                {plan?.bestSeller ? (
                  <Alert
                    message="MAIS VENDIDO"
                    type="info"
                    icon={<TrophyOutlined />}
                    showIcon
                  />
                ) : null}
              </div>
            </Flex>
          </>
        ),
        dataIndex: plan._id,
        align: 'center',
        render: value => {
          if (value === 'price') {
            const resellerGrossPrice = plan?.summary?.total?.resellerGrossPrice;
            const resellerNetPrice = plan?.summary?.total?.totalToPay;

            const valueWithDiscount = financialService.discount(
              resellerNetPrice,
              5,
              2,
              true,
            );

            return (
              <Flex justify="center" style={{ flexDirection: 'column' }}>
                <Statistic
                  className={`price-from-to text-center`}
                  title={`DE: ${financialService.formatMoney({
                    value: resellerGrossPrice,
                    localeSymbol: 'brl',
                    resultToStr: true,
                  })}`}
                  value={`${financialService.formatMoney({
                    value: resellerNetPrice,
                    localeSymbol: 'brl',
                    resultToStr: true,
                  })}`}
                  prefix="POR:"
                />

                <div>em 6X cartão ou {valueWithDiscount} no pix</div>
              </Flex>
            );
          } else if (value === 'make_purchase') {
            return (
              <Flex justify="center">
                <Button
                  size="large"
                  // style={{ marginLeft: 18 }}
                  type="primary"
                  icon={<ShoppingCartOutlined />}
                  // disabled={disabled}
                  onClick={() => {
                    setSelectedRecord(plan);
                    setShowCompareModal(false);
                    onClickOpenCheckoutModal(plan);
                  }}
                >
                  Comprar
                </Button>
              </Flex>
            );
          } else if (value === 'all_coverages') {
            return (
              <Flex justify="center">
                {plan?.coveragesFileUrl ? (
                  <Button
                    type="link"
                    icon={<DownloadOutlined />}
                    onClick={() => onDownloadFile(plan)}
                  >
                    Download
                  </Button>
                ) : (
                  '-'
                )}
              </Flex>
            );
          } else if (value === 'additional_info') {
            return (
              <Flex justify="center">
                {plan?.coverageAdditionalInfo ? (
                  <Button
                    type="default"
                    icon={<PlusOutlined />}
                    onClick={() => onMoreInfo(plan)}
                  >
                    Info
                  </Button>
                ) : (
                  '-'
                )}
              </Flex>
            );
          } else {
            return value ? value : '-';
          }
        },
      })),
    ];

    const dataSource = coveragesList.map(coverageKey => {
      const rowData = { key: coverageKey, coverageKey };

      plans.forEach(item => {
        const coverage = item.coverages.find(
          cov => cov.coverageKey === coverageKey,
        );

        if (coverageKey === 'price') {
          rowData[item._id] = 'price';
        } else if (coverageKey === 'make_purchase') {
          rowData[item._id] = 'make_purchase';
        } else if (coverageKey === 'all_coverages') {
          rowData[item._id] = 'all_coverages';
        } else if (coverageKey === 'additional_info') {
          rowData[item._id] = 'additional_info';
        } else {
          rowData[item._id] = coverage
            ? appmultiService.getCoverageValue(coverage, true)
            : '-';
        }
      });

      return rowData;
    });

    setComparePlans([...plans]);
    setCompareColumns([...columns]);
    setCompareData([...dataSource]);

    return checked;
  };

  const isChecked = !!compare.find(p => p?._id === plan?._id);

  return (
    <>
      <Switch
        className="mr-3"
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        checked={isChecked}
        onChange={onClickCompare}
      />
      Comparar Plano
    </>
  );
};

export const CardPlan = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    plan,
    total = props?.plan?.summary?.total,
    highlights = props?.plan?.highlights,
    onClickOpenCheckoutModal,
    filter,
    profile = 'listing',
    strongBorder = true,
    setSelectedRecord,
    title,
  } = props;

  // local variables I ---------------------------------------------------------
  const isMobile = appService.isMobile();
  const priceAdjustmentModalRef = useRef(null);
  const resellerNetPrice = total?.resellerNetPrice || 0;
  const adjustedPrice = total?.totalAdjusted || 0;
  const isDiff = adjustedPrice !== resellerNetPrice;

  // component states ----------------------------------------------------------
  const [showCoveragesModal, setShowCoveragesModal] = useState(false);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const openPriceAdjustmentModal = () => {
    priceAdjustmentModalRef.current.setShowModal(true);
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      {/* PRICE ADJUSTMENT MODAL WRAPPER */}
      <MultiQuotationPriceAdjustmentModal
        {...props}
        total={total}
        ref={priceAdjustmentModalRef}
      />
      {/* PRICE ADJUSTMENT MODAL WRAPPER */}

      <MultiQuotationCoveragesModal
        showModal={showCoveragesModal}
        setShowModal={setShowCoveragesModal}
        record={plan}
        setSelectedRecord={setSelectedRecord}
      />

      <Card
        bordered={true}
        title={title}
        hoverable={profile === 'listing'}
        key={plan?._id}
        className={`pl-2 pr-2 ${strongBorder ? 'strong-border' : ''}`}
        style={{
          width: '100%',
        }}
      >
        {profile !== 'checkout' ? (
          <Flex justify="flex-end">
            <div
              style={{
                display: 'flex',
                gap: '8px',
                marginTop: -40,
                marginBottom: 12,
              }}
            >
              {plan?.cheaper ? (
                <Alert
                  message="MAIS BARATO"
                  type="success"
                  icon={<TagOutlined />}
                  showIcon
                />
              ) : null}

              {plan?.moreExpansive ? (
                <Alert
                  message="MAIS CARO"
                  type="error"
                  icon={<TagOutlined />}
                  showIcon
                />
              ) : null}

              {plan?.bestSeller ? (
                <Alert
                  message="MAIS VENDIDO"
                  type="info"
                  icon={<TrophyOutlined />}
                  showIcon
                />
              ) : null}
            </div>
          </Flex>
        ) : null}

        <Row gutter={ROW_GUTTER} align="stretch">
          <Col
            xs={24}
            md={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: 'auto',
            }}
          >
            <Flex vertical justify="space-between" className="h-100">
              <Meta
                className="title-without-margin icon-64 brake-title title-risk"
                avatar={
                  <Avatar
                    size={86}
                    style={{
                      border: `1px ${
                        plan?.__operator?.backColor || 'darkcyan'
                      } solid`,
                    }}
                    src={plan?.__operator?.pictureUrl}
                  />
                }
                title={<span className="text-uppercase">{plan?.name}</span>}
                description={
                  <>
                    <div>
                      {plan?.minAge} á {plan?.maxAge} Anos | Mínimo{' '}
                      {plan?.minDays} dia{plan?.minDays > 1 ? 's' : ''}
                    </div>
                    <div>{toCapitalCase(plan?.__operator?.name)}</div>
                  </>
                }
              />

              {profile === 'listing' ? (
                <Flex className="mt-4" justify="start">
                  {!isMobile ? <PlanComparatorSwitch {...props} /> : null}
                </Flex>
              ) : filter ? (
                <Flex className="mt-5" justify={isMobile ? 'center' : 'start'}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ marginTop: 2 }}>
                      <Icon type={FaPlaneDeparture} className="mr-2" />
                    </div>
                    <div className="font-weight-bold">
                      {filter?.departure
                        ? dayjs(filter.departure).format('L')
                        : null}
                    </div>
                  </div>

                  <div className="ml-4" style={{ display: 'flex' }}>
                    <div style={{ marginTop: 2 }}>
                      <Icon type={FaPlaneArrival} className="mr-2" />
                    </div>
                    <div className="font-weight-bold">
                      {filter?.arrival
                        ? dayjs(filter.arrival).format('L')
                        : null}
                    </div>
                  </div>
                </Flex>
              ) : null}
            </Flex>
          </Col>

          <Col xs={24} md={7}>
            <Flex
              vertical
              justify="center"
              className={`h-100 ${isMobile ? 'mt-3' : 'mt-0'}`}
            >
              <Steps
                className="fit-last-item mt-4 mb-3 mt-md-0 mb-md-0"
                current={99}
                size="small"
                direction="vertical"
                items={highlights}
              />
            </Flex>
          </Col>

          <Col
            xs={24}
            md={5}
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: 'auto',
            }}
          >
            <Flex vertical justify="space-between" className="h-100">
              <div>
                <Statistic
                  className={`price-from-to text-right ${
                    isDiff ? 'price-from-to-value' : ''
                  }`}
                  title={`DE: ${financialService.formatMoney({
                    value: total?.resellerGrossPrice,
                    localeSymbol: 'brl',
                    resultToStr: true,
                  })}`}
                  value={`${financialService.formatMoney({
                    value: total?.resellerNetPrice,
                    localeSymbol: 'brl',
                    resultToStr: true,
                  })}`}
                  prefix="POR:"
                />

                {isDiff ? (
                  <Statistic
                    className="price-from-to text-right"
                    value={`${financialService.formatMoney({
                      value: total?.totalAdjusted,
                      localeSymbol: 'brl',
                      resultToStr: true,
                    })}`}
                    prefix={translateX('adjusted_price')}
                  />
                ) : null}
              </div>
              <Flex
                className="mt-4"
                style={{
                  flexDirection: !isMobile ? 'row' : 'column-reverse',
                }}
                // justify={isMobile ? 'end' : 'space-between'}
                justify="end"
              >
                {profile === 'listing' ? (
                  <div className={isMobile ? 'mt-4' : 'mt-0'}>
                    {isMobile ? <PlanComparatorSwitch {...props} /> : null}
                  </div>
                ) : null}

                <div className="text-right">
                  {profile === 'checkout' ? (
                    <>
                      {/*<Tooltip
                        title={translateX( 'discount_coupon')}
                        placement="bottom"
                      >
                        <Button
                          className="ml-2"
                          icon={<PercentageOutlined />}
                          // onClick={() => setShowCoveragesModal(true)}
                        />
                      </Tooltip>*/}

                      <Tooltip
                        title={translateX('price_adjustment')}
                        placement="bottom"
                      >
                        <Button
                          danger
                          className="ml-2"
                          icon={<DollarOutlined />}
                          onClick={openPriceAdjustmentModal}
                        />
                      </Tooltip>
                    </>
                  ) : null}

                  <Tooltip title={translateX('coverages')} placement="bottom">
                    <Button
                      className="ml-2"
                      icon={<AiOutlineSafetyCertificate />}
                      onClick={() => setShowCoveragesModal(true)}
                    />
                  </Tooltip>

                  {profile === 'listing' ? (
                    <Tooltip title={translateX('buy')} placement="bottom">
                      <Button
                        type="primary"
                        className="ml-2"
                        icon={<MdOutlineShoppingCart />}
                        onClick={() => onClickOpenCheckoutModal(plan)}
                      />
                    </Tooltip>
                  ) : null}
                </div>
              </Flex>
            </Flex>
          </Col>
        </Row>
      </Card>
    </>
  );
};
