import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { translateX } from 'utils/helpers';
import { Card } from 'antd';
import {
  goFocus,
  JarvislyFormContext,
  JarvislyTextArea,
} from 'components/jarvisly-components/others/FormComponents';
import UploadFirebaseComponent from 'components/jarvisly-components/others/UploadFirebaseComponent';

// COMPONENT *******************************************************************
// *****************************************************************************
const CrmRequirementTab = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------
  const { isFormDisabled, elRefs } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const { setIsWorking, document, tab } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------
  const [attachments, setAttachments] = useState([]);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    goFocus(elRefs, 'requirement', 'end');
  }, [tab, elRefs]);

  useEffect(() => {
    setAttachments(document?.__attachments || []);
  }, [document]); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    attachments,
  }));

  // methods -------------------------------------------------------------------
  const onUploadBefore = async () => setIsWorking(true);
  const onUploadAfter = async () => setIsWorking(false);
  const onUploadError = async () => setIsWorking(false);

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {/* REQUIREMENT */}
      <Card
        title={
          <div className="text-muted">
            {translateX('requirements_analysis')}
          </div>
        }
      >
        <JarvislyTextArea name="requirement" rows={2} label="description" />
      </Card>
      {/* REQUIREMENT */}

      {/* ATTACHMENTS */}
      <Card
        title={<div className="text-muted">{translateX('attachments')}</div>}
      >
        <UploadFirebaseComponent
          hideUploadButton={true}
          buttonTitle="Upload"
          name="requirementFiles"
          tags="requirement"
          listType="picture"
          attachments={attachments}
          setAttachments={setAttachments}
          onUploadBefore={onUploadBefore}
          onUploadAfter={onUploadAfter}
          onUploadError={onUploadError}
          isCroppingEnabled={false}
          accept={'*'}
          fileSize={3072}
          isDisabled={isFormDisabled}
          fileFolder="crm/requirements"
          maxFiles={10}
        />
      </Card>
      {/* ATTACHMENTS */}
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
});

export default CrmRequirementTab;
