import React, { useState } from 'react';
import { Button, Card, Descriptions, Flex, Row, Tooltip } from 'antd';
import { parseCpfToDisplay, translateX } from 'utils/helpers';
import Icon from 'components/util-components/Icon';
import {
  AuditOutlined,
  CarryOutOutlined,
  EditOutlined,
} from '@ant-design/icons';
import AddVoucherModal from './Modals/AddVoucherModal';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const MultiOrdersVoucher = props => {
  // props deconstruction ------------------------------------------------------
  const { document } = props;

  // local variables -----------------------------------------------------------
  const isInsertVoucherDisabled = document?.status !== 'waiting_for_voucher';

  // const isInsertVoucherDisabled =
  //   document?.status &&
  //   ['waiting_for_payment', 'cancelled', 'cancelled_by_customer'].includes(
  //     document.status,
  //   );
  // component states ----------------------------------------------------------
  const [showVoucherModal, setShowVoucherModal] = useState(false);
  const [selectedPassenger, setSelectedPassenger] = useState(null);

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onInsertVoucher = t => {
    setSelectedPassenger(t);
    setShowVoucherModal(true);
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <div className="p-2">
      {/* SUMMARY */}
      <Row justify="center">
        <Card
          title={
            <Flex align="center" style={{ color: 'darkcyan' }}>
              <div style={{ paddingTop: 4 }}>
                <Icon type={AuditOutlined} />
              </div>
              <div style={{ marginLeft: 8 }}>{translateX('vouchers')}</div>
            </Flex>
          }
          className="w-100"
        >
          <AddVoucherModal
            {...props}
            setShowModal={setShowVoucherModal}
            showModal={showVoucherModal}
            setSelectedPassenger={setSelectedPassenger}
            selectedPassenger={selectedPassenger}
          />

          <Descriptions
            bordered
            items={buildDescriptionsVouchers(document?.travellers || [])}
            className="w-100 mb-4"
            column={2}
          />
        </Card>
      </Row>
      {/* SUMMARY */}
    </div>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildDescriptionsVouchers(travellers) {
    if (travellers?.length === 0) return null;

    const vouchers = [];

    travellers.map((t, index) => {
      vouchers.push(
        {
          key: `traveller_${t._id}`,
          label: `${translateX('traveller')} ${index + 1}`,
          children: (
            <>
              <div>{t?.name}</div>
              <div>{dayjs(t?.birthdate).format('L')}</div>
              <div>{parseCpfToDisplay(t?.cpf)}</div>
              <div>{translateX(t?.gender)}</div>
            </>
          ),
        },
        {
          key: `voucher_${t._id}`,
          label: 'Voucher',
          children: (
            <>
              {!t.voucherUrl ? (
                <Button
                  size="small"
                  type="primary"
                  ghost
                  onClick={() => onInsertVoucher(t)}
                  disabled={isInsertVoucherDisabled}
                >
                  <span className="text-uppercase">
                    {translateX('insert_voucher')}
                  </span>
                </Button>
              ) : (
                <Flex justify="space-between" align="center">
                  <Tooltip title="Abrir Voucher PDF">
                    <Link to={t.voucherUrl} target="_blank">
                      <Flex>
                        <div>
                          <Icon
                            type={AuditOutlined}
                            className="text-primary font-size-md"
                            style={{ flexShrink: 0 }}
                          />
                        </div>
                        <div
                          style={{ paddingLeft: 8 }}
                          className="text-uppercase"
                        >
                          {t.voucherNumber}
                        </div>
                      </Flex>
                      <Flex>
                        <div>
                          <Icon
                            type={CarryOutOutlined}
                            className="text-primary font-size-md"
                            style={{ flexShrink: 0 }}
                          />
                        </div>
                        <div style={{ paddingLeft: 8 }}>
                          {dayjs(t?.voucherDate).format('L')}
                        </div>
                      </Flex>
                    </Link>
                  </Tooltip>

                  <Tooltip title="Editar">
                    <Button
                      size="small"
                      type="primary"
                      ghost
                      icon={<EditOutlined />}
                      onClick={() => onInsertVoucher(t)}
                      disabled={isInsertVoucherDisabled}
                    />
                  </Tooltip>
                </Flex>
              )}
            </>
          ),
        },
      );

      return t;
    });

    return vouchers;
  }
};

// EXPORT ====================================================================
// ===========================================================================

export default MultiOrdersVoucher;
