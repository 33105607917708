import {
  goFocus,
  JarvislyCnpj,
  JarvislyCpf,
  JarvislyCpfCnpj,
  JarvislyDatePicker,
  JarvislyFormContext,
  JarvislyInput,
  JarvislySegmented,
  JarvislySelectCompanyType,
  JarvislySelectGender,
  JarvislyTitle,
} from 'components/jarvisly-components/others/FormComponents';
import appService from 'services/appService';
import entityService from 'services/entityService';
import {
  isValidCnpj,
  isValidCpfCnpj,
  returnOnlyNumbers,
  translateX,
} from 'utils/helpers';
import { Col, Flex, Row } from 'antd';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import React, { useContext, useEffect, useState } from 'react';
import { ShopOutlined, UserOutlined } from '@ant-design/icons';
import JarvislyContactForm from './JarvislyContactForm';
import JarvislyAddressForm from './JarvislyAddressForm';
import { ModuleContext } from '../../../jarvisly-module/ModuleContext';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// COMPONENT *******************************************************************
// *****************************************************************************
const JarvislyEntityForm = props => {
  // providers context ---------------------------------------------------------
  const { elRefs } = useContext(JarvislyFormContext);
  const { refreshDocument } = useContext(ModuleContext);

  // props deconstruction ------------------------------------------------------
  const {
    context,
    showTitle,
    showCpf,
    showBirthdate = true,
    showGender = true,
    form,
    profile = 'auto', // 'auto', 'manual', 'company', 'person'
    document,
    activeTab,
    initTab,
  } = props;

  // local variables I ---------------------------------------------------------
  const isMobile = appService.isMobile('sm');
  const segmentedOptions = buildSegmentedOptions();
  const { selectedModule } = useSelector(s => s.moduleSlice);
  const navigate = useNavigate();

  // component states ----------------------------------------------------------
  const [entityType, setEntityType] = useState('company');
  const [personShowGender, setPersonShowGender] = useState(
    entityType === 'person' && showGender,
  );

  // local variables II --------------------------------------------------------
  // const columnSpan =
  //   24 +
  //   (showCpf ? -8 : 0) +
  //   (showBirthdate ? -2 : 0) +
  //   (personShowGender ? -6 : 0);
  // const columnSpanWithoutBirthdate =
  //   24 + (showCpf ? -8 : 0) + (showBirthdate ? -8 : 0);
  const cpfCnpjLabel = buildCpfCnpjLabel();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (initTab !== activeTab) return;

    if (profile === 'auto') {
      goFocus(elRefs, 'cpfCnpj', null, 400);
    } else {
      if (entityType === 'company') {
        goFocus(elRefs, 'cnpj', null, 400);
      } else {
        goFocus(elRefs, 'cpf', null, 400);
      }
    }
    setPersonShowGender(entityType === 'person' && showGender);
  }, [entityType, activeTab]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (document?.dataType) setEntityType(document.dataType);
  }, [document]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onCpfCnpjChange = async ev => {
    let tagId = ev?.target?.id;
    let fieldName;

    if (tagId) {
      const parts = tagId.split('_');
      fieldName = parts && parts.length > 0 ? parts[parts.length - 1] : null;
    }

    const value = typeof ev === 'object' ? ev?.target?.value : ev;
    const cpfCnpj = returnOnlyNumbers(value);

    if (!isValidCpfCnpj(cpfCnpj)) return;

    const doc = await entityService.getEntityByField(cpfCnpj);

    if (!doc) {
      setEntityType(isValidCnpj(cpfCnpj) ? 'company' : 'person');
      return true;
    }

    let title = translateX(
      'document_exists',
      ['%CPF_CNPJ%'],
      [doc?.cpf ? 'CPF' : 'CNPJ'],
    );
    const options = {
      Content: translateX(
        'document_exists_description',
        ['%CPF_CNPJ%'],
        [doc?.cpf ? 'CPF' : 'CNPJ'],
      ),
      okText: 'yes',
      cancelText: 'no',

      onCancel: () => {
        if (!form) {
          console.warn(`no 'form' found on props!`);
          return;
        }

        if (context) {
          form.setFieldValue([context, fieldName], '');
        } else {
          form.setFieldValue(fieldName, '');
        }

        if (fieldName && elRefs) goFocus(elRefs, fieldName, 'all');
        return false;
      },

      onOk: () => {
        if (!form) {
          console.warn(`no 'form' found on props!`);
          return;
        }

        const route = selectedModule?.routes.find(r =>
          r?.includes(selectedModule?.urlForm || selectedModule?.url),
        );

        if (route) {
          const newUrl = route.replace(':_id', doc?._id);
          navigate(newUrl);
        }

        refreshDocument(doc?._id).then();

        setEntityType(doc.dataType || 'company');
        if (fieldName && elRefs) goFocus(elRefs, fieldName, 'all');
        return false;
      },
    };

    appService.modal('q', title, options);

    return false;
  };

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {showTitle ? (
        <JarvislyTitle
          title={typeof showTitle === 'string' ? showTitle : 'personal_data'}
        />
      ) : null}

      {profile === 'manual' ? (
        <Flex
          justify={isMobile ? 'center' : 'space-between'}
          align="center"
          vertical={isMobile}
        >
          {/* ENTITY TYPE OPTIONS */}
          <JarvislySegmented
            name="dataType"
            context={context}
            x-no-label
            options={segmentedOptions}
            onChange={v => setEntityType(v)}
            block={false}
          />
          {/* ENTITY TYPE OPTIONS */}
        </Flex>
      ) : null}

      <Row gutter={ROW_GUTTER}>
        {/* CPF/CNPJ */}
        {showCpf ? (
          <Col xs={24} sm={8} md={6}>
            {profile === 'cnpj' ||
            (profile === 'manual' && entityType === 'company') ? (
              <JarvislyCnpj
                context={context}
                label={cpfCnpjLabel}
                // required={requiredFields.find(x => x === 'cnpj')}
                onChange={onCpfCnpjChange}
              />
            ) : profile === 'cpf' ||
              (profile === 'manual' && entityType === 'person') ? (
              <JarvislyCpf
                context={context}
                label={cpfCnpjLabel}
                // required={requiredFields.find(x => x === 'cpf')}
                onChange={onCpfCnpjChange}
              />
            ) : (
              <JarvislyCpfCnpj
                context={context}
                label={cpfCnpjLabel}
                // required={requiredFields.find(x => x === 'cpf' || x === 'cnpj')}
                onChange={onCpfCnpjChange}
              />
            )}
          </Col>
        ) : null}
        {/* CPF/CNPJ */}

        {/* NAME */}
        <Col xs={24} sm={8} md={12}>
          <JarvislyInput
            context={context}
            name="name"
            label={translateX(
              entityType === 'person' ? 'name_social' : 'company',
            )}
          />
        </Col>
        {/* NAME */}

        {/* GENDER */}
        <Col xs={24} sm={8} md={6}>
          {personShowGender ? (
            <JarvislySelectGender context={context} />
          ) : (
            <JarvislySelectCompanyType context={context} />
          )}
        </Col>
        {/* GENDER */}
      </Row>

      <Row gutter={ROW_GUTTER}>
        {/* FULL NAME */}
        <Col xs={24} sm={16}>
          <JarvislyInput
            context={context}
            name="fullName"
            // required={requiredFields.find(x => x === 'fullName')}
            label={translateX(
              entityType === 'person' ? 'full_name' : 'company_name',
            )}
          />
        </Col>
        {/* FULL NAME */}

        {/* BIRTHDATE */}
        {showBirthdate ? (
          <Col xs={24} sm={8}>
            <JarvislyDatePicker
              context={context}
              name="birthdate"
              label={translateX(
                entityType === 'person' ? 'birthdate' : 'foundation_date',
              )}
            />
          </Col>
        ) : null}
        {/* BIRTHDATE */}
      </Row>

      {/* CONTACTS */}
      <JarvislyContactForm {...props} showWebsite />
      {/* CONTACTS */}

      {/* ADDRESS */}
      <JarvislyAddressForm {...props} />
      {/* ADDRESS */}
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildSegmentedOptions() {
    return [
      {
        label: translateX('legal_person'),
        value: 'company',
        icon: <ShopOutlined />,
      },
      {
        label: translateX('individual_person'),
        value: 'person',
        icon: <UserOutlined />,
      },
    ];
  }

  function buildCpfCnpjLabel() {
    switch (profile) {
      case 'cpf':
        return 'cpf';

      case 'cnpj':
        return 'cnpj';

      case 'manual':
        return translateX(entityType === 'company' ? 'cnpj' : 'cpf');

      case 'auto':
      default:
        return undefined;
    }
  }
};
export default JarvislyEntityForm;
