import { SettingOutlined } from '@ant-design/icons';
import { translateWord, translateX } from 'utils/helpers';
import React, { useState } from 'react';
import { Modal, Tabs } from 'antd';
import AppdedaloAgendasSettingsHollydaysTab from './SettingsHolidaysTab';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************
const initTab = 'agendaBlock';
const moduleName = 'plans';

// COMPONENT *******************************************************************
// *****************************************************************************

const AppdedaloAgendasSettings = props => {
  // props deconstruction ------------------------------------------------------
  const { showModal, setShowModal } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------
  const [tab, setTab] = useState(initTab);

  // local variables II --------------------------------------------------------
  const tabItems = buildTabItens();

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onClose = () => {
    setShowModal(false);
  };

  const onTabClick = tab => {
    if (!tabItems.find(t => t.key === tab)) tab = initTab;
    setTab(tab);
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <Modal
      title={
        <>
          <SettingOutlined />
          <span className="ml-2">{translateX('module_parametrization')}</span>
        </>
      }
      destroyOnClose={true}
      open={showModal}
      // width={640}
      okText={translateX('close')}
      onCancel={onClose}
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={onClose}
      getContainer={false}
      okType="default"
    >
      <Tabs
        type="line"
        tabPosition="top"
        activeKey={tab}
        rootClassName="tab-in-form"
        items={tabItems.filter(x => !x.hide)}
        onTabClick={onTabClick}
      />
    </Modal>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildTabItens() {
    return [
      {
        label: translateWord('agenda_block'),
        key: initTab,
        hide: false,
        children: (
          <AppdedaloAgendasSettingsHollydaysTab
            {...props}
            moduleName={moduleName}
          />
        ),
      },
      // {
      //   label: translateWord('coverages'),
      //   key: 'coverages',
      //   hide: false,
      //   disabled: false,
      //   children: (
      //     <MultiPlansSettingsCoveragesTab {...props} moduleName={moduleName} />
      //   ),
      // },
    ];
  }
};

export default AppdedaloAgendasSettings;
