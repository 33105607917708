import { Card, Tabs } from 'antd';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { translateX } from 'utils/helpers';
import {
  JarvislyFormProvider,
  JarvislyTabProvider,
} from 'components/jarvisly-components/others/FormComponents';
import JarvislyBlocksIndex from 'components/jarvisly-components/forms/JarvislyBlocksIndex';
import JarvislyProfilesIndex from 'components/jarvisly-components/forms/JarvislyProfilesIndex';
import BlockModal from '../modals/BlockModal';
import ProfilesModal from '../modals/ProfilesModal';

const ModuleSettingsBlocks = props => {
  // props deconstruction ------------------------------------------------------
  const { selectedModule, document } = props;

  // local variables I ---------------------------------------------------------
  const [searchParams] = useSearchParams();
  const initTab = selectedModule.documentState.initTab;

  // component states ----------------------------------------------------------
  const [tab, setTab] = useState(searchParams.get('tab') || initTab);
  const [dataBlocks,] = useState(null);
  const [dataProfiles,] = useState(null);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  console.log('A tab --> ', tab);

  // local variables II --------------------------------------------------------
  const tabItems = buildTabItens();

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  const onSave = async ({ body }) => {
    if (!body) return;
    return body;
  };

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <BlockModal
        {...props}
        setShowBlockModal={setShowBlockModal}
        showBlockModal={showBlockModal}
      />
      <ProfilesModal
        {...props}
        setShowProfileModal={setShowProfileModal}
        showProfileModal={showProfileModal}
      />
      <JarvislyFormProvider
        name="form-units"
        document={document}
        showSubmitButton={false}
        autoFocus="name"
        requiredFields={['name']}
        // showSubmitButton={tab !== 'contacts'}
        setTab={setTab}
        onSave={onSave}
      >
        <Tabs
          type="line"
          tabPosition="top"
          activeKey={tab}
          rootClassName="tab-in-form"
          items={tabItems.filter(x => !x.hide)}
          onTabClick={onTabClick}
        />
      </JarvislyFormProvider>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTabItens() {
    return [
      {
        label: translateX('blocks'),
        key: initTab,
        hide: false,
        children: (
          <JarvislyTabProvider tab="blocks">
            <Card className="cad-with-body-no-padding">
              <JarvislyBlocksIndex
                valueData={dataBlocks}
                setShowBlockModal={setShowBlockModal}
              />
            </Card>
          </JarvislyTabProvider>
        ),
      },
      {
        label: translateX('profiles'),
        key: 'profiles',
        hide: false,
        children: (
          <JarvislyTabProvider tab="profiles">
            <Card className="cad-with-body-no-padding">
              <JarvislyProfilesIndex
                valueData={dataProfiles}
                setShowProfileModal={setShowProfileModal}
              />
            </Card>
          </JarvislyTabProvider>
        ),
      },
    ];
  }
};

export default ModuleSettingsBlocks;
