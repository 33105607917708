import { Card, Col, Row, Tabs } from 'antd';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { translateX } from 'utils/helpers';
import {
  JarvislyFormProvider,
  JarvislyInput,
  JarvislyPhone,
  JarvislySelect,
  JarvislyTabProvider
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';

const UnitsForm = props => {
  // context deconstruction ----------------------------------------------------
  // const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const { selectedModule, document } = props;

  // local variables I ---------------------------------------------------------
  const [searchParams] = useSearchParams();
  const initTab = selectedModule.documentState.initTab;

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  const [tab, setTab] = useState(searchParams.get('tab') || initTab);

  // methods -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  // const buildRamal = ({ target: { value: ramal } }) => {
  //   console.log('Ramal', ramal);
  //   form.setFieldValue('directCall', ramal);
  // };

  const onSave = async ({ body }) => {
    if (!body) return;

    console.log('O body --> ', body);

    const dataResponsible = {
      name: body.dataResponsible.name,
      phone: body.dataResponsible.phone,
      email: body.dataResponsible.email,
      _id: body.dataResponsible._id,
    };

    const payload = {
      ...body,
      dataResponsible,
    };
    return payload;
  };

  // local variables II --------------------------------------------------------
  const tabItems = buildTabItens();

  // UI COMPONENTS -------------------------------------------------------------

  return (
    <JarvislyFormProvider
      name="form-units"
      document={document}
      autoFocus="name"
      requiredFields={['name']}
      showSubmitButton={tab !== 'contacts'}
      setTab={setTab}
      onSave={onSave}
    >
      <Tabs
        type="line"
        tabPosition="top"
        activeKey={tab}
        rootClassName="tab-in-form"
        items={tabItems.filter(x => !x.hide)}
        onTabClick={onTabClick}
      />
    </JarvislyFormProvider>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildTabItens() {
    return [
      {
        label: translateX('record'),
        key: initTab,
        hide: false,
        children: (
          <JarvislyTabProvider tab={initTab}>
            <>
              <Card title={translateX('basic_info')}>
                <Row gutter={ROW_GUTTER}>
                  <Col xs={24} sm={8} md={3} lg={4}>
                    <JarvislyInput
                      name="room"
                      label="apartment"
                      required
                      // onChange={buildRamal}
                    />
                  </Col>
                  <Col xs={24} md={7}>
                    <JarvislySelect
                      name="selectProfileId"
                      label="perfil"
                      showSearch
                      allowClear
                      required
                      // options={profilesOptions}
                    />
                  </Col>
                  <Col xs={24} md={7}>
                    <JarvislySelect
                      name="selectBlockId"
                      label="Bloco"
                      showSearch
                      allowClear
                      required
                      // options={banksOptions}
                    />
                  </Col>
                  <Col xs={24} md={3}>
                    <JarvislyInput name="roomNumber" required />
                  </Col>

                  <Col xs={24} md={3}>
                    <JarvislyInput
                      name="directCall"
                      label="direct_call"
                      required
                    />
                  </Col>
                </Row>
              </Card>
              <Card title={translateX('responsible')}>
                <Row gutter={ROW_GUTTER}>
                  <Col xs={24} sm={8} md={8} lg={8}>
                    <JarvislyInput
                      name={['dataResponsible', 'name']}
                      required
                    />
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8}>
                    <JarvislyPhone
                      name={['dataResponsible', 'phone']}
                      required
                    />
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8}>
                    <JarvislyInput
                      name={['dataResponsible', 'email']}
                      required
                    />
                  </Col>
                </Row>
              </Card>
            </>
          </JarvislyTabProvider>
        ),
      },
    ];
  }
};

export default UnitsForm;
