const appdedaloService = {};

appdedaloService.getTypeTagColor = typeId => {
  typeId = Number(typeId);

  switch (typeId) {
    case 1: // Initial
      return 'blue';

    case 2: // Revalidation
      return 'green';

    case 3: // Resource
      return 'purple';

    case 4: // Post Acident
      return 'magenta';

    default:
      return 'gray';
  }
};

appdedaloService.getTypeTagName = typeId => {
  typeId = Number(typeId);

  switch (typeId) {
    case 1: // Initial
      // return 'initial';
      return 'Inicial';

    case 2: // Revalidation
      // return 'revalidation';
      return 'Revalidação';

    case 3: // Resource
      // return 'resource';
      return 'Recurso';

    case 4: // Post Acident
      // return 'post_acident';
      return 'Pós Acidente';

    default:
      return 'n_a';
  }
};

export default appdedaloService;
