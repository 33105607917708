import React from 'react';
import { Card, Col, Row } from 'antd';
import {
  JarvislyInput,
  JarvislyNumber,
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { translateX } from 'utils/helpers';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const MultiPlansParametersIndex = props => {
  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------

  return (
    <>
      {/* ************************************************************
        PROFITS
        ************************************************************ */}
      <Card title={translateX('values')}>
        <Row gutter={ROW_GUTTER}>
          {/* MARKDOWN */}
          <Col span={12}>
            <JarvislyNumber
              name="markdown"
              decimalSeparator=","
              addonBefore={null}
              addonAfter="%"
              suffixStyle={{ width: 60 }}
              min={0}
              max={100}
            />
          </Col>
          {/* MARKDOWN */}

          {/* DISCOUNT */}
          <Col span={12}>
            <JarvislyNumber
              name="discount"
              decimalSeparator=","
              addonBefore={null}
              addonAfter="%"
              suffixStyle={{ width: 60 }}
              min={0}
              max={100}
            />
          </Col>
          {/* DISCOUNT */}
        </Row>
      </Card>

      {/* ************************************************************
        INTEGRATION
        ************************************************************ */}
      <Card
        title={
          <Row justify="space-between" align="start">
            <div style={{ fontSize: 17 }}>
              {translateX('webservice_integration')}
            </div>

            {/*            <JarvislySwitch
              name="integrated"
              className="form-item-no-margin"
              x-no-label
            />*/}
          </Row>
        }
      >
        <Row gutter={ROW_GUTTER}>
          {/* PLAN ID */}
          <Col xs={24} sm={6}>
            <JarvislyInput
              name="operatorPlanCode"
              label="operator_id"
              // disabled={isDisabled}
              // required={integrated}
            />
          </Col>
          {/* PLAN ID */}

          {/* PLAN NAME */}
          <Col xs={24} sm={12}>
            <JarvislyInput
              name="operatorPlanName"
              label="plan_name"
              // disabled={isDisabled}
              // required={integrated}
            />
          </Col>
          {/* PLAN NAME */}

          {/* PLAN TAX CODE */}
          <Col xs={24} sm={6}>
            <JarvislyInput
              name="operatorPlanTaxCode"
              label="rate_code"
              // disabled={isDisabled}
              // required={integrated}
            />
          </Col>
          {/* PLAN TAX CODE */}
        </Row>
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default MultiPlansParametersIndex;
