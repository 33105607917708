import React from 'react';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import { Card, Tooltip, Tag, Flex } from 'antd';
import { getLabelsColor, AssigneeAvatar } from './utils';
import {
  CalendarOutlined,
  CommentOutlined,
  NumberOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Icon } from 'components/util-components/Icon';

const InnerCardList = props => {
  const { onClickOpen } = props;

  return (
    <>
      {props.contents?.map((item, index) => (
        <Draggable key={item.id} draggableId={item.id} index={index}>
          {(dragProvided, dragSnapshot) => (
            <div
              className="mb-3"
              key={item.id}
              ref={dragProvided.innerRef}
              {...dragProvided.draggableProps}
              {...dragProvided.dragHandleProps}
            >
              <Card
                hoverable
                className="board-card no-change-border-color"
                cover={null}
                onClick={() => onClickOpen(item)}
              >
                {item.cover ? (
                  <img
                    src={item.cover}
                    className="rounded img-fluid"
                    alt="cover"
                  />
                ) : null}
                {item.labels
                  ? item.labels?.map(label => (
                      <Tooltip title={label} key={label}>
                        <div
                          className={`board-label ${getLabelsColor(label)}`}
                        ></div>
                      </Tooltip>
                    ))
                  : null}
                <h4 className="mb-0">{item.name}</h4>
                <Flex justify="flex-start" align="center">
                  <Icon
                    type={NumberOutlined}
                    className="text-primary font-size-md"
                    style={{ flexShrink: 0 }}
                  />
                  <div className="ml-1 text-muted" style={{ flex: 1 }}>
                    {item?._metadata?.counter}
                  </div>
                </Flex>
                {/*<p className="text-muted">{item?._metadata?.counter}</p>*/}
                <div className="d-flex justify-content-between align-items-center">
                  {/*<div className="d-flex align-items-center">*/}

                  <Flex justify="flex-end" align="flex-end" className="w-100">
                    {item.dueDate ? (
                      <Tag>
                        <CalendarOutlined />
                        <span className="ml-1 font-weight-semibold">
                          {dayjs(item.dueDate).format('DD MMMM')}
                        </span>
                      </Tag>
                    ) : null}

                    <SubIndicator
                      counts={item?.followUp?.length || 0}
                      icon={<CommentOutlined />}
                    />
                    <SubIndicator
                      counts={item?.attachments?.length || 0}
                      icon={<PaperClipOutlined />}
                    />
                    {/*</div>*/}
                  </Flex>
                  <Flex
                    justify="flex-end"
                    align="flex-end"
                    style={{ backgroundColor: 'red' }}
                  >
                    {item?.members?.map(member => (
                      <AssigneeAvatar
                        key={member}
                        id={member}
                        size={25}
                        chain
                      />
                    ))}
                  </Flex>
                </div>
              </Card>
            </div>
          )}
        </Draggable>
      ))}
    </>
  );
};

function InnerList(props) {
  const { contents, dropProvided, cardData, listId, onClickOpen } = props;

  return (
    <div className="board-dropzone" ref={dropProvided.innerRef}>
      <InnerCardList
        cardData={cardData}
        contents={contents}
        listId={listId}
        onClickOpen={onClickOpen}
      />
      {dropProvided.placeholder}
    </div>
  );
}

function SubIndicator(props) {
  if (props.counts) {
    return (
      <p className="mb-0 mr-2">
        {props.icon}
        <span className="ml-1">{props.counts}</span>
      </p>
    );
  }
  return null;
}

export default function BoardCard(props) {
  const {
    ignoreContainerClipping,
    internalScroll,
    scrollContainerStyle,
    isDropDisabled,
    isCombineEnabled,
    listId = 'LIST',
    listType,
    style,
    contents,
    useClone,
    cardData,
    onClickOpen,
  } = props;

  return (
    <>
      <Droppable
        droppableId={listId}
        type={listType}
        ignoreContainerClipping={ignoreContainerClipping}
        isDropDisabled={isDropDisabled}
        isCombineEnabled={isCombineEnabled}
        renderClone={useClone}
      >
        {(dropProvided, dropSnapshot) => (
          <Scrollbars
            style={style}
            className="board-wrapper flex-auto"
            autoHide
            {...dropProvided.droppableProps}
          >
            {internalScroll ? (
              <div
                className="board-scrollContainer"
                style={scrollContainerStyle}
              >
                <InnerList
                  contents={contents}
                  listId={listId}
                  cardData={cardData}
                  dropProvided={dropProvided}
                  onClickOpen={onClickOpen}
                />
              </div>
            ) : (
              <InnerList
                contents={contents}
                listId={listId}
                cardData={cardData}
                dropProvided={dropProvided}
                onClickOpen={onClickOpen}
              />
            )}
          </Scrollbars>
        )}
      </Droppable>
    </>
  );
}
