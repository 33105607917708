import React, { useContext, useEffect, useState } from 'react';
import { JarvislyFormContext } from 'components/jarvisly-components/others/FormComponents';
import { useSelector } from 'react-redux';
import JarvislyEntityCardIndex from 'components/jarvisly-components/forms/JarvislyEntityCardIndex';

// COMPONENT *******************************************************************
// *****************************************************************************
const CrmCustomerTab = props => {
  // providers context ---------------------------------------------------------
  const { form, formRefreshed } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const { document } = props;

  // local variables I ---------------------------------------------------------
  const { selectedCompany } = useSelector(s => s.authSlice);

  // component states ----------------------------------------------------------
  const [mainValue, setMainValue] = useState(0);

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    mainValue,
    setMainValue,
    selectedCompany,
    document,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (document) {
      form.setFieldsValue({
        ...document,
        // selectedBank: selectedCompany?.__mainBank?.accountCode,
        installment: 1,
        interest: 3,
        fine: 2,
      });

      setMainValue(document?.value);

      // setMainValue(document?.value || 0);
    } else {
      form.setFieldsValue({
        selectedBank: selectedCompany?.__mainBank?.accountCode,
        installment: 1,
        interest: 3,
        fine: 2,
      });
    }
  }, [formRefreshed, document]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {/*CUSTOMER CARD */}
      <JarvislyEntityCardIndex {...childProps} context="customer" />
      {/*CUSTOMER CARD */}
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default CrmCustomerTab;
