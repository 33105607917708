import React, { useState } from 'react';
import { Flex, Table } from 'antd';
import { translateX } from 'utils/helpers';
import ImageDescription from 'components/jarvisly-components/others-old/ImageDescription';
import {
  FormButton,
  JarvislyEditViewRemoveItem,
} from 'components/jarvisly-components/others/FormComponents';
import appService from 'services/appService';
import { PlusOutlined } from '@ant-design/icons';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const JarvislyBlocksIndex = props => {
  // props deconstruction ------------------------------------------------------
  const { valueData, setShowBlockModal } = props;

  // local variables -----------------------------------------------------------
  // const isDisabled = isWorking || mode === 'view';
  const tableColumns = buildTableColumns();

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onClick = record => {
    // setSelectedRecord(record);
    setShowBlockModal(true);
  };

  const onDelete = async record => {
    console.log('Tentou deletar um bloco');
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Table
        rowKey="_id"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={valueData?.data || []}
        pagination={false}
        disabled={false}
      />

      <Flex justify={appService.isMobile() ? 'center' : 'end'}>
        <FormButton
          title="add_new_block"
          className="m-3"
          style={{ width: 'fit-content' }}
          icon={<PlusOutlined />}
          disabled={false}
          onClick={onClick}
        />
      </Flex>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTableColumns() {
    return [
      {
        title: translateX('name'),
        dataIndex: 'name',
        className: 'text-truncate',
        render: (_, record) => {
          return (
            <div className="d-flex" style={{ width: 200 }}>
              <ImageDescription
                shape="circle"
                size="small"
                title={record?.name}
                subtitle={record?.note}
                showAge={record?.birthdate}
                foreColor={record?.foreColor}
                backColor={record?.backColor}
              />
            </div>
          );
        },
      },
      {
        title: translateX('abbreviation'),
        dataIndex: 'abbreviation',
        render: (_, record) => {
          return (
            <>
              <div>{record?.abbreviation}</div>
            </>
          );
        },
      },
      {
        title: translateX('floors'),
        dataIndex: 'floors',
        render: (_, record) => {
          return (
            <>
              <div>{record?.floors}</div>
              {/* <div>{record?.floors}</div> */}
            </>
          );
        },
      },

      {
        title: translateX('actions'),
        dataIndex: 'actions',
        width: 120,
        className: 'text-center',
        render: (_, record) => {
          return (
            <JarvislyEditViewRemoveItem
              onEdit={onClick}
              onDelete={onDelete}
              record={record}
              recordId={record?.entityId}
              disabled={false}
              deleteTitle={translateX(
                'remove_this_item',
                ['%ITEM%'],
                ['contact'],
              )}
            />
          );
        },
      },
    ].filter(x => !x.hidden);
  }
};

export default JarvislyBlocksIndex;
