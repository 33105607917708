import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/reset.css';
import './index.css';
import { ConfigProvider } from 'antd';
import app from 'configs/ConfigIndex';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorLink: app.BASE_THEME.colorPrimary,
          // colorBgContainer: '#f6ffed',
        },
      }}
    >
      <App />
    </ConfigProvider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about apiConfig workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
