import LoginAdm from 'modules/auth-views/authentication/login-adm';
import LoginUser from 'modules/auth-views/authentication/login-user';
import Register from 'modules/auth-views/authentication/register';
import ForgotPassword from 'modules/auth-views/authentication/forgot-password';
import app from './ConfigIndex';

const publicRoutes = [
  {
    key: 'login',
    path: `${app.AUTH_PREFIX_PATH}/signing`,
    component: app.APPLICATION_LAYER === 'user' ? LoginUser : LoginAdm,

    // component: React.lazy(() => {
    //
    //   if (app.APPLICATION_LAYER === "user") {
    //     return import("views/auth-views/authentication/login-user");
    //
    //   } else {
    //     return import("views/auth-views/authentication/login-adm");
    //   }
    // })
  },
  // {
  //   key: 'register',
  //   path: `${app.AUTH_PREFIX_PATH}/register`,
  //   // component: React.lazy(() => import('views/auth-views/authentication/register')),
  //   component: Register,
  // },
  // {
  //   key: 'forgot-password',
  //   path: `${app.AUTH_PREFIX_PATH}/forgot-password`,
  //   // component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
  //   component: ForgotPassword,
  // },
];

if (app.SELF_REGISTRATION) {
  publicRoutes.push({
    key: 'register',
    path: `${app.AUTH_PREFIX_PATH}/register`,
    // component: React.lazy(() => import('views/auth-views/authentication/register')),
    component: Register,
  });
}

if (app.RESET_PASSWORD) {
  publicRoutes.push({
    key: 'forgot-password',
    path: `${app.AUTH_PREFIX_PATH}/forgot-password`,
    // component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    component: ForgotPassword,
  });
}

export default publicRoutes;
