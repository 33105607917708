import React from 'react';
import PropTypes from 'prop-types';
import { Popover, Progress } from 'antd';
import Card from 'components/shared-components/Card';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const GoalWidget = props => {
  const {
    title,
    value,
    size,
    subtitle,
    strokeWidth,
    strokeColor,
    extra,

    suffix = '',
    infoContent,
    infoTitle,
    notFoundTitle,
    record,
  } = props;

  const isNotInstalled = !record || !record?.installed;
  const isOffline = record?.status !== 'online';
  const isUnavailable = isNotInstalled || isOffline;

  return (
    <Card>
      {/*{*/}
      {/*  <div className="text-right">*/}
      {/*    <Popover*/}
      {/*      placement="top"*/}
      {/*      title={infoTitle}*/}
      {/*      content={infoContent}*/}
      {/*      trigger="hover"*/}
      {/*    >*/}
      {/*      <ExclamationCircleOutlined />*/}
      {/*    </Popover>*/}
      {/*  </div>*/}
      {/*}*/}

      <div className="text-center">
        {title && <h4 className="mb-3 font-weight-bold">{title}</h4>}
        <Progress
          type="dashboard"
          className="hide-percentage"
          style={{ opacity: isUnavailable ? 0.3 : 1 }}
          // percent={Math.min(value, 100)} // Isso garante que o valor visual fique limitado a 100%
          // format={percent =>
          //   isNotInstalled
          //     ? notFoundTitle || `0${suffix}`
          //     : `${percent}${suffix}`
          // }
          percent={Math.min(value, 100)} // Isso garante que o valor visual fique limitado a 100%
          format={percent => `${value !== undefined ? parseInt(value) : 0}%`} // Se percent for undefined, exibe 0%
          //  format={(percent) => `${value}%`} // Mostra o valor real em texto, mesmo que seja maior que 100

          size={size}
          strokeWidth={strokeWidth}
          strokeColor={strokeColor}
        />
        <div
          className={`mt-2 mx-auto text-muted ${extra ? 'mb-3' : ''}`}
          style={{ maxWidth: `${size + 30}px` }}
        >
          {subtitle}
        </div>
        {extra}
      </div>
    </Card>
  );
};

GoalWidget.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  value: PropTypes.number,
  size: PropTypes.number,
  subtitle: PropTypes.string,
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

GoalWidget.defaultProps = {
  strokeWidth: 4,
  size: 150,
};

export default GoalWidget;
