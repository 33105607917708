import React, { useMemo } from 'react';
import LoginForm from '../../components/LoginForm';
import { Row, Col, Grid, Skeleton } from 'antd';
import { useSelector } from 'react-redux';
import { version } from 'appversion';
import NavLanguage from 'components/layout-components/NavLanguage';
import utils from 'utils';
import { translateX } from 'utils/helpers';
import appService from 'services/appService';
import { THEME_BACKGROUND } from 'constants/ThemeConstant';
import { Link } from 'react-router-dom';
import app from '../../../../configs/ConfigIndex';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************
const { useBreakpoint } = Grid;
// const backgroundURL = '/img/others/img-17.jpg'
const backgroundURL = THEME_BACKGROUND[app.THEME_COLOR];
const backgroundStyle = {
  backgroundImage: `url(${backgroundURL})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

// COMPONENT *******************************************************************
// *****************************************************************************

const LoginAdm = props => {
  // props deconstruction ------------------------------------------------------

  // component states ----------------------------------------------------------
  // const [quote, setQuote] = useState("");

  // local variables -----------------------------------------------------------
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const { currentTheme, quote } = useSelector(state => state.theme);
  const headerNavColor = useSelector(state => state.theme.headerNavColor);
  const isDarkTheme = currentTheme === 'dark';
  const navMode = useMemo(() => {
    if (!headerNavColor) {
      return utils.getColorContrast(isDarkTheme ? '#000000' : '#ffffff');
    }
    return utils.getColorContrast(headerNavColor);
  }, [isDarkTheme, headerNavColor]);

  const logo = appService.getLogo(isDarkTheme);
  const logoSide = appService.getLogo(isDarkTheme, 'login-side');

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------

  return (
    // <div className={`h-100 ${currentTheme === 'light' ? 'bg-white' : ''}`}>
    <div className={`h-100`}>
      <Row justify="center" className="align-items-stretch h-100">
        <Col xs={20} sm={20} md={24} lg={16}>
          <Row justify="space-between" style={{ height: '100vh' }}>
            <Col span={24}>
              <Row
                justify="center"
                align="top"
                style={{
                  height: '100%',
                  paddingTop: 48,
                }}
              >
                {isMobile && <img src={logo} alt="logo" />}
              </Row>
            </Col>

            <Col span={24}>
              <Row
                justify="center"
                align="top"
                style={{
                  height: '100%',
                  marginTop: -40,
                }}
              >
                <Col
                  style={{
                    minWidth: 300,
                    maxWidth: 300,
                  }}
                >
                  <h1>{translateX('sign_in')}</h1>

                  <div className="pb-3 pl-1">
                    {app.SELF_REGISTRATION && (
                      <p style={{ fontSize: 12 }}>
                        {translateX('dont_have_account_yet')}
                        &nbsp;&nbsp;
                        {/* <a href="/auth/register">{translateX( "sign_up")}</a> */}
                        <Link to={'/auth/register'}>
                          {translateX('sign_up')}
                        </Link>
                      </p>
                    )}
                  </div>

                  <div className="mt-4">
                    <LoginForm
                      {...props}
                      showForgetPassword={app.RESET_PASSWORD}
                    />
                  </div>

                  {/*  <div className="text-center mt-4">
                                        {
                                            app.SELF_REGISTRATION &&
                                            <p style={{fontSize: 12}}>{translateX( 'dont_have_account_yet')}&nbsp;&nbsp;
                                                <a href="/auth/register">{translateX( 'sign_up')}</a>
                                            </p>
                                        }
                                    </div> */}
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row
                justify="space-between"
                align="bottom"
                className="pb-4 pl-0 pr-0 pl-md-4 pr-md-4"
                style={{ height: '100%' }}
              >
                <Col>
                  <span className="font-size-sm text-muted">
                    Jarvisly App v{version}
                  </span>
                </Col>
                <Col>
                  {app?.NAVIGATION?.LANGUAGES ? (
                    <NavLanguage mode={navMode} onFixed="login" />
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col xs={0} sm={0} md={0} lg={8}>
          <div
            className="d-flex flex-column justify-content-between h-100 px-4"
            style={backgroundStyle}
          >
            <div className="text-right">
              <img src={logoSide} alt="logo" />
            </div>

            <Row justify="center" style={{ marginTop: '-50%' }}>
              <Col xs={0} sm={0} md={0} lg={20}>
                <img
                  className="img-fluid mb-5"
                  src="/img/others/img-18.png"
                  alt=""
                />

                <Skeleton active loading={!app.SLOGAN && !quote}>
                  <p className="text-white">
                    {app.SLOGAN || `"${quote?.quote}"`}
                  </p>
                  <p className="text-white font-weight-bold text-right font-italic">
                    {app.SLOGAN ? app.SLOGAN2 : `${quote?.author}`}
                  </p>
                </Skeleton>
              </Col>
            </Row>

            <div className="d-flex justify-content-end pb-4">
              <div>
                <a
                  className="text-white font-size-sm"
                  href="/#"
                  onClick={e => e.preventDefault()}
                >
                  {translateX('term_conditions')}
                </a>
                <span className="mx-2 text-white"> | </span>
                <a
                  className="text-white font-size-sm"
                  href="/#"
                  onClick={e => e.preventDefault()}
                >
                  {translateX('privacy_policy')}
                </a>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default LoginAdm;
