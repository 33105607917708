import { Tabs } from 'antd';
import { translateX } from 'utils/helpers';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  JarvislyFormProvider,
  JarvislyTabProvider,
} from 'components/jarvisly-components/others/FormComponents';
import ReceivablesRecordIndex from './Record/RecordIndex';
import useUrl from 'components/jarvisly-components/hooks/urlHooks';
import JarvislyContactsModal from 'components/jarvisly-components/forms/modals/JarvislyContactsModal';
import JarvislyDocumentStatusModal from 'components/jarvisly-components/forms/modals/JarvislyDocumentStatusModal';
import entityService from 'services/entityService';
import bankService from 'services/bankService';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************

const ReceivablesForm = forwardRef((props, ref) => {
  // props deconstruction ------------------------------------------------------
  const { selectedModule, document, _id, setModuleFocus } = props;

  // local variables I ---------------------------------------------------------
  const [searchParams] = useSearchParams();
  const url = useUrl();
  const initTab = selectedModule.documentState.initTab;
  const availableTabs = [initTab, 'contacts', 'parameters'];
  const contactModalRef = useRef(null);
  const documentStatusModalRef = useRef(null);

  // component states ----------------------------------------------------------
  const [tab, setTab] = useState(searchParams.get('tab') || initTab);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    selectedCustomer,
    setSelectedCustomer,
  };
  const tabItems = buildTabItens();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (!availableTabs.includes(tab)) setTab(initTab);
    url.updateUrlQueryParams({ tab }, true);
    setModuleFocus(prev => ({ ...prev, tab: initTab, field: 'name' }));
  }, [tab, _id]); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    documentStatusModalRef,
  }));

  // methods -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  const onSave = async ({ body, values }) => {
    const bank = bankService.getBankByAccountCode(body?.selectedBank) || {};

    let customer = entityService.decorateEntity(
      {
        ...selectedCustomer,
        ...body.customer,
        _id: selectedCustomer?._id || body?.customer?.selectedEntity?.key,
      },
      3,
      null,
      'customer',
    );

    customer = await entityService.upsertEntity(customer?._id, customer);

    const _indice = `${customer.name} | ${customer.cpfCnpj} | ${customer.email} | ${customer.phone} `;

    return {
      billingType: body?.billingType,
      _indice,
      value: body?.value,
      valueCurrency: body?.valueSuffix,
      dueDate: body?.dueDate,

      bankCode: bank?.bankCode,
      agencyCode: bank?.agencyCode,
      accountCode: bank?.accountCode,

      description: body?.description,
      installment: body?.installment,
      installmentTotal: body?.installmentTotal || body?.installment,

      // FINE AND INTEREST
      fineValue:
        (body?.finePrefix === 'value' ? body?.fine : body?.fineSuffix) || 0,
      finePercentage:
        (body?.finePrefix === 'percentage' ? body?.fine : body?.fineSuffix) ||
        0,
      fineType: body?.finePrefix,

      interestValue:
        (body?.interestPrefix === 'value'
          ? body?.interest
          : body?.interestSuffix) || 0,
      interestPercentage:
        (body?.interestPrefix === 'percentage'
          ? body?.interest
          : body?.interestSuffix) || 0,
      interestType: body?.interestPrefix,

      discountValue:
        (body?.discountPrefix === 'value'
          ? body?.discount
          : body?.discountSuffix) || 0,
      discountPercentage:
        (body?.discountPrefix === 'percentage'
          ? body?.discount
          : body?.discountSuffix) || 0,
      discountType: body?.discountPrefix,

      discountExpireDate: body?.discountExpireDate,

      // CUSTOMER
      entityId: customer._id,
    };
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      {/* DOCUMENT STATUS MODAL WRAPPER */}
      <JarvislyDocumentStatusModal {...props} ref={documentStatusModalRef} />
      {/* DOCUMENT STATUS MODAL WRAPPER */}

      {/* CONTACTS MODAL WRAPPER */}
      <JarvislyContactsModal {...props} ref={contactModalRef} />
      {/* CONTACTS MODAL WRAPPER */}

      <JarvislyFormProvider
        name="form-receivables"
        document={document}
        autoFocus="selectedBank"
        requiredFields={[
          'selectedBank',
          'value',
          'dueDate',
          'billingType',
          'installment',

          'mobile',
          'billingEmails',
        ]}
        // showSubmitButton={tab !== 'contacts'}
        setTab={setTab}
        onSave={onSave}
        // onValuesChange={onValuesChange}
      >
        <Tabs
          type="line"
          tabPosition="top"
          activeKey={tab}
          rootClassName="tab-in-form"
          items={tabItems.filter(x => !x.hide)}
          onTabClick={onTabClick}
        />
      </JarvislyFormProvider>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTabItens() {
    return [
      {
        label: translateX('charge'),
        key: initTab,
        hide: false,
        children: (
          <JarvislyTabProvider tab={initTab}>
            <ReceivablesRecordIndex {...childProps} activeTab={tab} />
          </JarvislyTabProvider>
        ),
      },
    ];
  }
});

export default ReceivablesForm;
