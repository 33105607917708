import {
  forwardRef,
  useContext,
  useEffect, useState
} from 'react';
import { translateX } from 'utils/helpers';
import axiosService from 'services/axiosService';
import appService from 'services/appService';
import {
  Button, ColorPicker,
  Flex,
  Form,
  Input,
  Modal, Space,
  Tabs
} from 'antd';
import {
  MinusCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { refreshParameters, refreshList } = useContext(ModuleContext);

  // props deconstruction ------------------------------------------------------
  const { showModal, setShowModal } = props;

  // local variables I ----------------------------------------------------------
  const { parameters } = useSelector(s => s.moduleSlice);
  const [form] = Form.useForm();

  // component states -----------------------------------------------------------
  const [tabIdx, setTabIdx] = useState();
  const [categories,] = useState(['sapato', 'meia']);

  // local variables II ---------------------------------------------------------
  const showColor = false;

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    // setCategories(parameters?.categories || [])
    form?.setFieldsValue({ categories: parameters?.categories || [] });
  }, [parameters]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    refreshParameters();
    refreshList();
    setShowModal(false);
  };

  const onSubmit = async data => {
    data.categories?.map(c => {
      if (c.color?.toHexString) c.color = c.color?.toHexString();
      return c;
    });
    const axiosOptions = {
      url: '/v1/iot-device-parameters',
      _id: parameters?._id,
      data,
    };
    appService.message('s', 'saved_parameters', 'onSave');
    await axiosService.upsert(axiosOptions);
    onClose();
  };

  function onTabClick(key) {
    setTabIdx(key);

    switch (key) {
      // case 'configurations':
      //   configurationTabRef?.current?.getFirstFocus();
      //   break;

      // case 'requiredFields':
      //   requiredFieldsTabRef?.current?.getFirstFocus();
      //   break;

      default:
        break;
    }
  }

  // local variables III --------------------------------------------------------
  const tabItems = [
    {
      label: translateX('categories'),
      key: 'categories',
      hide: false,
      children: (
        <>
          <Form.List name="categories">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: 'flex',
                      marginBottom: 8,
                      width: '100%',
                    }}
                    align="center"
                    className="custom-space-item"
                  >
                    {/* LABEL */}
                    <Form.Item
                      {...restField}
                      name={[name, 'label']}
                      rules={[
                        {
                          required: true,
                          message: translateX('required'),
                        },
                      ]}
                      style={{ width: '100%' }}
                    >
                      <Input placeholder={translateX('category')} />
                    </Form.Item>
                    {/* LABEL */}

                    {/* _ID */}
                    <Form.Item
                      {...restField}
                      name={[name, '_id']}
                      style={{ display: 'none', width: '0%' }}
                    ></Form.Item>
                    {/* _ID */}

                    {/* COLOR */}
                    <Form.Item
                      {...restField}
                      name={[name, 'color']}
                      style={{ display: showColor ? 'block' : 'none', width: '0%' }}
                    >
                      <ColorPicker
                        defaultValue="#6E6E6E"
                        defaultFormat="hex"
                        size="small"
                      />
                    </Form.Item>
                    {/* COLOR */}

                    <MinusCircleOutlined
                      className="parameters-list-form"
                      onClick={() => remove([name, 'label'])}
                    />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  ></Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Flex justify="flex-end" className="w-100">
            <Button type="primary" htmlType="submit">
              {translateX('save')}
            </Button>
          </Flex>
        </>
      ),
    },
    // {
    //   label: translateX('t2'),
    //   key: 't2',
    //   hide: false,
    //   children: <h1> tab2 </h1>,
    // },
  ];

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Form
        form={form}
        name="form-iotdevices-parameters"
        // document={selectedRecord}
        // requiredFields={['cpf', 'nameResponsable']}
        initialValues={{ categories }}
        // onConfirm={onConfirm}
        onFinish={onSubmit}
        // autoFocus="nameResponsable"
      >
        <Modal
          // className="title-fixed-with-18 modal-top"
          title={translateX('parameters')}
          destroyOnClose={true}
          open={showModal}
          width={640}
          // okText={translateX('save')}
          onCancel={onClose}
          cancelText={translateX('close')}
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
          // onOk={() => (isDisabled ? onClose() : form.submit())}
          getContainer={false}
          // okType={'primary'}
          // confirmLoading={isWorking}
          // afterClose={afterClose}
        >
          <Tabs onChange={onTabClick} items={tabItems} activeKey={tabIdx} />
        </Modal>
      </Form>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const IotDevicesParametersModal = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // for 'contacts' tab
  // const [showModal, setShowModal] = useState(false);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  // useImperativeHandle(ref, () => ({
  //   showModal,
  //   setShowModal: value => setShowModal(value),
  // }));

  // methods -------------------------------------------------------------------

  // variables III -------------------------------------------------------------
  // const childProps = {
  //   ...props,
  //   showModal,
  //   setShowModal,
  // };

  // UI COMPONENT --------------------------------------------------------------
  return <ModalComponent {...props} />;
});

export default IotDevicesParametersModal;
