import {
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyInput,
  JarvislyTitle,
} from '../../../../../components/jarvisly-components/others/FormComponents';
import { forwardRef, useContext, useEffect, useState } from 'react';
import { translateX } from 'utils/helpers';
import axiosService from 'services/axiosService';
import { Card, Col, Modal, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { API_profiles } from 'constants/ApiConstant';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    record,
    afterClose,
    isDisabled,
    updating,
    showProfileModal,
    setShowProfileModal,
  } = props;

  // local variables I ----------------------------------------------------------
  const title = isDisabled
    ? translateX('view')
    : !record?._id
    ? translateX('new_profile')
    : translateX('edit_profile');

  const childProps = {
    ...props,
    form,
  };

  // component states -----------------------------------------------------------
  const { setDocumentContext } = useContext(ModuleContext);

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    form?.resetFields();
    if (record) form?.setFieldsValue(record);
    setDocumentContext({ valueBlock: record });
  }, [record, form]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    form.resetFields();
    setShowProfileModal(false);
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        className="title-fixed-with-18 modal-top"
        title={
          <>
            <UserOutlined />
            <span className="ml-2">{title}</span>
          </>
        }
        destroyOnClose={true}
        open={showProfileModal}
        width={640}
        okText={translateX(!isDisabled ? 'save' : 'close')}
        onCancel={onClose}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => (isDisabled ? onClose() : form.submit())}
        getContainer={false}
        okType={isDisabled ? 'default' : 'primary'}
        confirmLoading={updating}
        afterClose={afterClose}
      >
        <Card>
          <JarvislyTitle title={'responsible'} />
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={24} lg={12}>
              <JarvislyInput name="name" label="name" />
            </Col>
            <Col xs={24} sm={24} lg={12}>
              <JarvislyInput name="m2" label="Mt2" />
            </Col>
            <Col xs={24} sm={24} lg={12}>
              <JarvislyInput name="apartmentDigits" label="format" />
            </Col>
          </Row>
        </Card>
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const ProfilesModal = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    // _id,
    // document,
    isWorking,
    refreshDocument,
    setShowProfileModal,
    showProfileModal,
  } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  const [selectedRecord, setSelectedRecord] = useState(null);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  const onConfirm = async ({ body, searchedEntity, form }) => {
    const url = API_profiles;
    let entityId = selectedRecord?._id;

    if (!searchedEntity) {
      const [doc] = await axiosService.upsert({
        url,
        _id: entityId,
        data: body,
      });
      entityId = entityId;
    } else {
      entityId = searchedEntity._id;
    }

    form?.resetFields();

    refreshDocument(body._id, true);
    setShowProfileModal(false);

    return body;
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <JarvislyFormProvider
      name="form-contact"
      document={selectedRecord}
      requiredFields={['cpf', 'nameResponsable']}
      showSubmitButton={false}
      onConfirm={onConfirm}
      autoFocus="nameResponsable"
    >
      <ModalComponent
        showBirthdate
        updating={isWorking}
        record={selectedRecord}
        setShowProfileModal={setShowProfileModal}
        showProfileModal={showProfileModal}
        afterClose={() => {
          setSelectedRecord(null);
        }}
      />
    </JarvislyFormProvider>
  );
});

export default ProfilesModal;
