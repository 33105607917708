import React from 'react';
import { Tag, Tooltip } from 'antd';
import { getDocumentStatusColor, translateX } from 'utils/helpers';
import { DeleteOutlined, FolderOutlined } from '@ant-design/icons';
import DocumentStatusTag from './DocumentStatusTag';
import dayjs from 'dayjs';

// COMPONENT *******************************************************************
// *****************************************************************************

const DocumentTags = props => {
  const { record, style, className } = props;

  const atualizaInfo = record?.__atualizaInfo;
  const atualizaInfoStatus = atualizaInfo?.updateSentAt
    ? 'updated'
    : atualizaInfo?.updateRequiredAt
    ? 'pending_update'
    : 'not_updated';

  const atualizaInfoWaitingFor =
    atualizaInfo?.updateRequiredAt &&
    dayjs(atualizaInfo?.updateRequiredAt).fromNow();
  const atualizaInfoUpdatedFor =
    atualizaInfo?.updateSentAt && dayjs(atualizaInfo?.updateSentAt).fromNow();

  return (
    <div className={className} style={style}>
      {atualizaInfo && (
        <Tooltip
          placement="top"
          title={atualizaInfoUpdatedFor || atualizaInfoWaitingFor}
        >
          <Tag
            className="text-capitalize"
            style={{ margin: 4 }}
            color={getDocumentStatusColor(atualizaInfoStatus)}
          >
            {translateX(atualizaInfoStatus)}
          </Tag>
        </Tooltip>
      )}

      <DocumentStatusTag record={record} />

      {record?._metadata?.audit?.removedAt && (
        <Tooltip placement="top" title={translateX('this_document_removed')}>
          <Tag
            className="text-capitalize"
            style={{ margin: 4 }}
            color={getDocumentStatusColor('removed')}
          >
            <DeleteOutlined className="mr-2" />
            {translateX('removed')}
          </Tag>
        </Tooltip>
      )}

      {record?._metadata?.audit?.filedAt && (
        <Tooltip placement="top" title={translateX('this_document_filed')}>
          <Tag
            className="text-capitalize"
            style={{ margin: 4 }}
            color={getDocumentStatusColor('archive')}
          >
            <FolderOutlined className="mr-2" />
            {translateX('filed')}
          </Tag>
        </Tooltip>
      )}
    </div>
  );
};

export default DocumentTags;
