import { EditOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import { parseCpfToDisplay, setLocale, translateX } from 'utils/helpers';
import dayjs from 'dayjs';
import DocumentStatusTag from 'components/jarvisly-components/others-old/DocumentStatusTag';
import ImageDescription from 'components/jarvisly-components/others-old/ImageDescription';
import React from 'react';

const OrdersList = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    isWorking,
    onTableChanges,
    // columnSorter,
    // columnSearch,
    // columnFilters,
    tableKey,
    resetTableSearch,
    pagination,
    dataList,
    buildRowSelection,
    showNumberOfLine,
    onClickOpen,
  } = props;

  // local variables I ---------------------------------------------------------
  const tableColumns = buildTableColumns();

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Table
        key={tableKey}
        rowKey="_id"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={dataList || []}
        pagination={pagination}
        onChange={onTableChanges}
        rowSelection={buildRowSelection}
        className="with-checkbox"
        resetTableSearch={resetTableSearch}
        loading={isWorking}
        expandable={{
          expandedRowRender: buildTableItemsColumns,
          defaultExpandedRowKeys: ['0'],
        }}
      />
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildTableColumns() {
    return [
      {
        hidden: !showNumberOfLine,
        title: translateX('line'),
        width: 80,
        render: (item, record, index) => {
          if (!pagination) return;

          const { current, pageSize } = pagination;

          return (
            <div className="text-left">
              {current * pageSize - pageSize + index + 1 || index}
            </div>
          );
        },
      },

      {
        title: translateX('order'),
        dataIndex: '_metadata.counter',
        width: 140,
        render: (_, record) => (
          <span className="text-uppercase text-muted">
            {record?._metadata?.__documentCode}
          </span>
        ),
      },

      {
        title: translateX('date'),
        dataIndex: 'position',
        render: (_, record) => <span>{translateX(record?.position)}</span>,
      },

      {
        title: translateX('client'),
        dataIndex: 'name',
        className: 'text-truncate',
        render: (_, record) => {
          return (
            <div className="d-flex" style={{ width: 200 }}>
              <ImageDescription
                imageUrl={record?.pictureUrl}
                title={record?.name}
                showAge={true}
                subtitle={parseCpfToDisplay(record?.cpf)}
                foreColor={record?.foreColor}
                backColor={record?.backColor}
              />
            </div>
          );
        },
      },

      {
        title: translateX('total'),
        dataIndex: 'status',
        render: (_, record) => <DocumentStatusTag record={record} />,
      },

      {
        title: translateX('status'),
        dataIndex: 'status',
        render: (_, record) => <DocumentStatusTag record={record} />,
      },

      {
        title: translateX('last_update'),
        dataIndex: '_metadata.audit.updatedAt',
        hidden: false,
        render: (_, record) => (
          <>
            {record?._metadata?.audit?.updatedAt &&
              dayjs(record._metadata.audit.updatedAt).fromNow()}
          </>
        ),
      },

      {
        title: setLocale('actions'),
        dataIndex: 'actions',
        align: 'center',
        width: 50,
        render: (_, record) => (
          <div className="text-right d-flex justify-content-end">
            <Tooltip title={setLocale('edit')}>
              <Button
                className="mr-2"
                icon={<EditOutlined />}
                onClick={() => onClickOpen(record)}
                size="small"
              />
            </Tooltip>

            {/* <Tooltip title={setLocale('view')}>
              <Button
                className="mr-2"
                icon={<EyeOutlined />}
                onClick={() => onClickPreview(record)}
                size="small"
              />
            </Tooltip>*/}
          </div>
        ),
      },
    ].filter(x => !x.hidden);
  }

  function buildTableItemsColumns(record) {
    const dataItem = dataList.filter(i => i?._id === record?._id);

    const columns = [
      {
        title: translateX('reference'),
        dataIndex: 'reference',
        render: (_, record) => <span>{translateX(record?.reference)}</span>,
      },

      {
        title: translateX('description'),
        dataIndex: 'description',
        render: (_, record) => <span>{translateX(record?.description)}</span>,
      },

      {
        title: translateX('unit'),
        dataIndex: 'unit',
        render: (_, record) => <span>{translateX(record?.unit)}</span>,
      },

      
      {
        title: translateX('price'),
        dataIndex: 'price',
        render: (_, record) => <span>{translateX(record?.price)}</span>,
      },
      
      {
        title: translateX('quantity'),
        dataIndex: 'quantity',
        render: (_, record) => <span>{translateX(record?.quantity)}</span>,
      },

      {
        title: translateX('total'),
        dataIndex: 'total',
        render: (_, record) => <span>{translateX(record?.total)}</span>,
      },
    ].filter(x => !x.hidden);

    return (
      <Table
        rowKey="_id"
        size="small"
        className="no-table-overflow"
        columns={columns}
        dataSource={dataItem || []}
        pagination={false}
      />
    );
  }

};

export default OrdersList;
