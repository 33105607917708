import { Card, Row, Col, ColorPicker, Form, Tooltip } from 'antd';
import {
  JarvislyDatePicker,
  JarvislyInput,
  JarvislyNumber,
  JarvislySelect,
  JarvislyTextArea,
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { useSelector } from 'react-redux';
import appveerticalService from 'services/appveericalService';
import { translateX } from 'utils/helpers';
import JarvislyEntityCardIndex from 'components/jarvisly-components/forms/JarvislyEntityCardIndex';
import React, { useState } from 'react';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const IotDeviceRecord = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { document } = props;

  // local variables I ---------------------------------------------------------
  const { parameters } = useSelector(s => s.moduleSlice);
  const devicesOptions = appveerticalService.buildDevicesOptions();
  const devicesCategoriesOptions =
    appveerticalService.buildDevicesCategoriesOptions(parameters);
  const devicesFormatOptions = appveerticalService.buildDevicesFormatOptions();
  const devicesMaterialOptions =
    appveerticalService.buildDevicesMaterialOptions();
  const devicesInstallationLocationOptions =
    appveerticalService.buildDevicesInstallationLocationOptions();
  const devicesModelOptions = appveerticalService.buildDevicesModelOptions();
  const { selectedCompany } = useSelector(s => s.authSlice);

  // component states ----------------------------------------------------------
  //   const [categories, setCategories] = useState([]);
  const [mainValue, setMainValue] = useState(0);

  // local variables II ---------------------------------------------------------
  const childProps = {
    ...props,
    mainValue,
    setMainValue,
    selectedCompany,
    document,
  };

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {/*CUSTOMER CARD */}
      <JarvislyEntityCardIndex {...childProps} context="customer" />
      {/*CUSTOMER CARD */}

      <Card title={translateX('device')}>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={12} lg={4}>
            <JarvislyInput name="channel" required />
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <JarvislySelect
              label="device"
              name="type"
              options={devicesOptions}
              required
            />
          </Col>
          <Col xs={24} sm={8} lg={6}>
            <JarvislySelect
              label="model"
              name="model"
              options={devicesModelOptions}
              required
            />
          </Col>
          <Col xs={24} sm={22} lg={6}>
            <JarvislySelect
              label="category"
              name="categoryId"
              options={devicesCategoriesOptions}
              required
            />
          </Col>
          {/* <Col style={{display: 'none'}}>
            <JarvislySelect name="service" options={devicesTypesOptions} />
          </Col> */}
          <Col xs={24} sm={2} lg={2}>
            <Tooltip title={translateX('dashboard_color')}>
              <Form.Item
                name="color"
                style={{ width: '0%', marginTop: '25px' }}
                required={true}
              >
                <ColorPicker
                  defaultValue="#c0c0c0"
                  defaultFormat="hex"
                  size="small"
                />
              </Form.Item>
            </Tooltip>
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={12} lg={12}>
            <JarvislyInput name="title" required />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <JarvislyInput name="description" required />
          </Col>
        </Row>
      </Card>
      <Card title={translateX('calibration')}>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={12} lg={12}>
            <JarvislyNumber
              label="maximal_distance"
              name={['field1', 'finishRangeValue']}
              min={0}
              max={62000}
              addonBefore={null}
              addonAfter={'cm'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              disabled={true}
            />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <JarvislyNumber
              label="minimum_distance"
              name={['field1', 'startRangeValue']}
              min={0}
              max={62000}
              addonBefore={null}
              addonAfter={'cm'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              disabled={true}
            />
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={12} lg={12}>
            <JarvislyDatePicker
              label="first_reading"
              name="firstReadAt"
              disabled={true}
            />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <JarvislyDatePicker
              label="last_reading"
              name="lastReadAt"
              disabled={true}
            />
          </Col>
        </Row>
      </Card>
      <Card title={translateX('tank_dimensions')}>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={6} lg={6}>
            <JarvislyNumber
              label="depth"
              name={['water_level_sensor', 'depth']}
              min={1}
              max={62000}
              addonBefore={null}
              addonAfter={'cm'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
            />
          </Col>

          <Col xs={24} sm={6} lg={6}>
            <JarvislyNumber
              label="width"
              name={['water_level_sensor', 'width']}
              min={1}
              max={62000}
              addonBefore={null}
              addonAfter={'cm'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
            />
          </Col>
          <Col xs={24} sm={6} lg={6}>
            <JarvislyNumber
              label="length"
              name={['water_level_sensor', 'length']}
              min={1}
              max={62000}
              addonBefore={null}
              addonAfter={'cm'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
            />
          </Col>
          <Col xs={24} sm={6} lg={6}>
            <JarvislyNumber
              label="base_sensor_distance"
              name={['water_level_sensor', 'baseSensorDistance']}
              min={1}
              max={62000}
              addonBefore={null}
              addonAfter={'cm'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
            />
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={8} lg={8}>
            <JarvislySelect
              label="format"
              name={['water_level_sensor', 'tankShape']}
              options={devicesFormatOptions}
              required
            />
          </Col>
          <Col xs={24} sm={8} lg={8}>
            <JarvislySelect
              label="material"
              name={['water_level_sensor', 'tankMaterial']}
              options={devicesMaterialOptions}
              required
            />
          </Col>

          <Col xs={24} sm={8} lg={8}>
            <JarvislySelect
              label="installation_location"
              name={['water_level_sensor', 'tankInstallation']}
              options={devicesInstallationLocationOptions}
              required
            />
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={24} lg={24}>
            <JarvislyTextArea name="note" />
          </Col>
        </Row>
      </Card>

      <Card title={translateX('alarm_tolerance_distance')}>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={12} lg={12}>
            <JarvislyNumber
              label="minimum_distance_tolerance"
              name={['field1', 'alarmMinimumDistanceTolerance']}
              min={1}
              max={62000}
              addonBefore={null}
              addonAfter={'cm'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
            />
          </Col>

          <Col xs={24} sm={12} lg={12}>
            <JarvislyNumber
              label="maximum_distance_tolerance"
              name={['field1', 'alarmMaximumDistanceTolerance']}
              min={1}
              max={62000}
              addonBefore={null}
              addonAfter={'cm'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default IotDeviceRecord;
