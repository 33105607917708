import React from 'react';
import { Col, Row, Skeleton, Tag, Tooltip } from 'antd';
import { Icon } from 'components/util-components/Icon';
import { GlobalOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { parsePhoneToDisplay, translateX } from 'utils/helpers';
import dayjs from 'dayjs';
import { FaPlaneArrival, FaPlaneDeparture } from 'react-icons/fa';
import Flex from 'components/shared-components/Flex';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
export const MultiLeadsDocumentSubtitle = props => {
  const { document } = props;

  return (
    <>
      <Tooltip title={translateX('origin')} placement="bottom">
        <Tag color="blue">
          <span className="text-uppercase">{document?.origin}</span>
        </Tag>
      </Tooltip>

      <Tooltip title={translateX('status')} placement="bottom">
        <Tag color="volcano">
          <span className="text-uppercase">Aguardando Cliente</span>
        </Tag>
      </Tooltip>
    </>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const MultiLeadsFormHeaderColumn2 = props => {
  const { document } = props;

  return (
    <Col xs={24} sm={8}>
      <Row style={{ display: document?.email ? 'initial' : 'none' }}>
        <Col>
          {document?.email && (
            <Row className="p-1" style={{ minHeight: 29 }} align={'middle'}>
              <Tooltip placement="right" title={translateX('email')}>
                <Flex
                  flexDirection="row"
                  justifyContent="start"
                  alignItems="center"
                  className="text-truncate"
                >
                  <Icon
                    type={MailOutlined}
                    className="text-primary font-size-md"
                    style={{ flexShrink: 0 }}
                  />
                  <div
                    className="ml-2 font-weight-semibold"
                    style={{ flex: 1 }}
                  >
                    {document?.email}
                  </div>
                </Flex>
              </Tooltip>
            </Row>
          )}
        </Col>
      </Row>

      <Row style={{ display: document?.phone ? 'initial' : 'none' }}>
        <Col>
          {document?.phone && (
            <Row className="p-1" style={{ minHeight: 29 }} align={'middle'}>
              <Tooltip placement="right" title={translateX('phone')}>
                <Flex
                  flexDirection="row"
                  justifyContent="start"
                  alignItems="center"
                  className="text-truncate"
                >
                  <Icon
                    type={PhoneOutlined}
                    className="text-primary font-size-md"
                    style={{ flexShrink: 0 }}
                  />
                  <div
                    className="ml-2 font-weight-semibold"
                    style={{ flex: 1 }}
                  >
                    {parsePhoneToDisplay(document?.phone)}
                  </div>
                </Flex>
              </Tooltip>
            </Row>
          )}
        </Col>
      </Row>
    </Col>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const MultiLeadsFormHeaderColumn3 = props => {
  const { document, isWorking } = props;

  const doc = document?.quotations?.[0];

  return (
    <Col xs={24} sm={8}>
      {/* DESTINY */}
      <Skeleton
        active
        loading={!document?._id && isWorking}
        paragraph={{
          rows: 0,
          width: 200,
        }}
      >
        <Row
          style={{
            display: doc?.destiny ? 'initial' : 'none',
          }}
        >
          <Col>
            <Row className="p-1" style={{ minHeight: 29 }}>
              {doc?.destiny && (
                <Tooltip placement="right" title={translateX('destiny')}>
                  <Flex
                    flexDirection="row"
                    justifyContent="start"
                    alignItems="center"
                    className="text-truncate"
                  >
                    <Icon
                      type={GlobalOutlined}
                      className="text-primary font-size-md"
                      style={{ flexShrink: 0 }}
                    />
                    <span className="ml-2 font-weight-semibold">
                      {translateX(doc?.destiny)}
                    </span>
                  </Flex>
                </Tooltip>
              )}
            </Row>
          </Col>
        </Row>
      </Skeleton>
      {/* DESTINY */}

      {/* DEPARTURE */}
      <Skeleton
        active
        loading={!document?._id && isWorking}
        paragraph={{
          rows: 0,
          width: 200,
        }}
      >
        <Row
          style={{
            display: doc?.departure ? 'initial' : 'none',
          }}
        >
          <Col>
            <Row className="p-1" style={{ minHeight: 29 }}>
              {doc?.departure && (
                <Tooltip placement="right" title={translateX('departure')}>
                  <Flex
                    flexDirection="row"
                    justifyContent="start"
                    alignItems="center"
                    className="text-truncate"
                  >
                    <Icon
                      type={FaPlaneDeparture}
                      className="text-primary font-size-md"
                      style={{ flexShrink: 0 }}
                    />
                    <span className="ml-2 font-weight-semibold">
                      {doc?.departure && dayjs(doc.departure).format('L')}
                    </span>
                  </Flex>
                </Tooltip>
              )}
            </Row>
          </Col>
        </Row>
      </Skeleton>
      {/* DEPARTURE */}

      {/* ARRIVAL */}
      <Skeleton
        active
        loading={!document?._id && isWorking}
        paragraph={{
          rows: 0,
          width: 200,
        }}
      >
        <Row
          style={{
            display: doc?.arrival ? 'initial' : 'none',
          }}
        >
          <Col>
            <Row className="p-1" style={{ minHeight: 29 }}>
              {doc?.arrival && (
                <Tooltip placement="right" title={translateX('arrival')}>
                  <Flex
                    flexDirection="row"
                    justifyContent="start"
                    alignItems="center"
                    className="text-truncate"
                  >
                    <Icon
                      type={FaPlaneArrival}
                      className="text-primary font-size-md"
                      style={{ flexShrink: 0 }}
                    />
                    <span className="ml-2 font-weight-semibold">
                      {doc?.arrival && dayjs(doc.arrival).format('L')}
                    </span>
                  </Flex>
                </Tooltip>
              )}
            </Row>
          </Col>
        </Row>
      </Skeleton>
      {/* ARRIVAL */}
    </Col>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
