import React, { useContext, useEffect, useState } from 'react';
import JarvislyAddressForm from './Items/JarvislyAddressForm';
import JarvislyContactForm from './Items/JarvislyContactForm';
import {
  goFocus,
  JarvislyAsyncAutoComplete,
  JarvislyCpfCnpj,
  JarvislyFormContext,
  JarvislyInput,
  JarvislyRadioButtons,
  JarvislySegmented,
  JarvislySelectAdd,
} from '../others/FormComponents';
import appService from 'services/appService';
import entityService from 'services/entityService';
import { returnOnlyNumbers, translateX } from 'utils/helpers';
import { Card, Col, Collapse, Flex, Row } from 'antd';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { ShopOutlined, UserOutlined } from '@ant-design/icons';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************

const JarvislyEntityCardIndex = props => {
  // providers context ---------------------------------------------------------
  const { form, elRefs, formRefreshed, requiredFields } =
    useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    selectedCustomer,
    setSelectedCustomer,
    context,
    document,
    _id,
    mode,
    isWorking,
    entityType,
    setEntityType,
    uniqueCustomer = false,
  } = props;

  // local variables I ---------------------------------------------------------
  const isMobile = appService.isMobile('sm');
  const isDisabled = isWorking || mode === 'view';
  const segmentedOptions = buildSegmentedOptions();

  // component states ----------------------------------------------------------
  const [, setBillingEmailsOptions] = useState([]);
  const [customerType, setCustomerType] = useState('registered_customer');

  // local variables II --------------------------------------------------------
  const customerAdditionalFields = buildCustomerAdditionalFields();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    form.setFieldValue(
      context ? [context, 'entityType'] : 'entityType',
      entityType,
    );
  }, [customerType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    goFocus(
      elRefs,
      context ? [context, 'cpfCnpjNewCustomer'] : 'cpfCnpjNewCustomer',
      null,
    );
  }, [entityType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const data = context
      ? {
          [context]: {
            customerType: 'registered_customer',
          },
        }
      : {
          customerType: 'registered_customer',
        };

    form.setFieldsValue(data);

    setSelectedCustomer(document?.customer);
    if (_id) setCustomerType('registered_customer');
  }, [formRefreshed, document]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onChangeCustomerType = async ev => {
    const value = typeof ev === 'object' ? ev?.target?.value : ev;

    form.setFieldValue([context], {});

    if (value === 'registered_customer') {
      goFocus(elRefs, context ? [context, 'selectedEntity'] : 'selectedEntity');
    } else {
      goFocus(
        elRefs,
        context ? [context, 'cpfCnpjNewCustomer'] : 'cpfCnpjNewCustomer',
      );
    }

    setCustomerType(value);
    setSelectedCustomer(null);
    setBillingEmailsOptions([]);
  };

  const onCustomerSelect = async value => {
    const doc = await entityService.getEntityById(value.key);

    setSelectedCustomer(doc || null);
    setBillingEmailsOptions(entityService.getEntityAllEmailsArr(doc));

    const body = entityService.decorateEntity(doc, 1, context);

    form.setFieldsValue(body);
    form.validateFields([[context, 'mobile']]);
  };

  const onCpfCnpjChange = async ev => {
    let tagId = ev?.target?.id;
    let fieldName;

    if (tagId) {
      const parts = tagId.split('_');
      fieldName = parts && parts.length > 0 ? parts[parts.length - 1] : null;
    }

    const value = typeof ev === 'object' ? ev?.target?.value : ev;
    const eType = entityService.getEntityTypeByCpfCnpj(value);
    setEntityType(eType);

    if (!eType) return true;

    const cpfCnpj = returnOnlyNumbers(value);
    const doc = await entityService.getEntityByField(cpfCnpj);

    if (!doc) return true;

    const body = entityService.decorateEntity(doc, 1, context);
    body[context].customerType = 'registered_customer';

    let title = translateX(
      'document_exists',
      ['%CPF_CNPJ%'],
      [doc?.cpf ? 'CPF' : 'CNPJ'],
    );
    const options = {
      Content: translateX(
        'document_exists_description',
        ['%CPF_CNPJ%'],
        [doc?.cpf ? 'CPF' : 'CNPJ'],
      ),
      okText: 'yes',
      cancelText: 'no',

      onCancel: () => {
        form.setFieldValue(
          [context, fieldName],
          selectedCustomer?.cpf || selectedCustomer?.cnpj,
        );
        if (fieldName) goFocus(elRefs, fieldName, 'all');
        return false;
      },

      onOk: () => {
        setCustomerType('registered_customer');
        setBillingEmailsOptions(entityService.getEntityAllEmailsArr(doc));
        setSelectedCustomer(doc);
        // updateFormAdressState(doc, 'customer');

        // setRefreshAddress(+new Date());

        form.setFieldValue([context], body[context]);
        form.validateFields([[context, 'mobile']]);
        goFocus(elRefs, 'selectedEntity');
        return false;
      },
    };

    appService.modal('q', title, options);

    return false;
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <Card title={<div className="text-muted">{translateX('customer')}</div>}>
      {!(!_id && uniqueCustomer) ? (
        <Flex
          justify={isMobile ? 'center' : 'space-between'}
          align="center"
          vertical={isMobile}
        >
          {/* CUSTOMER OPTIONS */}
          <JarvislyRadioButtons
            context={context}
            name="customerType"
            onChange={onChangeCustomerType}
            noLabel
            options={[
              {
                value: 'registered_customer',
                label: translateX('registered_customer'),
              },
              {
                value: 'new_customer',
                label: translateX('new_customer'),
              },
            ]}
          />
          {/* CUSTOMER OPTIONS */}

          {/* ENTITY TYPE OPTIONS */}
          {customerType === 'new_customer' ? (
            <JarvislySegmented
              name="entityType"
              context={context}
              x-no-label
              disabled={isWorking}
              options={segmentedOptions}
              value={entityType}
              onChange={v => {
                setEntityType(v);
                // form.setFieldValue([context, 'entityType'], v);
              }}
              block={false}
            />
          ) : null}
          {/* ENTITY TYPE OPTIONS */}
        </Flex>
      ) : null}

      {/* SELECT CUSTOMER FORM */}
      <Row
        gutter={ROW_GUTTER}
        style={{
          display: customerType === 'registered_customer' ? 'flex' : 'none',
        }}
      >
        {/* CUSTOMER */}
        <Col
          xs={24}
          sm={16}
          md={document?.customer?.dataType === 'company' ? 12 : 18}
        >
          <JarvislyAsyncAutoComplete
            context={context}
            name="selectedEntity"
            label="name"
            required={customerType === 'registered_customer'}
            disabled={
              isDisabled ||
              (uniqueCustomer && document?.status && document.status !== 'new')
            }
            x-data-profile={[
              'customer',
              'company',
              'provider',
              'collaborator',
              'resident',
              'operator',
            ]}
            x-on-select={onCustomerSelect}
          />
        </Col>
        {/* CUSTOMER */}

        {/* CPF/CNPJ */}
        <Col xs={24} sm={8} md={6}>
          <JarvislyCpfCnpj
            context={context}
            label={translateX(
              document?.customer?.dataType === 'company' ? 'cnpj' : 'cpf',
            )}
            disabled={
              isDisabled ||
              !selectedCustomer ||
              (document?.status && document.status !== 'new')
            }
            required={
              requiredFields.includes('cpfCnpj') &&
              customerType === 'registered_customer'
            }
            onChange={onCpfCnpjChange}
          />
        </Col>
        {/* CPF/CNPJ */}

        {/* CONTACT */}
        {document?.customer?.dataType === 'company' ? (
          <Col xs={24} sm={24} md={6}>
            <JarvislySelectAdd
              context={context}
              name="selectedContact"
              label="contact"
            />
          </Col>
        ) : null}
        {/* CONTACT */}
      </Row>
      {/* SELECT CUSTOMER FORM */}

      {/* NEW CUSTOMER FORM */}
      <Row
        gutter={ROW_GUTTER}
        style={{
          display: customerType !== 'registered_customer' ? 'flex' : 'none',
        }}
      >
        <Col xs={24} sm={8}>
          <JarvislyCpfCnpj
            context={context}
            name="cpfCnpjNewCustomer"
            label={translateX(entityType === 'company' ? 'cnpj' : 'cpf')}
            required={
              requiredFields.includes('cpfCnpjNewCustomer') &&
              customerType !== 'registered_customer'
            }
            onChange={onCpfCnpjChange}
          />
        </Col>

        <Col xs={24} sm={16}>
          <JarvislyInput
            context={context}
            name="name"
            label={entityType === 'company' ? 'company' : 'name'}
            required={customerType !== 'registered_customer'}
          />
        </Col>
      </Row>

      {/* CONTACT */}
      {customerType !== 'registered_customer' ? (
        <JarvislyContactForm
          {...props}
          // style={{
          //   display: customerType !== 'registered_customer' ? 'flex' : 'none',
          // }}
          phoneOrEmailRequired={true}
          contactNameRequired={entityType === 'company'}
          showName={entityType === 'company'}
        />
      ) : null}
      {/* CONTACT */}

      {/* OBS */}
      <Row
        style={{
          display: 'flex',
        }}
      >
        <Col xs={24}>
          <JarvislyInput context={context} name="note" />
        </Col>
      </Row>
      {/* OBS */}

      {/* ADDITIONAL FIELDS */}
      <Collapse
        ghost
        style={{
          display:
            customerType !== 'registered_customer' || selectedCustomer
              ? 'flex'
              : 'none',
        }}
        onChange={keys => {
          if (keys?.includes('additional_fields')) {
            goFocus(elRefs, 'zip', 'all');
          }
        }}
        items={customerAdditionalFields}
        className="no-collapse-padding"
      />
      {/* ADDITIONAL FIELDS */}

      {/* NEW CUSTOMER FORM */}
    </Card>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildSegmentedOptions() {
    return [
      {
        label: translateX('legal_person'),
        value: 'company',
        icon: <ShopOutlined />,
      },
      {
        label: translateX('individual_person'),
        value: 'person',
        icon: <UserOutlined />,
      },
    ];
  }

  function buildCustomerAdditionalFields() {
    return [
      {
        key: 'additional_fields',
        label: `${translateX('additional_info')} (${translateX('optional')})`,
        children: (
          <>
            {/* ADDRESS */}
            <JarvislyAddressForm {...props} addressContext={context} />
            {/* ADDRESS */}

            {/* NOTE */}
            {/*<JarvislyNoteForm*/}
            {/*  {...props}*/}
            {/*  disabled={*/}
            {/*    customerType === 'registered_customer' && !selectedCustomer*/}
            {/*  }*/}
            {/*/>*/}
            {/* NOTE */}
          </>
        ),
      },
    ];
  }
};

// EXPORT **********************************************************************
// *****************************************************************************

export default JarvislyEntityCardIndex;
