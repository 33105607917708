import fetch from 'auth/FetchInterceptor';
import { getIpWan, getLocale, returnOnlyNumbers } from '../utils/helpers';
import { rdxSetUser } from '../store/slices/authSlice';
import store from '../store';
import { AUTH_TOKEN } from '../constants/AuthConstant';
// import app from '../configs/ConfigIndex';
//
// const loginTheme = app?.APPLICATION_LAYER;

const authService = {};

authService.account = null;

authService.subscription = null;

authService.login = async function (data, loginTheme = 'adm') {
  const uri = loginTheme === 'user' ? 'login-user' : 'login-adm';

  return await fetch({
    url: `/v1/auth/${uri}`,
    method: 'post',
    data: data,
    headers: { 'x-resultasobject': true },
  });
};

authService.sendRegistrationCode = async function (values, loginTheme = 'adm') {
  const { name, email, mobile } = values;

  const data = {
    ip: await getIpWan(),
    language: getLocale(true).toLowerCase(),
    name,
    email,
    mobile,
    accountType: loginTheme,
  };

  const result = await fetch({
    url: `/v1/auth/send-${mobile ? 'sms' : 'email'}-registration-code`,
    method: 'post',
    headers: { 'x-resultasobject': true },
    data,
  });

  return typeof result?.success === 'undefined' ? 'error' : !!result?.success;
};

authService.sendRequestUpdateMessage = async function (name, phone, values) {
  const { channel, message } = values;

  const data = {
    ip: await getIpWan(),
    language: getLocale(true).toLowerCase(),
    name,
    phone,
    channel,
    message,
  };

  const result = await fetch({
    url: `/v1/auth/send-${channel}-update-link`,
    method: 'post',
    headers: { 'x-resultasobject': true },
    data,
  });

  return typeof result?.success === 'undefined' ? 'error' : !!result?.success;
};

authService.validateRegistrationCode = async values => {
  const result = await fetch({
    url: `/v1/auth/validate-email-code`,
    method: 'post',
    headers: { 'x-resultasobject': true },
    data: values,
  });

  return result?.success;
};

authService.logout = function () {
  return fetch({
    url: '/auth/logout',
    method: 'post',
  });
};

authService.loginInOAuth = function () {
  return fetch({
    url: '/auth/loginInOAuth',
    method: 'post',
  });
};

authService.validateLoginByCpf = async (cpf, setStateWorking) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = {
        loginKey: 'cpf',
        loginValue: returnOnlyNumbers(cpf),
      };
      const user = await authService.login(data, 'user');

      setStateWorking(false);

      if (user) {
        resolve(true);
      } else {
        reject();
      }
    } catch (e) {
      console.error(e);
    }
  });
};

// GET ACCOUNT FROM DB
authService.getAccountByToken = async () => {
  const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;

  if (!jwtToken) return null;

  return fetch({
    url: `/v1/auth/get-account-by-token`,
    method: 'get',
    headers: {
      'x-resultasobject': true,
      'x-subscriptionid': null,
    },
  });
};

authService.refreshAccount = () => {
  const token = localStorage.getItem(AUTH_TOKEN) || null;
  store.dispatch(rdxSetUser(token));
};

export default authService;
