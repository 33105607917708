import React, { Component } from 'react';

export class Icon extends Component {
  render() {
    const { type, className } = this.props;

    let specialClass = type?.toString()?.includes('tag') ? 'icon-adapted' : '';

    return (
      <>
        {React.createElement(type, {
          className: `${className} ${specialClass}`,
        })}
      </>
    );
  }
}

export default Icon;
