import { TbFileInvoice } from 'react-icons/tb';

const MODULE_TITLE = 'invoices';
const MODULE_ICON = TbFileInvoice;
const MODULE_MAIN_ROUTE = `/app/financial/${MODULE_TITLE}`;

const INVOICES_MODULE = {
  name: 'invoices',
  title: MODULE_TITLE,
  icon: MODULE_ICON,
  menus: {
    financial: [
      {
        // key: 'financial.invoices',
        path: MODULE_MAIN_ROUTE,
        title: MODULE_TITLE,
        icon: MODULE_ICON,
        submenu: [],
      },
    ],
  },

  url: MODULE_MAIN_ROUTE,
  routes: [`${MODULE_MAIN_ROUTE}/:_id/:section?`, MODULE_MAIN_ROUTE],
};

export default INVOICES_MODULE;
