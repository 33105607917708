import { Col, Row } from 'antd';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import React from 'react';
import { JarvislyInput } from '../../others/FormComponents';

// COMPONENT *******************************************************************
// *****************************************************************************

const JarvislyNoteForm = props => {
  // props deconstruction ------------------------------------------------------
  const { context, showNote=true } = props;

  // local variables -----------------------------------------------------------

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {/* ************************************************************
      NOTE
      ************************************************************ */}
      {/*<FormTitle title={"note"} />*/}

      {showNote ? (
        <Row gutter={ROW_GUTTER}>
          <Col xs={24}>
            <JarvislyInput context={context} name="note" />
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default JarvislyNoteForm;
