import {
  FormInput,
  FormZip,
} from 'components/jarvisly-components/others/FormComponents';
import { Col, Row } from 'antd';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import React from 'react';
import appService from '../../../services/appService';
import { debounce } from '../../../utils/helpers';

// COMPONENT *******************************************************************
// *****************************************************************************

const AddressComponent2 = props => {
  // props deconstruction ------------------------------------------------------
  const {
    form,
    elRefs,
    isDisabled,
    address, // state from parent component (with FormWrapper)
    setAddress, // state from parent component (with FormWrapper)
  } = props;

  // local variables -----------------------------------------------------------

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onAfterZipSearch = address => {
    setAddress({
      addressTitle: address?.addressTitle,
      neighborhood: address?.neighborhood,
      city: address?.city,
      province: address?.province,
      addressCoordinates: address?.addressCoordinates,
      addressAdditionalInfo: address?.addressAdditionalInfo,
      addressMetadata: address?.addressMetadata,
    });

    // setAddress(address);

    if (address) {
      form.setFieldsValue({
        address1: address.address1,
        neighborhood: address.neighborhood,
        __city: address.__city,
      });

      setTimeout(() => {
        if (address?.addressMetadata?.zipWithMultipleAddress) {
          elRefs?.address1?.current?.focus();
        } else {
          elRefs?.number?.current?.focus();
        }
      }, 50);
    } else {
      form.resetFields(['address1', 'neighborhood', '__city']);
    }
  };

  const onChangeNumber = event => {
    debounce(fn, 1000);

    async function fn() {
      let number = event?.target?.value;

      const formAddress = form.getFieldValue('address1');

      if (!formAddress || !number) return;
      const fullAddress = `${formAddress}, ${number}`;
      const addressCoordinates = await appService.getGpsByAddress(fullAddress);

      setAddress({ ...address, addressCoordinates });
    }
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {/* ************************************************************
      ADDRESS
      ************************************************************ */}
      {/*<FormTitle title="address" />*/}

      <Row gutter={ROW_GUTTER}>
        <Col xs={24} md={6} lg={8}>
          <FormZip
            form={form}
            elRefs={elRefs}
            afterSearch={onAfterZipSearch}
            disabled={isDisabled}
            required
          />
        </Col>

        <Col xs={24} md={14} lg={16}>
          <FormInput
            name="address1"
            label="address"
            disabled={
              !address?.addressMetadata?.zipWithMultipleAddress || isDisabled
            }
            elRefs={elRefs}
            required={address?.addressMetadata?.zipWithMultipleAddress}
          />
        </Col>

        <Col xs={24} md={4} lg={6}>
          <FormInput
            name="number"
            elRefs={elRefs}
            disabled={isDisabled}
            onChange={onChangeNumber}
            required
          />
        </Col>

        <Col xs={24} md={8} lg={8}>
          <FormInput name="address2" disabled={isDisabled} elRefs={elRefs} />
        </Col>

        <Col xs={24} md={8} lg={10}>
          <FormInput
            name="__city"
            label="city"
            disabled={true}
            elRefs={elRefs}
          />
        </Col>
      </Row>
      {/* ADDRESS */}
    </>
  );
};

export default AddressComponent2;
