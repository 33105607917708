import fetch from 'auth/FetchInterceptor';
import { isMongoObjectId } from 'utils/helpers';
import appService from './appService';
import store from '../store';
import { rdxSetApiResponse } from '../store/slices/moduleSlice';

const axiosService = {};

axiosService.get = async options => {
  const returnAsObject =
    typeof options?.returnAsObject === 'undefined'
      ? !!options?._id
      : options.returnAsObject;

  if (!options?.url || typeof options.url !== 'string') {
    appService.console(
      'e',
      "'options.url' is missing in 'axiosService.get(options)'",
    );
  }

  if (options?._id && !options.url.includes(options?._id)) {
    options.url = `${options.url}/${options._id}`;
  }

  let urlWithQueryString = options.url;

  if (options.qs) {
    const queryString = Object.entries(options.qs)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return value
            .map(
              item => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`,
            )
            .join('&');
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      })
      .join('&');

    if (queryString) {
      urlWithQueryString += `?${queryString}`;
    }
  }

  try {
    return done(
      await fetch({
        url: urlWithQueryString.toString(),
        method: 'get',
        headers: options?.headers,
      }),
      returnAsObject,
    );
  } catch (error) {
    throw error;
  }
};

axiosService.post = async options => {
  const returnAsObject =
    typeof options?.returnAsObject === 'undefined'
      ? true
      : options.returnAsObject;

  if (!options?.url || typeof options.url !== 'string') {
    const msg = "'options.url' is missing in 'axiosService.post(options)'";
    appService.console('e', msg);
    throw Error(msg);
  }

  if (!options?.data || typeof options.data !== 'object') {
    const msg = "'options.data' is missing in 'axiosService.post(options)'";
    appService.console('e', msg);
    throw Error(msg);
  }

  try {
    return done(
      await fetch({
        url: options.url,
        method: 'post',
        headers: options?.headers,
        data: options.data,
      }),
      returnAsObject,
    );
  } catch (error) {
    throw error;
  }
};

axiosService.put = async options => {
  const returnAsObject =
    typeof options?.returnAsObject === 'undefined'
      ? true
      : options.returnAsObject;

  if (!options?.url || typeof options.url !== 'string') {
    appService.console(
      'e',
      "'options.url' is missing in 'axiosService.put(options)'",
    );
  }
  // if (!options?._id) {
  //   appService.console(
  //     'e',
  //     "'options._id' is missing in 'axiosService.put(options)'",
  //   );
  // }
  if (!options?.data || typeof options.data !== 'object') {
    appService.console(
      'e',
      "'options.data' is missing in 'axiosService.put(options)'",
    );
  }

  try {
    return done(
      await fetch({
        url: options._id ? `${options.url}/${options._id}` : options.url,
        method: 'put',
        headers: options?.headers,
        data: options.data,
      }),
      returnAsObject,
    );
  } catch (error) {
    throw error;
  }
};

axiosService.patch = async options => {
  const returnAsObject =
    typeof options?.returnAsObject === 'undefined'
      ? true
      : options.returnAsObject;

  if (!options?.url || typeof options.url !== 'string') {
    appService.console(
      'e',
      "'options.url' is missing in 'axiosService.patch(options)'",
    );
  }
  // if (!options?._id || !isMongoObjectId(options._id)) {
  //   appService.console(
  //     'e',
  //     "'options._id' is missing in 'axiosService.patch(options)'",
  //   );
  // }
  if (!options?.data || typeof options.data !== 'object') {
    appService.console(
      'e',
      "'options.data' is missing in 'axiosService.patch(options)'",
    );
  }

  try {
    return done(
      await fetch({
        url: options._id ? `${options.url}/${options._id}` : options.url,
        method: 'patch',
        headers: options?.headers,
        data: options.data,
      }),
      returnAsObject,
    );
  } catch (error) {
    throw error;
  }
};

axiosService.upsert = async options => {
  const { _id, url, data } = options;

  const returnAsObject =
    typeof options?.returnAsObject === 'undefined'
      ? true
      : options.returnAsObject;

  if (_id) {
    return await axiosService.put({
      _id,
      url,
      data,
      returnAsObject,
    });
  } else {
    return await axiosService.post({
      url,
      data,
      returnAsObject,
    });
  }
};

axiosService.delete = async options => {
  const returnAsObject =
    typeof options?.returnAsObject === 'undefined'
      ? true
      : options.returnAsObject;

  if (!options?._id || !isMongoObjectId(options._id)) {
    appService.console(
      'e',
      "'options._id' is missing in 'axiosService.delete(options)'",
    );
  }

  try {
    return done(
      await fetch({
        url: `${options.url}/${options._id}`,
        method: 'delete',
        headers: options?.headers,
      }),
      returnAsObject,
    );
  } catch (error) {
    throw error;
  }
};

export default axiosService;

function done(result, returnAsObject) {
  store.dispatch(rdxSetApiResponse(result || null));

  let docs;

  if (returnAsObject) {
    if (Array.isArray(result?.data)) {
      if (result?.data?.length > 0) {
        docs = result.data[0];
      } else {
        docs = null;
      }
    } else {
      docs = result?.data || null;
    }
  } else {
    if (result?.data) {
      if (!Array.isArray(result?.data)) {
        docs = [result.data];
      } else {
        if (result.data.length > 0) {
          docs = result.data;
        } else {
          docs = null;
        }
      }
    } else {
      docs = null;
    }
  }

  return [docs, result];
}
