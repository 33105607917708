import {
  goFocus,
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyNumber,
} from 'components/jarvisly-components/others/FormComponents';
import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from 'react';
import { translateX } from 'utils/helpers';
import { Button, Col, Form, Modal, Row, Spin } from 'antd';
import {
  MinusCircleOutlined,
  PlusOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import appmultiService from 'services/appmultiService';
import axiosService from 'services/axiosService';
import { APPMULTI_PLANS_API } from '../../plans';

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form, elRefs } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    document,
    showModal,
    setShowModal,
    afterCloseFn,
    isDisabled,
    updating,
    isWorking,
  } = props;

  // local variables I ----------------------------------------------------------
  const currencyOptions = appmultiService.getCurrencies();

  // component states -----------------------------------------------------------

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    setShowModal(false);
  };

  const onAfterClose = () => {
    afterCloseFn && afterCloseFn();
    form?.resetFields();
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        className="title-fixed-with-18"
        title={
          <>
            <UnorderedListOutlined />
            <span className="ml-2">{translateX('table_price')}</span>
          </>
        }
        destroyOnClose={true}
        open={showModal}
        // width={640}
        onCancel={onClose}
        getContainer={false}
        confirmLoading={updating}
        afterClose={onAfterClose}
        onOk={() => (isDisabled ? onClose() : form.submit())}
        okButtonProps={{ style: { width: 120 } }}
        okType={isDisabled ? 'default' : 'primary'}
        okText={translateX(isDisabled ? 'close' : 'save')}
        cancelButtonProps={{
          style: { width: 120, display: isDisabled ? 'none' : 'inline' },
        }}
      >
        <Spin spinning={isWorking} size="large">
          <Form.List name="priceTable">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => {
                  return (
                    <Row align="bottom" key={key}>
                      {/* FROM */}
                      <Col xs={6} className="pr-2">
                        <JarvislyNumber
                          {...restField}
                          x-no-label
                          name={[name, 'from']}
                          placeholder={translateX('from')}
                          min={1}
                          max={999}
                          addonBefore={null}
                          addonAfter={null}
                          required={true}
                        />
                      </Col>
                      {/* FROM */}

                      {/* TO */}
                      <Col xs={6} className="pr-2">
                        <JarvislyNumber
                          {...restField}
                          x-no-label
                          name={[name, 'to']}
                          placeholder={translateX('to')}
                          min={1}
                          max={999}
                          addonBefore={null}
                          addonAfter={null}
                          required={true}
                        />
                      </Col>
                      {/* TO */}

                      {/* VALUE */}
                      <Col xs={9} className="pr-2">
                        <JarvislyNumber
                          {...restField}
                          x-no-label
                          name={[name, 'value']}
                          placeholder={translateX('value')}
                          decimalSeparator=","
                          min={0.01}
                          max={9999.99}
                          addonBefore={null}
                          addonAfter={currencyOptions}
                          suffixDefaultValue={
                            document?.__operator.parameters?.__currency || 'R$'
                          }
                          suffixStyle={{ width: 60 }}
                          required={true}
                          suffixDisabled={true}
                        />
                      </Col>
                      {/* VALUE */}

                      {/* MINUS BUTTON */}
                      <Col xs={3} className="text-right">
                        <Button
                          style={{ marginBottom: 24 }}
                          disabled={fields.length <= 1}
                          icon={<MinusCircleOutlined />}
                          onClick={() => remove(name)}
                        />
                      </Col>
                      {/* MINUS BUTTON */}
                    </Row>
                  );
                })}
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                    setTimeout(
                      () => goFocus(elRefs, [fields.length, 'name'].join(',')),
                      0,
                    );
                  }}
                  block
                  icon={<PlusOutlined />}
                >
                  {translateX('daily_m_p')}
                </Button>
              </>
            )}
          </Form.List>
        </Spin>
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const PricesTableModal = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { setIsWorking, isWorking, document, refreshDocument, _id } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------
  const [showModal, setShowModal] = useState(false);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useImperativeHandle(ref, () => ({
    showModal,
    setShowModal: value => setShowModal(value),
  }));

  // methods -------------------------------------------------------------------
  const afterCloseFn = () => {
    refreshDocument(_id, false, false);
    setIsWorking(false);
  };

  const onConfirm = async ({ body }) => {
    setIsWorking(true);
    body.priceTable.map((i, idx) => {
      if (body?.[`${idx}`]?.valueSuffix) {
        i.currency = body[`${idx}`].valueSuffix;
      }

      return i;
    });

    const { priceTable } = body;

    const axiosOptions = {
      _id: document?._id,
      url: `${APPMULTI_PLANS_API}`,
      data: { priceType: 'table', priceTable },
    };

    await axiosService.put(axiosOptions);
    setShowModal(false);
  };

  // local variables III -------------------------------------------------------
  const modalProps = {
    ...props,
    showModal,
    setShowModal,
    afterCloseFn,
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <JarvislyFormProvider
      name="form-plan-prices-table"
      document={document}
      requiredFields={[]}
      showSubmitButton={false}
      onConfirm={onConfirm}
      autoFocus="selectedBank"
      abortComponentSave={true}
    >
      <ModalComponent {...modalProps} showBirthdate updating={isWorking} />
    </JarvislyFormProvider>
  );
});

export default PricesTableModal;
