import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Card, Col, Row, Switch } from 'antd';
import {
  JarvislyAsyncAutoComplete,
  JarvislyFormContext,
  JarvislyInput,
  JarvislyNumber,
  JarvislyRequiredCard,
  JarvislySelect,
  JarvislySwitch,
} from 'components/jarvisly-components/others/FormComponents';
import { isOdd, translateX } from 'utils/helpers';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import appmultiService from 'services/appmultiService';
import UploadFirebaseComponent from 'components/jarvisly-components/others/UploadFirebaseComponent';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const MultiPlansRecordIndex = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------
  const { form, isFormDisabled } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const { document, mode, moduleRefreshed, setIsWorking, pricesTableModalRef } =
    props;

  // local variables I ---------------------------------------------------------
  const currencyOptions = appmultiService.getCurrencies();
  const priceTypesOptions = appmultiService.getPriceTypes();
  const destinationsOptions = appmultiService.getDestinations();
  const travelProfileOptions = appmultiService.getTravelProfiles();
  const grievanceOptions = appmultiService.getGrievanceLevels();

  // component states ----------------------------------------------------------
  const [grievanceLevel, setGrievanceLevel] = useState('none');
  const [attachments, setAttachments] = useState([]);
  const [cardRequiredErrors, setCardRequiredErrors] = useState([]);
  const [showInWebsite, setShowInWebsite] = useState(false);
  const [priceType, setPriceType] = useState('daily');

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (mode === 'add') setCardRequiredErrors([]);
  }, [moduleRefreshed]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (document?.grievanceLevel) setGrievanceLevel(document.grievanceLevel);
    setAttachments(document?.__attachments || []);
    setShowInWebsite(document?.showInWebsite || false);
    setPriceType(document?.priceType || 'daily');
  }, [document]); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({ attachments, showInWebsite }));

  // methods -------------------------------------------------------------------
  const validateDestinationsCard = () => {
    const formFields = form.getFieldsValue();
    const group = formFields?.__destinations;

    let isAnyChecked = false;
    group &&
      Object.keys(group).map(key => {
        if (group[key]) isAnyChecked = true;
        return key;
      });

    if (isAnyChecked) {
      setCardRequiredErrors([
        ...cardRequiredErrors.filter(i => i !== 'destinations'),
      ]);
      return Promise.resolve();
    } else {
      setCardRequiredErrors([...cardRequiredErrors, 'destinations']);
      return Promise.reject();
    }
  };

  const validateProfilesCard = () => {
    const formFields = form.getFieldsValue();
    const group = formFields?.__travelProfiles;

    let isAnyChecked = false;
    group &&
      Object.keys(group).map(key => {
        if (group[key]) isAnyChecked = true;
        return key;
      });

    if (isAnyChecked) {
      setCardRequiredErrors([
        ...cardRequiredErrors.filter(i => i !== 'travelProfiles'),
      ]);
      return Promise.resolve();
    } else {
      setCardRequiredErrors([...cardRequiredErrors, 'travelProfiles']);
      return Promise.reject();
    }
  };

  const onUploadBefore = async () => setIsWorking(true);
  const onUploadAfter = async () => setIsWorking(false);
  const onUploadError = async () => setIsWorking(false);

  const onPriceTypePrefixChange = value => {
    if (value === 'table') {
      form.setFieldValue('price', '');
    } else {
      form.setFieldValue('price', document?.price);
    }
    setPriceType(value);
  };

  const onPriceTypeClick = () => {
    if (priceType !== 'table') return;
    pricesTableModalRef?.current?.setShowModal(true);
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {/*************************************************************
        CHARACTERISTICS
        *************************************************************/}
      <Card title={<div className="text-muted">{translateX('plan')}</div>}>
        <Row gutter={ROW_GUTTER}>
          {/*NAME*/}
          <Col xs={24} sm={12}>
            <JarvislyInput name="name" />
          </Col>
          {/*NAME*/}

          {/*OPERATOR*/}
          <Col xs={24} sm={12}>
            <JarvislyAsyncAutoComplete
              name="selectedOperator"
              label="operator"
              x-data-type="company"
              x-data-profile="operator"
            />
          </Col>
          {/*OPERATOR*/}
        </Row>

        <Row gutter={ROW_GUTTER}>
          {/*PRICE*/}
          <Col md={8}>
            <JarvislyNumber
              name="price"
              // name="dailyPrice"
              // label="daily_price"
              decimalSeparator=","
              min={0.01}
              max={99999.99}
              addonBefore={priceTypesOptions}
              onPrefixChange={onPriceTypePrefixChange}
              prefixDefaultValue={document?.priceType || 'daily'}
              x-prefix-style={{ minWidth: 90 }}
              addonAfter={priceType !== 'table' ? currencyOptions : null}
              suffixDefaultValue={document?.currency || 'R$'}
              suffixStyle={{ width: 60 }}
              placeholder={priceType === 'table' ? 'Click para editar!' : null}
              onKeyDown={e => priceType === 'table' && e.preventDefault()}
              keyboard={priceType !== 'table'}
              controls={priceType !== 'table'}
              className={priceType === 'table' ? 'hide-up-down-buttons' : ''}
              required={priceType !== 'table'}
              onClick={onPriceTypeClick}
            />
          </Col>
          {/*PRICE*/}

          {/*MINIMAL DAYS*/}
          <Col xs={8}>
            <JarvislyNumber
              name="minDays"
              label="min_days"
              min={1}
              max={999}
              addonBefore={null}
              addonAfter={null}
            />
          </Col>
          {/*MINIMAL DAYS*/}

          {/*MAXIMUM DAYS*/}
          <Col xs={8}>
            <JarvislyNumber
              name="maxDays"
              label="max_days"
              min={1}
              max={999}
              addonBefore={null}
              addonAfter={null}
            />
          </Col>
          {/*MAXIMUM DAYS*/}
        </Row>

        <Row
          gutter={ROW_GUTTER}
          justify="end"
          align="middle"
          style={{ height: 40 }}
        >
          <Col>Exibir no Website</Col>
          {/*<Col style={{ marginBottom: 24 }}>Exibir no Website</Col>*/}
          <Col>
            <Switch
              value={showInWebsite}
              onClick={v => setShowInWebsite(v)}
              disabled={mode === 'view'}
            />
            {/*<JarvislySwitch*/}
            {/*  name="showInWebsite"*/}
            {/*  // value={showInWebsite}*/}
            {/*  x-no-label*/}
            {/*  // onClick={v => {*/}
            {/*  //   form.setFieldValue('showInWebsite', v);*/}
            {/*  //   setShowInWebsite(v);*/}
            {/*  // }}*/}
            {/*/>*/}
          </Col>
        </Row>
      </Card>

      {/*************************************************************
        DESTINATIONS
        *************************************************************/}
      <JarvislyRequiredCard
        errorsArr={cardRequiredErrors}
        value="destinations"
        title="destinations"
      >
        <Row gutter={ROW_GUTTER}>
          {destinationsOptions?.map((item, i) => (
            <Col key={i} xs={24} sm={12} style={{ height: 38 }}>
              <Row gutter={ROW_GUTTER} className={!isOdd(i) ? 'ml-sm-4' : ''}>
                <Col span={16}>{item.label}</Col>
                <Col span={8} className="text-right text-sm-left">
                  <JarvislySwitch
                    name={['__destinations', item.value]}
                    noLabel
                    rules={[
                      {
                        validator: validateDestinationsCard,
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </JarvislyRequiredCard>

      {/*************************************************************
        AGES
        *************************************************************/}
      <Card
        title={
          <div className="text-muted">
            {/*<span style={{ color: 'red', marginRight: 6 }}>*</span>*/}
            {translateX('age_limits')}
          </div>
        }
      >
        <Row gutter={ROW_GUTTER}>
          {/*MINIMAL AGE*/}
          <Col xs={8}>
            <JarvislyNumber
              name="minAge"
              label="minimal_age"
              min={0}
              max={99}
              addonBefore={null}
              addonAfter={null}
            />
          </Col>
          {/*MINIMAL AGE*/}

          {/*MAXIMUM AGE*/}
          <Col xs={8}>
            <JarvislyNumber
              name="maxAge"
              label="maximum_age"
              min={0}
              max={99}
              addonBefore={null}
              addonAfter={null}
            />
          </Col>
          {/*MAXIMUM AGE*/}

          {/*BEST AGE*/}
          <Col xs={8}>
            <JarvislySelect
              name="grievanceLevel"
              label="grievance_level"
              options={grievanceOptions}
              onChange={v => setGrievanceLevel(v)}
            />
          </Col>
          {/*BEST AGE*/}
        </Row>

        {/* GRIEVANCE LEVEL 1 */}
        {['level1', 'level2', 'level3'].includes(grievanceLevel) ? (
          <Row gutter={ROW_GUTTER}>
            {/*BEST MINIMAL AGE 1*/}
            <Col xs={8}>
              <JarvislyNumber
                name="grievanceMinAge1"
                label="minimal_age"
                min={0}
                max={99}
                addonBefore={null}
                addonAfter={null}
              />
            </Col>
            {/*BEST MINIMAL AGE 1*/}

            {/*BEST MAXIMUM AGE 1*/}
            <Col xs={8}>
              <JarvislyNumber
                name="grievanceMaxAge1"
                label="maximum_age"
                min={0}
                max={99}
                addonBefore={null}
                addonAfter={null}
              />
            </Col>
            {/*BEST MAXIMUM AGE 1*/}

            {/*BEST AGE ADDITION 1*/}
            <Col xs={8}>
              <JarvislyNumber
                name="grievanceAdditionAge1"
                label="addition"
                min={1.0}
                max={999.99}
                addonBefore={null}
                decimalSeparator=","
                addonAfter="%"
                suffixStyle={{ width: 60 }}
              />
            </Col>
            {/*BEST AGE ADDITION 1*/}
          </Row>
        ) : null}
        {/* GRIEVANCE LEVEL 1 */}

        {/* GRIEVANCE LEVEL 3 */}
        {['level2', 'level3'].includes(grievanceLevel) ? (
          <Row gutter={ROW_GUTTER}>
            {/*BEST MINIMAL AGE 2*/}
            <Col xs={8}>
              <JarvislyNumber
                name="grievanceMinAge2"
                label="minimal_age"
                min={0}
                max={99}
                addonBefore={null}
                addonAfter={null}
              />
            </Col>
            {/*BEST MINIMAL AGE 2*/}

            {/*BEST MAXIMUM AGE 2*/}
            <Col xs={8}>
              <JarvislyNumber
                name="grievanceMaxAge2"
                label="maximum_age"
                min={0}
                max={99}
                addonBefore={null}
                addonAfter={null}
              />
            </Col>
            {/*BEST MAXIMUM AGE 2*/}

            {/*BEST AGE ADDITION 2*/}
            <Col xs={8}>
              <JarvislyNumber
                name="grievanceAdditionAge2"
                label="addition"
                min={1.0}
                max={999.99}
                addonBefore={null}
                decimalSeparator=","
                addonAfter="%"
                suffixStyle={{ width: 60 }}
              />
            </Col>
            {/*BEST AGE ADDITION 2*/}
          </Row>
        ) : null}
        {/* GRIEVANCE LEVEL 2 */}

        {/* GRIEVANCE LEVEL 3 */}
        {['level3'].includes(grievanceLevel) ? (
          <Row gutter={ROW_GUTTER}>
            {/*BEST MINIMAL AGE 3*/}
            <Col xs={8}>
              <JarvislyNumber
                name="grievanceMinAge3"
                label="minimal_age"
                min={0}
                max={99}
                addonBefore={null}
                addonAfter={null}
              />
            </Col>
            {/*BEST MINIMAL AGE 3*/}

            {/*BEST MAXIMUM AGE 3*/}
            <Col xs={8}>
              <JarvislyNumber
                name="grievanceMaxAge3"
                label="maximum_age"
                min={0}
                max={99}
                addonBefore={null}
                addonAfter={null}
              />
            </Col>
            {/*BEST MAXIMUM AGE 3*/}

            {/*BEST AGE ADDITION 3*/}
            <Col xs={8}>
              <JarvislyNumber
                name="grievanceAdditionAge3"
                label="addition"
                min={1.0}
                max={999.99}
                addonBefore={null}
                decimalSeparator=","
                addonAfter="%"
                suffixStyle={{ width: 60 }}
              />
            </Col>
            {/*BEST AGE ADDITION 3*/}
          </Row>
        ) : null}
        {/* GRIEVANCE LEVEL 3 */}
      </Card>

      {/*************************************************************
        TRAVEL PROFILE
        *************************************************************/}

      <JarvislyRequiredCard
        errorsArr={cardRequiredErrors}
        value="travelProfiles"
        title="travel_profile"
      >
        <Row gutter={ROW_GUTTER}>
          {travelProfileOptions?.map((item, i) => (
            <Col key={i} xs={24} sm={12} style={{ height: 38 }}>
              <Row gutter={ROW_GUTTER} className={!isOdd(i) ? 'ml-sm-4' : ''}>
                <Col span={16}>{item.label}</Col>
                <Col span={8} className="text-right text-sm-left">
                  <JarvislySwitch
                    name={['__travelProfiles', item.value]}
                    noLabel
                    rules={[
                      {
                        validator: validateProfilesCard,
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </JarvislyRequiredCard>

      {/*************************************************************
        ATTACHMENTS
        *************************************************************/}
      <Card
        title={
          <div className="text-muted">
            {/*<span style={{ color: 'red', marginRight: 6 }}>*</span>*/}
            {translateX('coverage_terms')}
          </div>
        }
      >
        {/* COVERAGE */}
        <UploadFirebaseComponent
          hideUploadButton={true}
          buttonTitle="Upload"
          name="coveragesFiles"
          tags="coverages"
          listType="picture"
          attachments={attachments}
          setAttachments={setAttachments}
          onUploadBefore={onUploadBefore}
          onUploadAfter={onUploadAfter}
          onUploadError={onUploadError}
          isCroppingEnabled={false}
          accept={'.pdf'}
          fileSize={3072}
          isDisabled={isFormDisabled}
          fileFolder="plans/coverages"
          maxFiles={1}
        />
        {/* COVERAGE */}
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
});

export default MultiPlansRecordIndex;
