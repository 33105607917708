import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import app from 'configs/ConfigIndex';

const ProtectedRoute = () => {
  const { token } = useSelector(state => state.authSlice);
  const location = useLocation();

  if (!token) {
    return (
      <Navigate
        to={`${app.AUTH_PREFIX_PATH}${app.UNAUTHENTICATED_ENTRY}?${app.REDIRECT_URL_KEY}=${location.pathname}`}
        replace
      />
    );
  }

  return <Outlet />;
};

export default ProtectedRoute;
