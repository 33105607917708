import {
  JarvislyFormProvider,
  JarvislyTabProvider,
} from 'components/jarvisly-components/others/FormComponents';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { translateX } from 'utils/helpers';
import { Tabs } from 'antd';
import IotDeviceRecord from './record/IotDeviceRecordTab';

const IotDevicesForm = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { selectedModule, document } = props;

  // local variables I ---------------------------------------------------------
  const [searchParams] = useSearchParams();
  const initTab = selectedModule.documentState.initTab;

  // component states ----------------------------------------------------------
  const [tab, setTab] = useState(searchParams.get('tab') || initTab);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [entityType, setEntityType] = useState('company');

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    selectedCustomer,
    setSelectedCustomer,
    entityType,
    setEntityType,
    tab,
  };
  const tabItems = buildTabItens();

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  const onSave = async ({ body }) => {
    if (!body) return;

    if (body.color?.toHexString) body.color = body.color?.toHexString();

    const partialUpdate = {
      'field1.finishRangeValue': body.field1.fieldMetric4,
      'field1.startRangeValue': body.field1.startRangeValue,
      'field1.alarmMinimumDistanceTolerance':
        body.field1.alarmMinimumDistanceTolerance,
      'field1.alarmMaximumDistanceTolerance':
        body.field1.alarmMaximumDistanceTolerance,
      // Outros campos do body que não são parte de `field1` ainda estão incluídos.
      ...Object.keys(body)
        .filter(key => key !== 'field1') // Exclui `field1` para evitar sobrescrever o objeto inteiro
        .reduce((acc, key) => ({ ...acc, [key]: body[key] }), {}),
      customerId: body?.customer?.selectedEntity?.key,
    };

    console.log('oNSAVE BODY', body, partialUpdate);

    return partialUpdate;
  };

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <JarvislyFormProvider
        name="form-iot-devices"
        document={document}
        initialValues={{ service: 'veertical' }}
        autoFocus="name"
        requiredFields={['name']}
        // showSubmitButton={tab !== 'record'}
        setTab={setTab}
        onSave={onSave}
      >
        <Tabs
          type="line"
          tabPosition="top"
          activeKey={tab}
          rootClassName="tab-in-form"
          items={tabItems.filter(x => !x.hide)}
          onTabClick={onTabClick}
        />
      </JarvislyFormProvider>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTabItens() {
    return [
      {
        label: translateX('record'),
        key: initTab,
        hide: false,
        children: (
          <JarvislyTabProvider tab={initTab}>
            <IotDeviceRecord {...childProps} activeTab={tab} />
          </JarvislyTabProvider>
        ),
      },
    ];
  }
};

export default IotDevicesForm;
