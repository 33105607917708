import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card, Col, Row } from 'antd';
import JarvislyPersonIndex from 'components/jarvisly-components/forms/JarvislyPersonIndex';
import {
  JarvislyFormContext,
  JarvislyNumber,
  JarvislySelect,
  JarvislyTitle,
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { translateX } from 'utils/helpers';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const AthletesRecordTab = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);
  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  const [height, setHeight] = useState(null);
  const [weight, setWeight] = useState(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (height && weight) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        const calculatedImc = (weight / (height * height)).toFixed(2);
        form?.setFieldValue('imc', calculatedImc);
      }, 1000);
    }
  }, [height, weight]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const handleHeightChange = value => {
    setHeight(value);
  };

  const handleWeightChange = value => {
    setWeight(value);
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Card>
        <JarvislyPersonIndex
          {...props}
          showCpf
          showBirthdate
          showTitle
          showGender
          phoneOrEmailRequired
        />
      </Card>
      <Card>
        <JarvislyTitle title="anthropometry" />
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} md={8}>
            <JarvislyNumber
              name="height"
              min={1}
              max={3}
              addonBefore={null}
              addonAfter={'m'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
              onChange={handleHeightChange}
            />
          </Col>
          <Col xs={24} md={8}>
            <JarvislyNumber
              name="weight"
              min={1}
              max={100}
              addonBefore={null}
              addonAfter={'kg'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
              onChange={handleWeightChange}
            />
          </Col>
          <Col xs={24} md={8}>
            <JarvislyNumber
              name="imc"
              addonBefore={null}
              addonAfter={null}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
              disabled
            />
          </Col>
          <Col xs={24} md={12}>
            <JarvislyNumber
              name="waistCircumference"
              label="waist_circumference"
              min={1}
              max={100}
              addonBefore={null}
              addonAfter={'cm'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
            />
          </Col>
          <Col xs={24} md={12}>
            <JarvislySelect
              name="leftRightHanded"
              label="left_right_handed"
              options={[
                { value: 'leftHanded', label: translateX('left_handed') },
                { value: 'rightHanded', label: translateX('right_handed') },
              ]}
              required
            />
          </Col>
        </Row>
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default AthletesRecordTab;
