const routeService = {};

routeService.getQueryString = (fieldsArr, searchParams) => {
  const result = {};

  if (!fieldsArr || !Array.isArray(fieldsArr) || !searchParams) return {};

  fieldsArr?.map(f => {
    result[f] = searchParams.get(f);
    return f;
  });

  return result;
};

routeService.setQueryString = (keyValueObj, navigate) => {
  if (
    !keyValueObj ||
    typeof keyValueObj !== 'object' ||
    Object.keys(keyValueObj)?.length <= 0 ||
    !navigate
  )
    return;

  const queryString = new URLSearchParams(keyValueObj).toString();
  navigate(`?${queryString}`, { replace: true });
};

routeService.buildQueryString = (
  fieldsArr,
  navigate,
  searchParams,
  changeUrl = true,
  addFieldsObj = {},
) => {
  if (
    !fieldsArr ||
    !fieldsArr ||
    !Array.isArray(fieldsArr) ||
    !navigate ||
    !searchParams
  ) {
    return;
  }

  const qsObj = {
    ...routeService.getQueryString(fieldsArr, searchParams),
    ...addFieldsObj,
  };

  const queryString = routeService.queryStringObjToStr(qsObj);
  if (changeUrl) navigate(`?${queryString}`, { replace: true });

  return queryString;
};

routeService.queryStringObjToStr = obj => {
  return new URLSearchParams(obj).toString();
};

export default routeService;
