import { translateX } from 'utils/helpers';
import { List, Switch, Card, Row, Col } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axiosService from 'services/axiosService';
import { ROW_GUTTER } from '../../../../../constants/ThemeConstant';
import { JarvislySelect } from '../../../../../components/jarvisly-components/others/FormComponents';

const CustomersParametersFormTab = props => {
  // props deconstruction ------------------------------------------------------'
  const { requiredFields, setRequiredFields, setIsUpdated } = props;

  // local variables I ---------------------------------------------------------
  const { parameters } = useSelector(s => s.moduleSlice);
  const initialState = buildInitialState();
  const personTypeOptions = buildPersonTypeOptions();
  const personTypeDefaultOptions = buildPersonTypeDefaultOptions();

  // component states ----------------------------------------------------------
  const [listRequiredFields, setListRequiredFields] = useState(initialState);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onChange = async (item, checked) => {
    const idx = listRequiredFields.findIndex(
      i => i.fieldName === item.fieldName,
    );
    listRequiredFields[idx].checked = checked;
    setListRequiredFields([...listRequiredFields]);

    const updatedRequiredFields = checked
      ? [...requiredFields, item.fieldName]
      : requiredFields.filter(f => f !== item.fieldName);

    setRequiredFields(updatedRequiredFields);
    setIsUpdated(true);

    setTimeout(async () => {
      if (!parameters?.requiredFields) {
        const axiosOptions = {
          url: 'v1/entity-parameters',
          data: { requiredFields },
        };
        await axiosService.post(axiosOptions);
      } else {
        if (checked) {
          const url = `v1/entity-parameters/add-to-set/${parameters?._id}/$push/requiredFields`;
          await axiosService.put({
            url,
            data: { _id: item.fieldName },
          });
        } else {
          const url = `v1/entity-parameters/add-to-set/${parameters?._id}/$pull/requiredFields`;
          await axiosService.put({
            url,
            data: { _id: item.fieldName },
          });
        }
      }
    }, 400);
  };

  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Card
        title={<div className="text-muted">{translateX('data_types')}</div>}
      >
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={12}>
            <JarvislySelect
              options={personTypeOptions}
              name="selectPersonType"
              label="person_type"
            />
          </Col>

          <Col xs={24} sm={12}>
            <JarvislySelect
              options={personTypeDefaultOptions}
              name="selectPersonTypeDefault"
              label="initial_pattern"
            />
          </Col>
        </Row>
      </Card>

      <Card
        title={
          <div className="text-muted">{translateX('required_fields')}</div>
        }
      >
        <List
          itemLayout="horizontal"
          dataSource={listRequiredFields}
          renderItem={item => (
            <List.Item key={item.fieldName}>
              <div>
                <h4 className="mb-0">{item.label}</h4>
                {/*<div>{item.description}</div>*/}
              </div>

              <div>
                <Switch
                  checked={item.checked}
                  disabled={item.disabled}
                  onChange={checked => onChange(item, checked)}
                />
              </div>
            </List.Item>
          )}
        />
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildInitialState() {
    return [
      {
        fieldName: 'cpfCnpj',
        label: translateX('cpf_cnpj'),
        description: translateX('cpf_cnpj_required_description'),
        checked: parameters?.requiredFields?.includes('cpfCnpj'),
        disabled: false,
      },
      {
        fieldName: 'name',
        label: translateX('name_or_company'),
        // description: translateX('name_required_description'),
        checked: true,
        disabled: true,
      },
      {
        fieldName: 'fullName',
        label: translateX('full_name_or_company_name'),
        // description: translateX('name_required_description'),
        checked: true,
        disabled: true,
      },
      {
        fieldName: 'phone',
        label: translateX('phone'),
        checked: parameters?.requiredFields?.includes('phone'),
        disabled: false,
      },
      {
        fieldName: 'email',
        label: translateX('email'),
        checked: parameters?.requiredFields?.includes('email'),
        disabled: false,
      },
      {
        fieldName: 'phoneEmail',
        label: translateX('phone_or_email'),
        checked: parameters?.requiredFields?.includes('phoneEmail'),
        disabled: false,
      },
      {
        fieldName: 'address',
        label: translateX('address'),
        checked: parameters?.requiredFields?.includes('address'),
        disabled: false,
      },
    ];
  }

  function buildPersonTypeOptions() {
    return [
      {
        value: 'manual',
        label: translateX('manual'),
      },
      {
        value: 'automatic',
        label: translateX('automatic'),
      },
      {
        value: 'individual_person',
        label: translateX('individual_person_only'),
      },
      {
        value: 'legal_person',
        label: translateX('legal_person_only'),
      },
    ];
  }

  function buildPersonTypeDefaultOptions() {
    return [
      {
        value: 'individual_person',
        label: translateX('individual_person'),
      },
      {
        value: 'legal_person',
        label: translateX('legal_person'),
      },
    ];
  }
};

// EXPORT **********************************************************************
// *****************************************************************************

export default CustomersParametersFormTab;
