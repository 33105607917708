import React, { useContext, useEffect, useRef, useState } from 'react';
import { Modal, Tabs } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { translateX } from 'utils/helpers';
import IntlMessage from '../../../util-components/IntlMessage';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

// MAIN COMPONENT **************************************************************
// *****************************************************************************

const JarvislySettingsModal = props => {
  const { formContext, children, showModal, setShowModal } = props;

  // props deconstruction ------------------------------------------------------

  // component states -----------------------------------------------------------
  const [tabIdx, setTabIdx] = useState();
  const { setDocumentContext } = useContext(ModuleContext);
  // local variables ------------------------------------------------------------
  const configurationTabRef = useRef();
  const requiredFieldsTabRef = useRef();
  const title = translateX('parameters');

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------

  const onClose = () => {
    setShowModal(false);
  };

  function onTabClick(key) {
    setTabIdx(key);

    switch (key) {
      case 'configurations':
        configurationTabRef?.current?.getFirstFocus();
        break;

      case 'requiredFields':
        requiredFieldsTabRef?.current?.getFirstFocus();
        break;

      default:
        break;
    }
  }

  // UI COMPONENT --------------------------------------------------------------

  // all tabs together
  const tabItems = formContext?.sectionSettingsComponent
    ? [...formContext.sectionSettingsComponent]
    : [];

  useEffect(() => {
    if (tabItems.length > 0 && !tabIdx) setTabIdx(tabItems[0].key);
    setDocumentContext({ valueBlock: showModal });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Modal
        destroyOnClose={true}
        onClose={onClose}
        onCancel={onClose}
        onOk={onClose}
        open={showModal}
        width={640}
        okText={setLocale(true, 'close')}
        okButtonProps={{ type: 'default' }}
        cancelButtonProps={{ style: { display: 'none' } }}
        getContainer={false}
        className="modal-without-padding"
      >
        <h1>Parâmetros</h1>
        <Tabs onChange={onTabClick} items={tabItems} activeKey={tabIdx} />
        {children}
      </Modal>
    </>
  );
};

// EXPORT **********************************************************************
// *****************************************************************************

export default JarvislySettingsModal;
