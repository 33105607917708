import { forwardRef } from 'react';
import { Badge, Card, Descriptions, Flex, Row } from 'antd';
import { parsePhoneToDisplay, translateX } from 'utils/helpers';
import { MdFlightTakeoff } from 'react-icons/md';
import financialService from 'services/financialService';
import {
  CalendarOutlined,
  EnvironmentOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import appService from 'services/appService';
import { FaPlaneArrival, FaPlaneDeparture } from 'react-icons/fa';
import dayjs from 'dayjs';
import Icon from 'components/util-components/Icon';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const MultiOrdersIndex = forwardRef((props, ref) => {
  const { document } = props;

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------
  const isMobile = appService.isMobile();

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <div className="p-2">
      {/*<Row justify="center">
        <CardPlan
          {...props}
          title={
            <Flex align="center" style={{ color: 'darkcyan' }}>
              <div style={{ paddingTop: 4 }}>
                <Icon type={MdFlightTakeoff} />
              </div>
              <div style={{ marginLeft: 8 }}>
                {translateX('package_purchased')}
              </div>
            </Flex>
          }
          strongBorder={false}
          plan={document?.__plan}
          total={document?.totalInVoucherDate}
          highlights={highlights}
          profile="checkout"
        />
      </Row>
*/}

      {/* ORDER */}
      <Row justify="center">
        <Card
          title={
            <Flex align="center" style={{ color: 'darkcyan' }}>
              <div style={{ paddingTop: 4 }}>
                <Icon type={MdFlightTakeoff} />
              </div>
              <div style={{ marginLeft: 8 }}>Pacote adquirido</div>
            </Flex>
          }
          // style={{ maxWidth: 800, marginLeft: 18, marginRight: 18 }}
          className="w-100"
        >
          <Descriptions
            bordered
            items={buildDescriptionsPaymentItems(document)}
            className="w-100 mb-4"
            column={4}
          />
        </Card>
      </Row>
      {/* ORDER */}

      {/* SUMMARY */}
      <Row justify="center">
        <Card
          title={
            <Flex align="center" style={{ color: 'darkcyan' }}>
              <div style={{ paddingTop: 4 }}>
                <Icon type={EyeOutlined} />
              </div>
              <div style={{ marginLeft: 8 }}>{translateX('summary')}</div>
            </Flex>
          }
          // style={{ maxWidth: 800, marginLeft: 18, marginRight: 18 }}
          className="w-100"
        >
          <Descriptions
            bordered
            items={buildDescriptionsTravelItems(document)}
            className="w-100 mb-4"
            column={4}
          />
          {/*<Descriptions*/}
          {/*  bordered*/}
          {/*  items={items}*/}
          {/*  className="w-100 mb-4"*/}
          {/*  column={4}*/}
          {/*/>*/}
        </Card>
      </Row>
      {/* SUMMARY */}
    </div>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildDescriptionsPaymentItems(record) {
    if (!record) return null;

    const receivable = record?.__receivables?.[0];
    const operator = record?.__operator;
    const plan = record?.__plan;

    const items = [
      {
        key: 'plano',
        label: translateX('plan'),
        span: 4, //isMobile ? 4 : 2,
        children: (
          <>
            <div>
              <span className="font-weight-bold">{plan?.name}</span>
              <span style={{ marginLeft: 8 }} className="font-weight-bold">
                | {operator?.name}
              </span>
            </div>
          </>
        ),
      },

      {
        key: 'dueDate',
        label: translateX('due_date'),
        span: isMobile ? 4 : 2,
        children: (
          <>
            <div>
              <span className="font-weight-bold">
                {receivable?.dueDate
                  ? dayjs(receivable.dueDate).format('L')
                  : 'N/A'}
              </span>
            </div>
          </>
        ),
      },

      {
        key: 'price',
        label: translateX('value'),
        span: isMobile ? 4 : 2,
        children: (
          <>
            <div>
              <span className="font-weight-bold">
                {receivable?.value
                  ? financialService.formatMoney(
                      record?.totalInOrderDate?.totalToPay,
                      2,
                      'brl',
                      true,
                    )
                  : 'N/A'}
              </span>
            </div>
          </>
        ),
      },

      {
        key: 'bill',
        label: translateX('invoice_bill'),
        span: isMobile ? 4 : 2,
        children: receivable?._metadata?.counter
          ? `#${receivable._metadata.counter}`
          : 'N/A',
      },

      {
        key: 'status',
        label: translateX('status'),
        span: isMobile ? 4 : 2,
        children: translateX(receivable?.status),
      },
    ];

    if (record?.status !== 'removed') {
      items.push({
        key: 'paymentLink',
        label: translateX('payment_link'),
        span: 4,
        children: receivable?.paymentUrl ? (
          <a
            href={receivable.paymentUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {receivable?.paymentUrl}
          </a>
        ) : (
          'N/A'
        ),
      });
    }

    // BANK SLIP
    if (receivable?.bankSlipUrl && record?.status !== 'removed') {
      items.push({
        key: 'bankSlipLink',
        label: translateX('bank_slip'),
        span: 4,
        children: receivable?.bankSlipUrl ? (
          <a
            href={receivable.bankSlipUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {receivable?.bankSlipUrl}
          </a>
        ) : (
          'N/A'
        ),
      });
    }

    // TRANSACTION RECEIPT
    if (receivable?.transactionReceiptUrl) {
      items.push({
        key: 'transactionReceipt',
        label: (
          <span className={record?.status === 'removed' ? 'text-danger' : ''}>
            {translateX(
              record?.status === 'removed'
                ? 'exclusion_receipt'
                : 'transaction_receipt',
            )}
          </span>
        ),
        span: 4,
        children: receivable?.transactionReceiptUrl ? (
          <a
            href={receivable.transactionReceiptUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {receivable?.transactionReceiptUrl}
          </a>
        ) : (
          'N/A'
        ),
      });
    }

    return items;
  }

  function buildDescriptionsTravelItems(record) {
    if (!record) return null;

    const buyer = record?.__buyer;
    const travellers = record?.travellers;
    const emergencyContacts = record?.emergencyContacts;

    const items = [
      {
        key: 'destiny',
        label: translateX('destiny'),
        span: isMobile ? 4 : 2,
        children: (
          <>
            <div>
              <Icon type={EnvironmentOutlined} />
              <span style={{ marginLeft: 8 }}>
                {translateX(record?.destiny)}
              </span>
            </div>
            <div>
              <Icon type={CalendarOutlined} />
              <span style={{ marginLeft: 8 }}>
                {record?.days}{' '}
                <span className="text-lowercase">
                  {translateX(record?.days > 1 ? 'days' : 'day')}
                </span>
              </span>
            </div>
          </>
        ),
      },
      {
        key: 'period',
        label: translateX('period'),
        span: isMobile ? 4 : 2,
        children: (
          <>
            <Flex align="center">
              <Icon
                type={FaPlaneDeparture}
                className="text-primary font-size-md"
                style={{ flexShrink: 0 }}
              />
              <span
                className="ml-2 font-weight-semibold"
                style={{ marginLeft: 8 }}
              >
                {dayjs(record.departure).format('L')}
              </span>
            </Flex>

            <Flex align="center">
              <Icon
                type={FaPlaneArrival}
                className="text-primary font-size-md"
                style={{ flexShrink: 0 }}
              />
              <span
                className="ml-2 font-weight-semibold"
                style={{ marginLeft: 8 }}
              >
                {dayjs(record.arrival).format('L')}
              </span>
            </Flex>
          </>
        ),
      },
      {
        key: 'buyer',
        label: translateX('buyer'),
        span: isMobile ? 4 : 2,
        children: (
          <>
            <div>{buyer?.name}</div>
            <div>{parsePhoneToDisplay(buyer?.mobile)}</div>
            <div>{buyer?.email}</div>
          </>
        ),
      },
      {
        key: 'travellers',
        label: translateX('travelers'),
        span: isMobile ? 4 : 2,
        children: travellers?.map((t, idx) => (
          <div key={idx}>
            <Badge
              status={t.ageInTravelDate >= 60 ? 'warning' : 'success'}
              text={`${t.name}, ${t.ageInTravelDate} ${translateX(
                'years_old',
              )}`}
            />
            <br />
          </div>
        )),
      },
      {
        key: 'emergency',
        label: translateX('contacts'),
        span: 4,
        children: emergencyContacts?.map((c, idx) => (
          <div key={idx}>
            <Badge
              status="default"
              text={`${c.name}, Telefone: ${parsePhoneToDisplay(c.phone)} ${
                c.relationship ? ` 👨‍👩‍👧‍👦 ${c.relationship}` : ''
              }`}
            />
          </div>
        )),
      },
    ];

    // GENARAL CONDITIONS
    if (record?.generalConditionsUrl) {
      items.push({
        key: 'generalConditions',
        label: translateX('general_conditions'),
        span: 4,
        children: record?.generalConditionsUrl ? (
          <a
            href={record.generalConditionsUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {record?.generalConditionsUrl}
          </a>
        ) : (
          'N/A'
        ),
      });
    }

    // COVERAGES
    if (record?.coverageUrl) {
      items.push({
        key: 'coverages',
        label: translateX('coverages'),
        span: 4,
        children: record?.coverageUrl ? (
          <a
            href={record.coverageUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {record?.coverageUrl}
          </a>
        ) : (
          'N/A'
        ),
      });
    }

    // VOUCHER
    items.push(
      {
        key: 'voucher',
        label: translateX('voucher'),
        span: isMobile ? 4 : 2,
        children: record?.voucherUrl ? (
          <a href={record.voucherUrl} target="_blank" rel="noopener noreferrer">
            {record?.voucherUrl}
          </a>
        ) : record?.status === 'paid' ||
          record?.status === 'received' ||
          record?.status === 'waiting_for_voucher' ? (
          <Badge status="processing" text={translateX('in_issue_process')} />
        ) : record?.status === 'cancelled' ? (
          <Badge status="default" text={translateX('order_cancelled')} />
        ) : record?.status === 'cancelled_by_customer' ? (
          <Badge
            status="default"
            text={translateX('order_cancelled_by_customer')}
          />
        ) : (
          <Badge status="warning" text={translateX('waiting_for_payment')} />
        ),
      },
      {
        key: 'voucherNumber',
        label: translateX('voucher_number_acron'),
        span: isMobile ? 4 : 2,
        children: (
          <span className="font-weight-bold">
            {record?.voucherNumber || 'N/A'}
          </span>
        ),
      },
    );

    return items;
  }
});

// EXPORT ====================================================================
// ===========================================================================

export default MultiOrdersIndex;
