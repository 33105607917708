import { EditOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import {
  parseCnpjToDisplay,
  parseCpfToDisplay,
  setLocale,
  translateWord,
  translateX,
} from 'utils/helpers';
import dayjs from 'dayjs';
import React from 'react';
import { CrmStatusTag } from '../Components';

const CrmList = props => {
  // props deconstruction ------------------------------------------------------
  const {
    isWorking,
    onTableChanges,
    columnSorter,
    tableKey,
    resetTableSearch,
    pagination,
    dataList,
    buildRowSelection,
    showNumberOfLine,
    onClickOpen,
    columnSearch,
  } = props;

  // component states ----------------------------------------------------------

  // local variables -----------------------------------------------------------
  const tableColumns = buildTableColumns();

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------

  return (
    <>
      <Table
        key={tableKey}
        rowKey="_id"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={dataList || []}
        pagination={pagination}
        onChange={onTableChanges}
        rowSelection={buildRowSelection}
        className="with-checkbox"
        resetTableSearch={resetTableSearch}
        loading={isWorking}
        // expandable={{
        //   expandedRowRender,
        //   defaultExpandedRowKeys: ['0'],
        // }}

        expandable={{
          expandedRowRender: buildTableItemsColumns,
          defaultExpandedRowKeys: ['0'],
        }}

        // expandable={{
        //   expandedRowRender: record => (
        //     <p
        //       style={{
        //         margin: 0,
        //       }}
        //     >
        //       {record.departure}
        //     </p>
        //   ),
        //
        //   rowExpandable: record => record.name !== 'Not Expandable',
        // }}
      />
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTableColumns() {
    return [
      {
        hidden: !showNumberOfLine,
        title: setLocale('line'),
        width: 80,
        render: (item, record, index) => {
          if (!pagination) return;

          const { current, pageSize } = pagination;

          return (
            <div className="text-left">
              {current * pageSize - pageSize + index + 1 || index}
            </div>
          );
        },
      },

      {
        title: translateX('opportunity'),
        dataIndex: '_metadata.counter',
        ...columnSorter('_metadata.counter'),
        ...columnSearch('_metadata.counter', 'crm'),
        width: 140,
        render: (_, record) => (
          <span className="text-uppercase text-muted">
            {record?._metadata?.__documentCode}
          </span>
        ),
      },

      {
        title: translateWord('customer'),
        dataIndex: 'customer',
        className: 'text-truncate',
        render: (_, record) => (
          <span>
            {record?.customer?.dataType === 'company'
              ? record?.customer?.fullName
              : record?.customer?.name}
          </span>
        ),
      },

      {
        title: translateWord('cpf_cnpj'),
        dataIndex: 'cpfCnpj',
        className: 'text-truncate',
        render: (_, record) => (
          <span>
            {record?.customer?.dataType === 'company'
              ? parseCnpjToDisplay(record?.customer?.cnpj)
              : parseCpfToDisplay(record?.customer?.cpf)}
          </span>
        ),
      },

      // {
      //   title: translateWord('value'),
      //   dataIndex: 'value',
      //   className: 'text-truncate',
      //   align: 'right',
      //   render: (_, record) => (
      //     <span>
      //       {financialService.formatMoney(
      //         record?.totalInCrmDate?.totalToPay,
      //         2,
      //         'brl',
      //         true,
      //       )}
      //     </span>
      //   ),
      // },

      // {
      //   title: translateWord('id'),
      //   dataIndex: 'id',
      //   className: 'text-truncate',
      //   // ...columnSorter('_metadata.counter'),
      //   // ...columnSearch('_metadata.counter', 'crm'),
      //   render: (_, record) => record?._metadata?.counter,
      // },

      {
        title: translateWord('status'),
        dataIndex: 'status',
        width: 100,
        render: (_, record) => (
          // const receivable = record?.__receivables?.[0];
          // return receivable ? (
          //   <ReceivablesStatusTag record={receivable} />
          // ) : (
          <CrmStatusTag record={record} />
        ),
        // );
      },

      {
        title: translateWord('created'),
        dataIndex: 'date',
        className: 'text-truncate',
        render: (_, record) =>
          record?._metadata?.audit?.createdAt ? (
            <span>{dayjs(record._metadata.audit.createdAt).fromNow()}</span>
          ) : null,
      },

      {
        title: setLocale('actions'),
        dataIndex: 'actions',
        align: 'center',
        width: 50,
        render: (_, record) => (
          <div className="text-right d-flex justify-content-end">
            <Tooltip title={setLocale('edit')}>
              <Button
                className="mr-2"
                icon={<EditOutlined />}
                onClick={() => onClickOpen(record)}
                size="small"
              />
            </Tooltip>

            {/* <Tooltip title={setLocale('view')}>
              <Button
                className="mr-2"
                icon={<EyeOutlined />}
                onClick={() => onClickPreview(record)}
                size="small"
              />
            </Tooltip>*/}
          </div>
        ),
      },
    ].filter(x => !x.hidden);
  }

  function buildTableItemsColumns(record) {
    const dataItem = dataList.filter(i => i?._id === record?._id);

    const columns = [
      {
        title: translateX('code'),
        dataIndex: 'code',
        width: 140,
        render: (_, record) => (
          <span className="text-uppercase text-muted">
            {record?.__plan?._metadata?.__documentCode}
          </span>
        ),
      },

      // {
      //   title: translateWord('plan'),
      //   dataIndex: 'plan',
      //   className: 'text-truncate',
      //   render: (_, record) => {
      //     return (
      //       <>
      //         <div>{record?.__plan?.name}</div>
      //         <div className="text-muted">{record?.__operator?.name}</div>
      //       </>
      //     );
      //   },
      // },
      //
      // {
      //   title: setLocale('destiny'),
      //   dataIndex: 'arrival',
      //   render: (_, record) => <span>{translateX(record?.destiny)}</span>,
      // },
      //
      // {
      //   title: setLocale('dates'),
      //   dataIndex: 'date',
      //   render: (_, record) => (
      //     <>
      //       <div style={{ display: 'flex' }}>
      //         <div>
      //           {record?.departure && dayjs(record.departure).format('L')}
      //         </div>
      //         <div style={{ marginTop: 2 }}>
      //           <Icon type={FaPlaneDeparture} className="ml-2" />
      //         </div>
      //       </div>
      //
      //       <div style={{ display: 'flex' }}>
      //         <div>{record?.arrival && dayjs(record.arrival).format('L')}</div>
      //         <div style={{ marginTop: 2 }}>
      //           <Icon type={FaPlaneArrival} className="ml-2" />
      //         </div>
      //       </div>
      //     </>
      //   ),
      // },
      //
      // {
      //   title: setLocale('passengers'),
      //   dataIndex: 'passengers',
      //   render: (_, record) =>
      //     record?.travellers.map(t => (
      //       <div key={t._id}>
      //         {t.name}, {t.ageInTravelDate}
      //       </div>
      //     )),
      //   // render: (_, record) => (
      //   //   <>
      //   //
      //   //     <div>Fulano, 78</div>
      //   //     <div>Ciclano, 22</div>
      //   //   </>
      //   // ),
      // },
    ].filter(x => !x.hidden);

    return (
      <Table
        rowKey="_id"
        size="small"
        className="no-table-overflow"
        columns={columns}
        dataSource={dataItem || []}
        pagination={false}
      />
    );
  }
};

export default CrmList;
