import React, { useEffect, useState } from 'react';
import { Grid, Select, Tooltip } from 'antd';
import utils from 'utils';
import { setLocale, translateX } from 'utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import {
  LockTwoTone,
  SafetyCertificateTwoTone,
  UnlockTwoTone,
} from '@ant-design/icons';
import { rdxSetSubscription } from 'store/slices/authSlice';
import app from 'configs/ConfigIndex';
import { defaultFilterOption } from 'components/jarvisly-components/others/FormComponents';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const { useBreakpoint } = Grid;
const { Option } = Select;

// COMPONENT *******************************************************************
// *****************************************************************************

const SubscriberSelectorCombo = props => {
  // props deconstruction ------------------------------------------------------

  // local variables -----------------------------------------------------------
  const { selectedUser, selectedSubscription } = useSelector(s => s.authSlice);
  const { disableSubscriptionCombo } = useSelector(s => s.moduleSlice);

  const dispatch = useDispatch();
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('md');
  const placeholder = `${translateX(app.PROFILE.SELECT_A_COMPANY_PLACEHOLDER)}`;

  // component states ----------------------------------------------------------
  const [availableSubscriptions, setAvailableSubscriptions] = useState([]);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setAvailableSubscriptions(
      selectedUser?.subscriptions?.filter(s => s?.status === 2) || [],
    );
  }, [selectedSubscription]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onClick = value => {
    setTimeout(() => dispatch(rdxSetSubscription(value)), 200);
  };

  // UI COMPONENTS -------------------------------------------------------------

  if (availableSubscriptions?.length === 0) return null;

  return (
    <Select
      showSearch
      placeholder={placeholder}
      optionFilterProp="children"
      disabled={disableSubscriptionCombo}
      onChange={onClick}
      filterOption={defaultFilterOption}
      style={{
        maxWidth: isMobile ? 160 : 240,
        width: isMobile ? 160 : 240,
      }}
      // disabled={!selectedRouteInfo && !enableSubscriptionSelector}
      value={selectedSubscription?._id}
    >
      {availableSubscriptions?.map(s => {
        return (
          <Option
            key={s._id}
            value={s._id}
            index={s.index}
            title={s.organization}
          >
            {$getIcon(s)}&nbsp;&nbsp;<span>{s.label}</span>
          </Option>
        );
      })}
    </Select>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function $getIcon(subscription) {
    if (!subscription || !selectedUser) return;

    const member = subscription?.team?.find(
      x => x.accountId === selectedUser._id,
    );

    return (
      <SubscriptionProfileIcon profile={member?.profile} showToolTip={true} />
    );
  }
};

export const SubscriptionProfileIcon = ({ profile, showToolTip }) => {
  if (showToolTip) {
    if (profile === 'owner') {
      return (
        <Tooltip title={setLocale('owner')}>
          <SafetyCertificateTwoTone size="small" />
        </Tooltip>
      );
    } else if (profile === 'keyUser') {
      return (
        <Tooltip title={setLocale('key_user')}>
          <UnlockTwoTone size="small" />
        </Tooltip>
      );
    } else {
      // profile === 'guest'

      return (
        <Tooltip title={setLocale('common_user')}>
          <LockTwoTone twoToneColor="red" size="small" />
        </Tooltip>
      );
    }
  } else {
    if (profile === 'owner') {
      return <SafetyCertificateTwoTone size="small" style={{ marginTop: 2 }} />;
    } else if (profile === 'keyUser') {
      return <UnlockTwoTone size="small" style={{ marginTop: 2 }} />;
    } else {
      // profile === 'guest'

      return (
        <LockTwoTone twoToneColor="red" size="small" style={{ marginTop: 2 }} />
      );
    }
  }
};

// EXPORT **********************************************************************
// *****************************************************************************

export default SubscriberSelectorCombo;
