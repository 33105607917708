import MultiOperatorsList from './List/List';
import AppmultiOperatorsForm from './Form/Form';
import {
  MultiOperatorsDocumentHeaderColumn2,
  MultiOperatorsDocumentHeaderColumn3,
} from 'modules/app-views/appmulti/operators/Components';
import { getFromLocalStorage, parseDateToDisplay } from 'utils/helpers';
import {
  DefaultFormHeaderColumn1,
  DefaultFormHeaderCustomIcons,
} from 'components/jarvisly-module/Form/FormHeader';
import { API_ENTITIES } from 'constants/ApiConstant';
import { TfiWorld } from 'react-icons/tfi';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const MENU_GROUP = 'records';
const MODULE_TITLE = 'operators';
const MODULE_ICON = TfiWorld;
export const APPMULTI_OPERATORS_ROUTE = `/app/${MENU_GROUP}/appmulti-${MODULE_TITLE}`;

// MODULE PARAMETERS ***********************************************************
// *****************************************************************************

const MULTI_OPERATORS_MODULE = {
  rootName: 'operators',
  name: 'appmultiOperators',
  title: MODULE_TITLE,
  icon: MODULE_ICON,
  urlForm: null,
  api: API_ENTITIES,

  url: APPMULTI_OPERATORS_ROUTE,
  routes: [`${APPMULTI_OPERATORS_ROUTE}/:_id`, APPMULTI_OPERATORS_ROUTE],

  components: {
    dashboard: null,
    list: props => <MultiOperatorsList {...props} />,
    form: (props, ref) => <AppmultiOperatorsForm {...props} ref={ref} />,
    formHeader: null,
    formHeaderSubtitle: null,
    formHeaderColumn1: props => <DefaultFormHeaderColumn1 {...props} />,
    formHeaderColumn2: props => (
      <MultiOperatorsDocumentHeaderColumn2 {...props} />
    ),
    formHeaderColumn3: props => (
      <MultiOperatorsDocumentHeaderColumn3 {...props} />
    ),
    formHeaderTags: null,
    formHeaderCustomIcons: props => (
      <DefaultFormHeaderCustomIcons {...props} showStatusIcon showApiIcon />
    ),
    settings: null,
    dataImport: null,
    previewHeader: null,
    previewBody: null,
    previewActions: null,
  },

  methods: {
    decorateDocument,
  },

  initialRequest: {
    folder: 'documents',
    folderOptions: ['documents', 'recycle', 'filed'],

    filter: {
      dataProfile: ['operator'],
      dataType: ['company'],
    },
    filterOptions: {
      dataProfile: ['operator'],
      dataType: ['company'],
      'parameters.integrated': ['yes', 'no', 'whatever'],
      status: [
        // 'not_updated',
        // 'pending_update',
        // 'waiting_for_update',
        // 'updated',
        'in_analysis',
        'with_reservation',
        'revised',
      ],
    },

    pagination: {
      pageNumber: 1, // current page
      pageSize: getFromLocalStorage('pageSize'),
    },
    paginationOptions: {
      pageSize: [10, 20, 50, 100],
    },

    sorter: {
      '_metadata.audit.updatedAt': -1,
    },
    sorterOptions: [
      '_metadata.documentStatus',
      'parameters.__exchangeRate',
      'parameters.integrated',
      'name',
      '_metadata.audit.updatedAt',
      '_metadata.counter',
      'status',
    ],

    search: {},

    textSearch: {
      default: '',
    },
  },

  listState: {
    viewMode: ['list'], // 'list', 'kanban', 'grid' or 'dashboard'
    showNumberOfLine: false, // show number of line in table list
    showCheckBoxes: false, // show check boxes on table list for multiple selection
    hasFilter: true, // show the filter (folders and customs) drawer button
    hasAccessControl: true, // show the control access modal for this module
    isTableChanged: false, // detect if the table was changed, check if the currentQs is different of initialQs (includes column sorter, search and pagination)
    isFilterChanged: false, // detect if the filters was changed
  },

  documentState: {
    disableLockForEdit: false,
    initTab: 'record',
    fieldName: 'name',
    hasPicture: false,
    newDocumentTitle: 'new_operator',
    autoFill: false,
    actions: [
      'new',
      'edit',
      'reload',
      'divider',
      'duplicate',
      'file',
      'remove',
      'permanently_remove',
      'divider',
      'document_status',
    ],
    refreshParametersOnSave: true,

    mockDocument: {
      name: 'Infodesk',
      cnpj: '02934311000153',
      fullName: 'Infodesk Technologies Ltda',
      phone: '19982320000',
      email: 'biruel@infodesk.com.br',
      zip: '13070173',
      address1: 'Avenida Marechal Rondon',
      number: '700',
      address2: 'Sala 307',
      neighborhood: 'Jardim Chapadão',
      __city: 'Campinas - SP',
      parameters: { __exchangeRate: 5.21 },
    },
  },
};

// EXPORT ********************************************************************
// ***************************************************************************

export default MULTI_OPERATORS_MODULE;

// INTERNAL FUNCTIONS ========================================================
// ===========================================================================

function decorateDocument(doc) {
  if (doc?.parameters?.__currency === 'brl') {
    doc.parameters.__hideExchangeOnList = true;
  }

  // decorate date fields
  doc?.moreContacts?.map(
    c => (c.birthdate = c.birthdate && parseDateToDisplay(c.birthdate)),
  );

  return doc;
}
