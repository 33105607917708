import { Tabs } from 'antd';
import { returnOnlyNumbers, translateWord } from 'utils/helpers';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import MultiCustomersRecordIndex from './Record/RecordIndex';
import { useSearchParams } from 'react-router-dom';
import {
  JarvislyFormProvider,
  JarvislyTabProvider,
} from 'components/jarvisly-components/others/FormComponents';
import JarvislyDocumentStatusModal from 'components/jarvisly-components/forms/modals/JarvislyDocumentStatusModal';
import useUrl from 'components/jarvisly-components/hooks/urlHooks';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************

const CustomersPersonsForm = forwardRef((props, ref) => {
  // props deconstruction ------------------------------------------------------
  const { selectedModule, document, _id, setModuleFocus } = props;

  // local variables I ---------------------------------------------------------
  const [searchParams] = useSearchParams();
  const url = useUrl();
  const initTab = selectedModule.documentState.initTab;
  const availableTabs = [initTab];
  const documentStatusModalRef = useRef(null);

  // component states ----------------------------------------------------------
  const [tab, setTab] = useState(searchParams.get('tab') || initTab);
  const [formData, setFormData] = useState(null);

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    formData,
  };
  const tabItems = buildTabItens();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (!availableTabs.includes(tab)) setTab(initTab);
    url.updateUrlQueryParams({ tab }, true);
    setModuleFocus(prev => ({ ...prev, tab: initTab, field: 'cpf' }));
  }, [tab, _id]); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    documentStatusModalRef,
  }));
  // methods -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  const onSave = async ({ body }) => {
    if (!body) return;

    // decorate entity
    body.dataType = 'person';
    body.dataProfile = 'customer';
    body.uniqueKey = returnOnlyNumbers(body.cpf) || +new Date();
    body.status = document?.status;

    return body;
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      {/* DOCUMENT STATUS MODAL WRAPPER */}
      <JarvislyDocumentStatusModal {...props} ref={documentStatusModalRef} />
      {/* DOCUMENT STATUS MODAL WRAPPER */}

      <JarvislyFormProvider
        name="form-customer"
        document={document}
        autoFocus="cpf"
        requiredFields={['name']}
        setTab={setTab}
        onSave={onSave}
        onValuesChange={(v, allValues) => setFormData({ ...allValues })}
      >
        <Tabs
          type="line"
          tabPosition="top"
          activeKey={tab}
          rootClassName="tab-in-form"
          items={tabItems.filter(x => !x.hide)}
          onTabClick={onTabClick}
        />
      </JarvislyFormProvider>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTabItens() {
    return [
      {
        label: translateWord('record'),
        key: initTab,
        hide: false,
        children: (
          <JarvislyTabProvider tab={initTab}>
            <MultiCustomersRecordIndex {...childProps} activeTab={tab} />
          </JarvislyTabProvider>
        ),
      },
    ];
  }
});

export default CustomersPersonsForm;
