import app from './ConfigIndex';

const FirebaseConfig = {
  databaseURL: 'https://fake.firebaseio.com',

  apiKey: app['FIREBASE_CONFIG']['API_KEY'],
  authDomain: app['FIREBASE_CONFIG']['AUTH_DOMAIN'],
  projectId: app['FIREBASE_CONFIG']['PROJECT_ID'],
  storageBucket: app['FIREBASE_CONFIG']['STORAGE_BUCKET'],
  messagingSenderId: app['FIREBASE_CONFIG']['MESSAGING_SENDER_ID'],
  appId: app['FIREBASE_CONFIG']['APP_ID'],
  measurementId: app['FIREBASE_CONFIG']['MEASUREMENT_ID'],
};

export default FirebaseConfig;
