import { Button, Card, Col, Row, Tag, Tooltip } from 'antd';
import Meta from 'antd/es/card/Meta';
import { Suspense } from 'react';
import { translateX } from 'utils/helpers';
import appdedaloService from 'services/appdedaloService';
import Loading from 'components/jarvisly-components/others-old/Loading';

const AgendasGrid = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { isWorking, dataGrid } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const addAgenda = selectedWeekday => {
    console.log('NOVA AGENDA ->', selectedWeekday);
  };

  const editAgenda = selectedAgenda => {
    console.log('ABRIR AGENDA ->', selectedAgenda);
  };

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <Suspense
      fallback={
        <Row justify="center">
          <Col>
            <h1>Fallback Error!</h1>
          </Col>
        </Row>
      }
    >
      <Loading isFetching={isWorking} data={dataGrid} />

      <div className="module-view">
        {dataGrid?.map(w => (
          <div className="agenda-settings-container" key={w.__weekday}>
            <Row>
              <h3>{translateX(w.__weekdayName)}</h3>
            </Row>

            <Row>
              {w.agendas.map(a => (
                <div key={a._id}>
                  <Tooltip placement="top" title={translateX('edit_agenda')}>
                    <Card
                      className="agenda-settings-card card-clickable-effect"
                      onClick={() => editAgenda(a)}
                    >
                      <Row justify="space-between">
                        <Meta
                          title={`${a.hour}`}
                          description={`${a.seats} ${translateX('seats')}`}
                        />
                        <span className="font-size-sm text-muted">
                          {a.timezone}
                        </span>
                      </Row>

                      <div>
                        {a.types.map(tag => (
                          <Tag
                            key={tag}
                            color={appdedaloService.getTypeTagColor(tag)}
                            className="tag"
                          >
                            {appdedaloService.getTypeTagName(tag).toLowerCase()}
                          </Tag>
                        ))}
                      </div>
                    </Card>
                  </Tooltip>
                </div>
              ))}

              <Tooltip placement="top" title={translateX('create_agenda')}>
                <Button
                  type="default"
                  size="large"
                  onClick={() => addAgenda(w)}
                  className="agenda-settings-card card-clickable-effect add-agenda"
                >
                  +
                </Button>
              </Tooltip>
            </Row>
          </div>
        ))}
      </div>
    </Suspense>
  );
};

export default AgendasGrid;
