import {
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyInput,
  JarvislyTitle,
} from 'components/jarvisly-components/others/FormComponents';
import { forwardRef, useContext, useEffect, useState } from 'react';
import { translateX } from 'utils/helpers';
import axiosService from 'services/axiosService';
import { Card, Col, Modal, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { API_blocks } from 'constants/ApiConstant';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    record,
    showBlockModal,
    setShowBlockModal,
    afterClose,
    isDisabled,
    updating,
  } = props;

  // local variables I ----------------------------------------------------------
  const title = isDisabled
    ? translateX('view')
    : !record?._id
    ? translateX('new_blocks')
    : translateX('edit_blocks');

  // component states -----------------------------------------------------------
  const { setDocumentContext } = useContext(ModuleContext);

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    form?.resetFields();
    if (record) form?.setFieldsValue(record);
    setDocumentContext({ valueBlock: record });
  }, [record, form]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    form.resetFields();
    setShowBlockModal(false);
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        className="title-fixed-with-18 modal-top"
        title={
          <>
            <UserOutlined />
            <span className="ml-2">{title}</span>
          </>
        }
        isDisabled={false}
        destroyOnClose={true}
        open={showBlockModal}
        width={640}
        okText={translateX(!isDisabled ? 'save' : 'save')}
        onCancel={onClose}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => (isDisabled ? form.submit() : form.submit())}
        getContainer={false}
        okType={isDisabled ? 'default' : 'primary'}
        confirmLoading={updating}
        afterClose={afterClose}
      >
        <Card>
          <JarvislyTitle title={'responsible'} />
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={24} lg={12}>
              <JarvislyInput disabled={false} name="name" label="name" />
            </Col>
            <Col xs={24} sm={24} lg={12}>
              <JarvislyInput
                disabled={false}
                name="abbreviation"
                label="abbreviation"
              />
            </Col>
            <Col xs={24} sm={24} lg={12}>
              <JarvislyInput
                disabled={false}
                name="reference"
                label="reference"
              />
            </Col>
            <Col xs={24} sm={24} lg={12}>
              <JarvislyInput
                disabled={false}
                name={'ramal'}
                label="direct_call"
              />
            </Col>
            <Col xs={24} sm={24} lg={12}>
              <JarvislyInput disabled={false} name="floors" label="floors" />
            </Col>
          </Row>
        </Card>
        {/* PERSON */}
        {/* <JarvislySelect
              name="name"  label="responsible"
              options={[
                { value: 'maePai', label: translateX('father_mother')},
                { value: 'Pai  ', label: translateX('stepfather_mother') },
                { value: 'Vo  ', label: translateX('grandfather_mother') },
              ]}
            /> */}
        {/* <JarvislyPersonForm {...childProps} /> */}
        {/* PERSON */}

        {/* CONTACTS */}
        {/* <JarvislyContactForm {...props} /> */}
        {/* CONTACTS */}

        {/* NOTE */}
        {/* <JarvislyNoteForm {...props} /> */}
        {/* NOTE */}
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const BlockModal = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    // _id,
    // document,
    isWorking,
    refreshDocument,
    setShowBlockModal,
    showBlockModal,
  } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------
  const [selectedRecord, setSelectedRecord] = useState(null);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onConfirm = async ({ body, searchedEntity, form }) => {
    const url = API_blocks;
    // const name = body.nameResponsable.toLowerCase();
    // const cpf = returnOnlyNumbers(body?.cpf);
    let entityId = selectedRecord?._id;

    if (!searchedEntity) {
      const [doc] = await axiosService.upsert({
        url,
        _id: entityId,
        data: body,
      });
      entityId = entityId;
    } else {
      entityId = searchedEntity._id;
    }

    form?.resetFields();

    refreshDocument(body._id, true);
    setShowBlockModal(false);

    return body;
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <JarvislyFormProvider
      name="form-contact"
      document={selectedRecord}
      requiredFields={['cpf', 'nameResponsable']}
      showSubmitButton={false}
      onConfirm={onConfirm}
      autoFocus="nameResponsable"
    >
      <ModalComponent
        showBirthdate
        updating={isWorking}
        record={selectedRecord}
        setShowBlockModal={setShowBlockModal}
        showBlockModal={showBlockModal}
        afterClose={() => {
          setSelectedRecord(null);
        }}
      />
    </JarvislyFormProvider>
  );
});

export default BlockModal;
