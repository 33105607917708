import CrmList from './List/List';
import CrmForm from './Form/Form';
import {
  CrmFormHeaderTitle,
  CrmFormHeaderSubtitle,
  CrmFormHeaderColumn1,
  CrmFormHeaderColumn3,
} from './Components';
import CrmKanban from './Kanban/KanbanIndex';
import { LiaFunnelDollarSolid } from 'react-icons/lia';
import dayjs from 'dayjs';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************
const MENU_AREA = 'administrative';
const MENU_GROUP = 'commercial';
const MODULE_TITLE = 'opportunities';
const MODULE_ICON = LiaFunnelDollarSolid;
export const CRM_API = '/v1/crm';
export const CRM_ROUTE = `/app/${MENU_AREA}/${MENU_GROUP}/${MODULE_TITLE}`;

// MODULE PARAMETERS ***********************************************************
// *****************************************************************************
const CRM_MODULE = {
  rootName: 'crm',
  name: 'crm',
  title: MODULE_TITLE,
  icon: MODULE_ICON,
  url: CRM_ROUTE,
  urlForm: null,
  api: CRM_API,
  routes: [`${CRM_ROUTE}/:_id`, CRM_ROUTE],
  components: {
    dashboard: null,
    list: props => <CrmList {...props} />,
    kanban: props => <CrmKanban {...props} />,
    form: (props, ref) => <CrmForm {...props} ref={ref} />,
    formHeaderTitle: props => <CrmFormHeaderTitle {...props} />,
    formHeaderSubtitle: props => <CrmFormHeaderSubtitle {...props} />,
    formHeaderColumn1: props => <CrmFormHeaderColumn1 {...props} />,
    // formHeaderColumn2: props => <CrmFormHeaderColumn2 {...props} />,
    formHeaderColumn3: props => <CrmFormHeaderColumn3 {...props} />,
    // formHeaderTags: props => <CrmFormHeaderTags {...props} />,
    formHeaderCustomIcons: null,
    settings: () => <></>,
    dataImport: null,
    previewHeader: null,
    previewBody: null,
    previewActions: null,
  },
  methods: {
    decorateDocument,
  },
  initialRequest: {
    folder: 'documents',
    folderOptions: ['documents', 'recycle', 'filed'],
    filter: {},
    filterOptions: {},
    pagination: {
      pageNumber: 1, // current page
      pageSize: ['10', '20', '50', '100'].includes(
        localStorage.getItem('pageSize'),
      )
        ? Number(localStorage.getItem('pageSize'))
        : 10,
    },
    paginationOptions: {
      pageSize: [10, 20, 50, 100],
    },
    sorter: {
      '_metadata.audit.updatedAt': -1,
    },
    sorterOptions: [
      'customer',
      '_metadata.audit.updatedAt',
      '_metadata.counter',
      'status',
    ],
    search: {},
    textSearch: {
      default: '',
    },
  },
  listState: {
    viewMode: ['list', 'kanban'], // 'list', 'kanban', 'grid' or 'dashboard'
    showNumberOfLine: false, // show number of line in table list
    showCheckBoxes: false, // show check boxes on table list for ple selection
    hasFilter: true, // show the filter (folders and customs) drawer button
    hasAccessControl: true, // show the control access modal for this module
    isTableChanged: false, // detect if the table was changed, check if the currentQs is different of initialQs (includes column sorter, search and pagination)
    isFilterChanged: false, // detect if the filters was changed
  },
  documentState: {
    disableLockForEdit: false,
    initTab: 'customer',
    fieldName: '_metadata.__documentCode',
    newDocumentTitle: 'new_opportunity',
    // autoFill: true,
    actions: [
      'new',
      'edit',
      'reload',
      'divider',
      'duplicate',
      'file',
      'remove',
      'permanently_remove',
      'divider',
      'document_status',
    ],
    refreshParametersOnSave: true,
    mockDocument: {},
  },
};
export default CRM_MODULE;
function decorateDocument(doc) {
  if (doc?.followUp && Array.isArray(doc.followUp)) {
    doc.followUp = doc.followUp.map(f => {
      f.label = (
        <>
          <div>
            Data de criação:
            {dayjs(f.createdAt).format('DD/MM/YYYY HH:mm:ss')}
          </div>
        </>
      );
      f.children = (
        <>
          <div>Comentario: {f.text}</div>
          <div>Lembrete: {dayjs(f.reminderDate).format('DD/MM/YYYY')}</div>
        </>
      );
      return f;
    });
  }
  return doc;
}
