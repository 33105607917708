import { Tag, Tooltip } from 'antd';
import {
  getDocumentStatusColor,
  getDocumentStatusDescription, translateX
} from 'utils/helpers';

// COMPONENT *******************************************************************
// *****************************************************************************

const DocumentStatusTag = props => {
  const { record, className } = props;

  if (!record) return;

  const dse = record?.status || record?._metadata?.__documentStatus; // document status in english
  const documentStatus = dse && translateX(dse);
  const reservationNote =
    record?.statusNote || record?._metadata?.audit?.reservationNote;

  const tooltip = reservationNote
    ? reservationNote
    : translateX(getDocumentStatusDescription(dse, record));

  return (
    <span className={className}>
      {documentStatus && (
        <Tooltip placement="top" title={tooltip}>
          <Tag
            className="text-capitalize"
            style={{ margin: 4 }}
            color={getDocumentStatusColor(dse)}
          >
            {documentStatus}
          </Tag>
        </Tooltip>
      )}
    </span>
  );
};

export default DocumentStatusTag;
