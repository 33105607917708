import {
  JarvislyFormContext,
  JarvislyFormProvider,
} from '../../others/FormComponents';
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { returnOnlyNumbers, toCapitalCase, translateX } from 'utils/helpers';
import axiosService from 'services/axiosService';
import appService from 'services/appService';
import { Modal } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import JarvislyEntityForm from '../Items/JarvislyEntityForm';
import { API_ENTITIES } from 'constants/ApiConstant';
import JarvislyContactForm from '../Items/JarvislyContactForm';
import JarvislyNoteForm from '../Items/JarvislyNoteForm';

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const { record, showModal, setShowModal, afterClose, isDisabled, updating } =
    props;

  // local variables I ----------------------------------------------------------
  const title = isDisabled
    ? translateX('view_contact')
    : !record?.entityId
    ? translateX('new_contact')
    : translateX('edit_contact');

  const childProps = {
    ...props,
    form,
  };

  // component states -----------------------------------------------------------

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    form?.resetFields();
    if (record) form?.setFieldsValue(record);
  }, [record, form]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    form.resetFields();
    setShowModal(false);
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        className="title-fixed-with-18 modal-top"
        title={
          <>
            <UserOutlined />
            <span className="ml-2">{title}</span>
          </>
        }
        destroyOnClose={true}
        open={showModal}
        width={640}
        okText={translateX(!isDisabled ? 'save' : 'close')}
        onCancel={onClose}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => (isDisabled ? onClose() : form.submit())}
        getContainer={false}
        okType={isDisabled ? 'default' : 'primary'}
        confirmLoading={updating}
        afterClose={afterClose}
      >
        {/* PERSON */}
        <JarvislyEntityForm {...childProps} />
        {/* PERSON */}

        {/* CONTACTS */}
        <JarvislyContactForm {...props} />
        {/* CONTACTS */}

        {/* NOTE */}
        <JarvislyNoteForm {...props} />
        {/* NOTE */}
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const JarvislyContactsModal = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { _id, document, isWorking, refreshDocument } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // for 'contacts' tab
  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  // local variables II --------------------------------------------------------
  const contactsTabProps = {
    ...props,
    showModal,
    setShowModal,
    selectedRecord,
    setSelectedRecord,
  };

  // hooks ---------------------------------------------------------------------
  useImperativeHandle(ref, () => ({
    showModal,
    setShowModal: value => setShowModal(value),
    selectedRecord: selectedRecord,
    setSelectedRecord: value => setSelectedRecord(value),
  }));

  // methods -------------------------------------------------------------------

  const onConfirm = async ({ body, searchedEntity, form }) => {
    const url = API_ENTITIES;
    const name = body.name.toLowerCase();
    const cpf = returnOnlyNumbers(body?.cpf);
    let entityId = selectedRecord?.entityId;

    if (
      document?.moreContacts.findIndex(
        c => c?.name?.toLowerCase() === name && c?.entityId !== entityId,
      ) > -1
    ) {
      appService.notification(
        'e',
        'check_info',
        'check_info',
        'contact_already_exists',
        5,
      );
      return;
    }

    body.name = toCapitalCase(body.name);
    body.dataType = 'person';
    body.dataProfile = 'contact';
    body.uniqueKey = cpf || +new Date();

    // save person in entities collection
    if (!searchedEntity) {
      const [doc] = await axiosService.upsert({
        url,
        _id: entityId,
        data: body,
      });
      entityId = doc?._id;
    } else {
      entityId = searchedEntity._id;
    }

    // update moreContacts subdocument
    const contact = {
      entityId,
      note: body?.note,
    };

    if (!document?.moreContacts) document.moreContacts = [];
    let idx = document.moreContacts.findIndex(c => c?.entityId === entityId);
    if (idx === -1) {
      document.moreContacts.push(contact);
    } else {
      document.moreContacts[idx].note = body?.note;
    }
    const data = { moreContacts: document.moreContacts };
    const [doc] = await axiosService.put({ _id, url, data });

    form?.resetFields();

    refreshDocument(doc._id, true);
    setShowModal(false);

    return doc;
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <JarvislyFormProvider
      name="form-contact"
      document={selectedRecord}
      requiredFields={['cpf', 'name']}
      showSubmitButton={false}
      onConfirm={onConfirm}
      autoFocus="name"
    >
      <ModalComponent
        {...contactsTabProps}
        showBirthdate
        updating={isWorking}
        record={selectedRecord}
        afterClose={() => {
          setSelectedRecord(null);
        }}
      />
    </JarvislyFormProvider>
  );
});

export default JarvislyContactsModal;
