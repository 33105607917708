import React, { useEffect, useState } from 'react';
import { Row, Button, Col, Card } from 'antd';
import {
  MessageFilled,
  PlusOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import { parsePhoneToDisplay, translateX } from 'utils/helpers';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { io } from 'socket.io-client';
import AddNewWppSession from '../NewSessionModal/AddNewWppSession';
import whatsappService from 'services/whatsappService';
import { useSelector } from 'react-redux';

import Meta from 'antd/es/card/Meta';
import appService from 'services/appService';
import TestWppModal from '../TestMessageModal/TestWppModal';
import { BsTrashFill } from 'react-icons/bs';

// --- Status guide ---
// WAITING (Waiting to read qrcode)
// FAILED_SESSION (Did not read qrcode in time)
// WAITING_SESSION (After click reload qrcode, goes to waiting to generate a new session, here does not exist a session yet)
// SUCESS_SESSION (Sucess connection with the device)
// CONNECTED (The device is connected)
// UNPAIRED (The user has been disconnected of his device)

// COMPONENT *******************************************************************
// *****************************************************************************
const AccountWhatsAppList = props => {
  // props deconstruction ------------------------------------------------------
  const { activeTab } = props;
  const { selectedSubscription } = useSelector(s => s?.authSlice);

  // component states ----------------------------------------------------------
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [qrCodeData, setQrCodeData] = useState(null);
  const [sessionNameMeta, setSessionNameMeta] = useState('');
  const [sessionList, setSessionList] = useState([]);
  const [list, setList] = useState([]);
  const [showQrCode, setShowQrCode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showReloadButton, setShowReloadButton] = useState(false);
  const [ressetQrLoading, setRessetQrLoading] = useState(false);
  const [sessionStatus, setSessionStatus] = useState('STANDBY');
  const [showTestModal, setTestShowModal] = useState(false);
  const [currentSession, setCurrentSession] = useState('');

  // local variables -----------------------------------------------------------
  const childProps = {
    ...props,
    socket,
    showModal,
    setShowModal,
    sessionNameMeta,
    setSessionNameMeta,
    showQrCode,
    setShowQrCode,
    qrCodeData,
    setQrCodeData,
    sessionList,
    showReloadButton,
    setShowReloadButton,
    ressetQrLoading,
    setRessetQrLoading,
    sessionStatus,
    setSessionStatus,
    list,
    showTestModal,
    setTestShowModal,
    currentSession,
    setCurrentSession,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    const newSocket = io('http://localhost:8077');
    setSocket(newSocket);

    newSocket.on('connect', () => {
      setIsConnected(true);
    });

    newSocket.on('disconnect', () => {
      setIsConnected(false);
    });

    newSocket.on('current_status', status => {
      console.log('Status recebido do backend:', status);
      setSessionStatus(status);
    });

    newSocket.on('qrcode', data => {
      if (sessionNameMeta === data.name) {
        if (data && data.data) {
          setQrCodeData(`data:image/png;base64,${data.data}`);
          setShowQrCode(true);
          setRessetQrLoading(false);
          setShowReloadButton(false);
        } else {
          console.log('No QR code to show...');
          setShowQrCode(false);
        }
      }
    });

    newSocket.on('list-sessions', data => {
      setSessionList(data);
    });

    newSocket.on('delete-instance', data => {
      console.log(`Received delete-instance event with data:`, data);
      // setSessionList(prevList => prevList.filter(item => item.id !== data))
    });

    return () => newSocket.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, sessionNameMeta]);

  useEffect(() => {
    (async () => await getWppList(selectedSubscription._id))();
    if (socket) {
      socket.emit('list-sessions');
    }
  }, [activeTab, selectedSubscription._id, socket, showModal]);

  //TODO - CORS ERROR
  // useEffect(() => {

  //   if (list && sessionList) {
  //     const checkSessions = async () => {
  //       for (const item of list) {
  //         console.log(
  //           'Contém?? --> ',
  //           item,
  //           !sessionList.some(session => session === item.session),
  //         );
  //         if (
  //           item.session &&
  //           !sessionList.some(session => session === item.session)
  //         ) {
  //           await deleteSession(item._id, item.session);
  //         }
  //       }
  //     };

  //     checkSessions();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [list, sessionList]);

  // methods -------------------------------------------------------------------

  const askDelete = async (_id, session) => {
    const options = {
      Content: 'remove_whatsapp_ask',
      okText: 'yes',
      cancelText: 'no',
      onOk: async () => {
        await deleteSession(_id, session);
      },
    };

    appService.modal('c', 'remove_whatsapp_title', options);
  };

  const deleteSession = async (_id, session) => {
    try {
      await whatsappService.deleteWhatsapp(_id);
      socket.emit('delete-session', { name: session });
      setList(prevList => prevList.filter(item => item._id !== _id));
    } catch (error) {
      console.log('Erro ao deletar');
    }
  };

  const getWppList = async id => {
    try {
      const response = await whatsappService.getWhatsAppList(id);
      setList(response[0]);
    } catch (error) {
      console.log('Error getting list');
    }
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <AddNewWppSession {...childProps} />
      <TestWppModal {...childProps} />
      <Row justify="end">
        <Button
          type="primary"
          className="ml-2 mt-2"
          onClick={() => setShowModal(true)}
          disabled={!isConnected}
        >
          <PlusOutlined />
          <span>{translateX('new_session')}</span>
        </Button>
      </Row>

      <div className={`my-4 container-fluid`}>
        {list?.map(record => (
          <Row gutter={ROW_GUTTER} key={record._id}>
            {record?.status !== 'removed' && (
              <Col xs={24} md={12} xl={8}>
                <Card
                  title={record?.title}
                  className="strong-border"
                  actions={[
                    <div
                      key="delete"
                      onClick={() => askDelete(record?._id, record?.session)}
                      style={{
                        cursor: 'pointer',
                        display: isConnected ? 'block' : 'none',
                      }}
                    >
                      <BsTrashFill size={21} />
                    </div>,
                    <div
                      key="testchat"
                      onClick={() => {
                        setTestShowModal(true);
                        setCurrentSession(record?.session);
                      }}
                      style={{
                        cursor: 'pointer',
                        display: isConnected ? 'block' : 'none',
                      }}
                    >
                      <MessageFilled style={{ fontSize: '18px' }} />
                    </div>,
                  ]}
                >
                  <Meta
                    avatar={<WhatsAppOutlined />}
                    title={`${record?.mobileDialCode} ${parsePhoneToDisplay(
                      record?.mobile,
                    )}`}
                    description={record?.description}
                  />
                </Card>
              </Col>
            )}
          </Row>
        ))}
      </div>
    </>
  );
};

// EXPORT **********************************************************************
// *****************************************************************************

export default AccountWhatsAppList;
