import React, { useEffect } from 'react';
import { Card, Col, Row } from 'antd';
import JarvislyPersonIndex from 'components/jarvisly-components/forms/JarvislyPersonIndex';
import { JarvislyInput } from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const MultiCustomersRecordIndex = props => {
  // providers context ---------------------------------------------------------
  // props deconstruction ------------------------------------------------------
  // const valueXpto = props.document?.lkq_emergency.emergencyContacts[0].name;

  props?.document?.lkq_emergency?.sort(
    (a, b) => new Date(b.departure) - new Date(a.departure),
  );

  // Agora, `sortedArray` tem o objeto com a data de departure mais recente na primeira posição.
  // props.document?.lkq_emergency = sortedArray;

  console.log('props ->>>>>>>', props);

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {}, []);
  // methods -------------------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Card>
        <JarvislyPersonIndex
          {...props}
          showCpf
          showBirthdate
          showTitle
          phoneOrEmailRequired
        />
        {props?.document?.lkq_emergency?.length > 0 && (
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={24} lg={12}>
              <JarvislyInput
                disabled={true}
                name={['lkq_emergency', 0, 'emergencyContacts', 0, 'name']}
                label="name_emergency"
              />
            </Col>

            <Col xs={24} sm={24} lg={12}>
              <JarvislyInput
                disabled={true}
                name={['lkq_emergency', 0, 'emergencyContacts', 0, 'phone']}
                label="phone_emergency"
              />
            </Col>
          </Row>
        )}
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default MultiCustomersRecordIndex;
