import React from 'react';
import { Input } from 'antd';
import { applyNumberMask, returnOnlyNumbers } from 'utils/helpers';
import countriesProfiles from 'assets/data/countriesProfiles.json';

const CpfCnpjInput = React.forwardRef((props, ref) => {
  // props deconstruction ------------------------------------------------------
  const {
    onChange,
    profile,
    prefix,
    suffix,
    onBlur,
    autoFocus,
    allowClear,
    disabled,
    countryCode = 'br',
  } = props;

  const documentType = profile
    ? ['cnpj', 'ein'].includes(profile?.toLowerCase())
      ? 'ein'
      : 'ssn'
    : null;

  // local variables I ---------------------------------------------------------
  let maxLength = 14;
  let value = $buildValueMaks();

  // methods -------------------------------------------------------------------
  const onLocalChange = ev => {
    const valueNumber = returnOnlyNumbers(ev?.target?.value);
    const mask = getDocMaskByNumberLength(
      valueNumber,
      documentType,
      countryCode,
    );

    let nextLength = valueNumber?.length || 0;

    if (nextLength > maxLength) return;

    value = $buildValueMaks(valueNumber);

    onChange && onChange(ev, mask);
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <Input
      {...props}
      ref={ref}
      value={value}
      onBlur={onBlur}
      autoFocus={autoFocus}
      prefix={prefix}
      suffix={suffix}
      allowClear={allowClear}
      disabled={disabled}
      onChange={onLocalChange}
    />
  );

  // internal functions --------------------------------------------------------
  function $buildValueMaks() {
    const sc = countriesProfiles.find(c => c.countryCode === countryCode);
    const masks = sc?.documentMasks || {
      ssn: '999.999.999-99',
      ein: '99.999.999/9999-99',
    };
    const digits =
      documentType && masks?.[documentType]
        ? returnOnlyNumbers(masks[documentType])
        : returnOnlyNumbers(masks?.ein || '99.999.999/9999-99');

    maxLength = digits.length;

    let value = props?.value && returnOnlyNumbers(props.value);

    if (value) {
      return applyNumberMask(
        value,
        masks[getDocMaskByNumberLength(value, documentType, countryCode)],
      );
    } else {
      return '';
    }
  }
});

export const getDocMaskByNumberLength = (value, documentType, countryCode) => {
  if (
    documentType &&
    ['cnpj', 'ein', 'cpf', 'ssn'].includes(documentType?.toLowerCase())
  ) {
    return ['cnpj', 'ein'].includes(documentType?.toLowerCase())
      ? 'ein'
      : 'ssn';
  }

  if (countryCode === 'br') {
    return value?.length > 11 ? 'ein' : 'ssn';
  }

  return 'ein';
};

export default CpfCnpjInput;
