import { BsCashCoin } from 'react-icons/bs';

const MODULE_TITLE = 'payable';
const MODULE_ICON = BsCashCoin;
const MODULE_MAIN_ROUTE = `/app/financial/${MODULE_TITLE}`;

const PAYABLE_MODULE = {
  name: 'payable',
  title: MODULE_TITLE,
  icon: MODULE_ICON,
  menus: {
    financial: [
      {
        path: MODULE_MAIN_ROUTE,
        title: MODULE_TITLE,
        icon: MODULE_ICON,
        submenu: [],
      },
    ],
  },

  url: MODULE_MAIN_ROUTE,
  routes: [`${MODULE_MAIN_ROUTE}/:_id/:section?`, MODULE_MAIN_ROUTE],
};

export default PAYABLE_MODULE;
