import React from 'react';
import { Button, Col, Row, Skeleton, Tag, Tooltip } from 'antd';
import { Icon } from 'components/util-components/Icon';
import {
  EditOutlined,
  EnvironmentOutlined,
  GlobalOutlined,
  LinkOutlined,
  MailOutlined,
  PhoneOutlined,
  StarOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { parsePhoneToDisplay, toCapitalCase, translateX } from 'utils/helpers';
import dayjs from 'dayjs';
import { FaPlaneArrival, FaPlaneDeparture } from 'react-icons/fa';
import Flex from 'components/shared-components/Flex';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
export const MultiOrdersFormHeaderTitle = props => {
  const { document } = props;

  return (
    <Tooltip title={translateX('order_number')}>
      <span className="text-uppercase">
        {/*{document?._metadata?.__documentCode}*/}
        <span>
          {translateX('order')} Nº {document?._metadata?.counter}
        </span>
      </span>
    </Tooltip>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const MultiOrdersFormHeaderSubtitle = props => {
  const { document } = props;

  return (
    <Tooltip title={translateX('customer')}>
      <span>{document?.__buyer?.name}</span>
    </Tooltip>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const MultiOrdersFormHeaderTags = props => {
  return <MultiOrdersStatusTag {...props} />;
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const MultiOrdersFormHeaderColumn1 = props => {
  const { document } = props;

  const buyer = document?.__buyer;
  const paymentUrl =
    document?.__receivables?.[0]?.paymentUrl || document?.paymentUrl;

  const onOpenPaymentUrl = () => {
    if (!paymentUrl) return;
    window.open(paymentUrl, '_blank');
  };

  return (
    <Col xs={24} sm={8}>
      <Row style={{ display: buyer?.email ? 'initial' : 'none' }}>
        <Col>
          {buyer?.email && (
            <Row className="p-1" style={{ minHeight: 29 }} align={'middle'}>
              <Tooltip placement="right" title={translateX('email')}>
                <Flex
                  flexDirection="row"
                  justifyContent="start"
                  alignItems="center"
                  className="text-truncate"
                >
                  <Icon
                    type={MailOutlined}
                    className="text-primary font-size-md"
                    style={{ flexShrink: 0 }}
                  />
                  <div
                    className="ml-2 font-weight-semibold"
                    style={{ flex: 1 }}
                  >
                    {buyer?.email}
                  </div>
                </Flex>
              </Tooltip>
            </Row>
          )}
        </Col>
      </Row>

      <Row style={{ display: buyer?.phone ? 'initial' : 'none' }}>
        <Col>
          {buyer?.phone && (
            <Row className="p-1" style={{ minHeight: 29 }} align={'middle'}>
              <Tooltip placement="right" title={translateX('phone')}>
                <Flex
                  flexDirection="row"
                  justifyContent="start"
                  alignItems="center"
                  className="text-truncate"
                >
                  <Icon
                    type={PhoneOutlined}
                    className="text-primary font-size-md"
                    style={{ flexShrink: 0 }}
                  />
                  <div
                    className="ml-2 font-weight-semibold"
                    style={{ flex: 1 }}
                  >
                    {parsePhoneToDisplay(buyer?.phone)}
                  </div>
                </Flex>
              </Tooltip>
            </Row>
          )}
        </Col>
      </Row>

      <Row style={{ display: paymentUrl ? 'initial' : 'none' }}>
        {/*<Row style={{ minHeight: 31 }}>*/}
        <Col>
          <Row className="p-1" align={'middle'}>
            <Tooltip placement="right" title={translateX('payment_link')}>
              <Flex
                flexDirection="row"
                justifyContent="start"
                alignItems="center"
                className="text-truncate"
              >
                <Icon
                  type={LinkOutlined}
                  className="text-primary font-size-md"
                  style={{ flexShrink: 0 }}
                />
                <div className="ml-2 font-weight-semibold" style={{ flex: 1 }}>
                  <Button
                    type="link"
                    style={{ height: 22 }}
                    className="p-0"
                    onClick={onOpenPaymentUrl}
                    danger={!paymentUrl}
                  >
                    {translateX(paymentUrl ? 'view_billing' : 'n_a')}
                  </Button>
                </div>
              </Flex>
            </Tooltip>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const MultiOrdersFormHeaderColumn2 = props => {
  const { document, isWorking } = props;

  return (
    <Col xs={24} sm={8}>
      {/* DESTINY */}
      <Skeleton
        active
        loading={!document?._id && isWorking}
        paragraph={{
          rows: 0,
          width: 200,
        }}
      >
        <Row
          style={{
            display: document?.destiny ? 'initial' : 'none',
          }}
        >
          <Col>
            <Row className="p-1" style={{ minHeight: 29 }}>
              {document?.destiny && (
                <Tooltip placement="right" title={translateX('destiny')}>
                  <Flex
                    flexDirection="row"
                    justifyContent="start"
                    alignItems="center"
                    className="text-truncate"
                  >
                    <Icon
                      type={EnvironmentOutlined}
                      className="text-primary font-size-md"
                      style={{ flexShrink: 0 }}
                    />
                    <span className="ml-2 font-weight-semibold">
                      {translateX(document?.destiny)}
                    </span>
                  </Flex>
                </Tooltip>
              )}
            </Row>
          </Col>
        </Row>
      </Skeleton>
      {/* DESTINY */}

      {/* DEPARTURE */}
      <Skeleton
        active
        loading={!document?._id && isWorking}
        paragraph={{
          rows: 0,
          width: 200,
        }}
      >
        <Row
          style={{
            display: document?.departure ? 'initial' : 'none',
          }}
        >
          <Col>
            <Row className="p-1" style={{ minHeight: 29 }}>
              {document?.departure && (
                <Tooltip placement="right" title={translateX('departure')}>
                  <Flex
                    flexDirection="row"
                    justifyContent="start"
                    alignItems="center"
                    className="text-truncate"
                  >
                    <Icon
                      type={FaPlaneDeparture}
                      className="text-primary font-size-md"
                      style={{ flexShrink: 0 }}
                    />
                    <span className="ml-2 font-weight-semibold">
                      {document?.departure &&
                        dayjs(document?.departure).format('L')}
                    </span>
                  </Flex>
                </Tooltip>
              )}
            </Row>
          </Col>
        </Row>
      </Skeleton>
      {/* DEPARTURE */}

      {/* ARRIVAL */}
      <Skeleton
        active
        loading={!document?._id && isWorking}
        paragraph={{
          rows: 0,
          width: 200,
        }}
      >
        <Row
          style={{
            display: document?.arrival ? 'initial' : 'none',
          }}
        >
          <Col>
            <Row className="p-1" style={{ minHeight: 29 }}>
              {document?.arrival && (
                <Tooltip placement="right" title={translateX('arrival')}>
                  <Flex
                    flexDirection="row"
                    justifyContent="start"
                    alignItems="center"
                    className="text-truncate"
                  >
                    <Icon
                      type={FaPlaneArrival}
                      className="text-primary font-size-md"
                      style={{ flexShrink: 0 }}
                    />
                    <span className="ml-2 font-weight-semibold">
                      {document?.arrival &&
                        dayjs(document?.arrival).format('L')}
                    </span>
                  </Flex>
                </Tooltip>
              )}
            </Row>
          </Col>
        </Row>
      </Skeleton>
      {/* ARRIVAL */}
    </Col>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const MultiOrdersFormHeaderColumn3 = props => {
  const { document, isWorking } = props;

  return (
    <Col xs={24} sm={8}>
      {/* SELLER */}
      <Skeleton
        active
        loading={!document?._id && isWorking}
        paragraph={{
          rows: 0,
          width: 200,
        }}
      >
        <Row
        // style={{
        //   display: document?.__seller?.name ? 'initial' : 'none',
        // }}
        >
          <Col>
            <Row className="p-1" style={{ minHeight: 29 }}>
              <Tooltip placement="right" title={translateX('seller_a')}>
                <Flex
                  flexDirection="row"
                  justifyContent="start"
                  alignItems="center"
                  className="text-truncate"
                >
                  <Icon
                    type={
                      document?.__seller?.name ? UserOutlined : GlobalOutlined
                    }
                    className="text-primary font-size-md"
                    style={{ flexShrink: 0 }}
                  />
                  <span className="ml-2 font-weight-semibold">
                    {document?.__seller?.name
                      ? toCapitalCase(document?.__seller?.name)
                      : translateX('online_sale')}
                  </span>
                </Flex>
              </Tooltip>
            </Row>
          </Col>
        </Row>
      </Skeleton>
      {/* SELLER */}

      {/* CREATED AT */}
      <Skeleton
        active
        loading={!document?._id && isWorking}
        paragraph={{
          rows: 0,
          width: 200,
        }}
      >
        <Row
          style={{
            display: document?._metadata?.audit?.createdAt ? 'initial' : 'none',
          }}
        >
          <Col>
            <Row className="p-1" style={{ minHeight: 29 }}>
              {document?._metadata?.audit?.createdAt && (
                <Tooltip placement="right" title={translateX('creation_date')}>
                  <Flex
                    flexDirection="row"
                    justifyContent="start"
                    alignItems="center"
                    className="text-truncate"
                  >
                    <Icon
                      type={StarOutlined}
                      className="text-primary font-size-md"
                      style={{ flexShrink: 0 }}
                    />
                    <span className="ml-2 font-weight-semibold">
                      {document?._metadata?.audit?.createdAt &&
                        dayjs(document?._metadata?.audit?.createdAt).format(
                          'L LT',
                        )}
                    </span>
                  </Flex>
                </Tooltip>
              )}
            </Row>
          </Col>
        </Row>
      </Skeleton>
      {/* CREATED AT */}

      {/* LAST UPDATE */}
      <Skeleton
        active
        loading={!document?._id && isWorking}
        paragraph={{
          rows: 0,
          width: 200,
        }}
      >
        <Row
          style={{
            display: document?._metadata?.audit?.updatedAt ? 'initial' : 'none',
          }}
        >
          <Col>
            <Row className="p-1" style={{ minHeight: 29 }}>
              {document?._metadata?.audit?.updatedAt && (
                <Tooltip placement="right" title={translateX('last_update')}>
                  <Flex
                    flexDirection="row"
                    justifyContent="start"
                    alignItems="center"
                    className="text-truncate"
                  >
                    <Icon
                      type={EditOutlined}
                      className="text-primary font-size-md"
                      style={{ flexShrink: 0 }}
                    />
                    <span className="ml-2 font-weight-semibold">
                      {document?._metadata?.audit?.updatedAt &&
                        dayjs(document?._metadata?.audit?.updatedAt).format(
                          'L LT',
                        )}
                    </span>
                  </Flex>
                </Tooltip>
              )}
            </Row>
          </Col>
        </Row>
      </Skeleton>
      {/* LAST UPDATE */}
    </Col>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const MultiOrdersStatusTag = props => {
  const record = props?.record || props?.document;
  const dse = props?.status || record?.status; // document status in english
  const documentStatus = dse && translateX(dse);

  const overdue =
    !props?.status &&
    record?._metadata?.audit?.createdAt &&
    dayjs(record._metadata.audit.createdAt).diff(dayjs(), 'days') > 1;

  return (
    <>
      {documentStatus && (
        <Tag
          style={{ margin: 4 }}
          color={getOrderStatusColor(overdue ? 'overdue' : dse)}
        >
          {documentStatus}
        </Tag>
      )}
    </>
  );
};

// INTERNAL FUNCTIONS ========================================================
// ===========================================================================
export const getOrderStatusColor = status => {
  switch (status) {
    case 'waiting_for_payment':
      return 'orange';

    case 'overdue':
    case 'late_payment':
      return '#f50';

    case 'waiting_for_voucher':
    case 'paid':
    case 'received':
      return 'purple';

    case 'done':
      return 'green';

    case 'cancelled':
    case 'cancelled_by_customer':
      return 'gray';

    default:
      return 'geekblue';
  }
};
