import React from 'react';
import { Row, Col, Table, Tooltip, Button } from 'antd';
import {
  setLocale,
  translateWord,
  sort,
  translateX,
  parseDateToDisplay,
} from 'utils/helpers';
import Icon from 'components/util-components/Icon';
import { FaPlaneArrival, FaPlaneDeparture } from 'react-icons/fa';
import dayjs from 'dayjs';
import { TagsOutlined } from '@ant-design/icons';
import {
  MdBoy,
  MdChildFriendly,
  MdDirectionsWalk,
  MdOutlineElderly,
} from 'react-icons/md';
import routeService from 'services/routeService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { APPMULTI_QUOTATIONS_ROUTE } from '../../../quotations/quotations';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const QuotationsList = props => {
  // props deconstruction ------------------------------------------------------
  const { isWorking, tableKey, document } = props;

  // local variables -----------------------------------------------------------
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // component states ----------------------------------------------------------
  const tableColumns = buildTableColumns();

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onClickQuotePrice = value => {
    const urlQueryString = routeService.buildQueryString(
      ['passengers', 'arrival'],
      navigate,
      searchParams,
      false,
      {
        tab: null,
        destiny: value.destiny,
        departure: dayjs(value.departure).format('YYYY-MM-DD'),
        arrival: dayjs(value.arrival).format('YYYY-MM-DD'),
        passengers: value.passengers,
      },
    );

    navigate(`${APPMULTI_QUOTATIONS_ROUTE}?${urlQueryString}`);
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Table
        key={tableKey}
        rowKey="_id"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={
          document?.quotations?.sort((a, b) => sort(b, a, 'createdAt')) || []
        }
        className="with-checkbox"
        loading={isWorking}
        pagination={false}
      />
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTableColumns() {
    return [
      {
        title: setLocale('quote_in'),
        dataIndex: 'createdAt',
        width: 180,
        hidden: false,
        render: (_, record) => (
          <>{record?.createdAt && dayjs(record.createdAt).fromNow()}</>
        ),
      },

      {
        title: setLocale('destiny'),
        dataIndex: 'destiny',
        width: 200,
        hidden: false,
        render: (_, record) => translateWord(record?.destiny),
      },

      {
        title: setLocale('dates'),
        dataIndex: 'arrival',
        width: 160,
        hidden: false,
        render: (_, record) => (
          <>
            <div style={{ display: 'flex' }}>
              <div>
                {record?.departure && parseDateToDisplay(record.departure)}
              </div>
              <div style={{ marginTop: 2 }}>
                <Icon type={FaPlaneDeparture} className="ml-2" />
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div>{record?.arrival && parseDateToDisplay(record.arrival)}</div>
              <div style={{ marginTop: 2 }}>
                <Icon type={FaPlaneArrival} className="ml-2" />
              </div>
            </div>
          </>
        ),
      },

      {
        title: setLocale('passengers'),
        dataIndex: 'passengers',
        render: (_, record) => (
          <div className="text-center" style={{ display: 'flex' }}>
            {record?.passengers?.map((age, idx) => (
              <Tooltip
                key={idx}
                title={translateX('age_year_old', ['%AGE%'], [age?.toString() ? age.toString(): 0 ])}
              >
                <div style={{ marginRight: '8px' }}>
                  <Icon
                    type={
                      age >= 60
                        ? MdOutlineElderly
                        : age >= 18
                        ? MdDirectionsWalk
                        : age >= 10
                        ? MdBoy
                        : MdChildFriendly
                    }
                    className="text-primary font-size-md"
                    style={{ flexShrink: 0 }}
                  />
                </div>
              </Tooltip>
            ))}
          </div>
        ),
      },

      {
        title: <span className="mr-2">{setLocale('actions')}</span>,
        dataIndex: 'actions',
        width: 50,
        align: 'right',
        hidden: false,
        render: (_, record) => (
          <div className="text-right d-flex justify-content-end">
            <Tooltip title={setLocale('quote_price')}>
              <Button
                className="mr-2"
                icon={<TagsOutlined />}
                onClick={() => onClickQuotePrice(record)}
                size="small"
              />
            </Tooltip>
          </div>
        ),
      },
    ].filter(x => !x.hidden);
  }
};

// COMPONENT *******************************************************************
// *****************************************************************************
const QuotationsIndex = props => {
  // props deconstruction ------------------------------------------------------

  // local variables -----------------------------------------------------------

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {/*<Loading isFetching={isWorking} />*/}

      <Row gutter={16}>
        <Col xs={24}>
          {/*<Card title={translateWord('historical')}>*/}
          <QuotationsList {...props} />
          {/*</Card>*/}
        </Col>
      </Row>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// EXPORT ====================================================================
// ===========================================================================

export default QuotationsIndex;
