import React from 'react';
import Card from 'components/shared-components/Card';
import PropTypes from 'prop-types';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { returnOnlyNumbers } from 'utils/helpers';

const StatisticWidget = ({
  title,
  value,
  status,
  statusClass,
  subtitle,
  prefix,
}) => {
  let statusNumber = returnOnlyNumbers(status);

  if (status?.substring(0, 1) === '-') statusNumber = -statusNumber;

  return (
    <Card>
      {title && <h4 className="mb-0">{title}</h4>}
      <div className={`${prefix ? 'd-flex' : ''} ${title ? 'mt-3' : ''}`}>
        {prefix ? <div className="mr-2">{prefix}</div> : null}
        <div>
          <div className="d-flex align-items-center">
            <h1 className="mb-0 font-weight-bold">{value}</h1>
            {status ? (
              <span
                className={`font-size-md font-weight-bold ml-3 ${statusClass}`}
              >
                <span className={`${statusClass}`}>{status}</span>
                {statusNumber !== 0 && statusNumber > 0 ? (
                  <ArrowUpOutlined />
                ) : (
                  <ArrowDownOutlined />
                )}
              </span>
            ) : null}
          </div>
          {subtitle !== undefined && (
            <div className="text-gray-light mt-1">{subtitle}&nbsp;</div>
          )}
        </div>
      </div>
    </Card>
  );
};

StatisticWidget.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  value: PropTypes.string,
  subtitle: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  prefix: PropTypes.element,
};

export default StatisticWidget;
