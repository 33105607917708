import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Table, Tag } from 'antd';
import Flex from 'components/shared-components/Flex';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import DataDisplayWidget from 'components/shared-components/DataDisplayWidget';
import {
  UserSwitchOutlined,
  FileDoneOutlined,
  FileSyncOutlined,
} from '@ant-design/icons';
import axiosService from 'services/axiosService';
import appdedaloService from 'services/appdedaloService';

const DataSummary = ({ loading, data }) => {
  const schedulesDone = data?.schedulesDone?.toLocaleString('pt-BR');
  const schedulePendent = data?.schedulePendent?.toLocaleString('pt-BR');
  const registerOverdue = data?.registerOverdue?.toLocaleString('pt-BR');
  const registerReady = data?.registerReady?.toLocaleString('pt-BR');

  return (
    <Row gutter={16}>
      <Col xs={24} sm={12} lg={6}>
        <DataDisplayWidget
          loading={loading}
          icon={<FileDoneOutlined />}
          value={schedulesDone}
          title="Agendamentos Concluídos"
          color="cyan"
          vertical={true}
          avatarSize={55}
        />
      </Col>

      <Col xs={24} sm={12} lg={6}>
        <DataDisplayWidget
          loading={loading}
          icon={<FileSyncOutlined />}
          value={schedulePendent}
          title="Agendamentos Pendentes"
          color="gold"
          vertical={true}
          avatarSize={55}
        />
      </Col>

      <Col xs={24} sm={12} lg={6}>
        <DataDisplayWidget
          loading={loading}
          icon={<UserSwitchOutlined />}
          value={registerReady}
          title="Cadastros Vigentes"
          color="blue"
          vertical={true}
          avatarSize={55}
        />
      </Col>

      <Col xs={24} sm={12} lg={6}>
        <DataDisplayWidget
          loading={loading}
          icon={<UserSwitchOutlined />}
          value={registerOverdue}
          title="Cadastros Vencidos"
          color="volcano"
          vertical={true}
          avatarSize={55}
        />
      </Col>
    </Row>
  );
};

const TopScheduling = ({ loading, data }) => {
  const tableColumns = [
    {
      title: 'Candidato',
      dataIndex: 'name',
      render: (_, record) => (
        <Flex>
          <AvatarStatus size={30} src={record.image} name={record.name} />
        </Flex>
      ),
    },

    {
      title: 'Código Anac',
      dataIndex: 'id',
    },

    {
      title: 'Categoria',
      dataIndex: 'orderStatus',
    },

    {
      title: 'Agendado Para',
      dataIndex: 'date',
    },

    {
      title: 'Tipo',
      dataIndex: 'type',
      render: (_, record) => (
        <Col className="tag-col-container">
          <Tag
            color={appdedaloService.getTypeTagColor(record.type)}
            className="tag"
          >
            {appdedaloService.getTypeTagName(record.type).toLowerCase()}
          </Tag>
        </Col>
      ),
    },
  ];

  return (
    <Card title="Últimos Agendamentos">
      <Table
        pagination={false}
        columns={tableColumns}
        dataSource={data?.lastRegistrations || []}
        rowKey="id"
        loading={loading}
      />
    </Card>
  );
};

const AppdedaloHome = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      const axiosOptions = {
        url: `/v1/dashboard/summary`,
        returnAsObject: true,
      };
      const [docs] = await axiosService.get(axiosOptions);

      setData(docs);
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <DataSummary loading={loading} data={data} />

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <TopScheduling loading={loading} data={data} />
        </Col>
      </Row>
    </>
  );
};

export default AppdedaloHome;
