import axios from 'axios';
import { signOutSuccess, signOut } from 'store/slices/authSlice';
import store from 'store';
import { notification } from 'antd';
import {
  generateCode,
  getIpWan,
  getUrlFromBrowser,
  translateWord,
} from 'utils/helpers';
import dayjs from 'dayjs';
import { onRequestsWorking } from '../store/slices/themeSlice';
import appService from '../services/appService';
import app from '../configs/ConfigIndex';

const unauthorizedCode = [400, 401, 403];
const language = navigator.language;

let requests = 0;

// const backendLocal = 'http://177.153.58.33:8005'
// const backendLocal = 'http://veertical.vps-kinghost.net:8005'
// const backendLocal = 'http://localhost:8000'

let backendUrl;

if (app.DEV_MODE) {
  const protocol = app.API_DEV_HTTPS ? 'https' : 'http';
  let devHost, devPort;

  if (app.API_DEV_HOST === 'vps') {
    devHost = app.API_HOST_DEV_VPS;
    devPort = app.API_PORT_PRODUCTION;
  } else {
    devHost = app.API_HOST_DEV_LOCALHOST;
    devPort = app.API_PORT_DEV;
  }

  backendUrl = `${protocol}://${devHost}:${devPort}/api`;
} else {
  backendUrl = `https://${app.API_HOST_PRODUCTION}:${app.API_PORT_PRODUCTION}/api`;
}

const APP_HEADERS = {
  // [default]
  //----------------------------------------------------------------------------------------------------
  'Content-Type': 'application/json',
  'x-serverdomain': backendUrl,
  'x-clienturl': getUrlFromBrowser(),

  // [safe, track and format]
  //----------------------------------------------------------------------------------------------------
  'x-application': app.ID,
  'x-token': app.TOKEN,
  'x-requestcode': generateCode([2, 3], 6),
  'x-resultasobject': false,
  'x-origin': 0,
  'x-summary': true,
  'x-forcefiled': 0,
  'x-forceremoved': 0,
  'x-timestamp': +new Date(),
  'x-ip': await getIpWan(),

  // [subscriber identification]
  // ----------------------------------------------------------------------------------------------------
  'x-accountid': null, // accountId author
  'x-subscriptionid': null, // subscriber owner
  'x-companyid': null, // companyId owner

  // [frontend culture identification/i18n]
  // ----------------------------------------------------------------------------------------------------
  'x-countrycode': language.split('-')[1].toLowerCase(),
  'x-languagecode': dayjs().locale(), // 'pt-br', 'en-us', ...
  'x-localecode': dayjs().locale()?.split('-')?.[1]?.toLowerCase() || 'us', // 'br', 'us', ...
  'x-timezoneoffset': dayjs().utcOffset(),
  'x-tokenexpirationminutes': 60,
};

const apiConfig = axios.create({
  baseURL: backendUrl,
  timeout: 60000,
  headers: APP_HEADERS,
  withCredentials: true,
});

// API Request interceptor
apiConfig.interceptors.request.use(
  async config => {
    requests++;
    store.dispatch(onRequestsWorking(requests));

    notification.destroy('try-login');

    const requestCode = generateCode([2, 3], 6);
    const jwtToken = localStorage.getItem('auth_token') || null;

    if (jwtToken) config.headers['authorization'] = `Bearer ${jwtToken}`;

    config.headers['x-timestamp'] = +new Date();
    config.headers['x-requestcode'] = requestCode;
    // if (!config.headers["x-ip"]) config.headers["x-ip"] = await getIpWan();

    // const details = ['post', 'put'].includes(config.method) ? config.data : '';
    // appService.console(
    //   'i',
    //   `${requestCode} ${config.method.toUpperCase()} ${config.url}`,
    //   details,
    // );

    return config;
  },
  error => {
    requests--;
    store.dispatch(onRequestsWorking(requests));

    // Do something with request error here
    notification.destroy('try-login');
    notification.error({
      key: 'try-login',
      message: translateWord('error'),
    });

    Promise.reject(error);
  },
);

// API response interceptor
apiConfig.interceptors.response.use(
  response => {
    // custom error from server
    if (response?.data?.error) throw new Error(response.data.error);

    const docs = response?.data?.data;
    const currentToken = localStorage.getItem('auth_token');
    const newToken = response?.data?.summary?.token;
    const isTokenEquals = newToken === currentToken;

    if (newToken && !isTokenEquals) {
      localStorage.setItem('auth_token', newToken);
    }

    if (Array.isArray(docs)) {
      docs?.map(d => $removeSensitiveFields(d));
    } else {
      $removeSensitiveFields(docs);
    }

    requests--;
    store.dispatch(onRequestsWorking(requests));

    const result = response?.data;
    const requestCode = response.config.headers['x-requestcode'];
    const timestamp = Number(response.config.headers['x-timestamp']);
    const totalTime = +new Date() - timestamp;

    appService.console(
      's',
      `${requestCode} ${response.config.method.toUpperCase()} ${
        response.config.url
      } ${totalTime}ms`,
      result,
    );

    // refresh parameters
    /*
    const { selectedModule } = store.getState().moduleSlice;
    if (
      selectedModule?.documentState?.refreshParametersOnSave &&
      !response?.config?.url.includes(API_PARAMETERS) &&
      response.config.method !== 'get'
    ) {
      appService.getParameters().then();
    }
*/

    return result;

    function $removeSensitiveFields(d) {
      if (!d) return;
      delete d?.password;
    }
  },
  error => {
    requests--;
    store.dispatch(onRequestsWorking(requests));

    let alreadyShowedError = false;
    let notificationParam = {
      key: 'try-login',
      message: '',
      description: `${error?.config?.method?.toUpperCase()}: `,
    };

    if (!error?.config) {
      notificationParam.message = `500: ${translateWord('server_not_found')}`;
      notificationParam.description = backendUrl;
      notificationParam.duration = 0;

      localStorage.removeItem('auth_token');

      store.dispatch(signOut());
      store.dispatch(signOutSuccess());

      return showNotification();
    }

    const requestCode = error?.config?.headers['x-requestcode'];
    const timestamp = Number(error?.config?.headers['x-timestamp']);
    const totalTime = +new Date() - timestamp;
    const url = error?.config?.baseURL?.replace('/api', '');
    const isInternalRequest = error?.config?.url.substring(0, 2) === '/v';
    const jwtToken = localStorage.getItem('auth_token') || null;

    appService.console(
      'e',
      `${requestCode} ${error?.config?.method?.toUpperCase()} ${
        error?.config?.url
      } ${totalTime}ms`,
      error?.response?.data,
    );

    // Remove token and redirect
    if (
      !alreadyShowedError &&
      unauthorizedCode.includes(error?.response?.status) &&
      isInternalRequest
    ) {
      if (error.response.data?.status === 403) {
        notificationParam.message = translateWord('session_expired');
        notificationParam.description = translateWord(
          'session_expired_description',
        );
        notificationParam.duration = 0;
      } else if (!jwtToken) {
        notificationParam.message = translateWord('authentication_fail');
        notificationParam.description = translateWord(
          'authentication_fail_description',
        );
      }

      if (error?.response?.data?.forceLogout) {
        localStorage.removeItem('auth_token');
        store.dispatch(signOut());
        store.dispatch(signOutSuccess());
      }

      if (notificationParam.message) showNotification('warning');
    }

    if (!alreadyShowedError && error?.response?.status === 404) {
      notificationParam.message = `404: ${translateWord(
        'api_route_not_found',
      )}`;
      notificationParam.description += error?.response?.config?.url;
      notificationParam.duration = 0;
      showNotification();
    }

    if (!alreadyShowedError && error?.response?.status === 500) {
      notificationParam.message = `500: ${translateWord(
        'internal_server_error',
      )}`;
      notificationParam.description += error?.response?.config?.url;
      notificationParam.duration = 0;

      if (error?.response?.config?.url?.includes('/tool/get-quote')) {
        localStorage.removeItem('auth_token');
        store.dispatch(signOut());
        store.dispatch(signOutSuccess());
      }

      showNotification();
    }

    if (!alreadyShowedError && error?.response?.status === 508) {
      notificationParam.message = `508: ${translateWord('time_out')}`;
      notificationParam.description += error?.response?.config?.url;
      notificationParam.duration = 0;
      showNotification();
    }

    if (!alreadyShowedError && error?.message === 'Network Error') {
      if (isInternalRequest) {
        notificationParam.message = `500: ${translateWord('server_not_found')}`;
        notificationParam.description = url;

        localStorage.removeItem('auth_token');

        store.dispatch(signOut());
        store.dispatch(signOutSuccess());
      } else {
        notificationParam.message = `500: ${translateWord('url_not_found')}`;
        notificationParam.description = error?.config?.url;
      }

      notificationParam.duration = 0;

      showNotification();
    }

    if (!alreadyShowedError && error?.response?.status !== 400) {
      notificationParam.message = `${error?.response?.status}: ${translateWord(
        'internal_server_error',
      )}`;

      if (!error?.response?.status) {
        localStorage.removeItem('auth_token');
        store.dispatch(signOut());
        store.dispatch(signOutSuccess());
      }

      notificationParam.description += error?.response?.config?.url;
      notificationParam.duration = 0;
      showNotification();
    }

    // do not use Promise.resolve(error) here!
    return Promise.reject(error);

    function showNotification(method = 'error') {
      alreadyShowedError = true;
      notification.destroy('try-login');
      notification[method](notificationParam);
    }
  },
);

export default apiConfig;
