import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyInput,
  JarvislySegmented,
} from '../../others/FormComponents';
import { Col, Modal, Row } from 'antd';
import { translateX } from 'utils/helpers';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  IssuesCloseOutlined,
} from '@ant-design/icons';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import axiosService from 'services/axiosService';

// MODAL COMPONENTS ************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const { showModal, setShowModal, document, isWorking } = props;

  // local variables I ---------------------------------------------------------
  const segmentedOptions = buildSegmentedOptions();

  // component states ----------------------------------------------------------
  const [selectedStatus, setSelectedStatus] = useState(null);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    form.setFieldsValue(document);
    setSelectedStatus(document?.status);
  }, [document]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {}, [showModal]);

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    setShowModal(false);
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        title={translateX('document_status')}
        className="modal-top"
        open={showModal}
        getContainer={false}
        onCancel={onClose}
        onOk={() => form.submit()}
        confirmLoading={isWorking}
      >
        <Row gutter={ROW_GUTTER}>
          <Col xs={24}>
            <JarvislySegmented
              name="status"
              x-no-label
              disabled={isWorking}
              options={segmentedOptions}
              onChange={v => setSelectedStatus(v)}
            />
          </Col>
        </Row>

        <Row gutter={ROW_GUTTER}>
          {selectedStatus === 'with_reservation' ? (
            <Col xs={24}>
              <JarvislyInput
                name="statusNote"
                label="reservation"
                disabled={isWorking}
              />
            </Col>
          ) : null}
        </Row>
      </Modal>
    </>
  );

  // internal functions --------------------------------------------------------
  function buildSegmentedOptions() {
    return [
      {
        label: translateX('in_analysis'),
        value: 'in_analysis',
        icon: <ExclamationCircleOutlined />,
      },
      {
        label: translateX('revised'),
        value: 'revised',
        icon: <CheckCircleOutlined />,
      },
      {
        label: translateX('with_reservation'),
        value: 'with_reservation',
        icon: <IssuesCloseOutlined />,
      },
    ];
  }
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const JarvislyDocumentStatusModal = forwardRef(
  (props, documentStatusModalRef) => {
    // providers context ---------------------------------------------------------

    // props deconstruction ------------------------------------------------------
    const { _id, document, selectedModule, refreshDocument } = props;

    // local variables I ---------------------------------------------------------

    // component states ----------------------------------------------------------

    // for 'contacts' tab
    const [showModal, setShowModal] = useState(false);

    // local variables II --------------------------------------------------------
    const childProps = {
      ...props,
      showModal,
      setShowModal,
      _id,
      document,
    };

    // hooks ---------------------------------------------------------------------
    useImperativeHandle(documentStatusModalRef, () => ({
      showModal,
      setShowModal: value => setShowModal(value),
    }));

    // methods -------------------------------------------------------------------
    const onConfirm = async ({ body }) => {
      if (body.status !== 'with_reservation') body.statusNote = '';

      const axiosOptions = {
        url: selectedModule.api,
        _id,
        data: body,
      };

      return await axiosService.put(axiosOptions);
    };

    const onAfterSave = () => {
      refreshDocument(_id);
      setShowModal(false);
    };

    // UI COMPONENT --------------------------------------------------------------
    return (
      <JarvislyFormProvider
        name="form-document-status"
        document={document}
        requiredFields={['status', 'statusNote']}
        showSubmitButton={false}
        onConfirm={onConfirm}
        onAfterSave={onAfterSave}
        autoFocus="status"
      >
        <ModalComponent {...childProps} />
      </JarvislyFormProvider>
    );
  },
);

export default JarvislyDocumentStatusModal;
