import { useLocation, useNavigate } from 'react-router-dom';

const useUrl = props => {
  const navigate = useNavigate();
  const location = useLocation();

  const getUrl = () => {
    return location.pathname + location.search;
  };

  const getUrlQueryParams = (stringFormat = false) => {
    const queryString = new URLSearchParams(location.search);

    if (stringFormat) return queryString;

    const queryParams = {};
    for (const [key, value] of queryString.entries()) {
      queryParams[key] = value;
    }

    return queryParams;
  };

  const updateUrlQueryParams = (queryStringObj, replace = false) => {
    const currentQueryParams = getUrlQueryParams();
    const queryParams = { ...currentQueryParams, ...queryStringObj };
    const queryString = new URLSearchParams(queryParams).toString();

    navigate({ search: `?${queryString}`, replace });
  };

  return {
    getUrl,
    getUrlQueryParams,
    updateUrlQueryParams,
  };
};

export default useUrl;
