import React from 'react';
import { Col, Descriptions, Divider, Row } from 'antd';
import financialService from 'services/financialService';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { translateX } from 'utils/helpers';
import StatisticWidget from 'components/jarvisly-components/dashboards/StatisticWidget';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const SummaryTabIndex = props => {
  // props deconstruction ------------------------------------------------------
  const { document } = props;
  const travellers = document?.travellers || [];

  const order = document?.totalInOrderDate;
  const parameters = document?.parametersInOrderDate;
  if (!order) return null;

  // const orderDiscounts =
  //   (order?.adminDiscount?.percentage || 0) +
  //   (order?.couponDiscount?.discount || 0);

  const orderProviderNetPrice = order?.providerNetPrice || 0;
  const orderResellerNetPrice = order?.totalToPay || 0;
  const previousMarkdown = parameters?.markdown || 0;
  const orderMarkdown = financialService.getMarkdown(
    orderProviderNetPrice,
    orderResellerNetPrice,
  );
  const orderProfit = financialService.percentageToValue(
    orderResellerNetPrice,
    orderMarkdown,
    2,
    true,
  );
  const markdonwDiff =
    previousMarkdown > orderMarkdown
      ? `-${financialService.formatNumber(
          previousMarkdown - orderMarkdown,
          3,
          'brl',
          true,
        )}%`
      : `+${financialService.formatNumber(
          orderMarkdown - previousMarkdown,
          3,
          'brl',
          true,
        )}%`;
  const markdownDiffClass =
    previousMarkdown > orderMarkdown ? 'text-danger' : 'text-success';

  // local variables -----------------------------------------------------------

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <div className="p-2">
        <Row gutter={ROW_GUTTER}>
          {/* COST */}
          <Col xs={24} md={8}>
            <StatisticWidget
              title="Custo Operadora"
              value={financialService.formatMoney(
                orderProviderNetPrice,
                2,
                'brl',
                true,
              )}
              // status={22}
              subtitle=""
            />
          </Col>
          {/* COST */}

          {/* PRICE */}
          <Col xs={24} md={8}>
            <StatisticWidget
              title="Preço de Venda"
              value={financialService.formatMoney(
                orderResellerNetPrice,
                2,
                'brl',
                true,
              )}
              // status={`${priceDiff}%`}
              subtitle=""
            />
          </Col>
          {/* PRICE */}

          {/* PROFIT */}
          <Col xs={24} md={8}>
            <StatisticWidget
              title="Margem de Lucro"
              value={`${orderMarkdown}%`}
              status={markdonwDiff}
              statusClass={markdownDiffClass}
              subtitle={financialService.formatMoney(
                orderProfit,
                2,
                'brl',
                true,
              )}
            />
          </Col>
          {/* PROFIT */}
        </Row>

        <Row>
          <Divider>Memória de Cálculo</Divider>
        </Row>

        <Row>
          <Descriptions
            bordered
            items={buildSummaryOrder()}
            className="w-100 mb-4"
            column={4}
          />
        </Row>

        <Row>
          <Divider>Descontos</Divider>
        </Row>

        <Row>
          <Descriptions
            bordered
            items={buildSummaryDiscounts()}
            className="w-100 mb-4"
            column={4}
          />
        </Row>

        <Row>
          <Divider>Valores por Viajantes</Divider>
        </Row>

        {travellers?.map(t => {
          return (
            <Row key={t._id} justify="center">
              <Descriptions
                bordered
                items={buildSummaryVoucher(t)}
                className="w-100 mb-4"
                column={3}
              />
            </Row>
          );
        })}
      </div>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildSummaryOrder() {
    const operator = document?.__operator;
    const p = document?.parametersInOrderDate;
    const plan = document?.__plan;

    return [
      // LINHA 1
      {
        key: `a`,
        label: 'Operadora',
        children: <>{operator?.name}</>,
        span: 2,
      },
      {
        key: 'c',
        label: 'Dias',
        children: (
          <>{p?.minDays > document?.days ? p?.minDays : document?.days}</>
        ),
      },
      {
        key: `b`,
        label: `Câmbio (${p?.currency?.toUpperCase()})`,
        children: (
          <>{financialService.formatMoney(p.exchangeRate, 2, 'brl', true)}</>
        ),
      },

      // LINHA 2
      {
        key: 'd',
        label: 'Plano',
        children: <>{plan?.name}</>,
        span: 3,
      },
      {
        key: `e`,
        label: `Preço (dia)`,
        children: (
          <>
            <div>
              {financialService.formatMoney(
                p?.dailyPrice,
                2,
                p?.currency,
                true,
              )}
            </div>
          </>
        ),
      },

      // LINHA 3
      {
        key: 'f',
        label: 'Preço Operadora',
        children: (
          <>
            <div>
              {financialService.formatMoney(
                order?.providerGrossPrice,
                2,
                'brl',
                true,
              )}
            </div>
          </>
        ),
        span: 3,
      },
      {
        key: 'h',
        label: 'Markdown',
        children: (
          <>
            <div>{financialService.formatNumber(p?.markdown, 3, 'brl')}%</div>
          </>
        ),
        span: 1,
      },

      // LINHA 4
      {
        key: 'i1',
        label: 'Preço Cheio',
        children: (
          <>
            <div>
              {financialService.formatMoney(
                order?.resellerGrossPrice,
                2,
                'brl',
                true,
              )}
            </div>
          </>
        ),
        span: 3,
      },
      {
        key: 'f1',
        label: 'Desconto Operadora',
        children: (
          <>
            <div>{financialService.formatNumber(p?.discount, 3, 'brl')}%</div>
          </>
        ),
        span: 1,
      },

      // LINHA 5
      {
        key: 'i',
        label: 'Preço Tabela',
        children: (
          <>
            <div>
              {financialService.formatMoney(
                order?.resellerNetPrice,
                2,
                'brl',
                true,
              )}
            </div>
          </>
        ),
        span: 3,
      },
      {
        key: 'g',
        label: 'Preço Net',
        children: (
          <>
            <div>
              {financialService.formatMoney(
                order?.providerNetPrice,
                2,
                'brl',
                true,
              )}
            </div>
          </>
        ),
        span: 1,
      },
    ];
  }

  function buildSummaryDiscounts() {
    const salePrice = order?.totalAdjusted || 0;

    // #1 admin adjustment or discount
    const adminDiscountPercentage = order?.adminDiscount?.percentage || 0;
    const adminDiscountValue = order?.adminDiscount?.value || 0;
    const adminAdjustmentPercentage = order?.adminAdjustment?.percentage || 0;
    const adminAdjustmentValue = order?.adminAdjustment?.value || 0;
    const salePriceWithAdminDiscount = adminDiscountValue
      ? salePrice - adminDiscountValue
      : salePrice + adminAdjustmentValue;

    // #2 coupon discount
    const couponDiscountPercentage = order?.couponDiscount?.discount || 0;
    const couponDiscountValue = order?.couponDiscount?.discountValue || 0;
    const salePriceWithCouponDiscount =
      salePriceWithAdminDiscount - couponDiscountValue;

    // #3 payment discount
    const paymentDiscountPercentage = order?.paymentDiscount || 0;
    const paymentDiscountValue = order?.paymentDiscountValue || 0;
    const salePriceWithPaymentDiscount =
      salePriceWithCouponDiscount - paymentDiscountValue;

    return [
      // LINHA 1
      {
        key: 'f',
        label: 'Reajuste Administrativo',
        children: adminDiscountPercentage ? (
          <div style={{ color: 'red' }}>
            -{financialService.formatMoney(adminDiscountValue, 2, 'brl', true)}
            &nbsp;&nbsp;(
            {financialService.formatNumber(adminDiscountPercentage, 3, 'brl')}
            %)
          </div>
        ) : adminAdjustmentPercentage ? (
          <div style={{ color: 'green' }}>
            +
            {financialService.formatMoney(adminAdjustmentValue, 2, 'brl', true)}
            &nbsp;&nbsp;(
            {financialService.formatNumber(adminAdjustmentPercentage, 3, 'brl')}
            %)
          </div>
        ) : (
          <div>N/A</div>
        ),
        span: 2,
      },
      {
        key: 'ia',
        label: 'Valor',
        children: (
          <>
            {financialService.formatMoney(
              salePriceWithAdminDiscount,
              2,
              'brl',
              true,
            )}
          </>
        ),
        span: 2,
      },

      // LINHA 2
      {
        key: 'f1',
        label: 'Desconto Cupom',
        children: couponDiscountPercentage ? (
          <div style={{ color: 'red' }}>
            -{financialService.formatMoney(couponDiscountValue, 2, 'brl', true)}
            &nbsp;&nbsp;(
            {financialService.formatNumber(couponDiscountPercentage, 3, 'brl')}
            %)
          </div>
        ) : (
          //   <div>
          //   <div>
          //     {financialService.formatNumber(
          //       couponDiscountPercentage,
          //       3,
          //       'brl',
          //     )}
          //     %
          //   </div>
          //   <div>
          //     {financialService.formatMoney(
          //       couponDiscountValue,
          //       2,
          //       'brl',
          //       true,
          //     )}
          //   </div>
          // </div>
          <div>N/A</div>
        ),
        span: 2,
      },
      {
        key: 'ia',
        label: 'Valor',
        children: (
          <>
            {financialService.formatMoney(
              salePriceWithCouponDiscount,
              2,
              'brl',
              true,
            )}
          </>
        ),
        span: 2,
      },

      // LINHA 3
      {
        key: 'g',
        label: 'Desconto PIX/Boleto',
        children: paymentDiscountPercentage ? (
          <div style={{ color: 'red' }}>
            -
            {financialService.formatMoney(paymentDiscountValue, 2, 'brl', true)}
            &nbsp;&nbsp;(
            {financialService.formatNumber(paymentDiscountPercentage, 3, 'brl')}
            %)
          </div>
        ) : (
          //   <div>
          //   <div>
          //     {financialService.formatNumber(
          //       paymentDiscountPercentage,
          //       3,
          //       'brl',
          //     )}
          //     %
          //   </div>
          //   <div>
          //     {financialService.formatMoney(
          //       paymentDiscountValue,
          //       2,
          //       'brl',
          //       true,
          //     )}
          //   </div>
          // </div>
          <div>N/A</div>
        ),
        span: 2,
      },
      {
        key: 'ia',
        label: 'Valor',
        children: (
          <>
            {financialService.formatMoney(
              salePriceWithPaymentDiscount,
              2,
              'brl',
              true,
            )}
          </>
        ),
        span: 2,
      },
    ];
  }

  function buildSummaryVoucher(t) {
    const plan = document?.__plan;
    let grievancePercentage = t.grievancePercentage;

    if (!grievancePercentage && t?.grievanceLevel) {
      switch (t.grievanceLevel) {
        case 3:
          grievancePercentage = plan.grievanceAdditionAge3;
          break;

        case 2:
          grievancePercentage = plan.grievanceAdditionAge2;
          break;

        case 1:
          grievancePercentage = plan.grievanceAdditionAge1;
          break;

        default:
          grievancePercentage = 0;
      }
    }

    // per traveller
    const pNetPrice = t?.valuesInOrderDate?.providerPrice || 0;
    // const rGrossPrice = t?.valuesInOrderDate?.resellerPrice || 0;
    // const rNetPrice = financialService.discount(rGrossPrice, orderDiscounts, 2);
    // console.log('p/r ===>', pNetPrice, orderMarkdown, );
    // const markDown = financialService.getMarkdown(pNetPrice, rNetPrice, true);
    // console.log('markDown', markDown);

    const rNetPrice = financialService.markdownReverse(
      pNetPrice,
      orderMarkdown,
      2,
    );

    const profitValue = rNetPrice - pNetPrice;

    return [
      // LINHA 1
      {
        key: `j`,
        label: 'Viajante',
        children: (
          <>
            <div>{`${t.name}, ${t.ageInTravelDate} ${translateX(
              'years_old',
            )}`}</div>
            {/*<div>{t?.voucherNumber}</div>*/}
          </>
        ),
        span: 2,
      },
      {
        key: 'l',
        label: 'Agravo',
        children:
          t.grievanceLevel > 0 ? (
            <>
              <div>{`Nível ${
                t.grievanceLevel
              } - ${financialService.formatNumber(
                grievancePercentage,
                3,
                'brl',
              )}%`}</div>
            </>
          ) : (
            'N/A'
          ),
      },

      // LINHA 2
      {
        key: `j`,
        label: 'Preço Operadora',
        children: (
          <>{financialService.formatMoney(pNetPrice, 2, 'brl', true)}</>
        ),
      },
      {
        key: `k`,
        label: 'Preço Venda',
        children: (
          <>{financialService.formatMoney(rNetPrice, 2, 'brl', true)}</>
        ),
      },
      {
        key: 'l',
        label: 'Lucro',
        children: (
          <>{financialService.formatMoney(profitValue, 2, 'brl', true)}</>
        ),
      },
    ];
  }
};

// EXPORT ====================================================================
// ===========================================================================

export default SummaryTabIndex;
