import { useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { ModuleContext } from '../../jarvisly-module/ModuleContext';
import useUrl from './urlHooks';

const useTab = (availableTabs, fieldFocus) => {
  // providers context ---------------------------------------------------------
  const { selectedModule, setModuleFocus, _id } = useContext(ModuleContext);

  // props deconstruction ------------------------------------------------------
  // local variables I ---------------------------------------------------------
  const [searchParams] = useSearchParams();
  const initTab = selectedModule.documentState.initTab;
  const url = useUrl();

  // methods -------------------------------------------------------------------
  // const onTabClick = value => {
  //   if (!tabItems.find(t => t.key === value)) value = initTab;
  //   setTab(value);
  // };

  // component states ----------------------------------------------------------
  const [tab, setTab] = useState(searchParams.get('tab') || initTab);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (!availableTabs.includes(tab)) setTab(initTab);
    url.updateUrlQueryParams({ tab }, true);
    setModuleFocus(prev => ({ ...prev, tab: initTab, field: fieldFocus }));
  }, [tab, _id]); // eslint-disable-line react-hooks/exhaustive-deps

  return [tab, setTab];
};

export default useTab;
