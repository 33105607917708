import { Button, Col, Input, Row, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FolderOpenOutlined,
  FolderOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  clone,
  getObjectByField,
  setLocale,
  translateWord,
  translateX,
} from 'utils/helpers';
import { NumericFormat } from 'react-number-format';
import { useLocation, useNavigate } from 'react-router-dom';
import { useModuleContext } from 'components/jarvisly-module/ModuleContext';
import ListPreview from './ListPreview';
import ListFilter from './ListFilter';
import appService from 'services/appService';
import { setSelectedModule } from 'configs/ConfigIndex';
import { buildUrlQueryString } from '../module-methods';
import dayjs from 'dayjs';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************
let loading = false;

// COMPONENT *******************************************************************
// *****************************************************************************

const ListIndex = props => {
  // local variables -----------------------------------------------------------
  const navigate = useNavigate();
  const searchInput = useRef(null);
  const location = useLocation();

  const { listState, components, apiRequest, apiResponse, selectedModule } =
    useSelector(s => s.moduleSlice);

  const {
    refreshList,
    dataKanban,
    dataList,
    dataGrid,
    dataDashboard,
    isWorking,
    pagination,
    searchText,
    sortOrder,
    setSearchText,
    showFilter,
    setShowFilter,
    subscriptionId,
  } = useModuleContext();

  // const viewMode = listState?.viewMode || 'list'
  const defaultPageSize = apiResponse?.summary?.pagination?.pageSize;

  // component states ----------------------------------------------------------
  const [selectedItem, setSelectedItem] = useState();
  const [showPreviewDrawer, setShowPreviewDrawer] = useState(false);
  const [buildRowSelection] = useState(false);
  const [tableKey, setTableKey] = useState(0);
  const [searchVisible] = useState({});
  const [initSearch, setInitSearch] = useState(true);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    appService.initRoute({
      moduleType: 'list',
      showPageHeader: true,
      showBreadcrumb: true,
      disableSubscriptionCombo: false,
    });

    setSelectedModule(location.pathname);

    if (
      subscriptionId &&
      selectedModule?.url === location?.pathname &&
      !loading
    ) {
      loading = true;

      const qs = location.state?.qsObj || '';

      if (qs === 'noRefresh' && dataList?.length > 0) {
        const qsStr = buildUrlQueryString(apiRequest.currentQs);
        navigate(`?${qsStr}`);
        return;
      }

      (async () => {
        await refreshList(qs || 'url');
        loading = false;
      })();
      if (location?.state?.qsObj) delete location.state.qsObj;
    }
  }, [selectedModule?.url, subscriptionId]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onClickOpen = record => navigate(`${selectedModule.url}/${record._id}`);

  const onClickPreview = record => {
    setSelectedItem(record);
    setShowPreviewDrawer(true);
  };

  const onTableChanges = async (p, f, s, e) => {
    const tableOptions = clone(apiRequest?.currentQs || {});

    // pagination
    const pagination = p && {
      pageNumber: p?.current || 1,
      pageSize: p.pageSize || defaultPageSize,
    };
    if (pagination) {
      tableOptions.pagination = pagination;
      localStorage.setItem('pageSize', pagination?.pageSize);
    }

    // sorter
    let tableSort = {};
    let toSort;

    if (s && Array.isArray(s)) {
      toSort = {};

      s.sort((a, b) => a.column.sorter.multiple - b.column.sorter.multiple).map(
        x => {
          toSort[x.field] =
            x?.order === 'ascend' ? 1 : x?.order === 'descend' ? -1 : null;
          tableSort[x.field] = x?.order || null;
          return x;
        },
      );
    } else {
      toSort = s &&
        s?.field && {
          [s.field]:
            s?.order === 'ascend' ? 1 : s?.order === 'descend' ? -1 : null,
        };

      tableSort = s && s?.field && { [s.field]: s?.order || null };
    }

    if (toSort) tableOptions.sorter = toSort;

    // filter (search)
    const search = {};
    f &&
      Object.entries(f).map(([k, v]) => {
        if (Array.isArray(v)) {
          if (v.length > 0) {
            const value = v.join(',');
            search[k] = !isNaN(value) ? Number(value) : value;
          }
        } else if (v) {
          search[k] = v;
        }

        return [k, v];
      });

    tableOptions.search = search;

    if (!e) {
      await refreshList();
    } else {
      await refreshList(tableOptions);
    }
  };

  const columnSorter = fieldName => {
    const idx = selectedModule?.initialRequest?.sorterOptions?.findIndex(
      f => f === fieldName,
    );

    if (idx === -1) {
      console.error(
        `missing sorterOptions parameter in the module.json (initialRequest property) for ${fieldName} field!`,
      );
      return null;
    }

    const precedenceOrder = idx + 1;

    return {
      sortOrder: sortOrder?.[fieldName] || null,
      sorter: {
        multiple: precedenceOrder,
      },
    };
  };

  const columnFilters = (dataIndex, filters) => {
    const filteredValue = searchText?.[dataIndex]
      ? searchText?.[dataIndex]?.split(',')
      : [];

    return {
      filters: filters,
      filteredValue: filteredValue,
    };
  };

  const resetTableSearch = async clearFilters => {
    if (clearFilters) {
      clearFilters();
    } else {
      setTableKey(p => p + 1);
      await onTableChanges();
    }

    setSearchText({});
    if (searchInput?.current?.input?.value)
      searchInput.current.input.value = '';
  };

  const handleSearch = (selectedKeys, confirm, fieldName) => {
    setInitSearch(false);

    setSearchText({
      ...searchText,
      [fieldName]: selectedKeys[0],
    });

    confirm();
  };

  const columnSearch = (fieldName, placeholder) => {
    return {
      filteredValue: searchText?.[fieldName] ? [searchText?.[fieldName]] : null,

      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => {
        if (
          !selectedKeys[0] &&
          searchText?.[fieldName] &&
          !searchVisible?.[fieldName] &&
          initSearch
        ) {
          setSelectedKeys([searchText[fieldName]]);
        }

        return (
          <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={`${translateWord('search')} ${
                placeholder ? translateX(placeholder) : translateX(fieldName)
              }`}
              value={selectedKeys[0]}
              onChange={e =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() =>
                handleSearch(selectedKeys, confirm, fieldName)
              }
              style={{ display: 'block' }}
            />
          </div>
        );
      },

      filterIcon: (
        filtered, // <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}/>
      ) => (
        <SearchOutlined
          style={{
            color: searchText[fieldName] || filtered ? '#1890ff' : undefined,
          }}
        />
      ),

      onFilter: (value, record) => getObjectByField(record, fieldName),

      onFilterDropdownOpenChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
    };
  };

  const close = () => {
    setShowPreviewDrawer(false);
  };

  // local variables III -------------------------------------------------------

  const numberLineColumn = {
    hidden: !listState?.showNumberOfLine,
    title: translateWord('line'),
    width: 80,
    render: (item, record, index) => {
      if (!pagination) return;

      const { current, pageSize } = pagination;

      return (
        <div className="text-left">
          {current * pageSize - pageSize + index + 1 || index}
        </div>
      );
    },
  };

  const documentCodeColumn = {
    title: translateX('document'),
    dataIndex: '_metadata.counter',
    ...columnSorter('_metadata.counter'),
    ...columnSearch('_metadata.counter'),
    width: 140,
    render: (_, record) => (
      <span className="text-uppercase text-muted">
        {record?._metadata?.__documentCode}
      </span>
    ),
  };

  const editViewColumn = {
    title: translateX('actions'),
    dataIndex: 'actions',
    align: 'center',
    width: 50,
    render: (_, record) => (
      <div className="text-right d-flex justify-content-end">
        <Tooltip title={translateX('edit')}>
          <Button
            className="mr-2"
            icon={<EditOutlined />}
            onClick={() => onClickOpen(record)}
            size="small"
          />
        </Tooltip>

        <Tooltip title={translateX('view')}>
          <Button
            className="mr-2"
            icon={<EyeOutlined />}
            onClick={() => onClickPreview(record)}
            size="small"
          />
        </Tooltip>
      </div>
    ),
  };

  const lastUpdatedColumn = {
    title: translateWord('last_update'),
    dataIndex: '_metadata.audit.updatedAt',
    ...columnSorter('_metadata.audit.updatedAt'),
    width: 160,
    hidden: false,
    render: (_, record) => (
      <>
        {record?._metadata?.audit?.updatedAt &&
          // moment(record._metadata.audit.updatedAt).format('L LT')}
          dayjs(record._metadata.audit.updatedAt).fromNow()}
      </>
    ),
  };

  const createdColumn = {
    title: translateWord('created'),
    dataIndex: 'date',
    className: 'text-truncate',
    render: (_, record) =>
      record?._metadata?.audit?.createdAt ? (
        <span>{dayjs(record._metadata.audit.createdAt).fromNow()}</span>
      ) : null,
  };

  // UI COMPONENTS -------------------------------------------------------------

  const tableProps = {
    ...props,
    isWorking,
    pagination,
    buildRowSelection,
    dataList,
    dataKanban,
    dataGrid,
    dataDashboard,
    onTableChanges,
    columnSorter,
    columnSearch,
    columnFilters,
    resetTableSearch,
    tableKey,
    listState,
    showNumberOfLine: listState?.showNumberOfLine,
    sortOrder,
    onClickOpen,
    onClickPreview,

    numberLineColumn,
    documentCodeColumn,
    editViewColumn,
    createdColumn,
    lastUpdatedColumn,
  };

  const TableTitle = () => (
    <Row className="mt-2 m-2" justify="space-between" align={'bottom'}>
      <Col>
        {apiRequest?.currentQs?.folder === 'documents' && (
          <Tooltip placement="right" title={translateX('documents')}>
            <FolderOpenOutlined className="mr-2" />
            {translateX('documents')}
          </Tooltip>
        )}

        {apiRequest?.currentQs?.folder === 'recycle' && (
          <Tooltip placement="right" title={translateX('recycle')}>
            <DeleteOutlined className="mr-2" />
            {translateX('recycle')}
          </Tooltip>
        )}

        {apiRequest?.currentQs?.folder === 'filed' && (
          <Tooltip placement="right" title={translateX('archive')}>
            <FolderOutlined className="mr-2" />
            {translateX('archive')}
          </Tooltip>
        )}
      </Col>

      <Col>
        <Tooltip placement="left" title={translateX('viewing_documents')}>
          {apiResponse?.summary?.pagination?.pages > 1 && (
            <>
              <NumericFormat
                value={apiResponse?.summary?.pagination?.recordsOfFrom}
                thousandSeparator="."
                decimalSeparator=","
                displayType="text"
              ></NumericFormat>
              &nbsp;-&nbsp;
              <NumericFormat
                value={apiResponse?.summary?.pagination?.recordsOfTo}
                thousandSeparator="."
                decimalSeparator=","
                displayType="text"
              ></NumericFormat>
              &nbsp;{translateX('of')}&nbsp;
            </>
          )}
          <NumericFormat
            value={apiResponse?.summary?.pagination?.records || 0}
            thousandSeparator="."
            decimalSeparator=","
            displayType="text"
          ></NumericFormat>
          &nbsp;
        </Tooltip>
      </Col>
    </Row>
  );

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {/* FILTER RIGHT DRAWER */}
      <ListFilter
        showModal={showFilter}
        setShowModal={setShowFilter}
        apiRequest={apiRequest}
        // refreshData={refreshData}
      />
      {/* FILTER RIGHT DRAWER */}

      {/* PREVIEW LEFT DRAWER */}
      <ListPreview
        close={close}
        visible={showPreviewDrawer}
        record={selectedItem}

        // showDrawer={showPreviewDrawer}
        // setShowDrawer={setShowPreviewDrawer}
        // _module={_module}
        // rdxModuleSettings={rdxModuleSettings}
      />
      {/* PREVIEW LEFT DRAWER */}

      {listState?.hasFilter ? <TableTitle /> : null}

      {listState?.viewMode === 'list' ? (
        <div className="table-with-border">
          {components?.list ? components.list(tableProps) : null}
        </div>
      ) : listState?.viewMode === 'cards' ? (
        <div>CARDS</div>
      ) : listState?.viewMode === 'kanban' ? (
        <div>{components?.kanban ? components.kanban(tableProps) : null}</div>
      ) : listState?.viewMode === 'grid' ? (
        <div>{components?.grid ? components.grid(tableProps) : null}</div>
      ) : listState?.viewMode === 'dashboard' ? (
        <div>
          {components?.dashboard ? components.dashboard(tableProps) : null}
        </div>
      ) : null}

      {/* </Card> */}
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// EXPORT ====================================================================
// ===========================================================================

export default ListIndex;
