import { CalendarOutlined } from '@ant-design/icons';
import AgendasList from './List/List';
import AgendasGrid from './Grid/GridIndex';
import AppdedaloAgendasSettings from './Settings/Settings';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const MENU_GROUP = 'records';
const MODULE_TITLE = 'agendas';
const MODULE_ICON = CalendarOutlined;
export const APPDEDALO_AGENDAS_API = '/v1/agendas';
export const APPDEDALO_AGENDAS_ROUTE = `/app/${MENU_GROUP}/agendas`;

// MODULE PARAMETERS ***********************************************************
// *****************************************************************************

const APPDEDALO_AGENDAS_MODULE = {
  rootName: 'agendas',
  name: 'appdedaloAgendas',
  title: MODULE_TITLE,
  icon: MODULE_ICON,
  url: APPDEDALO_AGENDAS_ROUTE,
  urlForm: null,
  api: APPDEDALO_AGENDAS_API,

  routes: [`${APPDEDALO_AGENDAS_ROUTE}/:_id`, APPDEDALO_AGENDAS_ROUTE],

  components: {
    dashboard: null,
    list: props => <AgendasList {...props} />,
    kanban: null,
    grid: props => <AgendasGrid {...props} />,
    // form: (props, ref) => <AgendasForm {...props} ref={ref} />,
    // formHeaderTitle: props => <AgendasFormHeaderTitle {...props} />,
    // formHeaderSubtitle: props => <AgendasFormHeaderSubtitle {...props} />,
    // formHeaderColumn1: props => <AgendasFormHeaderColumn1 {...props} />,
    // formHeaderColumn2: props => <AgendasFormHeaderColumn2 {...props} />,
    // formHeaderColumn3: props => <AgendasFormHeaderColumn3 {...props} />,
    // formHeaderTags: props => <AgendasFormHeaderTags {...props} />,
    formHeaderCustomIcons: null,
    parameters: props => <AppdedaloAgendasSettings {...props} />,
    dataImport: null,
    previewHeader: null,
    previewBody: null,
    previewActions: null,
  },

  methods: {
    decorateDocument,
  },

  initialRequest: {
    folder: 'documents',
    folderOptions: ['documents', 'recycle', 'filed'],

    filter: {},
    filterOptions: {},

    pagination: {
      pageNumber: 1, // current page
      pageSize: ['10', '20', '50', '100'].includes(
        localStorage.getItem('pageSize'),
      )
        ? Number(localStorage.getItem('pageSize'))
        : 10,
    },
    paginationOptions: {
      pageSize: [10, 20, 50, 100],
    },

    sorter: {
      '_metadata.audit.updatedAt': -1,
    },
    sorterOptions: [
      'customer',
      '_metadata.audit.updatedAt',
      '_metadata.counter',
      'status',
    ],

    search: {},

    textSearch: {
      default: '',
    },
  },

  listState: {
    viewMode: ['grid'], // 'list', 'kanban', 'grid' or 'dashboard'
    showNumberOfLine: false, // show number of line in table list
    showCheckBoxes: false, // show check boxes on table list for ple selection
    hasFilter: false, // show the filter (folders and customs) drawer button
    hasAccessControl: true, // show the control access modal for this module
    hasNewOptionsButtons: false,
    isTableChanged: false, // detect if the table was changed, check if the currentQs is different of initialQs (includes column sorter, search and pagination)
    isFilterChanged: false, // detect if the filters was changed
  },

  documentState: {
    disableLockForEdit: false,
    initTab: 'customer',
    fieldName: '_metadata.__documentCode',
    newDocumentTitle: 'new_agenda',
    // autoFill: true,
    actions: [
      // 'new',
      'edit',
      'reload',
      'divider',
      'duplicate',
      'file',
      'remove',
      'permanently_remove',
      'divider',
      'document_status',
    ],
    refreshParametersOnSave: true,

    mockDocument: {},
  },
};

export default APPDEDALO_AGENDAS_MODULE;

function decorateDocument(doc) {
  console.log('--->', doc);

  return doc;
}
