import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
//import { app.AUTHENTICATED_ENTRY } from 'configs/AppConfig'
import app from 'configs/ConfigIndex';

const PublicRoute = () => {
  const { token } = useSelector(state => state.authSlice);

  return token ? <Navigate to={app.AUTHENTICATED_ENTRY} /> : <Outlet />;
};

export default PublicRoute;
