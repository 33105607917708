import React, { useContext, useEffect } from 'react';
import { Form, Tooltip } from 'antd';
import { returnOnlyNumbers, translateX } from 'utils/helpers';
import { JarvislyFormContext } from './FormComponents';
import { ModuleContext } from '../../jarvisly-module/ModuleContext';

const FormItem = props => {
  // providers context ---------------------------------------------------------
  const { form, redefinedForm } = useContext(JarvislyFormContext);
  const { mode, moduleRefreshed } = useContext(ModuleContext);

  // props deconstruction ------------------------------------------------------
  const {
    componentName,
    invalidErrorLabel,
    name,
    rules = [],
    styleFormItem = {},
    required,
    validateData,
    forceValidation,
    validationFn,
    children,
    profile,
    min,

    hasFeedback,
    feedBackStatus,
    setFeedBackStatus,
    toolTipText,
  } = props;

  // local variables I ---------------------------------------------------------
  const label = $buildLabel(props);

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------
  $buildRulesValidator();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if ((validateData && form.getFieldValue([name])) || forceValidation) {
      form.validateFields([name]);
    }
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      mode === 'add' &&
      feedBackStatus &&
      setFeedBackStatus &&
      feedBackStatus !== 'validating'
    ) {
      setFeedBackStatus('');
    }
  }, [moduleRefreshed]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (setFeedBackStatus && feedBackStatus !== 'validating') {
      setFeedBackStatus('');
    }
  }, [redefinedForm]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (typeof feedBackStatus === 'object') form.validateFields([name]);
  }, [feedBackStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <Form.Item
      name={name}
      label={label}
      rules={rules}
      hasFeedback={hasFeedback}
      validateStatus={feedBackStatus}
      style={styleFormItem}
      required={required}
    >
      {children}
    </Form.Item>
  );

  // internal functions --------------------------------------------------------
  function $buildLabel(props) {
    let dynamicLabel = '';

    if (componentName === 'JarvislyCpfCnpj') {
      const value = form?.getFieldValue(name);
      const v = returnOnlyNumbers(value);

      if (v?.length === 11) dynamicLabel = 'cpf';
      if (v?.length === 14) dynamicLabel = 'cnpj';
    }

    const fromFieldName = props?.name
      ? Array.isArray(props.name) && props.name.length > 0
        ? props.name[props.name.length - 1]
        : props.name
      : undefined;

    const text =
      !props?.noLabel && !props?.xNoLabel
        ? dynamicLabel || props?.label || fromFieldName || 'n_a'
        : null;

    const translatedLabel = translateX(text);

    if (toolTipText) {
      return (
        <Tooltip title={toolTipText}>
          <span>{translatedLabel}</span>
        </Tooltip>
      );
    } else {
      return translatedLabel;
    }
  }

  function $buildRulesValidator() {
    if (min > 0 && rules.findIndex(x => x.min) === -1) {
      rules.push({
        type: 'number',
        min: min,
        message: translateX('invalid_minimal', ['%MINIMAL%'], [min]),
      });
    }

    if (
      rules.findIndex(x => x.validator) === -1 &&
      componentName !== 'JarvislySwitch'
    )
      rules.push({
        validator: async function (fieldProps, value) {
          if (!setFeedBackStatus) return;

          // workaround for isValidPhoneNumber function
          if (validationFn?.name === 'isValidPhoneNumber' && value === '(') {
            value = null;
          }

          if (
            (typeof value === 'undefined' ||
              value === '' ||
              value?.length === 0) &&
            required
          ) {
            setFeedBackStatus('error');
            return Promise.reject(translateX('required'));
          } else if (
            componentName === 'JarvislyAsyncAutoComplete' &&
            !value?.key &&
            required
          ) {
            setFeedBackStatus('error');
            return Promise.reject(translateX('required'));
          } else if (value && validationFn && !validationFn(value, profile)) {
            setFeedBackStatus('error');
            return Promise.reject(translateX(invalidErrorLabel));
          } else {
            if (feedBackStatus === 'validating') return;

            if (feedBackStatus?.error) {
              setFeedBackStatus('error');
              return Promise.reject(translateX(feedBackStatus?.message));
            } else {
              setFeedBackStatus(value ? 'success' : '');
              form.setFieldValue(name, value);
              return Promise.resolve();
            }
          }
        },
      });
  }
};

export default FormItem;
