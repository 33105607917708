import { Tabs } from 'antd';
import { translateWord } from 'utils/helpers';
import React, { forwardRef, useRef, useState } from 'react';
import CrmCustomerTab from './customerTab/CrmCustomerTab';
import CrmProposalTab from './proposalTab/CrmProposalTab';
import {
  JarvislyFormProvider,
  JarvislyTabProvider,
} from 'components/jarvisly-components/others/FormComponents';
import useTab from 'components/jarvisly-components/hooks/useTabHook';
import entityService from 'services/entityService';
import CrmRequirementTab from './requirementTab/CrmRequirementTab';
import appService from 'services/appService';
import CrmFollowupTab from './followupTab/CrmFollowupTab';

// COMPONENT *******************************************************************
// *****************************************************************************
const CrmForm = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { document, selectedModule, mode } = props;

  // local variables I ---------------------------------------------------------
  const initTab = selectedModule.documentState.initTab;
  const availableTabs = [initTab, 'requirement', 'proposal', 'followup'];
  const [tab, setTab] = useTab(availableTabs, 'name');

  const requirementTabRef = useRef(null);
  const proposalTabRef = useRef(null);

  // component states ----------------------------------------------------------
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [entityType, setEntityType] = useState('company');

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    selectedCustomer,
    setSelectedCustomer,
    entityType,
    setEntityType,
    tab,
  };
  const tabItems = buildTabItens();

  // hooks ---------------------------------------------------------------------
  // useEffect(() => {
  //   if (!availableTabs.includes(tab)) setTab(initTab);
  //   url.updateUrlQueryParams({ tab }, true);
  //   setModuleFocus(prev => ({ ...prev, tab: initTab, field: 'name' }));
  // }, [tab, _id]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  const onSave = async ({ body }) => {
    console.log('BODY =>', body);

    // create or update entity
    let customer;

    if (body?.customer) {
      body.customer.dataType = entityType;
      body.customer._id = body.customer?.selectedCustomer?.key;

      customer = entityService.decorateEntity(
        {
          ...selectedCustomer,
          ...body.customer,
          _id: selectedCustomer?._id || body?.customer?.selectedEntity?.key,
        },
        3,
        null,
        'customer',
      );

      console.log('Customer --> ', customer);

      customer = await entityService.upsertEntity(customer?._id, customer);
    }

    // contact
    // const contact = {
    //   entityId: c.contactId,
    //   roleId: c.roleId,
    //   name: c.contactName,
    // };

    // post or put crm
    const dataSet = {
      ...body,
    };

    // CUSTOMER
    if (customer) dataSet.customerId = customer._id;
    if (dataSet?.customer) delete dataSet.customer;

    // ATTACHMENTS
    const requirementAttachs = requirementTabRef?.current?.attachments || [];
    const proposalAttachs = proposalTabRef?.current?.attachments || [];

    const attachments = [...requirementAttachs, ...proposalAttachs];
    dataSet.attachmentIds = appService.parseAttachmentsState(attachments);

    console.log('ATTACHMENTS ====>', attachments);

    console.log('PUT NO BACKEND =>', dataSet);

    return dataSet;
  };

  // UI COMPONENT --------------------------------------------------------------

  return (
    <>
      <JarvislyFormProvider
        name="form-crm"
        document={document}
        autoFocus="selectedCustomer"
        // requiredFields={[
        //   ['customer', 'phone'],
        //   ['customer', 'email'],
        // ]}
        showSubmitButton={tab !== 'followup'}
        onSave={onSave}
        setTab={setTab}
      >
        <Tabs
          type="line"
          tabPosition="top"
          activeKey={tab}
          rootClassName="tab-in-form"
          items={tabItems.filter(x => !x.hide)}
          onTabClick={onTabClick}
        />
      </JarvislyFormProvider>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildTabItens() {
    return [
      {
        label: translateWord('customer'),
        key: initTab,
        hide: false,
        children: (
          <JarvislyTabProvider tab={initTab}>
            <CrmCustomerTab {...childProps} activeTab={tab} />
          </JarvislyTabProvider>
        ),
      },
      {
        label: translateWord('requirement'),
        key: 'requirement',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab="requirement">
            <CrmRequirementTab
              {...childProps}
              activeTab="requirement"
              ref={requirementTabRef}
            />
          </JarvislyTabProvider>
        ),
      },
      {
        label: translateWord('proposal'),
        key: 'proposal',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab="proposal">
            <CrmProposalTab
              {...childProps}
              activeTab="proposal"
              ref={proposalTabRef}
            />
          </JarvislyTabProvider>
        ),
      },
      {
        label: translateWord('followup'),
        key: 'followup',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab="followup">
            <CrmFollowupTab {...childProps} activeTab="followup" />
          </JarvislyTabProvider>
        ),
      },
    ];
  }
});

export default CrmForm;
