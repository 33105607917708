import React, { createContext, useEffect, useState } from 'react';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import app from 'configs/ConfigIndex';
import { onProduction } from 'utils/helpers';

const protocol = app.API_DEV_HTTPS ? 'wss' : 'ws';
let devHost;

if (app.API_DEV_HOST === 'heroku') {
  devHost = app.API_HOST_DEV_HEROKU;
} else if (app.API_DEV_HOST === 'vps') {
  devHost = app.API_HOST_DEV_VPS;
} else {
  devHost = app.API_HOST_DEV_LOCALHOST;
}

const wsUri = `${protocol}://${devHost}:${app.WS_PORT}`;
const webSocketUri = onProduction()
  ? `wss://${app.API_HOST_PRODUCTION}:${app.API_PORT_PRODUCTION}`
  : wsUri;

export const WebSocketContext = createContext({});

export const WebSocketProvider = ({ children }) => {
  const [message, setMessage] = useState(null);
  const [messageInfo, setMessageInfo] = useState(null);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const client = connectWebsocket();

    return () => {
      if (client && client?.readyState === client?.OPEN) {
        client.close();
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const sendMessage = (ev, text = 'Olá, servidor WebSocket!') => {
    if (socket && socket.readyState === socket.OPEN) {
      socket.send(text);
      console.log('Mensagem enviada para o servidor WebSocket:', text);
    } else {
      console.error(
        'Erro ao enviar mensagem: conexão WebSocket não está aberta',
      );
    }
  };

  const exposedValues = { message, messageInfo, sendMessage };

  return (
    <WebSocketContext.Provider value={exposedValues}>
      {children}
    </WebSocketContext.Provider>
  );

  function connectWebsocket() {
    try {
      const client = new W3CWebSocket(webSocketUri);

      client.onopen = () => {
        console.log('Conexão estabelecida com o servidor WebSocket');
      };

      client.onmessage = message => {
        console.log(
          'Mensagem recebida do servidor WebSocket:',
          message.data,
          message,
        );

        setMessageInfo(message);
        setMessage(message.data);
      };

      client.onclose = () => {
        console.log('Conexão WebSocket fechada');
      };

      setSocket(client);

      return client;
    } catch (error) {
      console.error(error);
    }
  }
};
