import React, { useState } from 'react';
import { Button, Col, Row, Skeleton, Tag, Tooltip } from 'antd';
import { Icon } from 'components/util-components/Icon';
import {
  AuditOutlined,
  CalendarOutlined,
  CaretRightOutlined,
  CarryOutOutlined,
  EditOutlined,
  EyeOutlined,
  EyeTwoTone,
  FileTextOutlined,
  GlobalOutlined,
  InfoCircleOutlined,
  LinkOutlined,
  MailOutlined,
  PhoneOutlined,
  StarOutlined,
  SyncOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { parsePhoneToDisplay, toCapitalCase, translateX } from 'utils/helpers';
import dayjs from 'dayjs';
import Flex from 'components/shared-components/Flex';
import asaasService from 'services/asaasService';
import { BsCashCoin } from 'react-icons/bs';
import financialService from 'services/financialService';
import appService from 'services/appService';
import { FiShoppingBag } from 'react-icons/fi';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
export const CouponsFormHeaderTitle = props => {
  const { document } = props;

  return (
    <Tooltip title={translateX('billing_code')}>
      <span className="text-uppercase">
        <span>{translateX(document?.name)}</span>
      </span>
    </Tooltip>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const CouponsFormHeaderSubtitle = props => {
  const { document } = props;

  return (
    <Tooltip title={translateX('customer')}>
      <span>{document?.customer?.name}</span>
    </Tooltip>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const CouponsFormHeaderTags = props => {
  return <CouponsStatusTag {...props} />;
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const CouponsFormHeaderColumn1 = props => {
  const { document, refreshDocument, _id, mode } = props;

  const customer = document?.customer;

  const [generatingBilling, setGeneratingBilling] = useState(false);

  const onOpenPaymentUrl = () => {
    if (!document?.paymentUrl) return;
    window.open(document.paymentUrl, '_blank');
  };

  const onClickGeneratePaymentUrl = async () => {
    try {
      setGeneratingBilling(true);
      await asaasService.generatePaymentUrl(document, document.customer);
      refreshDocument(_id, false, false);
      setGeneratingBilling(false);
    } catch (error) {}
  };

  return (
    <Col xs={24} sm={8}>
      <Row style={{ display: customer?.email ? 'initial' : 'none' }}>
        <Col>
          {customer?.email && (
            <Row className="p-1" style={{ minHeight: 29 }} align={'middle'}>
              <Tooltip placement="right" title={translateX('email')}>
                <Flex
                  flexDirection="row"
                  justifyContent="start"
                  alignItems="center"
                  className="text-truncate"
                >
                  <Icon
                    type={MailOutlined}
                    className="text-primary font-size-md"
                    style={{ flexShrink: 0 }}
                  />
                  <div
                    className="ml-2 font-weight-semibold"
                    style={{ flex: 1 }}
                  >
                    {customer?.email}
                  </div>
                </Flex>
              </Tooltip>
            </Row>
          )}
        </Col>
      </Row>

      <Row style={{ display: customer?.phone ? 'initial' : 'none' }}>
        <Col>
          {customer?.phone && (
            <Row className="p-1" style={{ minHeight: 29 }} align={'middle'}>
              <Tooltip placement="right" title={translateX('phone')}>
                <Flex
                  flexDirection="row"
                  justifyContent="start"
                  alignItems="center"
                  className="text-truncate"
                >
                  <Icon
                    type={PhoneOutlined}
                    className="text-primary font-size-md"
                    style={{ flexShrink: 0 }}
                  />
                  <div
                    className="ml-2 font-weight-semibold"
                    style={{ flex: 1 }}
                  >
                    {parsePhoneToDisplay(customer?.phone)}
                  </div>
                </Flex>
              </Tooltip>
            </Row>
          )}
        </Col>
      </Row>

      <Row style={{ minHeight: 31 }}>
        <Col>
          <Row className="p-1" align={'middle'}>
            {/*<Tooltip*/}
            {/*  placement="right"*/}
            {/*  title={document?.paymentUrl ? translateX('payment_link') : ''}*/}
            {/*>*/}
            <Flex
              flexDirection="row"
              justifyContent="start"
              alignItems="center"
              className="text-truncate"
            >
              {generatingBilling ? (
                <SyncOutlined spin />
              ) : !document?.paymentUrl ? (
                <Icon
                  type={CaretRightOutlined}
                  className="text-primary font-size-md"
                  style={{ flexShrink: 0 }}
                />
              ) : (
                <Icon
                  type={InfoCircleOutlined}
                  className="text-primary font-size-md"
                  style={{ flexShrink: 0 }}
                />
              )}

              <div className="ml-2 font-weight-semibold" style={{ flex: 1 }}>
                {document?.paymentUrl ? (
                  <PaymentsUrlIcons {...props} />
                ) : (
                  <Button
                    type="link"
                    style={{ height: 22 }}
                    disabled={mode !== 'view'}
                    className="p-0"
                    onClick={
                      document?.paymentUrl
                        ? onOpenPaymentUrl
                        : onClickGeneratePaymentUrl
                    }
                    danger={!document?.paymentUrl}
                  >
                    {translateX(
                      !document?.paymentUrl
                        ? generatingBilling
                          ? 'generating_billing'
                          : 'generate_billing'
                        : 'view_billing',
                    )}
                  </Button>
                )}
              </div>
            </Flex>
            {/*</Tooltip>*/}
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const CouponsFormHeaderColumn2 = props => {
  const { document, isWorking } = props;

  const value = financialService.formatMoney(document?.value, 2, 'brl', true);
  const netValue = financialService.formatMoney(
    document?.netValue,
    2,
    'brl',
    true,
  );

  return (
    <Col xs={24} sm={8}>
      {/* ORDER NUMBER */}
      <Skeleton
        active
        loading={!document?._id && isWorking}
        paragraph={{
          rows: 0,
          width: 200,
        }}
      >
        <Row
          style={{
            display: document?.order ? 'initial' : 'none',
          }}
        >
          <Col>
            <Row className="p-1" style={{ minHeight: 29 }}>
              {document?.order && (
                <Tooltip placement="right" title={translateX('order')}>
                  <Flex
                    flexDirection="row"
                    justifyContent="start"
                    alignItems="center"
                    className="text-truncate"
                  >
                    <Icon
                      type={FiShoppingBag}
                      className="text-primary font-size-md"
                      style={{ flexShrink: 0 }}
                    />
                    <span className="ml-2 font-weight-semibold">
                      {document?.order}
                    </span>
                  </Flex>
                </Tooltip>
              )}
            </Row>
          </Col>
        </Row>
      </Skeleton>
      {/* ORDER NUMBER */}

      {/* VALUE */}
      <Skeleton
        active
        loading={!document?._id && isWorking}
        paragraph={{
          rows: 0,
          width: 200,
        }}
      >
        <Row
          style={{
            display: document?.value ? 'initial' : 'none',
          }}
        >
          <Col>
            <Row className="p-1" style={{ minHeight: 29 }}>
              {document?.value && (
                <Tooltip
                  placement="right"
                  title={translateX(
                    'value_to_receive',
                    ['%NET_VALUE%'],
                    [netValue],
                  )}
                >
                  <Flex
                    flexDirection="row"
                    justifyContent="start"
                    alignItems="center"
                    className="text-truncate"
                  >
                    <Icon
                      type={BsCashCoin}
                      className="text-primary font-size-md"
                      style={{ flexShrink: 0 }}
                    />
                    <span className="ml-2 font-weight-semibold">{value}</span>
                  </Flex>
                </Tooltip>
              )}
            </Row>
          </Col>
        </Row>
      </Skeleton>
      {/* VALUE */}

      {/* DUE DATE */}
      <Skeleton
        active
        loading={!document?._id && isWorking}
        paragraph={{
          rows: 0,
          width: 200,
        }}
      >
        <Row
          style={{
            display: document?.dueDate ? 'initial' : 'none',
          }}
        >
          <Col>
            <Row className="p-1" style={{ minHeight: 29 }}>
              {document?.dueDate && (
                <Tooltip placement="right" title={translateX('due_date')}>
                  <Flex
                    flexDirection="row"
                    justifyContent="start"
                    alignItems="center"
                    className="text-truncate"
                  >
                    <Icon
                      type={CalendarOutlined}
                      className="text-primary font-size-md"
                      style={{ flexShrink: 0 }}
                    />
                    <span className="ml-2 font-weight-semibold">
                      {document?.dueDate &&
                        dayjs(document?.dueDate).format('L')}
                    </span>
                  </Flex>
                </Tooltip>
              )}
            </Row>
          </Col>
        </Row>
      </Skeleton>
      {/* DUE DATE */}

      {/* PAYMENT DATE */}
      <Skeleton
        active
        loading={!document?._id && isWorking}
        paragraph={{
          rows: 0,
          width: 200,
        }}
      >
        <Row
          style={{
            display: document?.paymentDate ? 'initial' : 'none',
          }}
        >
          <Col>
            <Row className="p-1" style={{ minHeight: 29 }}>
              {document?.paymentDate && (
                <Tooltip placement="right" title={translateX('payment_date')}>
                  <Flex
                    flexDirection="row"
                    justifyContent="start"
                    alignItems="center"
                    className="text-truncate"
                  >
                    <Icon
                      type={CarryOutOutlined}
                      className="text-primary font-size-md"
                      style={{ flexShrink: 0 }}
                    />
                    <span className="ml-2 font-weight-semibold">
                      {document?.paymentDate &&
                        dayjs(document?.paymentDate).format('L')}
                    </span>
                  </Flex>
                </Tooltip>
              )}
            </Row>
          </Col>
        </Row>
      </Skeleton>
      {/* PAYMENT DATE */}
    </Col>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const CouponsFormHeaderColumn3 = props => {
  const { document, isWorking } = props;

  const author = document?.__author;

  return (
    <Col xs={24} sm={8}>
      {/* AUTHOR */}
      <Skeleton
        active
        loading={!document?._id && isWorking}
        paragraph={{
          rows: 0,
          width: 200,
        }}
      >
        <Row
        // style={{
        //   display: author?.name ? 'initial' : 'none',
        // }}
        >
          <Col>
            <Row className="p-1" style={{ minHeight: 29 }}>
              <Tooltip placement="right" title={translateX('author')}>
                <Flex
                  flexDirection="row"
                  justifyContent="start"
                  alignItems="center"
                  className="text-truncate"
                >
                  <Icon
                    type={author?.name ? UserOutlined : GlobalOutlined}
                    className="text-primary font-size-md"
                    style={{ flexShrink: 0 }}
                  />
                  <span className="ml-2 font-weight-semibold">
                    {author?.name
                      ? toCapitalCase(author?.name)
                      : translateX('online_sale')}
                  </span>
                </Flex>
              </Tooltip>
            </Row>
          </Col>
        </Row>
      </Skeleton>
      {/* AUTHOR */}

      {/* CREATED AT */}
      <Skeleton
        active
        loading={!document?._id && isWorking}
        paragraph={{
          rows: 0,
          width: 200,
        }}
      >
        <Row
          style={{
            display: document?._metadata?.audit?.createdAt ? 'initial' : 'none',
          }}
        >
          <Col>
            <Row className="p-1" style={{ minHeight: 29 }}>
              {document?._metadata?.audit?.createdAt && (
                <Tooltip placement="right" title={translateX('creation_date')}>
                  <Flex
                    flexDirection="row"
                    justifyContent="start"
                    alignItems="center"
                    className="text-truncate"
                  >
                    <Icon
                      type={StarOutlined}
                      className="text-primary font-size-md"
                      style={{ flexShrink: 0 }}
                    />
                    <span className="ml-2 font-weight-semibold">
                      {document?._metadata?.audit?.createdAt &&
                        dayjs(document?._metadata?.audit?.createdAt).format(
                          'L LT',
                        )}
                    </span>
                  </Flex>
                </Tooltip>
              )}
            </Row>
          </Col>
        </Row>
      </Skeleton>
      {/* CREATED AT */}

      {/* LAST UPDATE */}
      <Skeleton
        active
        loading={!document?._id && isWorking}
        paragraph={{
          rows: 0,
          width: 200,
        }}
      >
        <Row
          style={{
            display: document?._metadata?.audit?.updatedAt ? 'initial' : 'none',
          }}
        >
          <Col>
            <Row className="p-1" style={{ minHeight: 29 }}>
              {document?._metadata?.audit?.updatedAt && (
                <Tooltip placement="right" title={translateX('last_update')}>
                  <Flex
                    flexDirection="row"
                    justifyContent="start"
                    alignItems="center"
                    className="text-truncate"
                  >
                    <Icon
                      type={EditOutlined}
                      className="text-primary font-size-md"
                      style={{ flexShrink: 0 }}
                    />
                    <span className="ml-2 font-weight-semibold">
                      {document?._metadata?.audit?.updatedAt &&
                        dayjs(document?._metadata?.audit?.updatedAt).format(
                          'L LT',
                        )}
                    </span>
                  </Flex>
                </Tooltip>
              )}
            </Row>
          </Col>
        </Row>
      </Skeleton>
      {/* LAST UPDATE */}
    </Col>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const CouponsStatusTag = props => {
  const record = props?.record || props?.document;
  const dse = record?.status; // document status in english
  const documentStatus = dse && translateX(dse);

  // const overdue =
  //   record?._metadata?.audit?.createdAt &&
  //   dayjs(record._metadata.audit.createdAt).diff(dayjs(), 'days') > 1;

  return (
    <>
      {documentStatus && (
        <Tag style={{ margin: 4 }} color={getOrderStatusColor(dse)}>
          {documentStatus}
        </Tag>
      )}
    </>
  );
};

// INTERNAL FUNCTIONS ========================================================
// ===========================================================================
export const getOrderStatusColor = status => {
  switch (status) {
    case 'new':
      return 'purple';

    case 'in_analysis':
      return 'cyan';

    case 'waiting_for_payment':
      return 'orange';

    case 'late_payment':
    case 'overdue':
      return '#f50';

    case 'paid':
      return 'green';

    case 'received':
      return '#87d068';

    case 'cancelled':
    case 'cancelled_by_customer':
      return 'gray';

    default:
      return 'geekblue';
  }
};

export const PaymentsUrlIcons = props => {
  const { document, isEmpty } = props;

  if (!document || isEmpty) return;

  const iconStyle = {
    fontSize: 17,
    marginTop: 2,
    marginRight: 12,
  };

  const billViewed = !!document?.invoiceViewdDate;
  const billViewedTooltip = billViewed
    ? translateX(
        'bill_viewed',
        ['%DATE%'],
        [dayjs(document?.invoiceViewdDate).format('L LT')],
      )
    : translateX('bill_not_viewed');

  return (
    <>
      {/* PAYMENT URL */}
      {document?.paymentUrl ? (
        <Tooltip title={translateX('payment_link')}>
          <a
            href={document?.paymentUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkOutlined style={iconStyle} />
            {/*<FileUnknownOutlined style={iconStyle} />*/}
          </a>
        </Tooltip>
      ) : null}
      {/* PAYMENT URL */}

      {/* BANK SLIP URL */}
      {document?.bankSlipUrl ? (
        <Tooltip title={translateX('bank_slip')}>
          <a
            href={document?.bankSlipUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FileTextOutlined style={iconStyle} />
          </a>
        </Tooltip>
      ) : null}
      {/* BANK SLIP URL */}

      {/* INVOICE URL */}
      {/*{document?.invoiceUrl ? (*/}
      {/*  <Tooltip title={translateX('invoice')}>*/}
      {/*    <a*/}
      {/*      href={document?.invoiceUrl}*/}
      {/*      target="_blank"*/}
      {/*      rel="noopener noreferrer"*/}
      {/*    >*/}
      {/*      <FileProtectOutlined style={iconStyle} />*/}
      {/*    </a>*/}
      {/*  </Tooltip>*/}
      {/*) : null}*/}
      {/* INVOICE URL */}

      {/* TRANSACTION URL */}
      {document?.transactionReceiptUrl ? (
        <Tooltip title={translateX('transaction_receipt')}>
          <a
            href={document?.transactionReceiptUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <AuditOutlined style={iconStyle} />
          </a>
        </Tooltip>
      ) : null}
      {/* TRANSACTION URL */}

      {/* BILL VIEWED */}
      {document?.paymentUrl ? (
        <Tooltip title={billViewedTooltip}>
          {billViewed ? (
            <EyeTwoTone style={iconStyle} />
          ) : (
            <EyeOutlined style={iconStyle} />
          )}
        </Tooltip>
      ) : null}
      {/* BILL VIEWED */}
    </>
  );
};

export const canEditDocument = document => {
  if (document?.transactionReceiptUrl) {
    appService.notification(
      'w',
      'edit_document_not_authorized_title',
      'not_allowed',
      'edit_document_not_authorized_description',
      10,
    );
    return false;
  } else {
    return true;
  }
};
