import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Row, Timeline } from 'antd';
import { translateX } from 'utils/helpers';
import TextArea from 'antd/es/input/TextArea';
import appService from 'services/appService';
import { useSelector } from 'react-redux';
import axiosService from 'services/axiosService';
import { CRM_API } from '../../crm';

// COMPONENT *******************************************************************
// *****************************************************************************
const CrmFollowupTab = React.memo(props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { isWorking, tab, _id, document, form } = props;

  // local variables I ---------------------------------------------------------
  const isMobile = appService.isMobile();
  const followupRef = useRef(null);
  const user = useSelector(s => s.authSlice.selectedUser);

  // component states ----------------------------------------------------------
  const [input, setInput] = useState('');

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setTimeout(() => followupRef?.current?.focus(), 200);
  }, [tab]);

  // methods -------------------------------------------------------------------
  const onAddFollowup = async () => {
    form.submit();

    const axiosOptions = {
      url: `${CRM_API}/add-to-set/${_id}/$push/followUp`,
      data: {
        accountId: user._id,
        text: input,
      },
    };

    await axiosService.put(axiosOptions);

    // refreshDocument(_id, true);
    setInput('');
    followupRef?.current?.focus();
  };

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Row justify="center">
        <Col
          span={24}
          style={{
            padding: isMobile ? '0' : '0 20%',
          }}
          className="text-center"
        >
          <TextArea
            ref={followupRef}
            rows={2}
            // disabled={isDisabled}
            value={input}
            onChange={({ target: { value } }) => setInput(value)}
          />
        </Col>
      </Row>
      <Row justify="end" className="pb-5 pt-3">
        <Col
          span={24}
          style={{
            padding: isMobile ? '0' : '0 20%',
          }}
          className="text-right"
        >
          <Button
            type="primary"
            onClick={onAddFollowup}
            // disabled={isDisabled}
            loading={isWorking}
          >
            <span>{translateX('save')}</span>
          </Button>
        </Col>
      </Row>

      <Timeline mode="left" items={document?.followUp || []} />
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
});

export default CrmFollowupTab;
