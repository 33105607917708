import { Tabs } from 'antd';
import { returnOnlyNumbers, translateWord } from 'utils/helpers';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import CustomersRecordIndex from './Record/RecordIndex';
import { useSearchParams } from 'react-router-dom';
import {
  JarvislyFormProvider,
  JarvislyTabProvider,
} from 'components/jarvisly-components/others/FormComponents';
import JarvislyDocumentStatusModal from 'components/jarvisly-components/forms/modals/JarvislyDocumentStatusModal';
import useUrl from 'components/jarvisly-components/hooks/urlHooks';
import { useSelector } from 'react-redux';
import { clone } from 'lodash';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const CustomersForm = forwardRef((props, ref) => {
  // props deconstruction ------------------------------------------------------
  const {
    selectedModule,
    document,
    _id,
    setModuleFocus,
    setRequiredFields,
    requiredFields,
  } = props;

  // local variables I ---------------------------------------------------------
  const [searchParams] = useSearchParams();
  const url = useUrl();
  const initTab = selectedModule.documentState.initTab;
  const availableTabs = [initTab];
  const documentStatusModalRef = useRef(null);
  const { parameters } = useSelector(s => s.moduleSlice);

  // component states ----------------------------------------------------------
  const [tab, setTab] = useState(searchParams.get('tab') || initTab);
  const [formData, setFormData] = useState(null);

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    activeTab: tab,
    formData,
    initTab,
  };
  const tabItems = buildTabItens();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (!availableTabs.includes(tab)) setTab(initTab);
    url.updateUrlQueryParams({ tab }, true);
    setModuleFocus(prev => ({ ...prev, tab: initTab, field: 'cpf' }));
  }, [tab, _id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const parameterRequiredFields = clone(parameters?.requiredFields || []);

    if (Array.isArray(parameterRequiredFields)) {
      if (parameterRequiredFields.includes('cpfCnpj')) {
        parameterRequiredFields.push('cpf', 'cnpj');
      }
      if (parameterRequiredFields.includes('address')) {
        parameterRequiredFields.push('zip', 'number');
      }
    }

    setRequiredFields([...parameterRequiredFields, 'name', 'fullName']);
  }, [parameters?.requiredFields]); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    documentStatusModalRef,
  }));

  // methods -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  const onSave = async ({ body }) => {
    if (!body) return;

    // decorate entity
    body.dataType = 'company';
    body.dataProfile = 'customer';
    body.uniqueKey = returnOnlyNumbers(body.cpf) || +new Date();
    body.status = document?.status;

    return body;
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      {/* DOCUMENT STATUS MODAL WRAPPER */}
      <JarvislyDocumentStatusModal
        {...childProps}
        ref={documentStatusModalRef}
      />
      {/* DOCUMENT STATUS MODAL WRAPPER */}

      <JarvislyFormProvider
        name="form-customer"
        document={document}
        requiredFields={requiredFields}
        setTab={setTab}
        onSave={onSave}
        onValuesChange={(v, allValues) => setFormData({ ...allValues })}
      >
        <Tabs
          type="line"
          tabPosition="top"
          activeKey={tab}
          rootClassName="tab-in-form"
          items={tabItems.filter(x => !x.hide)}
          onTabClick={onTabClick}
        />
      </JarvislyFormProvider>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTabItens() {
    return [
      {
        label: translateWord('record'),
        key: initTab,
        hide: false,
        children: (
          <JarvislyTabProvider tab={initTab}>
            <CustomersRecordIndex {...childProps} />
          </JarvislyTabProvider>
        ),
      },
    ];
  }
});

export default CustomersForm;
