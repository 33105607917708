import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Descriptions, Modal, Row } from 'antd';
import { strToNumber, translateX } from 'utils/helpers';
import {
  goFocus,
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyNumber,
} from 'components/jarvisly-components/others/FormComponents';
import financialService from 'services/financialService';
import { useSelector } from 'react-redux';
import appmultiService from 'services/appmultiService';

// MAIN COMPONENT **************************************************************
// *****************************************************************************

const ModalComponent = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------
  const { form, elRefs } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    plan,
    showModal,
    setShowModal,
    isDisabled,
    total,
    documentContext,
    travellers,
    setPlan,
    paymentSelected,
    creditCardInstallment,
  } = props;
  const { filter } = documentContext;

  // local variables I ---------------------------------------------------------
  const initialState = {
    fullPrice: 0,
    standardDiscount: 0,
    standardDiscountPercentage: 0,
    promotionalPrice: 0,
    discountCoupon: 0,
    discountCouponPercentage: 0,
    finalPrice: 0,
    adjustment: 0,
    adjustedPricePercentage: 0,
    adjustedPrice: 0,
    unit: 'price',
  };

  // component states ----------------------------------------------------------
  const [state, setState] = useState(initialState);

  // local variables II --------------------------------------------------------
  const descriptionItems = buildDescriptionItems();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (!plan || !showModal) return;
    calculateAdjustedPrice();
    goFocus(elRefs, 'price', 'all');
  }, [plan, showModal]); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    state,
  }));

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    setShowModal(false);
  };

  const onChangeValues = value => {
    calculateAdjustedPrice();
  };

  const onChangeUnit = unit => {
    // setState(prev => ({ ...prev, unit }));
    calculateAdjustedPrice(unit);
  };

  const calculateAdjustedPrice = (unit = state.unit || 'price') => {
    const fullPrice = total?.resellerGrossPrice || 0;
    const promotionalPrice = total?.resellerNetPrice || 0;
    const totalToPay = total?.totalToPay || 0;

    const standardDiscount = fullPrice - promotionalPrice;
    const standardDiscountPercentage = financialService.diffMarkdown(
      fullPrice,
      promotionalPrice,
    );

    const discountCoupon = total?.couponDiscount?.discountValue || 0;
    const discountCouponPercentage = total?.couponDiscount?.discount || 0;

    const finalPrice = promotionalPrice - discountCoupon;

    // ADJUSTED PRICE
    let adjustedPrice = !isNaN(form?.getFieldValue('price'))
      ? Number(form?.getFieldValue('price'))
      : totalToPay || 0;

    if (unit === 'price') {
      if (adjustedPrice < 0) adjustedPrice = 0;
    }

    const adjustedPricePercentage = financialService.diffMarkdown(
      finalPrice,
      adjustedPrice,
      3,
    );

    const adjustment = adjustedPrice - finalPrice;

    const newStateValues = {
      fullPrice: financialService.formatMoney(fullPrice),
      standardDiscount: standardDiscount
        ? financialService.formatMoney(standardDiscount)
        : '-',
      standardDiscountPercentage: standardDiscount
        ? financialService.formatNumber(standardDiscountPercentage, 3)
        : '-',
      promotionalPrice: financialService.formatMoney(promotionalPrice),

      discountCoupon: discountCoupon
        ? financialService.formatMoney(discountCoupon)
        : '-',
      discountCouponPercentage: discountCoupon
        ? financialService.formatNumber(discountCouponPercentage, 3)
        : '-',

      finalPrice: financialService.formatMoney(finalPrice),

      adjustment: adjustment
        ? financialService.formatMoney(adjustment, 2)
        : '-',

      adjustedPricePercentage: adjustment
        ? financialService.formatNumber(adjustedPricePercentage, 3)
        : '-',

      adjustedPrice: adjustment
        ? financialService.formatMoney(adjustedPrice)
        : financialService.formatMoney(finalPrice),

      unit: 'price',
    };

    setState(newStateValues);
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        cancelText={translateX(isDisabled ? 'close' : 'cancel')}
        title={
          <div className="text-muted text-uppercase mb-4">
            {translateX('price_adjustment')}
          </div>
        }
        // centered
        open={showModal}
        okText={translateX(!isDisabled ? 'confirm' : 'close')}
        onCancel={onClose}
        cancelButtonProps={isDisabled && { style: { display: 'none' } }}
        onOk={() => (isDisabled ? onClose() : form.submit())}
        getContainer={false}
        okType={isDisabled ? 'default' : 'primary'}
        afterClose={() => {
          const summary = appmultiService.calculatePlanValues({
            plan,
            days: filter.days,
            passengersArr: travellers,
            paymentMethod: paymentSelected,
            creditCardInstallment,
          });

          setPlan &&
            setPlan(prev => ({
              ...prev,
              summary: { ...summary },
            }));
        }}
      >
        <Descriptions
          bordered
          title={translateX('summary')}
          size="small"
          items={descriptionItems}
          column={2}
        />

        {!isDisabled ? (
          <Row justify="center" align="middle" className="pt-5 pr-5 pb-2 pl-5">
            <JarvislyNumber
              autoFocus={true}
              disabled={isDisabled}
              name="price"
              label="adjusted_price"
              addonBefore={null}
              addonAfter="R$"
              suffixDefaultValue={state.unit}
              onChange={onChangeValues}
              onSuffixChange={onChangeUnit}
            />
          </Row>
        ) : null}
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildDescriptionItems() {
    return [
      {
        key: 'fullPrice',
        label: translateX('full_price'),
        children: state.fullPrice,
        span: 2,
      },
      {
        key: 'standardDiscount',
        label: translateX('standard_discount'),
        children: state.standardDiscount,
      },
      {
        key: 'standardDiscountPercentage',
        label: '%',
        children: state.standardDiscountPercentage,
      },
      {
        key: 'promotionalPrice',
        label: translateX('promotional_price'),
        children: state.promotionalPrice,
        span: 2,
      },
      // {
      //   key: 'discountCoupon',
      //   label: translateX('discount_coupon'),
      //   children: state.discountCoupon,
      // },
      // {
      //   key: 'discountCouponPercentage',
      //   label: '%',
      //   children: state.discountCouponPercentage,
      // },
      {
        key: 'finalPrice',
        label: translateX('final_price'),
        children: state.finalPrice,
        span: 2,
      },

      {
        key: 'adjustment',
        label: translateX('adjust'),
        children: state.adjustment,
      },
      {
        key: 'adjustedPricePercentage',
        label: '%',
        children: state.adjustedPricePercentage,
      },
      {
        key: 'adjustedPrice',
        label: translateX('adjusted_price'),
        children: state.adjustedPrice,
        span: 2,
      },
    ];
  }
});

// EXPORT **********************************************************************
// *****************************************************************************

export const MultiQuotationPriceAdjustmentModal = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    setPlan,
    setAdminAdjustment,
    setAdminDiscount,
    total = props?.plan?.summary?.total,
  } = props;

  // local variables I ---------------------------------------------------------
  const price = financialService.formatNumber(total?.totalToPay);
  const modalRef = useRef(null);
  const { selectedUser } = useSelector(s => s.authSlice);

  // component states ----------------------------------------------------------
  const [showModal, setShowModal] = useState(false);

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    showModal,
    setShowModal,
  };

  // hooks ---------------------------------------------------------------------
  useImperativeHandle(ref, () => ({
    showModal,
    setShowModal,
    // setShowModal: value => setShowModal(value),
  }));

  // methods -------------------------------------------------------------------
  const onConfirm = async ({ body }) => {
    const { state } = modalRef.current;

    const adjustedPrice = strToNumber(state?.adjustedPrice);
    const adjustment = strToNumber(state?.adjustment);
    const adjustedPricePercentage = strToNumber(state?.adjustedPricePercentage);

    if (adjustedPricePercentage > 0) {
      const adminDiscount = {
        type: 'value',
        accountId: null,
        value: 0,
        percentage: 0,
      };
      const adminAdjustment = {
        type: 'value',
        accountId: selectedUser._id,
        value: adjustment,
        percentage: adjustedPricePercentage,
      };

      setAdminAdjustment(adminAdjustment);
      setAdminDiscount(adminDiscount);

      setPlan &&
        setPlan(prev => ({
          ...prev,
          summary: {
            ...prev.summary,
            total: {
              ...prev.summary.total,
              adminDiscount,
              adminAdjustment,
              adjustedPrice,
            },
          },
        }));
    } else if (adjustedPricePercentage < 0) {
      const adminDiscount = {
        type: 'value',
        accountId: selectedUser._id,
        value: -adjustment,
        percentage: -adjustedPricePercentage,
      };
      const adminAdjustment = {
        type: 'value',
        accountId: null,
        value: 0,
        percentage: 0,
      };

      setAdminAdjustment(adminAdjustment);
      setAdminDiscount(adminDiscount);

      setPlan &&
        setPlan(prev => ({
          ...prev,
          summary: {
            ...prev.summary,
            total: {
              ...prev.summary.total,
              adminDiscount,
              adminAdjustment,
              adjustedPrice,
            },
          },
        }));
    }

    setShowModal(false);
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <JarvislyFormProvider
      abortComponentSave
      name={`form-${+new Date()}`}
      document={{ price }}
      requiredFields={[]}
      showSubmitButton={false}
      onConfirm={onConfirm}
      autoFocus="price"
    >
      <ModalComponent {...childProps} ref={modalRef} />
    </JarvislyFormProvider>
  );
});

export default MultiQuotationPriceAdjustmentModal;
