import { forwardRef, useContext, useEffect, useState } from 'react';
import { Col, Modal } from 'antd';
import { translateX } from 'utils/helpers';
import { WhatsAppOutlined } from '@ant-design/icons';
import {
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyPhone,
  JarvislyTextArea,
} from 'components/jarvisly-components/others/FormComponents';
import whatsappService from 'services/whatsappService';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setFormMeta(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // props deconstruction ------------------------------------------------------
  const {
    showTestModal,
    setTestShowModal,
    title,
    confirmLoading,
    setConfirmLoading,
    setFormMeta,
    setCurrentSession,
  } = props;

  const onClose = () => {
    form.resetFields();
    setConfirmLoading(false);
    setTestShowModal(false);
    setCurrentSession('');
  };

  return (
    <Modal
      title={
        <>
          <WhatsAppOutlined />
          <span className="ml-2">{title}</span>
        </>
      }
      destroyOnClose={true}
      onClose={onClose}
      onCancel={onClose}
      onOk={() => form.submit()}
      open={showTestModal}
      width={640}
      confirmLoading={confirmLoading}
      okText={translateX('send_message')}
      okButtonProps={{ type: 'primary' }}
      getContainer={false}
      className="modal-without-padding"
    >
      <Col xs={24}>
        <JarvislyPhone name="phone" label="for" />
        <JarvislyTextArea name="message" rows={5} />
      </Col>
    </Modal>
  );
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************

const TestWppModal = forwardRef((props, ref) => {
  // props deconstruction ------------------------------------------------------
  const { setTestShowModal, currentSession, setCurrentSession } = props;

  // component states ----------------------------------------------------------
  const [formMeta, setFormMeta] = useState({});
  const [confirmLoading, setConfirmLoading] = useState(false);

  // local variables ------------------------------------------------------------
  const title = translateX('send_test_wpp');

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    title,
    setFormMeta,
    confirmLoading,
    setConfirmLoading,
  };

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------

  //TODO
  const onConfirm = async ({ body }) => {
    setConfirmLoading(true);
    try {
      await whatsappService.sendWhatsAppMessage(
        currentSession,
        body.phone,
        body.message,
      );
      formMeta.resetFields();
      setTestShowModal(false);
      setCurrentSession('');
      setConfirmLoading(false);
    } catch (error) {
      console.log('Error sending message --> ', error);
      setConfirmLoading(false);
    }
    return null;
  };

  // UI COMPONENT --------------------------------------------------------------

  return (
    <JarvislyFormProvider
      name="whatsapp-form"
      requiredFields={['phone', 'message']}
      showSubmitButton={false}
      onConfirm={onConfirm}
      abortComponentSave={true}
    >
      <ModalComponent {...childProps} />
    </JarvislyFormProvider>
  );
});

// EXPORT **********************************************************************
// *****************************************************************************

export default TestWppModal;
