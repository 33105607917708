import { Card, Tabs } from 'antd';
import { returnOnlyNumbers, translateX } from 'utils/helpers';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  JarvislyFormProvider,
  JarvislyTabProvider,
} from 'components/jarvisly-components/others/FormComponents';
import CompaniesRecordIndex from './Record/RecordIndex';
import useUrl from 'components/jarvisly-components/hooks/urlHooks';
import JarvislyContactsIndex from 'components/jarvisly-components/forms/JarvislyContactsIndex';
import JarvislyContactsModal from 'components/jarvisly-components/forms/modals/JarvislyContactsModal';
import MultiOperatorsParametersIndex from './Parameters/ParametersIndex';
import appService from 'services/appService';
import JarvislyDocumentStatusModal from 'components/jarvisly-components/forms/modals/JarvislyDocumentStatusModal';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************

const AppmultiOperatorsForm = forwardRef((props, ref) => {
  // props deconstruction ------------------------------------------------------
  const { selectedModule, document, _id, setModuleFocus, mode } = props;

  // local variables I ---------------------------------------------------------
  const [searchParams] = useSearchParams();
  const url = useUrl();
  const initTab = selectedModule.documentState.initTab;
  const availableTabs = [initTab, 'contacts', 'parameters'];

  const contactModalRef = useRef(null);
  const parametersTabRef = useRef(null);
  const documentStatusModalRef = useRef(null);

  const contactsTabProps = {
    ...props,
    showContactModal: contactModalRef?.current?.showModal,
    setShowContactModal: contactModalRef?.current?.setShowModal,
    selectedRecord: contactModalRef?.current?.selectedRecord,
    setSelectedRecord: contactModalRef?.current?.setSelectedRecord,
  };

  // component states ----------------------------------------------------------
  const [tab, setTab] = useState(searchParams.get('tab') || initTab);

  // local variables II --------------------------------------------------------
  const tabItems = buildTabItens();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (!availableTabs.includes(tab)) setTab(initTab);
    url.updateUrlQueryParams({ tab }, true);
    setModuleFocus(prev => ({ ...prev, tab: initTab, field: 'name' }));
  }, [tab, _id]); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    documentStatusModalRef,
  }));

  // methods -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  const onSave = async ({ body }) => {
    if (!body) return;

    // decorate entity
    body.dataType = 'company';
    body.dataProfile = 'operator';
    body.uniqueKey = returnOnlyNumbers(body.cnpj);

    // PARAMETERS
    if (body?.parameters?.__exchangeRateSuffix) {
      const parameters = document?.parameters || {};
      const exchanges = parameters?.exchanges || [];
      const exchangeCurrency = body?.parameters?.__exchangeRateSuffix || 'brl';
      const exchangeValue = body?.parameters?.__exchangeRate || 1;
      const idx = exchanges.findIndex(e => e.currency === exchangeCurrency);

      if (idx === -1) {
        exchanges.push({
          currency: exchangeCurrency,
          value: exchangeValue,
        });
      } else {
        exchanges[idx].value = exchangeValue;
      }

      parameters.exchanges = exchanges.map(e => {
        e.default = e.currency === exchangeCurrency;
        return e;
      });
      body.parameters = parameters;
    }

    // ATTACHMENTS
    if(parametersTabRef?.current) {
      const attachments = parametersTabRef?.current?.attachments || [];
      body.attachmentIds = appService.parseAttachmentsState(attachments);
  
      // discovery conditionsFileUrl
      let attach = appService.getAttachmentUrlByTag('conditions', attachments);
      if (attach !== false) body.conditionsFileUrl = attach;
  
      // discovery pictureUrl
      attach = appService.getAttachmentUrlByTag('logo', attachments);
      if (attach !== false) body.pictureUrl = attach;
    }

    body.status = document?.status;

    return body;
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      {/* DOCUMENT STATUS MODAL WRAPPER */}
      <JarvislyDocumentStatusModal {...props} ref={documentStatusModalRef} />
      {/* DOCUMENT STATUS MODAL WRAPPER */}

      {/* CONTACTS MODAL WRAPPER */}
      <JarvislyContactsModal {...props} ref={contactModalRef} />
      {/* CONTACTS MODAL WRAPPER */}

      <JarvislyFormProvider
        name="form-operator"
        document={document}
        autoFocus="name"
        requiredFields={['name', 'cnpj', 'fullName', 'phone', 'zip', 'number']}
        showSubmitButton={tab !== 'contacts'}
        setTab={setTab}
        onSave={onSave}
      >
        <Tabs
          type="line"
          tabPosition="top"
          activeKey={tab}
          rootClassName="tab-in-form"
          items={tabItems.filter(x => !x.hide)}
          onTabClick={onTabClick}
        />
      </JarvislyFormProvider>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTabItens() {
    return [
      {
        label: translateX('record'),
        key: initTab,
        hide: false,
        children: (
          <JarvislyTabProvider tab={initTab}>
            <CompaniesRecordIndex {...props} activeTab={tab} />
          </JarvislyTabProvider>
        ),
      },

      {
        label: translateX('contacts'),
        key: 'contacts',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab="contacts">
            <Card className="cad-with-body-no-padding">
              <JarvislyContactsIndex {...contactsTabProps} activeTab={tab} />
            </Card>
          </JarvislyTabProvider>
        ),
      },

      {
        label: translateX('parameters'),
        key: 'parameters',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab="parameters">
            <MultiOperatorsParametersIndex
              {...props}
              activeTab={tab}
              ref={parametersTabRef}
            />
          </JarvislyTabProvider>
        ),
      },
    ];
  }
});

export default AppmultiOperatorsForm;
